/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {FormGroup, ValidationErrors, Validator} from '@angular/forms';
import {DateTime} from "luxon";
import {combineDateAndTime} from "./date-range-utils";
import {TranslationHelperService} from "pcs-commons/http";
import {TranslateService} from "@ngx-translate/core";

@Injectable({providedIn: 'root'})
export class DateRangeValidatorService implements Validator {

  constructor(private translateService: TranslateService,
              private translationHelperService: TranslationHelperService) {
  }

  public validate(formGroup: FormGroup): ValidationErrors | null {
    const input = this.addBeginningIfNecessary(formGroup.getRawValue());

    const dateKeys = Object.keys(input).filter(key => key.startsWith("validFromDate"));

    if (dateKeys.length === 0) {
      return null;
    }

    const unsortedDateTimeArray = dateKeys.map((key) => ({
      key,
      fromDateTime: combineDateAndTime(key, input, 0),
      untilDateTime: combineDateAndTime(key.replace('From', 'Until'), input, 999),
    }));
    const sortedArray = unsortedDateTimeArray.sort((a, b) => a.fromDateTime?.toMillis() - b.fromDateTime?.toMillis());

    const beginning = sortedArray[0].fromDateTime;
    if (beginning && beginning.toMillis() !== 0) {
      const message = this.translateService.instant("validation.input.invalidDateRange.firstFieldMustBeEmpty");
      return {invalidDateRange: {message}};
    }
    const end = sortedArray[sortedArray.length - 1].untilDateTime;
    if (end) {
      const message = this.translateService.instant("validation.input.invalidDateRange.validUntilNotEmpty");
      return {invalidDateRange: {message}};
    }

    for (let i = 1; i < sortedArray.length; i++) {
      const previousUntil = sortedArray[i - 1].untilDateTime;
      const currentFrom = sortedArray[i].fromDateTime;
      if (previousUntil && currentFrom) {
        if (currentFrom.toMillis() > previousUntil.toMillis() + 1) {
          const message = this.translateService.instant(
            "validation.input.invalidDateRange.gap", {
              param0: this.translationHelperService.translateDateTimeSafe(previousUntil),
              param1: this.translationHelperService.translateDateTimeSafe(currentFrom)
            });
          return {invalidDateRange: {message}};
        } else if (currentFrom.toMillis() < previousUntil.toMillis() + 1) {
          const message = this.translateService.instant(
            "validation.input.invalidDateRange.overlap", {
              param0: this.translationHelperService.translateDateTimeSafe(previousUntil),
              param1: this.translationHelperService.translateDateTimeSafe(currentFrom)
            });
          return {invalidDateRange: {message}};
        }
      } else {
        const message = this.translateService.instant("validation.input.invalidDateRange.fillEveryField");
        return {invalidDateRange: {message}};
      }
    }

    return null;
  }

  private addBeginningIfNecessary(input: any): any {
    if (input['validFromDate-0'] === undefined) {
      const beginOfEpoch = DateTime.fromMillis(0).setZone('utc');
      input = {...input, 'validFromDate-0': beginOfEpoch};
    }
    if (input['validFromTime-0'] === undefined) {
      input = {...input, 'validFromTime-0': '00:00:00'};
    }
    return input;
  }
}

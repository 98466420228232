/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Inject} from '@angular/core';
import {CDRService} from '../../services/http/cdr.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PermissionAware} from '../../permission-aware';
import {AccessRights} from '../../datatypes/access-rights.enum';
import {CodeModel} from "@ngstack/code-editor";

@Component({
  selector: 'pcs-cdr-clearing-entry-dialog',
  templateUrl: './cdr-clearing-entry-dialog.component.html',
  styleUrls: [
    '../../dialog/dialog-common-styles.css',
    './cdr-clearing-entry-dialog.component.css'
  ]
})
export class CdrClearingEntryDialogComponent implements PermissionAware {
  public readonly reqEditPermission = [AccessRights.CDR_EDIT_WEB];
  public clearingEntryDetails = '';
  public loading = false;
  public invalidJson = false;
  public clearable = false;
  public ignoreChargeValidation = false;

  private editorCodeModelUri = 'cdr-clearing.ts';
  private editorCodeModelLanguage = 'json';
  public codeModel: CodeModel = null;

  constructor(
    private cdrService: CDRService,
    private dialogRef: MatDialogRef<CdrClearingEntryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('entry details: ', data.clearingEntry);
    this.clearingEntryDetails = JSON.stringify(data.clearingEntry, null, '\t');
    this.codeModel = {
      language: this.editorCodeModelLanguage,
      uri: this.editorCodeModelUri,
      value: this.clearingEntryDetails
    }
    this.clearable = data.clearable;
  }

  public verifyJson(): void {
    try {
      JSON.parse(this.clearingEntryDetails);
      this.invalidJson = false;
    } catch (e) {
      console.log(e);
      this.invalidJson = true;
    }
  }

  public retryClearing(): void {
    this.loading = true;
    console.log('retrying customer billing...');
    this.cdrService.retryCdrProcessing(this.data.id, this.clearingEntryDetails, this.ignoreChargeValidation).subscribe({
      next: () => {
        console.log('successfully billed the cdr to customer');
        this.loading = false;
        this.dialogRef.close(true);
      },
      error: () => this.loading = false
    });
  }

  public onEditorValueChange(newValue: string): void {
    this.clearingEntryDetails = newValue;
    this.verifyJson();
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import { Injectable } from '@angular/core';

import { TaxRecord } from '../../datatypes/taxrecord';
import { TaxData } from '../../datatypes/taxdata';

import { environment } from '../../../environments/environment';
import { HeaderEnabled } from './header-enabled.abstract';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TaxService extends HeaderEnabled {

  constructor(private http: HttpClient) {
    super();
  }

  getTaxRecords(): Observable<TaxData> {
    return this.http.get<TaxData>(
      environment.taxRateUrl,
      { headers: this.headers }
    );
  }

  updateTaxRecords(taxRecords: TaxRecord[]): Observable<TaxData> {
    return this.http
      .post<TaxData>(environment.taxRateUrl,
        taxRecords,
        { headers: this.headers }
      );
  }
}


/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Directive, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {SharedDataService} from '../services/shared-data.service';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class PriceColumnPipeBase implements OnDestroy {
  toUnsubscribe: Subscription[] = [];
  protected taxData = null;

  constructor(private dataSource: SharedDataService) {
    this.toUnsubscribe.push(this.dataSource.currentTaxData$.subscribe(newTaxData => this.taxData = newTaxData));
  }

  ngOnDestroy(): void {
    this.toUnsubscribe.forEach(sub => sub.unsubscribe());
  }
}

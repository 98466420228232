/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {ChargepointVisibility} from '../../chargepoint-visibility.enum';

export class ChargepointOverviewAdminDto {
  public static readonly EVSEID = 'evseId';
  public static readonly CPO_NAME = 'cpoName';
  public static readonly COUNTRY_CODE = 'countryCode';
  public static readonly ZIP = 'zip';
  public static readonly CITY = 'city';
  public static readonly MANUAL_CHANGES = 'manualChanges';
  public static readonly VISIBILITY = 'visibility';
  public static readonly RECEIVED_UPDATE = 'receivedUpdateFromIop';
  public static readonly STREET = 'street';
  public static readonly LOCATION_GROUP_ID = 'locationGroupId';
  public static readonly AVAILABILITY = 'availability';
  public static readonly LAST_USED = 'lastUsed';
  public static readonly LATITUDE = 'latitude';
  public static readonly LONGITUDE = 'longitude';

  public static readonly dataColumns: string[] = [
    ChargepointOverviewAdminDto.MANUAL_CHANGES,
    ChargepointOverviewAdminDto.EVSEID,
    ChargepointOverviewAdminDto.CPO_NAME,
    ChargepointOverviewAdminDto.COUNTRY_CODE,
    ChargepointOverviewAdminDto.ZIP,
    ChargepointOverviewAdminDto.CITY,
    ChargepointOverviewAdminDto.STREET,
    ChargepointOverviewAdminDto.VISIBILITY
  ];
  public static readonly dataColumnsUnusedCP: string[] = [
    ChargepointOverviewAdminDto.MANUAL_CHANGES,
    ChargepointOverviewAdminDto.EVSEID,
    ChargepointOverviewAdminDto.CPO_NAME,
    ChargepointOverviewAdminDto.COUNTRY_CODE,
    ChargepointOverviewAdminDto.ZIP,
    ChargepointOverviewAdminDto.CITY,
    ChargepointOverviewAdminDto.STREET,
    ChargepointOverviewAdminDto.VISIBILITY,
    ChargepointOverviewAdminDto.LAST_USED,
    ChargepointOverviewAdminDto.AVAILABILITY
  ];
  public id: number;
  public chargepointGroupId: number;
  public locationGroupId: number;
  public evseId: string;
  public cpoName: string;
  public countryCode: string;
  public zip: string;
  public city: string;
  public street: string;
  public houseNo: string;
  public visibility: ChargepointVisibility;
  public receivedUpdateFromIop: boolean;
  public manualChanges: boolean;
  public lastUsed: string;
  public availability: string;
  public latitude: string;
  public longitude: string;
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div class="mat-elevation-z1" id="query-history">
  <mat-toolbar class="headerToolbar">
    <span class="headerSpan">{{ 'query-history.header' | translate }}</span>
    <pcs-loading-spinner [component]="this"></pcs-loading-spinner>
  </mat-toolbar>
  <pcs-query-history-table
    [data]="observableOfHistoryEntries"
    [dataColumns]="queryHistoryColumns"
    [i18nBase]="'query-history'"
    (filterEvent)="onFilterChange($event)"
  >
  </pcs-query-history-table>
  <mat-paginator [pageSizeOptions]="pageSizeOptions" (page)="handlePageChange($event)"
                 [length]="resultLength"></mat-paginator>
</div>

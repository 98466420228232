/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';
import {LoginService} from 'pcs-commons/http';
import {Notification, NotificationType} from 'pcs-commons/datatypes';
import {Parameters} from 'pcs-commons/global';
import {NotificationStore} from './notification-store';
import {NotificationSnackBarComponent} from './notification-snack-bar/notification-snack-bar.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService implements OnDestroy {
  private toUnsub: Subscription;
  private notificationSnackBarRef: MatSnackBarRef<any>;
  private snackBarSub: Subscription;

  constructor(
    private translate: TranslateService,
    private loginService: LoginService,
    private snackBar: MatSnackBar) {
    this.toUnsub = NotificationStore.instance.notification.subscribe((newNotification) => {
      if (!newNotification) {
        this.dismiss();
      } else {
        this.notify(newNotification);
      }
    });
  }

  public notifyInvalidAuth(): void {
    const config = new MatSnackBarConfig();
    config.duration = undefined; // we dont want this message to disappear, rather the user has to click 'to Login'
    const snackBarRef = this.snackBar.open(
      this.translate.instant('message.error.authorizationInvalid'),
      this.translate.instant('button.toLogin'),
      config);
    // on 'to Login', click go to login page
    snackBarRef.onAction().subscribe(() => this.loginService.toCognitoLogin());
  }

  public notifyPasswordChange(): void {
    const config = new MatSnackBarConfig();
    config.duration = undefined; // we dont want this message to disappear
    const snackBarRef = this.snackBar.open(
      this.translate.instant('message.passwordChange'),
      this.translate.instant('button.reLogin'),
      config);
    // on 'reLogin', click go to login page
    snackBarRef.onAction().subscribe(() => this.loginService.toCognitoLogin());
  }

  private dismiss(): void {
    if (this.notificationSnackBarRef) {
      console.log('dismissing notification snackbar..');
      this.notificationSnackBarRef.dismiss();
      this.cleanup();
    }
  }

  private cleanup(): void {
    console.log('cleaning up..');
    this.notificationSnackBarRef = null;
    this.snackBarSub?.unsubscribe();
  }

  private notify(newNotification: Notification): void {
    this.dismiss();
    const duration = (NotificationType.INFO === newNotification.type || NotificationType.WARN === newNotification.type)
      ? Parameters.MSG_TIMEOUT_MILLIS : undefined;
    console.log('New notification! Duration: ', duration);

    this.notificationSnackBarRef = this.snackBar.openFromComponent(
      NotificationSnackBarComponent,
      {
        duration,
        data: newNotification,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: 'notification-snackbar-panel'
      });

    this.snackBarSub = this.notificationSnackBarRef.afterDismissed().subscribe(
      () => {
        this.cleanup();
      }
    );
  }

  public snackPasswordCopied(): void {
    this.snackBar.open(this.translate.instant('fleet.contract.message.copiedPassword'));
  }

  public ngOnDestroy(): void {
    this.toUnsub?.unsubscribe();
    this.snackBarSub?.unsubscribe();
  }
}

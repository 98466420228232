<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content>
  <h2 mat-dialog-title>
    {{'user.changePassword.header'|translate}}
  </h2>
  <div class="content-div">
    <form [formGroup]="passChangeForm">
      <mat-form-field appearance="outline">
        <mat-label>{{'user.changePassword.currentPassword' | translate}}</mat-label>
        <input matInput id="currentPassword" [type]="hideCurrPassword? 'password': 'text'"
               formControlName="currentPassword" required>
        <mat-icon matSuffix (click)="hideCurrPassword = !hideCurrPassword">
          {{hideCurrPassword ? 'visibility' : 'visibility_off'}}
        </mat-icon>
      </mat-form-field>
      <form [formGroup]="newPasswordFrom">
        <mat-form-field appearance="outline">
          <mat-label>{{'user.changePassword.newPassword' | translate}}</mat-label>
          <input matInput id="newPassword" [type]="hideNewPassword? 'password': 'text'"
                 formControlName="newPassword" required>
          <mat-icon matSuffix (click)="hideNewPassword = !hideNewPassword">
            {{hideNewPassword ? 'visibility' : 'visibility_off'}}
          </mat-icon>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'user.changePassword.confirmNewPassword' | translate}}</mat-label>
          <input matInput id="confirmNewPassword" [type]="hideConfirmNewPassword? 'password': 'text'"
                 formControlName="confirmNewPassword"
                 required [errorStateMatcher]="crossFieldErrorMatcher">
          <mat-icon matSuffix (click)="hideConfirmNewPassword = !hideConfirmNewPassword">
            {{hideConfirmNewPassword ? 'visibility' : 'visibility_off'}}
          </mat-icon>
          <mat-error *ngIf="confirmNewPassword.invalid || newPasswordFrom.errors">
            {{newPasswordFrom | validationMessage | async}}
          </mat-error>
        </mat-form-field>
      </form>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button id="actionBtn" class="dialogueButton"
          (click)="requestPasswordChange()" [disabled]="passChangeForm.invalid || newPasswordFrom.invalid">
    {{ 'button.save' | translate}}
  </button>
  <button mat-stroked-button id="cancelBtn" class="dialogueButton" [mat-dialog-close]="false">
    {{'button.cancel' | translate}}
  </button>
</mat-dialog-actions>

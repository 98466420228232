/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { ErrorRecord } from '../errorrecord';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pcs-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  public errormessage: string;
  public errorcode: string;
  public errorI18n: ErrorRecord;

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService
  ) {
    const userLanguage = navigator.language.substring(0, 2);
    translate.setDefaultLang(userLanguage);
    translate.use(userLanguage);
  }

  public ngOnInit(): void {
    // we have excluded handling of error code 401, because it should be handled in AbstractNotificationHandler
    this.errorcode = this.route.snapshot.paramMap.get('errorcode');
    this.errorI18n = new ErrorRecord();
    if (this.errorcode === '0' || this.errorcode?.startsWith('5')) {
      this.errorI18n.i18nError = 'ErrorCompNotAvailable';
    } else if (this.errorcode === '403') {
      this.errorI18n.i18nError = 'message.error.forbidden';
    } else if (this.errorcode === '404') {
      this.errorI18n.i18nError = 'ErrorCompURLnotExists';
    } else if (this.errorcode === '413') {
      this.errorI18n.i18nError = 'ErrorPayloadTooLarge';
    } else {
      this.errorI18n.i18nError = 'ErrorCompUnexpected';
    }
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {HeaderEnabled} from './header-enabled.abstract';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {CurrencyFactor} from '../../datatypes/currency-factor';

@Injectable({
  providedIn: 'root'
})
export class CurrencyFactorService extends HeaderEnabled {

  constructor(private http: HttpClient) {
    super();
  }

  public retrieveCurrencyFactors(currency: string, offset, pageSize): Observable<CurrencyFactor[]> {
    return this.http.get<CurrencyFactor[]>(environment.currencyFactorUrl, {
      headers: this.headers,
      params: {
        currency: currency,
        offset: offset,
        pageSize: pageSize
      }
    });
  }
  
  update(currencyFactorsToUpdate: CurrencyFactor[]): Observable<CurrencyFactor> {
    const url = environment.currencyFactorUrl + '/' + currencyFactorsToUpdate[0].currency;
    return this.http.put<CurrencyFactor>(url, currencyFactorsToUpdate, {headers: this.headers});
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
export enum AuthenticationMode {
  NFC_RFID_CLASSIC = 'NFC_RFID_CLASSIC',
  NFC_RFID_DES_FIRE = 'NFC_RFID_DES_FIRE',
  PLUG_AND_CHARGE = 'PN_C',
  REMOTE = 'REMOTE'
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {AbstractFilterFormComponent} from '../abstract-filter-form-component';
import {ChargepointFilterEventService} from '../../../services/chargepoint-filter-event.service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {ValidatorList, ValidationMessagePipe} from 'pcs-commons/validation';
import {Parameters} from '../../../global/parameters';
import {CountryCode} from 'pcs-commons/datatypes';
import {UnusedChargepointFilter} from '../../../datatypes/unused-chargepoint-filter';
import {CountryUtil} from '../../../utils/country-util';
import {FormHelper} from '../../../utils/form-helper';
import {Observable} from 'rxjs';

@Component({
  selector: 'unused-chargepoint-filter-form',
  templateUrl: './unused-chargepoint-filter-form.component.html',
  styleUrls: ['./unused-chargepoint-filter-form.component.css']
})
export class UnusedChargepointFilterFormComponent extends AbstractFilterFormComponent implements OnInit {

  private readonly COUNTRY = 'country';
  private readonly CPO_NAME = 'cpoName';
  private readonly NOT_USED_IN_DAYS = 'notUsedInDays';

  public cpoName: FormControl;
  public country: FormControl;
  public notUsedInDays: FormControl;
  public countryList: any[] = [];

  constructor(
    private filterService: ChargepointFilterEventService,
    private formBuilder: FormBuilder,
    private validationPipe: ValidationMessagePipe) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.countryList.push(...Object.values(CountryCode));
  }

  public triggerDisplayChargepoints(): void {
    console.log(this.filterFormGroup);
    if (this.filterFormGroup.invalid) {
      console.log('the inputs contain error. skipping filter event');
      return;
    }
    const filter = new UnusedChargepointFilter();
    this.fillFilterData(filter);
    console.log('emitting unused chargepoint filter event with filter: ', filter);
    this.filterService.triggerUnusedChargepointFilterEvent(filter);
  }

  public defineFromControls(): void {
    this.cpoName = new FormControl('', ValidatorList.STRING_DEFAULT);
    this.country = new FormControl('', ValidatorList.ALPHA2_COUNTRY);
    this.notUsedInDays = new FormControl(1, [Validators.min(1), Validators.required]);
    this.filterFormGroup = this.formBuilder.group({
      cpoName: this.cpoName,
      country: this.country,
      notUsedInDays: this.notUsedInDays
    });
  }

  get defaultMaxLength(): number {
    return Parameters.DEFAULT_MAX_LENGTH;
  }

  private fillFilterData(filter: UnusedChargepointFilter): void {
    filter.cpoName = FormHelper.getStringValue(this.filterFormGroup, this.CPO_NAME);
    filter.country = CountryUtil.toCountryCodeName(FormHelper.getValue(this.filterFormGroup, this.COUNTRY));
    const numberValue = FormHelper.getValue(this.filterFormGroup, this.NOT_USED_IN_DAYS);
    filter.notUsedInDays = numberValue ? numberValue as number : null;
  }

  public getError(fc: FormControl): Observable<any> {
    return this.validationPipe.transform(fc);
  }
}

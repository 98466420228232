/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, Input, OnChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxObjectDiffService } from '../../object-diff/ngx-object-diff.service';

@Component({
  selector: 'pcs-json-diff',
  templateUrl: './json-diff.component.html',
  styleUrls: ['./json-diff.component.css']
})
export class JsonDiffComponent implements OnChanges {
  @Input()
  public before: string | undefined;

  @Input()
  public after: string | undefined;

  public diffView: unknown;
  public diffValueChanges: unknown;
  public toggle = new FormControl<boolean>(false);

  constructor(private objectDiff: NgxObjectDiffService) {}

  public ngOnChanges(): void {
    // you can directly diff your objects js now or parse a Json to object and diff
    const diff = this.objectDiff.diff(JSON.parse(this.before ?? '{}'), JSON.parse(this.after ?? '{}'));
    // you can directly diff your objects including prototype properties and inherited properties using `diff` method
    // let diffAll = ObjectDiff.diff($scope.yourObjectOne, $scope.yourObjectTwo);

    // gives a full object view with Diff highlighted
    this.diffView = this.objectDiff.toJsonView(diff);
    // gives object view with only Diff highlighted
    this.diffValueChanges = this.objectDiff.toJsonDiffView(diff);
  }
}

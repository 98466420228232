<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content editKeyEvents class="edit-basic-fee-dialog">
  <h2 mat-dialog-title>
      {{'product-detail.title.basicFee'|translate}}
    <button mat-raised-button class="icon-only headerActionBtn add-button" (click)="addNewBasicFee()">
      <mat-icon> add</mat-icon>
    </button>
    <mat-spinner [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
  </h2>
  <div>
    <form class="dialog-form" [formGroup]="basicFeeInfoForm" *ngIf="basicFeeInfoForm">
      <autocomplete-string-select *ngIf="this.countries.length>0"
                                  [inputControl]="country" class="autocomplete-select"
                                  [label]="'product-detail.label.country'"
                                  [optionList]="countries" [optionI18nBase]="'global.country.'" required>
      </autocomplete-string-select>
      <mat-form-field appearance="outline">
        <mat-label>{{'product-detail.label.currency' | translate}}</mat-label>
        <mat-select required formControlName="currency">
          <mat-option *ngFor="let currency of currencies"
                      [value]="currency">{{currency}}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>
    <mat-toolbar class="error error-toolbar" *ngIf="basicFeeParentForm.invalid && basicFeeParentForm.errors">
      <span class="error-span">{{basicFeeParentForm | validationMessage | async}}</span>
    </mat-toolbar>
    <edit-basic-fee-table *ngIf="currentBasicFeeForms.getValue().length > 0"
                          [dataColumns]="basicFeeEditorTableColumns"
                          [data]="currentBasicFeeForms$"
                          [actionButtons]="true"
                          [basicFeeForm]="basicFeeParentForm"
                          [i18nBase]="'product-detail.label'"
                          (deleteEvent)="onRemoveBasicFee($event)">
    </edit-basic-fee-table>
    <mat-dialog-actions>
      <button okAction mat-raised-button class="dialogueButton dialogueButtonRight"
              [disabled]="!isSaveAllowed()"
              (click)="onSave()">
        {{'button.save' | translate}}
      </button>
      <button cancelAction mat-raised-button class="dialogueButton"
              (click)="onCancel()">
        {{'button.cancel' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</mat-dialog-content>

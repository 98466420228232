/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderEnabled } from './header-enabled.abstract';
import { Observable } from 'rxjs';
import { Parameters } from '../../global/parameters';
import { QueryHistoryEntryDto } from '../../datatypes/query-history/query-history-entry-dto';
import { HistoryFilter } from '../../datatypes/query-history/query-history-filter';

@Injectable({
  providedIn: 'root'
})
export class QueryHistoryService extends HeaderEnabled {
  constructor(private http: HttpClient) {
    super();
    this.headers = this.headers.append(Parameters.HEADER_ERROR_MSG_BASE, 'query-history.error.');
  }

  public retrieveQueryHistory(
    lastHistoryEntryUserId: string,
    lastHistoryEntryTimestamp: string,
    filter: HistoryFilter,
    pageSize: number
  ): Observable<QueryHistoryEntryDto[]> {
    const options = {
      headers: this.headers,
      params: {
        pageSize,
        lastHistoryEntryTimestamp,
        lastHistoryEntryUserId,
        userId: filter.userId || '',
        userLogin: filter.userLogin || '',
        fromDate: filter.fromDate || '',
        toDate: filter.toDate || '',
        query: filter.query || '',
        state: filter.state || '',
        database: filter.database || ''
      }
    };
    return this.http.get<QueryHistoryEntryDto[]>(environment.queryHistoryEndpoint, options);
  }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data && filterForm" class="scrollable-table-container">
  <form [formGroup]="filterForm">
    <mat-table [dataSource]="tableDataSource" [matSortActive]="nameCol" class="padding-lr-24" matSort
               matSortDirection="desc">
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container *ngIf="!downloadColumns.includes(col)"
                      matColumnDef="{{col}}">
          <mat-header-cell *matHeaderCellDef [mat-sort-header]="col">
            <div class="filter-header-cell">
              <div class="header-name">
                {{ getI18nBase(col) | translate }}
              </div>
              <mat-form-field class="header-filter">
                <input matInput class="header-filter" [formControlName]="col"
                       (click)="$event.stopPropagation()"
                       (keydown.space)="$event.stopPropagation()">
              </mat-form-field>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div *ngIf="col!==invoiceDateTimeCol">{{ element[col] }}</div>
            <div *ngIf="col===invoiceDateTimeCol">{{ element[col] | translateDateFromDateTime }}</div>
          </mat-cell>
        </ng-container>
      </div>
      <ng-container matColumnDef="{{downloadInvoice}}" *ngIf="downloadButtons">
        <mat-header-cell *matHeaderCellDef
                         class="actionCell"> {{ 'fleet.tableheader.invoices.download' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="actionCell">
          <div
            [matTooltip]="(element[syncStatusProp] === 'INITIAL'? 'fleet.invoices.download.disabled.tooltip': 'fleet.invoices.download.tooltip') | translate">
            <button mat-mini-fab class="edit mat-elevation-z0" (mouseenter)="changeStyle($event)"
                    (mouseleave)="changeStyle($event)" (click)="onDownloadInvoice(element)"
                    [disabled]="element[syncStatusProp] === 'INITIAL'">
              <mat-icon>file_download</mat-icon>
            </button>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{downloadBilleditemsFE}}" *ngIf="downloadButtons">
        <mat-header-cell *matHeaderCellDef
                         class="actionCell"> {{ 'fleet.tableheader.invoices.billeditem.download' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="actionCell">
          <div
            [matTooltip]="(invalidInvoice(element)? 'fleet.invoices.billeditem.download.disabled.tooltip': 'fleet.invoices.billeditem.download.tooltip') | translate">
            <button mat-mini-fab class="edit mat-elevation-z0" (mouseenter)="changeStyle($event)"
                    (mouseleave)="changeStyle($event)" (click)="onDownloadBilledItems(element)"
                    [disabled]="invalidInvoice(element)">
              <mat-icon>file_download</mat-icon>
            </button>
          </div>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="{{downloadBilledItemsPCS}}" *ngIf="downloadButtons">
        <mat-header-cell *matHeaderCellDef
                         class="actionCell"> {{ 'fleet.tableheader.invoices.billeditem.pcs.download' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="actionCell">
          <div
            [matTooltip]="(invalidInvoice(element)? 'fleet.invoices.billeditem.download.disabled.tooltip': 'fleet.invoices.billeditem.pcs.download.tooltip') | translate">
            <button mat-mini-fab class="edit mat-elevation-z0" (mouseenter)="changeStyle($event)"
                    (mouseleave)="changeStyle($event)" (click)="onDownloadBilledItemsPCS(element)"
                    [disabled]="invalidInvoice(element)">
              <mat-icon>file_download</mat-icon>
            </button>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </form>
</div>
<mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>

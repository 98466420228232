/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import {FormControl} from "@angular/forms";

export interface Plan {
  id: number;
  partner: string;
  partnerPlanId: string;
  inclusivePlan: boolean
}

export enum PlanColumns {
  PARTNER = "partner",
  PARTNER_PLAN_ID = "partnerPlanId",
  INCLUSIVE_PLAN = "inclusivePlan"
}

export type PlanFormGroupType = {
  partner: FormControl<string | undefined>;
  partnerPlanId: FormControl<string | undefined>;
  inclusivePlan: FormControl<boolean | false>;
};

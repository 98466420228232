/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {FinanceEnablerStatus} from './finance-enabler-status';
import {Identifiable} from 'pcs-commons/datatypes';

export class CDRBillingDto extends Identifiable {
  public financeEnablerStatus: FinanceEnablerStatus;
  public sessionId: string;
  public sessionStart: string;
  public sessionEnd: string;
  public contractId: string;
  public price: string;
  public currency: string;
  public comment: string;
}

export enum CDRBillingColumns {
  CONTRACT_ID = 'contractId',
  SESSION_ID = 'sessionId',
  SESSION_START = 'sessionStart',
  SESSION_END = 'sessionEnd',
  FINANCE_ENABLER_STATUS = 'financeEnablerStatus',
  PRICE = 'price',
  COMMENT = 'comment'
}

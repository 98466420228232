/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';

import {Product} from '../datatypes/product';
import {ProductService} from '../services/http/product.service';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {Action} from '../datatypes/action.enum';
import {WebConfigRouterService} from '../services/web-config-router.service';
import {UserDataSourceService} from '../services/user-data-source.service';
import {Role} from '../datatypes/role.enum';
import {BaseUrls} from '../datatypes/base-urls.enum';
import {PermissionAware} from '../permission-aware';
import {AccessRights} from '../datatypes/access-rights.enum';
import {ChangeRequestPayloadType} from "../datatypes/inbox/change-request-payload-type";
import {ChangeRequestService} from "../services/http/audit/change-request.service";

@Component({
  selector: 'pcs-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent extends AbstractNotificationHandler implements OnInit, PermissionAware {
  public readonly reqEditPermission: AccessRights[] = [AccessRights.PRODUCT_EDIT_WEB, AccessRights.FLEET_EDIT_WEB];
  private productsSource = new BehaviorSubject<Product[]>([]);
  public products$ = this.productsSource.asObservable();
  public productTableColumns = Product.dataColumns;
  public loading = false;

  constructor(
    private router: WebConfigRouterService,
    private userDataSource: UserDataSourceService,
    private productService: ProductService,
    private changeRequestService: ChangeRequestService) {
    super();
  }

  public async getProducts(): Promise<void> {
    this.loading = true;
    try {
      const products = await this.productService.getProducts();
      await this.checkForChangeRequests(products);
      this.productsSource.next(products);
    } finally {
      this.loading = false;
    }
  }

  private async checkForChangeRequests(products: Product[]): Promise<void> {
    const productsWithActiveChangeRequest = await firstValueFrom(this.changeRequestService.findAllActiveChangeRequestsByPayloadType(ChangeRequestPayloadType.PRODUCT));
    if (productsWithActiveChangeRequest.length < 1) {
      return;
    }
    products.forEach(product => {
      product.hasActiveChangeRequest = productsWithActiveChangeRequest.includes(product.id);
    })
  }

  public async ngOnInit(): Promise<void> {
    await this.getProducts();
  }

  public toEditProduct(toEdit: Product): void {
    this.router.navigate([BaseUrls.PRODUCT_DETAIL, toEdit.code], {queryParams: {mode: Action.EDIT}});
  }

  public toProductDetails(product: Product): void {
    this.router.navigate([BaseUrls.PRODUCT_DETAIL, product.code], {queryParams: {mode: Action.READ}});
  }

  public createNewProduct(): void {
    this.router.navigate([BaseUrls.PRODUCT_DETAIL], {queryParams: {mode: Action.CREATE}});
  }

  public isAdminUser(): boolean {
    return Role.PCS_ADMIN === this.userDataSource.currentUserRole;
  }
}

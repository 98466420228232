<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-sidenav-container class="side-nav-container" autosize>
  <mat-sidenav #sidenav [permanentAt]="1024" class="side-nav" mode="side" opened fixedInViewport [fixedTopGap]="10"
               [fixedBottomGap]="10">
    <div class="logo-bar">
      <div class="logo" (click)="goHome()"></div>
    </div>
    <div class="language-and-user">
      <pcs-language-and-user-bar></pcs-language-and-user-bar>
    </div>
    <div class="menu">
      <mat-nav-list *ngIf="menuList">
        <mat-list-item *ngFor="let menu of menuList">
          <menu-item [menu]="menu" class="menu-item"></menu-item>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="side-nav-content">
    <div class="body-margin">
      <ng-template [hideAt]="1024">
        <mat-toolbar class="responsive-header mat-elevation-z1">
          <button mat-icon-button class="side-nav-menu-button" (click)="sidenav.toggle()">
            <mat-icon>menu</mat-icon>
          </button>
        </mat-toolbar>
      </ng-template>
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

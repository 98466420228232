/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
export enum ServiceErrors {
  PRODUCT_NOT_VALID_FOR_COUNTRY = 'PRODUCT_NOT_VALID_FOR_COUNTRY',
  VIN_ALREADY_ASSIGNED_TO_OTHER_CONTRACT = 'VIN_ALREADY_ASSIGNED_TO_OTHER_CONTRACT',
  INVALID_DATE_FORMAT = 'INVALID_DATE_FORMAT',
  FLEET_INACTIVE = 'FLEET_INACTIVE',
  CONTRACT_INTERSECTION = 'CONTRACT_INTERSECTION',
  CONTRACT_INTERSECTION_TODAY = 'CONTRACT_INTERSECTION_TODAY',
  CARD_INVENTORY_DEPLETED = 'CARD_INVENTORY_DEPLETED',
  NOT_UNIQUE = 'NOT_UNIQUE',
  INVALID_PRODUCT_STATE = 'INVALID_PRODUCT_STATE',
  USER_UNKNOWN_TO_COGNITO = 'USER_UNKNOWN_TO_COGNITO',
  CANNOT_DELETE_LAST_USER = 'CANNOT_DELETE_LAST_USER',
  CDR_ALREADY_PROCESSED = 'CDR_ALREADY_PROCESSED',
  CDR_AND_SESSION_CHARGEPOINT_MISMATCH = 'CDR_AND_SESSION_CHARGEPOINT_MISMATCH',
  CDR_CLEARING_RETRY_PLATFORM_FAILED = 'CDR_CLEARING_RETRY_PLATFORM_FAILED',
  CDR_CONTRACT_MISMATCH = 'CDR_CONTRACT_MISMATCH',
  CDR_CONTRACT_NOT_FOUND = 'CDR_CONTRACT_NOT_FOUND',
  CDR_CPO_PLATFORM_MISMATCH = 'CDR_CPO_PLATFORM_MISMATCH',
  CDR_FAILED_RFID_IDENTIFICATION = 'CDR_FAILED_RFID_IDENTIFICATION',
  CDR_GENERIC_ERROR = 'CDR_GENERIC_ERROR',
  CDR_MAX_PRICE_REACHED = 'CDR_MAX_PRICE_REACHED',
  CDR_PERSISTING_FAILED = 'CDR_PERSISTING_FAILED',
  CHARGEPOINTS_NOT_FOUND_FOR_OPERATOR = 'CHARGEPOINTS_NOT_FOUND_FOR_OPERATOR',
  CHARGING_SESSION_INVALID = 'CHARGING_SESSION_INVALID',
  CHARGING_SESSION_INVALID_BY_SESSION_ID = 'CHARGING_SESSION_INVALID_BY_SESSION_ID',
  COULD_NOT_CREATE_NEW_CHARGINGCARD = 'COULD_NOT_CREATE_NEW_CHARGINGCARD',
  CURRENCY_FACTOR_AFTER_VALID_FROM = 'CURRENCY_FACTOR_AFTER_VALID_FROM',
  DUPLICATE_COLUMN_IDENTIFIER = 'DUPLICATE_COLUMN_IDENTIFIER',
  EMPTY_EXCEL_FILE = 'EMPTY_EXCEL_FILE',
  ENTITY_EXISTS = 'ENTITY_EXISTS',
  ENTITY_TOO_LARGE = 'ENTITY_TOO_LARGE',
  EVSEID_INVALID = 'EVSEID_INVALID',
  EXCEL_HEADER_MISSING = 'EXCEL_HEADER_MISSING',
  GROUP_NOT_EMPTY = 'GROUP_NOT_EMPTY',
  HUBJECT_FAILED_QR_CODE_IDENTIFICATION = 'HUBJECT_FAILED_QR_CODE_IDENTIFICATION',
  HUBJECT_NO_IDENTIFICATION = 'HUBJECT_NO_IDENTIFICATION',
  INVALID_INPUT_FORMAT = 'INVALID_INPUT_FORMAT',
  INVALID_PARAMETER = 'INVALID_PARAMETER',
  INVALID_PARAMETER_COMBINATION = 'INVALID_PARAMETER_COMBINATION',
  INVALID_QUERY = 'INVALID_QUERY',
  INVALID_STATE = 'INVALID_STATE',
  MANDATORY_PARAMETER_EMPTY = 'MANDATORY_PARAMETER_EMPTY',
  MANDATORY_PARAMETER_NOT_SET = 'MANDATORY_PARAMETER_NOT_SET',
  MANDATORY_PARAMETER_NOT_SET_OR_EMPTY = 'MANDATORY_PARAMETER_NOT_SET_OR_EMPTY',
  MAX_LENGTH = 'MAX_LENGTH',
  NAME_ALREADY_EXISTS = 'NAME_ALREADY_EXISTS',
  NONPRINTABLE_CHARACTER_NOT_ALLOWED = 'NONPRINTABLE_CHARACTER_NOT_ALLOWED',
  NOT_SEQUENTIAL = 'NOT_SEQUENTIAL',
  PARAMETER_MAX_LENGTH = 'PARAMETER_MAX_LENGTH',
  PARAMETER_MIN_LENGTH = 'PARAMETER_MIN_LENGTH',
  PARTNER_ALREADY_REGISTERED = 'PARTNER_ALREADY_REGISTERED',
  PARTNER_API_MANDATORY_MODULE_NOT_PRESENT = 'PARTNER_API_MANDATORY_MODULE_NOT_PRESENT',
  PARTNER_API_VERSION_INCOMPATIBLE = 'PARTNER_API_VERSION_INCOMPATIBLE',
  PASSWORD_VIOLATES_POLICY = 'PASSWORD_VIOLATES_POLICY',
  PURCHASE_CONDITION_OVERLAP = 'PURCHASE_CONDITION_OVERLAP',
  QUOTA_DEPLETED = 'QUOTA_DEPLETED',
  REFERENCED_OBJECT_NOT_FOUND = 'REFERENCED_OBJECT_NOT_FOUND',
  SMARTLAB_CONFIRMATION_FAILED = 'SMARTLAB_CONFIRMATION_FAILED',
  SMARTLAB_UNKNOWN_TOKEN_TYPE = 'SMARTLAB_UNKNOWN_TOKEN_TYPE',
  SQL_GRAMMAR = 'SQL_GRAMMAR',
  USER_LIMIT_EXCEEDED = 'USER_LIMIT_EXCEEDED',
  VERIFY_TOTP_USER_CODE_FAILED = 'VERIFY_TOTP_USER_CODE_FAILED',
  REIMBURSEMENT_REQUEST_EXIST = 'REIMBURSEMENT_REQUEST_EXIST',
  INCORRECT_PASSWORD = 'INCORRECT_PASSWORD',
  SERVICE_ID_ALREADY_USED = 'SERVICE_ID_ALREADY_USED',
  CDR_ALREADY_EXISTS_IN_CLEARING_POOL = 'CDR_ALREADY_EXISTS_IN_CLEARING_POOL',
  UPDATE_ADDRESS_AT_FE_FAILED = 'UPDATE_ADDRESS_AT_FE_FAILED',
  CAN_NOT_REMOVE_CDR_FROM_BILLRUN = 'CAN_NOT_REMOVE_CDR_FROM_BILLRUN',
  COULD_NOT_CALL_SERVICE = 'COULD_NOT_CALL_SERVICE',
  UPDATE_RECIPIENT_AT_FE_FAILED = 'UPDATE_RECIPIENT_AT_FE_FAILED',
  PORSCHE_ID_ACCOUNTS_RETRIEVAL_ERROR = 'PORSCHE_ID_ACCOUNTS_RETRIEVAL_ERROR',
  PORSCHE_ID_ACCOUNTS_PROCESSING_ERROR = 'PORSCHE_ID_ACCOUNTS_PROCESSING_ERROR',
  INVOICE_INVALID_FOR_BILLED_ITEMS_CSV_RETRIEVAL = 'INVOICE_INVALID_FOR_BILLED_ITEMS_CSV_RETRIEVAL',
  ACTIVE_DRIVER_ALREADY_EXISTS = 'ACTIVE_DRIVER_ALREADY_EXISTS',
  UNEXPECTED_SERVER_STATUS = 'UNEXPECTED_SERVER_STATUS',
  GENERIC_ERROR = 'GENERIC_ERROR',
  DEPRECATED_ENDPOINT = 'DEPRECATED_ENDPOINT',
  THROTTLING_ERROR = 'THROTTLING_ERROR',
  CONNECTION_FAILED = 'CONNECTION_FAILED',
  GENERAL_PROCESSING_FAILURE = 'GENERAL_PROCESSING_FAILURE',
  FAILED_TO_EXECUTE_CHANGE_REQUEST = 'FAILED_TO_EXECUTE_CHANGE_REQUEST',
  INVALID_JSON_FORMAT = 'INVALID_JSON_FORMAT',
  INVALID_PARAMETER_VALUE = 'INVALID_PARAMETER_VALUE',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
  INVALID_FORMAT = 'INVALID_FORMAT',
  ACTIVE_CHANGE_REQUEST_FOUND = 'ACTIVE_CHANGE_REQUEST_FOUND',
  INVALID_NUMBER_OF_REVIEWERS = 'INVALID_NUMBER_OF_REVIEWERS',
  INVALID_NUMBER_OF_INITIATORS = 'INVALID_NUMBER_OF_INITIATORS',
  USER_NOT_SUBSCRIBED = 'USER_NOT_SUBSCRIBED',
  INVALID_CHANGE_REQUEST_STATE = 'INVALID_CHANGE_REQUEST_STATE',
  MESSAGE_TYPE_NOT_ALLOWED_FOR_USER = 'MESSAGE_TYPE_NOT_ALLOWED_FOR_USER',
  ALREADY_APPROVED = 'ALREADY_APPROVED',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
  NOT_ALLOWED_ACTION = 'NOT_ALLOWED_ACTION',
  CANNOT_DEACTIVATE_SEND_TO_FINANCE_ENABLER_FLAG = 'CANNOT_DEACTIVATE_SEND_TO_FINANCE_ENABLER_FLAG',
  VEHICLE_NOT_FOUND = 'VEHICLE_NOT_FOUND',
  MULTIPLE_ACTIVE_VEHICLES_FOR_A_VIN = 'MULTIPLE_ACTIVE_VEHICLES_FOR_A_VIN',
  INVOICE_ORDER_ITEM_COUNT_MISMATCH = 'INVOICE_ORDER_ITEM_COUNT_MISMATCH',
  ORDER_ITEM_NOT_FOUND_FOR_BILLED_ITEM = 'ORDER_ITEM_NOT_FOUND_FOR_BILLED_ITEM',
  CDR_NOT_FOUND_FOR_ORDER_ITEM = 'CDR_NOT_FOUND_FOR_ORDER_ITEM',
  UNSUPPORTED_ACTION = 'UNSUPPORTED_ACTION',
  FAILED_TO_SEND_CORRECTION_ITEM = 'FAILED_TO_SEND_CORRECTION_ITEM',
  FAILED_TO_TRIGGER_CORRECTION_BILL_RUN = 'FAILED_TO_TRIGGER_CORRECTION_BILL_RUN',
  ORDER_ITEM_INVALID_VALUE = 'ORDER_ITEM_INVALID_VALUE',
  CLIENT_ERROR = 'CLIENT_ERROR',
  CHANGE_REJECTED = 'CHANGE_REJECTED',
  SERVER_CALL_REJECTED = 'SERVER_CALL_REJECTED'
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data" class="invoice-orderitems-table scrollable-table-container">
  <div>
    <mat-table [dataSource]="tableDataSource">
      <div *ngFor="let col of dataColumns; let columnIndex = index">

        <ng-container *ngIf="col === 'item_id'" matColumnDef="{{col}}" sticky>
          <mat-header-cell [styledColumn]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
          <mat-cell [styledColumn]="col" *matCellDef="let element">
            <ng-container>{{element[col]}}</ng-container>
          </mat-cell>
        </ng-container>
        <ng-container *ngIf="col !== actionColumn && col !== 'item_id'" matColumnDef="{{col}}">
          <mat-header-cell [styledColumn]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
          <mat-cell [styledColumn]="col" *matCellDef="let element">
            <ng-container
              *ngIf="col !== 'start_period_date_time' && col !== 'service_country'">{{element[col]}}</ng-container>
            <ng-container
              *ngIf="col === 'start_period_date_time'">{{translateDate(element[col])}}</ng-container>
            <ng-container
              *ngIf="col === 'service_country'">{{translateCountry(element[col])}}</ng-container>
          </mat-cell>
        </ng-container>
      </div>


      <ng-container matColumnDef="tableAction">
        <mat-header-cell *matHeaderCellDef class="actionCell"> {{'invoices.tableHeader.action' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="actionCell">
          <button mat-mini-fab class="edit tableActionBtn" (mouseenter)="changeStyle($event)"
                  (mouseleave)="changeStyle($event)" (click)="readElement(element)"
                  matTooltip="{{'invoice.tooltip.invoicechanges' | translate}}">
            <mat-icon> edit</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </div>
</div>
<mat-paginator [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions">
</mat-paginator>

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content editKeyEvents class="edit-taxes-dialog">
  <h2 mat-dialog-title>{{'taxes.edit.dialogue.header'| translate}} {{'global.country.' + country | translate }}
    <mat-spinner [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
    <button mat-raised-button class="icon-only headerActionBtn addTaxRecord" (click)="addTaxRecord()">
      <mat-icon>add</mat-icon>
    </button>
  </h2>
  <mat-toolbar class="error error-toolbar" *ngIf="showDateRangeError()">
    <span class="error-span">{{getError(editTaxesForm) | async}}</span>
  </mat-toolbar>

  <div>
    <edit-taxes-table *ngIf="editTaxesForm"
                      [data]="taxes$"
                      [dataColumns]="dataColumns"
                      [editTaxesForm]="editTaxesForm"
                      [i18nBase]="'taxes.label'"
                      [currencies]="currencies"
                      [actionButtons]="true"
                      (deleteEvent)="deleteRecord($event)">
    </edit-taxes-table>

    <mat-dialog-actions>
      <button okAction mat-raised-button class="dialogueButton dialogueButtonRight"
              [disabled]="!allInputsValid()"
              (click)="onSave()">
        {{'button.save' | translate}}
      </button>
      <button cancelAction mat-raised-button class="dialogueButton"
              (click)="onCancel()">
        {{'button.cancel' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</mat-dialog-content>

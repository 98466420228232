/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OptimizedTableBaseComponent} from './src/table-bases/optimized-table-base.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {HttpLoaderFactory} from 'pcs-commons/utils';
import {TableBaseComponent} from './src/table-bases/table-base.component';
import {PaginatedTableBaseComponent} from './src/table-bases/paginated-table-base.component';
import {FilterableTableBaseComponent} from './src/table-bases/filterable-table-base-component';

@NgModule({
  declarations: [
    OptimizedTableBaseComponent,
    TableBaseComponent,
    PaginatedTableBaseComponent,
    FilterableTableBaseComponent
  ],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    OptimizedTableBaseComponent,
    TableBaseComponent,
    PaginatedTableBaseComponent,
    FilterableTableBaseComponent
  ]
})
export class DataTablesModule {
}

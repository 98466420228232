/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, Renderer2} from '@angular/core';
import {ChargepointVisibility} from '../../datatypes/chargepoint-visibility.enum';
import {TranslateService} from '@ngx-translate/core';
import {ChargepointOverviewAdminDto} from '../../datatypes/location/chargepoint/chargepoint-overview-admin-dto';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';

@Component({
  selector: 'chargepoints-table',
  templateUrl: './chargepoints-table.component.html',
  styleUrls: ['./chargepoints-table.component.css']
})
export class ChargepointsTableComponent extends OptimizedTableBaseComponent {
  @Input() isLoadingResults;

  readonly visibilityColumn = ChargepointOverviewAdminDto.VISIBILITY;
  readonly manualDataColumn = ChargepointOverviewAdminDto.MANUAL_CHANGES;
  readonly receivedUpdateColumn = ChargepointOverviewAdminDto.RECEIVED_UPDATE;

  constructor(renderer: Renderer2, private translateService: TranslateService) {
    super(renderer);
    this.disableDelete = true;
  }

  belongsToDefaultNgContainer(col: string) {
    switch (col) {
      case this.actionColumn:
      case this.visibilityColumn:
      case this.manualDataColumn:
      case this.receivedUpdateColumn:
        return false;
      default:
        return true;
    }
  }

  getColumnValue(col: string, element: ChargepointOverviewAdminDto): any {
    const value = element[col];
    // if it's country code, lets translate
    return (ChargepointOverviewAdminDto.COUNTRY_CODE === col && value) ?
      this.translateService.instant('global.country.' + value) : value;
  }

  determineVisibilityIconClass(visibility: string): string {
    switch (visibility) {
      case ChargepointVisibility.NORMAL:
        return 'info-green fa fa-check-square';
      case ChargepointVisibility.RARELY_USED:
        return 'warning-yellow fa fa-question-circle';
      case ChargepointVisibility.HIDDEN:
        return 'warning-red fa fa-minus-circle';
      default:
        return null;
    }
  }

  getColumnClass(col: string): string {
    switch (col) {
      case ChargepointOverviewAdminDto.CPO_NAME:
        return 'cpo-column';
      case ChargepointOverviewAdminDto.STREET:
        return 'street-column';
      case ChargepointOverviewAdminDto.COUNTRY_CODE:
        return 'country-column';
      case ChargepointOverviewAdminDto.ZIP:
        return 'zip-column';
      case ChargepointOverviewAdminDto.CITY:
        return 'city-column';
      default:
        return '';
    }
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Renderer2} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {TranslationHelperService} from 'pcs-commons/http';
import {CurrencyFactor} from '../../datatypes/currency-factor';

@Component({
  selector: 'currency-factor-table',
  templateUrl: './currency-factor-table.component.html',
  styleUrls: [
    '../data-table.component.css',
    '../action-column-style.css',
    './currency-factor-table.component.css']
})
export class CurrencyFactorTableComponent extends OptimizedTableBaseComponent {

  constructor(renderer: Renderer2,
              private translateHelper: TranslationHelperService) {
    super(renderer);
  }

  public onEdit(currencyFactor: CurrencyFactor): void {
    this.editEvent.emit(currencyFactor);
  }

  public getDateTimeTranslation(value: string): string {
    return this.translateHelper.translateDateTimeUTCSafe(value);
  }
}

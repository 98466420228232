<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data && pricesForm">
  <form [formGroup]="pricesForm">
    <mat-grid-list cols="4" rowHeight="110px">
      <mat-grid-tile colspan="3">
        <mat-form-field class="date-picker">
          <mat-label>{{'product-detail.label.validFrom'| translate}}</mat-label>
          <input matInput [matDatepicker]="pickerValidFrom" formControlName="validFromDate">
          <mat-datepicker-toggle matSuffix [for]="pickerValidFrom"></mat-datepicker-toggle>
          <mat-datepicker #pickerValidFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="time-picker">
          <input matInput formControlName="validFromTime" type="time" step="1">
        </mat-form-field>

        <mat-form-field class="date-picker">
          <mat-label>{{'product-detail.label.validUntil'| translate}}</mat-label>
          <input matInput [matDatepicker]="pickerValidUntil" formControlName="validUntilDate">
          <mat-datepicker-toggle matSuffix [for]="pickerValidUntil"></mat-datepicker-toggle>
          <mat-datepicker #pickerValidUntil></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="time-picker">
          <input matInput formControlName="validUntilTime" type="time" step="1">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <mat-form-field>
          <mat-label>{{'product-detail.label.tax' | translate}}</mat-label>
          <input matInput formControlName="tax">
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-table [dataSource]="tableDataSource">
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
          <mat-header-cell priceTableCell [column]="col"
                           *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
          <mat-cell priceTableCell [column]="col" *matCellDef="let element">
            <div *ngIf="col===CHARGEPOINT_CLASS_COLUMN">{{'global.chargeClass.' + element[col] | translate}}</div>
            <div
              *ngIf="col!==CHARGEPOINT_CLASS_COLUMN && col !==PRICE_PER_MIN_COLUMN && col!==PRICE_PER_KWH_COLUMN">
              {{pricesForm | editableClassPriceColumnValue: col : country : element.chargepointClass}}
            </div>
            <div *ngIf="col===PRICE_PER_MIN_COLUMN">
              <mat-form-field>
                <input matInput class="textRight" [required]="false"
                       [formControlName]="calcFormControlName(element,'Min')">
              </mat-form-field>
            </div>
            <div *ngIf="col===PRICE_PER_KWH_COLUMN">
              <mat-form-field floatLabel="never">
                <input matInput class="textRight" [required]="false"
                       [formControlName]="calcFormControlName(element,'Kwh')">
              </mat-form-field>
            </div>
          </mat-cell>
        </ng-container>
      </div>
      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </form>
</div>

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-toolbar class="headerToolbar mat-elevation-z1">
  <span class="headerSpan">{{ 'database.header' | translate }}</span>
  <pcs-loading-spinner [component]="this"></pcs-loading-spinner>
</mat-toolbar>
<div class="section-container">
  <pcs-query-input editKeyEvents (queryEvent)="executeQuery($event)" (downloadEvent)="onDownloadResults()"
                   [resultsAvailable]="resultLength && resultLength > 0"></pcs-query-input>
</div>
<div class="section-container" *ngIf="resultLength > 0">
  <mat-accordion>
    <mat-expansion-panel [expanded]="resultLength > 0" hideToggle>
      <database-query-result-table [dataColumns]="dataColumns"
                                   [data]="queryResultSubject$">
      </database-query-result-table>
    </mat-expansion-panel>
  </mat-accordion>
</div>


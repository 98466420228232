/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import { Component, Renderer2 } from '@angular/core';
import { TableBaseComponent } from './table-base/table-base.component';

@Component({
  selector: 'data-table',
  templateUrl: './data-table.component.html',
  styleUrls: [
    './action-column-style.css',
    './data-table.component.css']
})
export class DataTableComponent extends TableBaseComponent {
  constructor(renderer: Renderer2) {
    super(renderer);
   }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div class="scrollable-table-container">
  <mat-table #table [dataSource]="tableDataSource" class="padding-lr-24">
    <ng-container *ngFor="let col of dataColumns; let columnIndex = index">
      <ng-container *ngIf="!colsWithCustomColDef.includes(col)" matColumnDef="{{ col }}">
        <mat-header-cell
          *matHeaderCellDef
          [styledColumn]="col"
          [ngClass]="[cpoCodeColumn, nameColumn].includes(col) ? 'filter-column-header-cell' : ''"
        >
          {{ getI18nBase(col) | translate }}
          <pcs-input-column-filter
            *ngIf="[cpoCodeColumn, nameColumn].includes(col)"
            [styledColumn]="col"
            (handleInputChange)="handleFilterValueChange(col, $event)"
            [preSelectedValue]="filterObj[col]"
          />
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [styledColumn]="col" class="cpoTableCell">
          {{ col === activationDateColumn ? (element[col] | translateDate: updated) : element[col] }}
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="col === platformColumn" matColumnDef="{{ col }}">
        <mat-header-cell *matHeaderCellDef [styledColumn]="col" class="filter-column-header-cell platform-cell">
          {{ getI18nBase(col) | translate }}
          <pcs-select-column-filter
            [styledColumn]="col"
            [options]="platformColumnValues"
            (handleSelectChange)="handleFilterValueChange(col, $event)"
            [preSelectedOption]="filterObj[col]"
          />
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [styledColumn]="col" class="platform-cell">
          {{ element['iopPlatformDto'].name }}
        </mat-cell
        >
      </ng-container>

      <ng-container *ngIf="col === displayNameColumn" matColumnDef="{{ col }}">
        <mat-header-cell *matHeaderCellDef [styledColumn]="col" class="filter-column-header-cell">
          {{ getI18nBase(col) | translate }}
          <pcs-input-column-filter
            [styledColumn]="displayNameColumn"
            (handleInputChange)="handleFilterValueChange(displayNameColumn, $event)"
            [preSelectedValue]="filterObj[col]"
          />
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [styledColumn]="col" class="cpoTableCell">
          <div *ngIf="!operatorIsInEdit(element)">
            <div class="displayNameLabel">
              <span>{{ element[col] }}</span>
            </div>
            <button
              mat-mini-fab
              class="tableActionBtn"
              (click)="enableEditForOperator(element)"
              *ngIf="editAllowed"
              [disabled]="updateOnProcess"
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <div *ngIf="operatorIsInEdit(element)">
            <mat-form-field class="displayNameInput">
              <input
                matInput
                [formControl]="displayNameControl"
                [maxlength]="displayNameMaxLength"
                [readonly]="disableEditUpdateName()"
              />
              <mat-error *ngIf="displayNameControl.invalid">
                {{ getError(displayNameControl) | async }}
              </mat-error>
            </mat-form-field>
            <button
              mat-mini-fab
              class="icon-only tableActionBtn push-down"
              (click)="updateDisplayName(element)"
              [disabled]="disableEditUpdateName()"
              [class.spinner]="updateDisplayNameOngoing(element)"
            >
              <mat-icon [class.hidden]="updateDisplayNameOngoing(element)">done</mat-icon>
            </button>
            <button
              mat-mini-fab
              class="tableActionBtn push-down"
              (click)="cancelUpdateDisplayName()"
              [disabled]="disableEditUpdateName()"
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </mat-cell>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="{{ subOperatorCount }}">
      <mat-header-cell *matHeaderCellDef [styledColumn]="subOperatorCount">
        {{ getI18nBase(subOperatorCount) | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" [styledColumn]="subOperatorCount">
        <button
          (click)="goToSubOperatorView(element)"
          [disabled]="element.subOperatorCount <= 0"
          class="cpo-table-button tableActionBtn"
          mat-mini-fab
        >
          {{ element.subOperatorCount }}
        </button>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="{{ activeColumn }}">
      <mat-header-cell *matHeaderCellDef class="center active-cell filter-column-header-cell">
        {{ getI18nBase(activeColumn) | translate }}
        <pcs-select-column-filter
          class="active-cell"
          [options]="activeColumnValues"
          [translationKey]="'operators.button.'"
          (handleSelectChange)="handleFilterValueChange(activeColumn, $event)"
          [preSelectedOption]="filterObj[activeColumn]"
        />
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center active-cell cpoTableCell">
        <button
          mat-raised-button
          class="normalButton"
          [color]="element.active ? 'success' : 'warn'"
          (click)="toggleActiveStatus(element)"
          [disabled]="!editAllowed"
        >
          <i [ngClass]="element.active ? 'fa fa-check-square' : 'fa fa-minus-circle'" aria-hidden="true"></i>
          {{ element.active ? ('operators.button.active' | translate) : ('operators.button.inactive' | translate) }}
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="{{ energySourceColumn }}">
      <mat-header-cell *matHeaderCellDef class="center energy-cell filter-column-header-cell">
        {{ getI18nBase(energySourceColumn) | translate }}
        <pcs-icon-column-filter
          class="energy-cell"
          [options]="energyColumnValues"
          (handleSelectChange)="handleFilterValueChange(energySourceColumn, $event)"
          [preSelectedOption]="filterObj[energySourceColumn]"
        />
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center energy-cell cpoTableCell">
        <button
          mat-mini-fab
          class="cpo-table-button tableActionBtn"
          (click)="toggleRenewableEnergy(element)"
          [disabled]="!editAllowed"
          matTooltip="{{
            (element.renewableEnergySource ? 'operators.renewableEnergy.tooltip' : 'operators.nonrenewableEnergy.tooltip')
              | translate
          }}"
        >
          <svg-icon *ngIf="element.renewableEnergySource" src="/assets/images/renewable_energy.svg"></svg-icon>
          <svg-icon *ngIf="!element.renewableEnergySource" src="/assets/images/non_renewable_energy.svg"></svg-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="{{ showStationName }}">
      <mat-header-cell *matHeaderCellDef class="center station-name-cell filter-column-header-cell">
        {{ getI18nBase(showStationName) | translate }}
        <pcs-icon-column-filter
          class="station-name-cell"
          [options]="showStationNameColumnValues"
          (handleSelectChange)="handleFilterValueChange(showStationName, $event)"
          [preSelectedOption]="filterObj[showStationName]"
        />
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center station-name-cell cpoTableCell">
        <button
          mat-mini-fab
          class="cpo-table-button tableActionBtn"
          (click)="toggleShowStationName(element)"
          [disabled]="!editAllowed"
          matTooltip="{{
            (element.showStationName ? 'operators.notShowStationName.tooltip' : 'operators.showStationName.tooltip') | translate
          }}"
        >
          <mat-icon [ngClass]="element.showStationName ? 'green-icon' : 'grey-icon'">ev_station</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="{{ pncColumn }}">
      <mat-header-cell *matHeaderCellDef class="center pnc-cell filter-column-header-cell">
        {{ getI18nBase(pncColumn) | translate }}
        <pcs-icon-column-filter
          class="pnc-cell"
          [options]="pncColumnValues"
          (handleSelectChange)="handleFilterValueChange(pncColumn, $event)"
          [preSelectedOption]="filterObj[pncColumn]"
        />
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center energy-cell cpoTableCell">
        <button
          mat-mini-fab
          class="pnc-button tableActionBtn"
          matTooltip="{{
            (element.enablePlugAndCharge ? 'operators.disablePlugAndCharge.tooltip' : 'operators.enablePlugAndCharge.tooltip')
              | translate
          }}"
          (click)="togglePlugAndCharge(element)"
          [disabled]="!editAllowed"
        >
          <svg-icon
            [viewBox]="'0 0 24 24'"
            [svgClass]="'pnc-icon'"
            *ngIf="element.enablePlugAndCharge"
            src="/assets/images/ic_plugandcharge_green.svg"
          >
          </svg-icon>
          <svg-icon
            [viewBox]="'0 0 24 24'"
            [svgClass]="'pnc-icon'"
            *ngIf="!element.enablePlugAndCharge"
            src="/assets/images/ic_plugandcharge_grey.svg"
          >
          </svg-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="{{ rfidColumn }}">
      <mat-header-cell *matHeaderCellDef class="center rfid-cell filter-column-header-cell">
        {{ getI18nBase(rfidColumn) | translate }}
        <pcs-icon-column-filter
          class="rfid-cell"
          [options]="rfidColumnValues"
          (handleSelectChange)="handleFilterValueChange(rfidColumn, $event)"
          [preSelectedOption]="filterObj[rfidColumn]"
        />
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center rfid-cell cpoTableCell">
        <button
          mat-mini-fab
          class="cpo-table-button tableActionBtn"
          (click)="toggleRfidAuth(element)"
          [disabled]="!editAllowed"
          matTooltip="{{
            (element.enableRfidAuth ? 'operators.disableRfidAuth.tooltip' : 'operators.enableRfidAuth.tooltip') | translate
          }}"
        >
          <mat-icon [ngClass]="element.enableRfidAuth ? 'green-icon' : 'grey-icon'">branding_watermark</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="{{ remoteColumn }}">
      <mat-header-cell *matHeaderCellDef class="center remote-cell filter-column-header-cell">
        {{ getI18nBase(remoteColumn) | translate }}
        <pcs-icon-column-filter
          class="remote-cell"
          [options]="remoteColumnValues"
          (handleSelectChange)="handleFilterValueChange(remoteColumn, $event)"
          [preSelectedOption]="filterObj[remoteColumn]"
        />
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center remote-cell cpoTableCell">
        <button
          mat-mini-fab
          class="cpo-table-button tableActionBtn"
          (click)="toggleRemoteAuth(element)"
          [disabled]="!editAllowed"
          matTooltip="{{
            (element.enableRemoteAuth ? 'operators.disableRemoteAuth.tooltip' : 'operators.enableRemoteAuth.tooltip') | translate
          }}"
        >
          <mat-icon [ngClass]="element.enableRemoteAuth ? 'green-icon' : 'grey-icon'">smartphone</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="{{ preferredColumn }}">
      <mat-header-cell *matHeaderCellDef class="center preferred-cell filter-column-header-cell">
        {{ getI18nBase(preferredColumn) | translate }}
        <pcs-icon-column-filter
          class="preferred-cell"
          [options]="preferredColumnValues"
          (handleSelectChange)="handleFilterValueChange(preferredColumn, $event)"
          [preSelectedOption]="filterObj[preferredColumn]"
        />
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center preferred-cell cpoTableCell">
        <button
          mat-mini-fab
          class="cpo-table-button tableActionBtn"
          (click)="togglePreferred(element)"
          [disabled]="!editAllowed"
          matTooltip="{{ 'operators.togglePreferred.tooltip' | translate }}"
        >
          <mat-icon [ngClass]="element.preferred ? 'green-icon' : 'grey-icon'">wb_sunny</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns"></mat-row>
  </mat-table>
</div>

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
export enum Statistics {
  CHARGE_STATISTIC = 'charge-statistic',
  FLEET_UTILISATION_REPORT = 'fleet-utilisation-report',
  CHARGE_ERROR_STATISTIC = 'charge-error-statistic',
  SESSION_STATISTIC = 'session-statistic',
  ENERGY_STATISTIC = 'energy-statistic',
  ENERGY_DETAIL_STATISTIC = 'energy-detail-statistic',
  ACTIVE_CONTRACTS = 'active-contracts-for-neighbours',
  CHARGEPOINT_STATISTIC = 'chargepoint-statistic',
  ACTIVE_CONTRACTS_PER_PRODUCT = 'active-contracts-per-product',
  ACTIVE_CONTRACTS_PER_COUNTRY = 'active-contracts-per-country',
  ACTIVE_HUBJECT_CONTRACTS_PER_PRODUCT = 'active-hubject-contracts-per-product',
  REGISTERED_IONITY_CONTRACTS_PER_MONTH = 'registered-ionity-contracts-per-month',
  CDR_CLEARING_POOL_STATISTIC = 'cdr-clearing-pool-statistic'
}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */

import {ContractStatus} from "./contract-status";

export interface EnrollmentStatusDto {
    emaid: string;
    enrolled: boolean;
    enrollmentDate: string;
    expirationDate: string;
    contractId: string;
    contractStatus: ContractStatus;
    planId: string;
}

export enum EnrollmentStatusColumns {
    EMAID = "emaid",
    ENROLLED = "enrolled",
    ENROLLMENT_DATE = "enrollmentDate",
    EXPIRATION_DATE = "expirationDate",
    CONTRACT_ID = "contractId",
    CONTRACT_STATUS = "contractStatus",
    PLAN_ID = "planId",
}

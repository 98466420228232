/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, ViewChild} from '@angular/core';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {ChargingCard, ChargingCardColumns} from '../datatypes/charging-card';
import {ChargingCardFilter} from '../datatypes/charging-card-filter';
import {BehaviorSubject} from 'rxjs';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {ChargingCardService} from '../services/http/charging-card.service';
import {Message} from '../datatypes/message';
import {MatDialog} from '@angular/material/dialog';
import {PermissionAware} from '../permission-aware';
import {AccessRights} from '../datatypes/access-rights.enum';
import {Parameters} from '../global/parameters';

@Component({
  selector: 'card-management',
  templateUrl: './card-management.component.html',
  styleUrls: ['./card-management.component.css']
})
export class CardManagementComponent extends AbstractNotificationHandler implements PermissionAware {
  public readonly reqEditPermission = [AccessRights.CUSTOMERMANAGEMENT_EDIT_WEB];
  public readonly pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS;
  public readonly chargingCardColumns: string[] = Object.values(ChargingCardColumns);
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public cardSearchResultSubject = new BehaviorSubject<ChargingCard[]>([]);
  public cardSearchResult$ = this.cardSearchResultSubject.asObservable();
  public collapseFilterViewSubject = new BehaviorSubject<boolean>(false);
  public collapseFilterView$ = this.collapseFilterViewSubject.asObservable();

  public loading = false;
  public currentFilter: ChargingCardFilter = null;
  public pageEventOnProcess = false;
  public resultLength = 0;

  constructor(
    private dialog: MatDialog,
    private chargingCardService: ChargingCardService
  ) {
    super();
  }

  public applyFilter(filter: ChargingCardFilter): void {
    console.log('Received charging card filter: ', filter);
    this.currentFilter = JSON.parse(JSON.stringify(filter));
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    const pageSize = this.paginator ? this.paginator.pageSize : this.pageSizeOptions[0];
    this.collapseFilterViewSubject.next(false);
    this.loading = true;
    this.getCardsByFilter(filter, 0, pageSize);
  }

  private getCardsByFilter(filter: ChargingCardFilter, pageIndex: number, pageSize: number): void {
    filter.offset = pageIndex * pageSize;
    // send one more so see if we have more records than requested
    filter.pageSize = pageSize + 1;
    console.log('requesting cards by filter: ', this.currentFilter);
    this.chargingCardService.getCardsByFilter(filter)
      .subscribe({
        next: (cards) => {
          this.loading = false;
          this.pageEventOnProcess = false;
          this.handleForFilterResult(cards, pageIndex, pageSize);
        },
        error: (error) => {
          this.loading = false;
          this.pageEventOnProcess = false;
        }
      });
  }

  private handleForFilterResult(cards, pageIndex: number, pageSize: number): void {
    this.resultLength = pageIndex * pageSize + cards.length;
    // since we requested one extra element, we remove it before showing
    if (cards.length > pageSize) {
      cards.splice(cards.length - 1, 1);
    }
    this.cardSearchResultSubject.next(cards);
    this.collapseFilterViewSubject.next(cards.length > 0);
    if (cards.length <= 0) {
      const msg = new Message();
      msg.message = 'cards.noRecordsFound';
      this.showInfo(msg);
    }
  }

  public handlePageChange(pageEvent: PageEvent): void {
    console.log('new page event: ', pageEvent);
    this.pageEventOnProcess = true;
    this.getCardsByFilter(this.currentFilter, pageEvent.pageIndex, pageEvent.pageSize);
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {AfterViewInit, Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {ChargepointClassPriceColumn} from '../chargepoint-class-price';
import {BasicFeeTableColumns} from '../datatypes/basicfee-record';

@Directive({
  selector: '[priceTableCell]'
})
export class PriceTableCellDirective implements AfterViewInit {
  @Input() column: string;

  private readonly PRICE_COLUMNS: string[] = [
    ChargepointClassPriceColumn.PRICE_PER_MIN,
    ChargepointClassPriceColumn.PRICE_PER_KWH,
    ChargepointClassPriceColumn.PRICE_PER_MIN_NETTO,
    ChargepointClassPriceColumn.PRICE_PER_KWH_NETTO,
    BasicFeeTableColumns.BASIC_FEE,
    BasicFeeTableColumns.BASIC_FEE_NETTO,
    BasicFeeTableColumns.DEFAULT_PRICE_MIN,
    BasicFeeTableColumns.DEFAULT_PRICE_MIN_NETTO,
    BasicFeeTableColumns.DEFAULT_PRICE_KWH,
    BasicFeeTableColumns.DEFAULT_PRICE_KWH_NETTO
  ];

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
  }

  ngAfterViewInit(): void {
    if (!this.column) {
      return;
    }
    if (this.PRICE_COLUMNS.includes(this.column)) {
      // this class is in data-table.component.css defined, please include this class in your components styleUrls
      this.renderer.addClass(this.el.nativeElement, 'price-cell');
      return;
    }
    const styleClass = this.column + '-cell';
    this.renderer.addClass(this.el.nativeElement, styleClass);
  }


}

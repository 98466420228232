/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input} from '@angular/core';
import {ChangeRequestDto} from "../../datatypes/audit-flow/change-request-dto";
import {ChangeRequestPayloadType} from "../../datatypes/inbox/change-request-payload-type";
import {ChangeRequestAction} from "../../datatypes/audit-flow/change-request-action";

@Component({
  selector: 'pcs-change-request-action-description',
  templateUrl: './change-request-action-description.component.html',
  styleUrls: ['./change-request-action-description.component.css']
})
export class ChangeRequestActionDescriptionComponent {
  @Input()
  public changeRequest: ChangeRequestDto;

  public get actionDescription(): string | undefined {
    switch (this.changeRequest?.payloadObjectType) {
      case ChangeRequestPayloadType.USER_GROUP:
        return this.getDescriptionForUserGroup();
      case ChangeRequestPayloadType.USER:
        return this.getDescriptionForUser();
      default:
        return undefined;
    }
  }

  private getDescriptionForUserGroup(): string | undefined {
    if (this.changeRequest?.action === ChangeRequestAction.DELETE) {
      return "changeRequest.info.description.user_group.delete";
    } else if (this.changeRequest?.action === ChangeRequestAction.CREATE) {
      return "changeRequest.info.description.user_group.create";
    }
    return undefined;
  }

  private getDescriptionForUser(): string | undefined {
    if (this.changeRequest?.action === ChangeRequestAction.DELETE) {
      return "changeRequest.info.description.user.delete";
    }
    return undefined;
  }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <div class="expansion-panel-header">
        <span class="headerSpan headerToolbarSub">{{'electricVehicle.filter.header' | translate}}</span>
        <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
      </div>
    </mat-expansion-panel-header>
    <form class="filter-form" [formGroup]="filterFormGroup" *ngIf="filterFormGroup">
      <div class="multi-column-div">
        <mat-form-field class="filter-form-field">
          <mat-label>{{'electricVehicle.vin' | translate}}</mat-label>
          <input matInput maxlength="{{inputMaxLength}}" formControlName="vinFilter">
        </mat-form-field>
      </div>
    </form>
    <mat-action-row>
      <button [disabled]="!isFilterValid()" okAction class="normalButton" mat-stroked-button
              (click)="onDisplayEVs()">
        <span class="filter-button-span">{{ "electricVehicle.button.filter" | translate }}</span>
        <i class="fa fa-refresh" aria-hidden="true"></i>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>

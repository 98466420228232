/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */

export enum BaseUrls {
  PRODUCTS = 'prod-list',
  PRODUCT_DETAIL = 'prod-detail',
  ERROR = 'error',
  STATISTICS = 'statistic',
  CARD_MANAGEMENT = 'card-management',
  USER_MANAGEMENT = 'user-management',
  USER_GROUPS = 'user-groups',
  CHARGEPOINTS = 'chargepoints',
  LOCATION_DETAILS = 'location-details',
  OPERATORS = 'operators',
  TAXES = 'tax-list',
  FLEETS = 'fleets',
  FLEET_CREATE = 'fleet-edit',
  FLEET_EDIT = 'fleet-edit',
  ID_CARDS = 'id-cards',
  CONFIGURATIONS = 'configurations',
  OCPI_PARTNERS = 'ocpi-partners',
  DATABASE = 'database',
  CUSTOMER_INFO = 'customerinfo',
  PURCHASE_CONDITION_OVERVIEW = 'purchase-conditions-overview',
  PURCHASE_CONDITION = 'purchase-condition',
  TOKEN_HTML = 'token.html',
  TOKENS = 'tokens',
  CURRENCY_FACTOR_OVERVIEW = 'currency-factors',
  TARIFF_IMPORT_RESULT = 'tariff-import-result',
  CDR_CLEARING_POOL = 'cdr-clearing-pool',
  INVOICES = 'invoices',
  CHANGE_REQUEST_DETAILS = 'change-request-details',
  ELECTRIC_VEHICLES = 'electric-vehicles',
  CHARGEPOINT_ATTRIBUTE_IMPORT = 'chargepoint-attribute-import',
  CHARGEPOINT_ATTRIBUTE_IMPORT_RESULT = 'chargepoint-attribute-import-result',
  CDR_REVOCATION = 'cdr-revocation',
  IONITY_CONTRACT_DATA = 'ionity-contract-data',
  FLEET_INVOICES = 'fleet-invoices',
  PLAN_HISTORY = 'contract-history',
  INBOX = 'inbox',
  CHANGE_REQUESTS_OVERVIEW = 'change-requests',
  ENROLLMENT_OVERVIEW = 'enrollment',
  QUERY_HISTORY_OVERVIEW = 'query-history-overview',
  KEY_TAG_IMPORT = 'key-tag-import'
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {UserService} from '../../services/http/user.service';
import {User} from '../../datatypes/user';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {PermissionAware} from '../../permission-aware';
import {AccessRights} from '../../datatypes/access-rights.enum';
import {Utils} from '../../utils/utils';
import {MatDialog} from '@angular/material/dialog';
import {UserDataSourceService} from '../../services/user-data-source.service';
import {SharedDataService} from '../../services/shared-data.service';
import {UserGroup} from '../../datatypes/user-group';
import {AddAdminUserComponent} from './add-user/add-admin-user.component';
import {UserTableComponent} from '../../data-table/user-table/user-table.component';
import {AuditService} from "../../services/audit.service";
import {ChangeRequestAction} from "../../datatypes/audit-flow/change-request-action";
import {ChangeRequestPayloadType} from "../../datatypes/inbox/change-request-payload-type";
import {ChangeRequestService} from "../../services/http/audit/change-request.service";
import {WithLoadingSpinner} from "pcs-commons/components";


@Component({
  selector: 'pcs-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent extends AbstractNotificationHandler implements OnInit, PermissionAware {
  public readonly reqEditPermission: AccessRights[] = [AccessRights.USERMANAGEMENT_EDIT_WEB];
  protected userTableColumns = User.dataTableColumns;
  protected availableGroups: UserGroup[];
  @ViewChild(UserTableComponent) private userTable: UserTableComponent;
  private actionResultSubject = new BehaviorSubject<boolean>(undefined);
  protected actionResult$ = this.actionResultSubject.asObservable();

  private usersSource = new BehaviorSubject<User[]>([]);
  protected userList$ = this.usersSource.asObservable();

  constructor(
    private userService: UserService,
    private userDataSource: UserDataSourceService,
    private dataService: SharedDataService,
    private changeRequestService: ChangeRequestService,
    private auditService: AuditService,
    public dialog: MatDialog
  ) {
    super();
  }


  public async ngOnInit(): Promise<void> {
    await this.getUsers().then(() => this.getUserGroups());
  }

  protected onCreateUser(): void {
    this.userTable.onCancel();
    const dialogRef = this.dialog.open(AddAdminUserComponent, {
      width: '600px'
    });

    dialogRef.afterClosed().subscribe(async result => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        await this.getUsers();
      }
    });
  }

  public async onUserEdit(toUpdate: User): Promise<void> {
    try {
      await firstValueFrom(this.userService.validateUserEditRequest(toUpdate));
      delete toUpdate.hasActiveChangeRequest; // we do not want this in the change request to appear
      const originalUser = this.usersSource.getValue().find(user => user.id === toUpdate.id);
      delete originalUser.hasActiveChangeRequest;
      const crCreated = await this.auditService.initiateChangeRequestForUser(ChangeRequestAction.MODIFY, originalUser, toUpdate);
      if (crCreated) {
        this.actionResultSubject.next(true);
        this.deactivateFurtherModificationForUser(toUpdate);
      }
    } catch (e) {
      this.actionResultSubject.next(false);
    }
  }

  public async onUserDelete(userToDelete: User): Promise<void> {
    try {
      await firstValueFrom(this.userService.validateUserDeleteRequest(userToDelete));
      delete userToDelete.hasActiveChangeRequest;
      const crCreated = await this.auditService.initiateChangeRequestForUser(ChangeRequestAction.DELETE, userToDelete, undefined);
      if (crCreated) {
        this.actionResultSubject.next(true);
        this.deactivateFurtherModificationForUser(userToDelete);
      }
    } catch (e) {
      this.actionResultSubject.next(false);
    }
  }

  public onResultAcknowledgement(): void {
    this.actionResultSubject.next(undefined);
  }

  @WithLoadingSpinner()
  private async getUsers(): Promise<void> {
    const users = await this.userService.getUsers();
    this.sortUsers(users);
    await this.checkForActiveChangeRequests(users);
    this.usersSource.next(users);
    console.log('finished loading users');
  }

  private async checkForActiveChangeRequests(users: User[]): Promise<void> {
    const usersWithActiveChangeRequests = await firstValueFrom(this.changeRequestService.findAllActiveChangeRequestsByPayloadType(ChangeRequestPayloadType.USER));
    users.forEach(u => u.hasActiveChangeRequest = usersWithActiveChangeRequests.includes(u.id));
  }

  private sortUsers(users: User[]): User[] {
    const currUser = this.userDataSource.getUserAuthDetails().login;
    console.log('user: ', currUser);
    Utils.sortArrayByStringProperty(users, 'login');
    return users;
  }

  private getUserGroups(): void {
    this.dataService.currentUserGroups$.subscribe(groups => this.availableGroups = groups);
  }

  private deactivateFurtherModificationForUser(toUpdate: User): void {
    this.usersSource.getValue().forEach(user => {
      if (user.userId === toUpdate.userId) {
        user.hasActiveChangeRequest = true;
      }
    });
  }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <div class="expansion-panel-header">
        <span class="headerSpan headerToolbarSub">{{ 'database.input.query.header' | translate }}</span>
      </div>
      <pcs-loading-spinner [component]="this"></pcs-loading-spinner>
    </mat-expansion-panel-header>
    <form [formGroup]="queryFormGroup" *ngIf="queryFormGroup">
      <div class="dbQuerySelection">
        <mat-form-field *ngIf="(allQueries$ | async)?.length>0" class="querySelection">
          <mat-label>{{ 'database.query.selection' | translate }}</mat-label>
          <mat-select formControlName="querySelection" class="querySelectionBox">
            <mat-option></mat-option>
            <mat-option *ngFor="let queryOption of (allQueries$ | async)"
                        [value]="queryOption.name">{{ queryOption.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="dbSelection">
        <mat-form-field class="dbSelection">
          <mat-label>{{ 'database.db.selection' | translate }}</mat-label>
          <mat-select formControlName="dbSelection" class="dbSelectionBox">
            <mat-option></mat-option>
            <mat-option *ngFor="let dbOption of allDbs"
                        [value]="dbOption">{{ 'database.' + dbOption | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div>
          <mat-label *ngIf="!backupDate">{{ 'database.db.timestamp.text' | translate }}</mat-label>
          <mat-label *ngIf="backupDate">{{
              'database.db.timestamp.text.date' | translate  :{ param0: getDateTimeTranslation() }
            }}
          </mat-label>
        </div>
      </div>
    </form>
    <mat-grid-list cols="20" rowHeight="400px" class="dbQueryInput">
      <mat-grid-tile colspan="18">
        <div class="editor-div">
          <ngs-code-editor theme="vs" class="editor"
                           [codeModel]="codeModel$ | async"
                           (valueChanged)="onQueryInputChange($event)">
          </ngs-code-editor>
        </div>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <div class="button-box">
          <button class="normalButton deleteQueryBtn queryBtn" mat-stroked-button (click)="onDeleteQuery()"
                  *ngIf="this.queryOnProcess.query && (reqEditPermission | accessAllowed)">
            <mat-icon> delete_outline</mat-icon>
          </button>
          <button class="icon-only normalButton editQueryBtn queryBtn" mat-stroked-button
                  (click)="onEditQuery()"
                  *ngIf="(reqEditPermission | accessAllowed)">
            <mat-icon *ngIf="!isQuerySelected()">add</mat-icon>
            <mat-icon *ngIf="isQuerySelected()">edit</mat-icon>
          </button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-action-row>
      <button class="normalButton" mat-stroked-button (click)="triggerResultDownload()"
              *ngIf="resultsAvailable && (reqEditPermission | accessAllowed)">
        <i class="fa fa-download" aria-hidden="true"></i>
      </button>
      <button okAction class="normalButton" mat-stroked-button (click)="triggerQueryExecution()"
              [disabled]="!queryOnProcess.query || dbSelection.invalid">
        <span class="filter-button-span">{{ "database.button.query" | translate }}</span>
        <i class="fa fa-refresh" aria-hidden="true"></i>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>

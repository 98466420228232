/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Directive, ElementRef, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AccessRights} from '../datatypes/access-rights.enum';
import {UserDataSourceService} from '../services/user-data-source.service';
import {arrayIncludesAny} from 'pcs-commons/utils';
@Directive({
  selector: '[ifAnyPerm]'
})
export class IfAnyPermDirective {

  @Input()
  public set ifAnyPerm(reqPerms: AccessRights[]) {
    const reqPermList: AccessRights[] = [AccessRights.ADMIN_WEB];
    if (reqPerms) {
      reqPermList.push(...reqPerms);
    }
    console.log('checking for required permission for element: ', reqPermList);
    const hasPermission = arrayIncludesAny(this.userDataSource.currentUserRights, reqPermList);
    if (!hasPermission) {
      console.log('user does not have permission to element: ', this.el ? this.el : this.templateRef, ' \nhiding element...');
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private el: ElementRef,
    private userDataSource: UserDataSourceService) {
  }
}

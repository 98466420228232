<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data">
  <ng-container>
    <mat-table #table [dataSource]="tableDataSource" matSort class="taxes-table padding-lr-24">
      <div *ngFor="let col of dataColumns; let columnIndex = index">

        <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
          <ng-container *ngIf="col==='country' || col=== 'currency'">
            <mat-header-cell *matHeaderCellDef mat-sort-header>{{getI18nBase(col) | translate}}</mat-header-cell>
          </ng-container>
          <ng-container *ngIf="col!=='country' && col!== 'currency'">
            <mat-header-cell *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
          </ng-container>
          <mat-cell [styledColumn]="col" *matCellDef="let element ">
            <ng-container *ngIf="col==='country'">{{'global.country.' + element[col] | translate}}</ng-container>
            <ng-container *ngIf="col==='taxRate'" class="taxRateColumn">{{ toPercent(element[col]) }} %</ng-container>
            <ng-container *ngIf="col==='currency'">{{element[col]}}</ng-container>
            <ng-container *ngIf="col.startsWith('validFrom') || col.startsWith('validUntil')">{{getDateTimeTranslation(element[col])}}</ng-container>
          </mat-cell>
        </ng-container>
      </div>
      <ng-container matColumnDef="tableAction">
        <mat-header-cell *matHeaderCellDef class="actionCell"> {{'taxes.tableheader.action' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="actionCell">
          <button mat-mini-fab class="tableActionBtn" (click)="onEdit(element)" matTooltip="{{'taxes.edit'|translate}}">
            <mat-icon>edit</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </ng-container>
</div>

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data" class="scrollable-table-container pc-table">
  <ng-container>
    <mat-table #table [dataSource]="tableDataSource" class="padding-lr-24">
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container *ngIf="col!==actionColumn" matColumnDef="{{col}}">
          <mat-header-cell [styledColumn]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
          <mat-cell [styledColumn]="col" *matCellDef="let element">
            <ng-container
              *ngIf="col !== 'validFrom' && col !== 'validUntil' && col!=='country' && col!=='status' ">{{element[col]}}</ng-container>
            <ng-container
              *ngIf="col === 'validFrom' || col === 'validUntil'">{{getDateTranslation(element[col])}}</ng-container>
            <ng-container
              *ngIf="col === 'country' && element[col]">{{'global.country.' + element[col] | translate}}</ng-container>
            <ng-container
              *ngIf="col === 'status' && element[col]">{{'purchaseConditions.status.' + element[col] | translate}}</ng-container>
          </mat-cell>
        </ng-container>
      </div>
      <ng-container matColumnDef="tableAction" stickyEnd>
        <mat-header-cell *matHeaderCellDef class="actionCell"> {{'fleet.tableheader.action' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="actionCell">
          <button mat-mini-fab class="template mat-elevation-z0" (mouseenter)="changeStyle($event)"
                  matTooltip="{{'button.template.tooltip' | translate}}" *ngIf="!disableEdit"
                  (mouseleave)="changeStyle($event)" (click)="onUseAsTemplate(element)" [disabled]="disableTableBtns">
            <mat-icon>content_copy</mat-icon>
          </button>
          <button mat-mini-fab class="edit mat-elevation-z0" (mouseenter)="changeStyle($event)"
                  matTooltip="{{'button.edit.tooltip' | translate}}"
                  (mouseleave)="changeStyle($event)" (click)="editElement(element)"
                  *ngIf="!disableEdit && editAllowed(element)" [disabled]="disableTableBtns">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-mini-fab class="edit mat-elevation-z0" (mouseenter)="changeStyle($event)"
                  matTooltip="{{'button.details.tooltip' | translate}}"
                  (mouseleave)="changeStyle($event)" (click)="readElement(element)"
                  *ngIf="disableEdit || !editAllowed(element)" [disabled]="disableTableBtns">
            <mat-icon>list_alt</mat-icon>
          </button>
          <button mat-mini-fab class="delete mat-elevation-z0" (mouseenter)="changeStyle($event)"
                  matTooltip="{{'button.delete.tooltip' | translate}}"
                  *ngIf="!disableDelete" [disabled]="disableTableBtns || !deleteAllowed(element)"
                  (mouseleave)="changeStyle($event)" (click)="deleteElement(element)">
            <mat-icon> delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="dataColumns" class="normal-row"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;" [ngClass]="getRowClass(row)"></mat-row>
    </mat-table>
  </ng-container>
</div>

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
export class Message {
  constructor(
    public message?: string,
    public param0?: string,
    public param1?: string,
    public param2?: string) {}
}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChangeRequestDto } from '../datatypes/audit-flow/change-request-dto';
import { CDRBillingDto } from '../datatypes/CDRBillingDto';
import { ChangeRequestInfoOperatorData } from '../datatypes/audit-flow/change-request-info-operator-data';

@Injectable()
export class ChangeRequestSharedDataService {
  private changeRequestDataSource = new BehaviorSubject<ChangeRequestDto>(null);
  private currentChangeRequestData$ = this.changeRequestDataSource.asObservable();

  private operatorDataSource = new BehaviorSubject<ChangeRequestInfoOperatorData>(null);
  private currentOperatorData$ = this.operatorDataSource.asObservable();

  private cdrDataSource = new BehaviorSubject<CDRBillingDto>(null);
  private currentCdrData$ = this.cdrDataSource.asObservable();

  public updateCurrentOperator(newChangeRequestInfoOperatorData: ChangeRequestInfoOperatorData): void {
    console.log('updating operator data: ', newChangeRequestInfoOperatorData);
    this.operatorDataSource.next(newChangeRequestInfoOperatorData);
  }

  public updateChangeRequestData(changeRequest: ChangeRequestDto): void {
    console.log('updating changeRequest data: ', changeRequest);
    this.changeRequestDataSource.next(changeRequest);
  }

  public updateCurrentCdr(newCdr: CDRBillingDto): void {
    console.log('updating cdr: ', newCdr);
    this.cdrDataSource.next(newCdr);
  }

  public clearChangeRequestSharedDataService(): void {
    this.updateChangeRequestData(null);
    this.updateCurrentOperator(null);
  }

  public getCurrentOperatorObservable(): Observable<ChangeRequestInfoOperatorData> {
    return this.currentOperatorData$;
  }

  public getCurrentChangeRequestObservable(): Observable<ChangeRequestDto> {
    return this.currentChangeRequestData$;
  }

  public getCurrentCdrObservable(): Observable<CDRBillingDto> {
    return this.currentCdrData$;
  }
}

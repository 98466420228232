<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div class="mat-elevation-z1">
  <mat-toolbar class="headerToolbar">
    <span *ngIf="mode === 'CREATE'"> {{'purchaseCondition.create.title'|translate}}</span>
    <span
      *ngIf="mode !== 'CREATE' && currPurchaseCondition"> {{'purchaseCondition.details.title' | translate}}</span>
    <mat-spinner class="header-spinner" [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
  </mat-toolbar>
</div>
<div *ngIf="purchaseConditionForm">
  <form class="purchase-condition-form" [formGroup]="purchaseConditionForm">
    <mat-grid-list cols="2" rowHeight="100px">
      <mat-grid-tile colspan="1">
        <autocomplete-cpo class="autocomplete-cpo" [parentFormGroup]="purchaseConditionForm"></autocomplete-cpo>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{'purchaseConditions.status' | translate}}</mat-label>
          <mat-select formControlName="status">
            <mat-option *ngFor="let status of statusList" [value]="status">
              {{'purchaseConditions.status.' + status | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-radio-group [formControl]="radioButtonSelection" class="radio-button-group">
      <mat-radio-button class="radio-button"
                        value="{{COUNTRY}}">{{'purchaseConditions.country' | translate}}</mat-radio-button>
      <mat-radio-button class="radio-button"
                        value="{{TARIFF_ID}}">{{'purchaseConditions.tariffId' | translate}}</mat-radio-button>
      <mat-radio-button class="radio-button"
                        value="{{EVSEIDS}}">{{'purchaseConditions.evseIds' | translate}}</mat-radio-button>
    </mat-radio-group>
    <div class="input-field">
      <div *ngIf="isTariffSelected()">
        <mat-form-field appearance="outline">
          <mat-label>{{'purchaseConditions.tariffId' | translate}}</mat-label>
          <input matInput formControlName="tariffId">
        </mat-form-field>
      </div>
      <div *ngIf="isEvseIdListSelected()">
        <mat-form-field appearance="outline">
          <mat-label>{{'purchaseConditions.evseIds.input' | translate}}</mat-label>
          <textarea matInput formControlName="evseIds"></textarea>
        </mat-form-field>
      </div>
      <autocomplete-string-select *ngIf="isCountrySelected()"
                                  [inputControl]="country" class="autocomplete-select"
                                  [label]="'purchaseConditions.country'"
                                  [optionList]="countryList" [optionI18nBase]="'global.country.'">
      </autocomplete-string-select>
    </div>
    <mat-grid-list cols="2" rowHeight="100px">
      <mat-grid-tile colspan="1">
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>{{'purchaseConditions.maxPrice' | translate}}</mat-label>
          <input matInput formControlName="maxPrice">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <autocomplete-string-select [inputControl]="currency" class="input-field  autocomplete-select"
                                    [label]="'purchaseConditions.currency'"
                                    [optionList]="currencyList" [required]="true" *ngIf="currencyList.length>0">
        </autocomplete-string-select>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <mat-form-field appearance="outline" floatLabel="always" class="input-field">
          <mat-label>{{'purchaseConditions.validFrom' | translate}}</mat-label>
          <input matInput required formControlName="validFrom"
                 placeholder="{{'global.dateFormat' | translate | lowercase}}"
                 [matDatepicker]="validFromPicker" [min]="minValidFrom"
                 [errorStateMatcher]="datepickerFormErrorMatcher">
          <mat-datepicker-toggle matSuffix [for]="validFromPicker"></mat-datepicker-toggle>
          <mat-datepicker #validFromPicker></mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <mat-form-field appearance="outline" floatLabel="always" class="input-field">
          <mat-label>{{'purchaseConditions.validUntil' | translate}}</mat-label>
          <input matInput formControlName="validUntil" placeholder="{{'global.dateFormat' | translate | lowercase}}"
                 [matDatepicker]="validUntilPicker" [min]="minValidUntil"
                 [errorStateMatcher]="datepickerFormErrorMatcher">
          <mat-datepicker-toggle matSuffix [for]="validUntilPicker"></mat-datepicker-toggle>
          <mat-datepicker #validUntilPicker></mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
  <!-- table view for PurchaseConditionElement -->
  <mat-tab-group dynamicHeight class="purchase-condition-elements mat-elevation-z1" [mat-stretch-tabs]="false">
    <mat-tab>
      <ng-template mat-tab-label>
        <span>{{ 'purchaseCondition.tabPanel.elements' | translate }} *</span>
        <button mat-raised-button class="tab-action-btn icon-only" (click)="onAddNewElement()"
                *ngIf="canEditDetails() && (reqEditPermission | accessAllowed)">
          <mat-icon>add</mat-icon>
        </button>
      </ng-template>
      <pc-element-table
        [dataColumns]="pcElementTableColumns"
        [data]="currElements$"
        [actionButtons]="allowElementEdit() && (reqEditPermission | accessAllowed)"
        (editEvent)="onPCElementEdit($event)"
        (deleteEvent)="onPCElementDelete($event)"
        [disableDelete]="!allowElementRemove()"
        [i18nBase]="'purchaseCondition.element'">
      </pc-element-table>
    </mat-tab>
  </mat-tab-group>
</div>
<!-- action buttons -->
<div class="action-buttons-bottom">
  <button mat-raised-button class="dialogueButton dialogueButtonRight"
          *ngIf="isCreateOrEditMode() && (reqEditPermission | accessAllowed)"
          [disabled]="!saveAllowed()"
          (click)="save()">
    <mat-icon class="button-icon">save</mat-icon>
    <span>{{ 'button.save' | translate }}</span>
  </button>
  <button mat-raised-button class="dialogueButton"
          *ngIf="isActivationAllowed()" (click)="onActivate()">
    <mat-icon class="button-icon">play_arrow</mat-icon>
    <span>{{'button.activate' | translate}}</span>
  </button>
  <button mat-raised-button class="deactivate negative-action"
          *ngIf="isDeactivationAllowed()" (click)="onDeactivate()">
    <mat-icon class="button-icon">block</mat-icon>
    <span>{{'button.deactivate' | translate}}</span>
  </button>
  <button mat-raised-button class="delete-pc negative-action"
          *ngIf="isDeletedAllowed()" (click)="onMarkAsDeleted()">
    <mat-icon class="button-icon">delete_sweep</mat-icon>
    <span>{{'button.delete' | translate}}</span>
  </button>
  <button mat-raised-button class="dialogueButton" (click)="goBack()">
    <mat-icon class="button-icon">arrow_back</mat-icon>
    <span>{{ 'button.back' | translate }}</span>
  </button>
</div>


<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="vehicleImportResult.length <= 0">
  <div id="platformList" class="platform-list" *ifAnyPerm="reqPermForPlatformList">
    <mat-toolbar class="headerToolbar mat-elevation-z1">
      <span class="headerSpan">{{'configurations.platform.list' | translate }}</span>
      <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loadingPlatforms"></mat-spinner>
    </mat-toolbar>
    <mat-grid-list cols="2" [rowHeight]="requiredRowHeight">
      <mat-grid-tile>
        <platform-list-card class="platform-list-card"
                            [titleKey]="'configurations.platform.dataPriority'"
                            [platformList]="platformListByDataPriority"
                            (itemMoveEvent)="handleDataPriorityChange($event)">
        </platform-list-card>
      </mat-grid-tile>
      <mat-grid-tile>
        <platform-list-card class="platform-list-card"
                            [titleKey]="'configurations.platform.chargingPriority'"
                            [platformList]="platformListByChargingPriority"
                            (itemMoveEvent)="handleChargingPriorityChange($event)">
        </platform-list-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <div id="import" class="mat-elevation-z1" *ifAnyPerm="reqPermForVehicleMasterData">
    <mat-toolbar class="headerToolbar">
      <span class="headerSpan">{{'configurations.vehicles.import' | translate }}</span>
      <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="importOnProcess"></mat-spinner>
    </mat-toolbar>
    <import-form (importEvent)="importVehicles($event)"
                 [importAllowed]="editPermForVehicleMasterData | accessAllowed"></import-form>
  </div>
</div>
<div *ngIf="vehicleImportResult.length > 0">
  <div id="importResult" class="mat-elevation-z1">
    <mat-toolbar class="headerToolbar">
      <span class="headerSpan">{{'configurations.vehicles.importResult' | translate }}</span>
    </mat-toolbar>
    <scrollable-table id="importResultTable" [data]="observableOfVehicleImportResult()"
                      class="padding-lr-24"
                      [i18nBase]="'vehicles.import.tableHeader'"
                      [dataColumns]="importResultTableColumns" [actionButtons]="false">
    </scrollable-table>
  </div>
  <mat-divider style="height: 10px;"></mat-divider>
  <button mat-raised-button class="normalButton back-button" (click)="onBack()">
    {{ 'button.back' | translate}}
  </button>
</div>

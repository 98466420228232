<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data" class="divContainer">
  <mat-table [dataSource]="tableDataSource" class="padding-lr-24">
    <div *ngFor="let col of dataColumns; let columnIndex = index">
      <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
        <mat-header-cell class="tableHeaderCell" *matHeaderCellDef>{{getI18nBase(col) | translate}}
        </mat-header-cell>

        <mat-cell *matCellDef="let element ">
          <div *ngIf="col !== userGroupColumn || !elementIsOnProcess(element)">{{element[col]}}</div>
          <div *ngIf="col === userGroupColumn && elementIsOnProcess(element)">
            <mat-form-field floatLabel="never">
              <mat-select [formControl]="userGroupCtrl" [autofocus]="elementIsOnProcess(element)">
                <mat-option></mat-option>
                <mat-option *ngFor="let group of availableGroups" [value]="group.name">
                  {{group.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-cell>
      </ng-container>
    </div>
    <ng-container matColumnDef="tableAction" stickyEnd>
      <mat-header-cell *matHeaderCellDef class="actionCell"> {{'fleet.tableheader.action' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="actionCell">
        <div *ngIf="!elementIsOnProcess(element) && !readOnly(element)" [matTooltip]="tooltip(element) | translate"
             id="normalModeDiv">
          <button mat-mini-fab class="edit mat-elevation-z0" (mouseenter)="changeStyle($event)"
                  (mouseleave)="changeStyle($event)" (click)="enableEditForElement(element)"
                  [disabled]="element.hasActiveChangeRequest"
                  *ngIf="!disableEdit">
            <mat-icon> edit</mat-icon>
          </button>
          <button mat-mini-fab class="delete mat-elevation-z0" (mouseenter)="changeStyle($event)"
                  (mouseleave)="changeStyle($event)" (click)="deleteElement(element)"
                  [disabled]="element.hasActiveChangeRequest"
                  *ngIf="!disableDelete">
            <mat-icon> delete_outline</mat-icon>
          </button>
        </div>
        <div id="editModeDiv" *ngIf="elementIsOnProcess(element)">
          <button okAction mat-mini-fab class="edit mat-elevation-z0" (mouseenter)="changeStyle($event)"
                  (mouseleave)="changeStyle($event)" (click)="doEditUser(element)">
            <mat-icon> done</mat-icon>
          </button>
          <button cancelAction mat-mini-fab class="delete mat-elevation-z0" (mouseenter)="changeStyle($event)"
                  (mouseleave)="changeStyle($event)" (click)="onCancel()">
            <mat-icon> close</mat-icon>
          </button>
        </div>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
  </mat-table>
</div>

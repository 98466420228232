/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {IopPlatformDto} from '../datatypes/iop-platform-dto';
import {RoamingPlatformService} from '../services/http/roaming-platform.service';
import {DragDropEventData} from '../datatypes/drag-drop-event-data';
import {VehicleService} from '../services/http/vehicle.service';
import {VehicleImportRecord} from '../datatypes/vehicle-import-record';
import {Observable, of} from 'rxjs';
import {ImportAction} from '../datatypes/import-action.enum';
import {Message} from '../datatypes/message';
import {AccessRights} from '../datatypes/access-rights.enum';

@Component({
  selector: 'pcs-configurations',
  templateUrl: './configurations.component.html',
  styleUrls: ['./configurations.component.css']
})
export class ConfigurationsComponent extends AbstractNotificationHandler implements OnInit {

  public platformList: IopPlatformDto[] = [];
  public loadingPlatforms = false;
  public importOnProcess = false;
  public importResultTableColumns = VehicleImportRecord.tableColumns;
  public vehicleImportResult: VehicleImportRecord[] = [];
  public resultHasFailedRecords = false;

  public reqPermForPlatformList = [AccessRights.CPO_AND_PARTNER_READ_WEB, AccessRights.CPO_AND_PARTNER_EDIT_WEB];
  public reqPermForVehicleMasterData = [AccessRights.MASTERDATA_READ_WEB, AccessRights.MASTERDATA_EDIT_WEB];
  public editPermForVehicleMasterData = [AccessRights.MASTERDATA_EDIT_WEB];

  constructor(
    private vehicleService: VehicleService,
    private platformService: RoamingPlatformService) {
    super();
  }

  public ngOnInit(): void {
    this.loadingPlatforms = true;
    this.platformService.getRoamingPlatforms()
      .subscribe({
        next:
          (platforms) => this.fillPlatformList(platforms),
        error: () => this.loadingPlatforms = false
      });
  }

  public get platformListByDataPriority(): IopPlatformDto[] {
    const temp: IopPlatformDto[] = Object.assign([], this.platformList);
    return temp.sort((p1, p2) => p1.dataPriority - p2.dataPriority);
  }

  public get platformListByChargingPriority(): IopPlatformDto[] {
    const temp: IopPlatformDto[] = Object.assign([], this.platformList);
    return temp.sort((p1, p2) => p1.chargingPriority - p2.chargingPriority);
  }

  public get requiredRowHeight(): string {
    return (this.platformList.length + 1) * 65 + 'px';
  }

  public handleDataPriorityChange(eventData: DragDropEventData): void {
    console.log('got priority change event: ', eventData);
    const temp: IopPlatformDto = eventData._item;
    const dataPriority = eventData._currentIndex;
    const toUpdate: IopPlatformDto = new IopPlatformDto(temp.id, temp.name, dataPriority, temp.chargingPriority);
    console.log('Platform to update: ', toUpdate);
    this.updatePlatformData(toUpdate);
  }

  public handleChargingPriorityChange(eventData: DragDropEventData): void {
    console.log('got priority change event: ', eventData);
    const temp: IopPlatformDto = eventData._item;
    const chargingPriority = eventData._currentIndex;
    const toUpdate: IopPlatformDto = new IopPlatformDto(temp.id, temp.name, temp.dataPriority, chargingPriority);
    console.log('Platform to update: ', toUpdate);
    this.updatePlatformData(toUpdate);
  }

  public importVehicles(file: File): void {
    if (!file) {
      return;
    }
    console.log('Importing vehicle master data file "%s"', file.name);
    const reader = new FileReader();
    // set the reader to make the import rest call when the file is loaded
    reader.onloadstart = (): void => {
      this.importOnProcess = true;
      console.log('[%s] import start', new Date().toLocaleDateString());
    };
    reader.onloadend = (): void => {
      console.log('[%s] import end', new Date().toLocaleDateString());
      const readerResult = reader.result as string;
      this.vehicleService
        .importMasterData(new Blob([readerResult.split(',')[1]]))
        .subscribe({
          next: (importResult) => {
            this.importOnProcess = false;
            console.log(importResult);
            this.vehicleImportResult = importResult.result;
            for (const record of this.vehicleImportResult) {
              if (ImportAction.FAILED === record.action) {
                this.resultHasFailedRecords = true;
                this.showErrorMessage();
                break;
              }
            }
          },
          error: () => this.importOnProcess = false
        });
    };
    reader.readAsDataURL(file);
  }

  private updatePlatformData(toUpdate: IopPlatformDto): void {
    this.loadingPlatforms = true;
    this.platformService.updateRoamingPlatform(toUpdate)
      .subscribe({
        next: (platforms) => this.fillPlatformList(platforms),
        error: () => this.loadingPlatforms = false
      });
  }

  private fillPlatformList(platforms): void {
    this.platformList = IopPlatformDto.toIopPlatformDto(platforms);
    this.loadingPlatforms = false;
  }

  public observableOfVehicleImportResult(): Observable<VehicleImportRecord[]> {
    return of(this.vehicleImportResult);
  }

  /**
   * this is used to go back to normal configurations view from the import result table
   */
  public onBack(): void {
    this.vehicleImportResult = [];
    this.resultHasFailedRecords = false;
  }

  private showErrorMessage(): void {
    const msg: Message = new Message();
    msg.message = 'configurations.vehicles.import.error';
    this.showError(msg);
  }

}

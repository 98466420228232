/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
export enum ChangeRequestAction {
  ACTIVATE = 'ACTIVATE',
  DEACTIVATE = 'DEACTIVATE',
  CREATE = "CREATE",
  MODIFY = "MODIFY",
  DELETE = 'DELETE',
  REMOVE_CDR_FROM_BILLRUN = 'REMOVE_CDR_FROM_BILLRUN',
  CORRECTION = 'CORRECTION',
  REIMBURSEMENT = 'REIMBURSEMENT',
  SET_PREFERRED_ATTRIBUTE = 'SET_PREFERRED_ATTRIBUTE'
}

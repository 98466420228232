/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserCredential } from '../../datatypes/user-credential';

@Component({
  selector: 'fleet-create-response',
  templateUrl: './fleet-create-response.component.html',
  styleUrls: ['./fleet-create-response.component.css']
})
export class FleetCreateResponseComponent{
  @Input() users: UserCredential[] = [];
  @Input() fleetId: string;

  @Output() okEventEmitter = new EventEmitter<Boolean>();

  constructor(private router: Router) { }

  goToFleetsPage() {
    this.okEventEmitter.emit(true);
    this.router.navigate(['/fleets']);
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {FormGroup, ValidationErrors, Validator} from '@angular/forms';
import {FormUtils} from 'pcs-commons/utils';
import {DateTime} from "luxon";
import {combineDateAndTime} from "./date-range-utils";
import {TranslationHelperService} from "pcs-commons/http";
import {TranslateService} from "@ngx-translate/core";

type TimeFrame = {
  validFromMillis: number;
  validUntilMillis: number;
}

/**
 * This Service has the same purpose as the DateRangeValidatorService.
 * The difference is that, it serves validation for FromGroups which has the validFromDate/validFromTime and
 * validUntilDate/validUntilTime dates inside its child groups
 */
@Injectable({
  providedIn: 'root'
})
export class DateRangeValidator2Service implements Validator {
  constructor(private translateService: TranslateService,
              private translationHelperService: TranslationHelperService) {
  }

  public validate(formGroup: FormGroup): ValidationErrors | null {
    const timeFrames = [] as Array<TimeFrame>;
    const epochAmount = this.fetchAllTimeFrames(formGroup, timeFrames);

    if (epochAmount > 1) { // there should only be one epoch validFrom
      const msg = this.translateService.instant("validation.input.invalidDateRange.onlyOneEpoch");
      return {invalidDateRange: {message: msg}};
    }

    if (timeFrames.length <= 0) {
      return null;
    }

    const sortedTimeFrames = timeFrames.sort((a, b) => a.validFromMillis - b.validFromMillis);
    return this.validateDateRange(sortedTimeFrames);
  }

  private fetchAllTimeFrames(formGroup: FormGroup, timeFrames: TimeFrame[]): number {
    let epochAmount = 0;
    Object.keys(formGroup.controls).forEach((name) => {
      const childGroup = FormUtils.getFormControl(formGroup, name);
      if (childGroup instanceof FormGroup) {
        const childInput = childGroup.value;
        const validFromMillis = combineDateAndTime("validFromDate", childInput, 0)?.toMillis() ?? 0;
        const validUntilMillis = combineDateAndTime("validUntilDate", childInput, 999)?.toMillis();
        if (validFromMillis === 0) {
          epochAmount++;
        }

        timeFrames.push({
          validFromMillis,
          validUntilMillis
        });
      }
    });

    return epochAmount;
  }

  private validateDateRange(sortedTimeFrames: TimeFrame[]): ValidationErrors | null {
    let pointInTime = -1;
    for (const timeFrame of sortedTimeFrames) {
      if (pointInTime === null || pointInTime === undefined) {
        const message = this.translateService.instant("validation.input.invalidDateRange.fillEveryField");
        return {invalidDateRange: {message}};
      }

      const validFrom = timeFrame.validFromMillis;
      if (isNaN(validFrom)) {
        const message = this.translateService.instant("validation.input.invalidDateRange.fillEveryField");
        return {invalidDateRange: {message}};
      }
      if (validFrom !== pointInTime + 1) {
        const from = this.translationHelperService.translateDateTimeSafe(DateTime.fromMillis(validFrom, {zone: 'utc'}));
        const till = this.translationHelperService.translateDateTimeSafe(DateTime.fromMillis(pointInTime, {zone: 'utc'}));
        const type = (validFrom > pointInTime + 1) ? 'gap' : 'overlap';
        const size = validFrom - pointInTime + 1;
        const message = this.translateService.instant(
          `validation.input.invalidDateRange.${type}`, {
            param0: from,
            param1: till
          });
        return {invalidDateRange: {message}};
      }
      pointInTime = timeFrame.validUntilMillis;
    }
    if (pointInTime) {
      const msg = this.translateService.instant("validation.input.invalidDateRange.fillEveryField");
      return {invalidDateRange: {message: msg}};
    }
    return null;
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Action} from "pcs-commons/datatypes";
import {OcpiPartner} from "../datatypes/ocpi-partner";

export class OcpiPartnerDialogData {
  public mode: Action;
  public partnerSource: OcpiPartner;
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div id="importResult" class="mat-elevation-z1">
  <mat-toolbar class="headerToolbar">
    <span class="headerSpan">{{'purchaseConditions.tariffs.importResult' | translate }}</span>
  </mat-toolbar>
  <tariff-import-result-table id="importResultTable" [data]="tariffImportResult$"
                              [i18nBase]="'purchaseConditions.import.tableHeader'"
                              [dataColumns]="importResultTableColumns" [actionButtons]="false">
  </tariff-import-result-table>
</div>
<mat-divider style="height: 10px;"></mat-divider>
<button mat-raised-button class="normalButton back-button" (click)="onBack()">
  <mat-icon class="button-icon">arrow_back</mat-icon>
  <span>{{ 'button.back' | translate }}</span>
</button>

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, Input, Renderer2 } from '@angular/core';
import { OptimizedTableBaseComponent } from 'pcs-commons/data-tables';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { BasicFeeTableColumns } from '../../datatypes/basicfee-record';
import { FormUtils } from 'pcs-commons/utils';

@Component({
  selector: 'edit-basic-fee-table',
  templateUrl: './edit-basic-fee-table.component.html',
  styleUrls: [
    '../price-table-styles.css',
    '../scrollable-table/scrollable-table.component.css',
    './edit-basic-fee-table.component.css'
  ]
})
export class EditBasicFeeTableComponent extends OptimizedTableBaseComponent {
  @Input() public basicFeeForm: FormGroup;

  public readonly VALID_FROM_DATE = BasicFeeTableColumns.VALID_FROM_DATE;
  public readonly VALID_FROM_TIME = BasicFeeTableColumns.VALID_FROM_TIME;
  public readonly VALID_UNTIL_DATE = BasicFeeTableColumns.VALID_UNTIL_DATE;
  public readonly VALID_UNTIL_TIME = BasicFeeTableColumns.VALID_UNTIL_TIME;
  public readonly editableColumns: string[] = [
    this.VALID_FROM_DATE,
    this.VALID_FROM_TIME,
    this.VALID_UNTIL_DATE,
    this.VALID_UNTIL_TIME,
    BasicFeeTableColumns.BASIC_FEE,
    BasicFeeTableColumns.DEFAULT_PRICE_KWH,
    BasicFeeTableColumns.DEFAULT_PRICE_MIN
  ];

  constructor(renderer: Renderer2) {
    super(renderer);
  }

  public isColEditable(col: string): boolean {
    return this.editableColumns.includes(col);
  }

  public getFormControl(formGroup: FormGroup, col: string): AbstractControl {
    return FormUtils.getFormControl(formGroup, col);
  }

  public getValue(formGroup: FormGroup, col: string): any {
    return FormUtils.getValue(formGroup, col);
  }

  protected readonly FormControl = FormControl;
}

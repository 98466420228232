<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data" class="div-container">
  <div class="scrollable-table-container">
    <mat-table [dataSource]="tableDataSource">
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container *ngIf="col === 'vin'" matColumnDef="{{col}}" sticky>
          <mat-header-cell [styledColumn]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
          <mat-cell [styledColumn]="col" *matCellDef="let element">
            <ng-container>{{element[col]}}</ng-container>
          </mat-cell>
        </ng-container>
        <ng-container *ngIf="col !== actionColumn && col !== 'vin'" matColumnDef="{{col}}">
          <mat-header-cell [styledColumn]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
          <mat-cell [styledColumn]="col" *matCellDef="let element">
            <ng-container
              *ngIf="col !== 'pcid' && col !== 'pncCertificateValidity' && col !== 'status' && col !== 'jobStatus' && col !== 'plugAndChargeEnabled'">{{element[col]}}</ng-container>
            <ng-container
              *ngIf="col === 'pncCertificateValidity'">{{translateDate(element[col])}}</ng-container>
            <ng-container
              *ngIf="col === 'status'">{{'global.label.' + element[col] | translate}}</ng-container>
            <ng-container
              *ngIf="col === 'jobStatus' && element[col]">{{'electricVehicle.jobStatus.' + element[col] | translate}}</ng-container>
            <ng-container
              *ngIf="col === 'plugAndChargeEnabled'">
              <svg-icon [viewBox]="'0 0 24 24'" [svgClass]="'pnc-icon'"
                        *ngIf="element[col]===true"
                        src="/assets/images/ic_plugandcharge_green.svg">
              </svg-icon>
            </ng-container>

            <ng-container *ngIf="col === 'pcid'">
              <div *ngIf="!evIsInEdit(element)" matTooltip="{{editButtonTooltip(element) | translate}}">
                <div class="pcidLabel">
                  <span>{{ element[col] }}</span>
                </div>
                <button mat-mini-fab class="tableActionBtn" (click)="enableEditForEv(element)"
                        *ngIf="actionButtons && pcidUpdateAllowed(element) "
                        [disabled]="loading || isjobPending(element)">
                  <mat-icon>edit</mat-icon>
                </button>
              </div>
              <div *ngIf="evIsInEdit(element)">
                <mat-form-field class="pcidInput">
                  <input matInput [formControl]="pcidControl" [maxlength]="pcidMaxLength"
                         [readonly]="disableEditPcid()">
                  <mat-error *ngIf="pcidControl.invalid">
                    {{ getError(pcidControl) | async}}
                  </mat-error>
                </mat-form-field>
                <button mat-mini-fab class="tableActionBtn" (click)="updatePcid(element)"
                        [disabled]="disableEditPcid()" [class.spinner]="updateEvOngoing(element)">
                  <mat-icon [class.hidden]="updateEvOngoing(element)">done</mat-icon>
                </button>
                <button mat-mini-fab class="tableActionBtn" (click)="cancelUpdatePcid()"
                        [disabled]="disableEditPcid()">
                  <mat-icon>clear</mat-icon>
                </button>
              </div>
            </ng-container>

          </mat-cell>
        </ng-container>
      </div>

      <ng-container matColumnDef="tableAction" stickyEnd>
        <mat-header-cell *matHeaderCellDef
                         class="actionCell center"> {{'tableHeader.action' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="actionCell center">
          <button mat-mini-fab class="delete tableActionBtn" (click)="revokePnCCert(element)"
                  matTooltip="{{'electricVehicle.tooltip.revokeCert' | translate}}"
                  *ngIf="!(disableEdit || revokeNotAllowed(element))">
            <mat-icon> remove_circle_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </div>
</div>

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {AfterViewInit, Component, OnDestroy, Renderer2, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {Parameters} from "../../global/parameters";
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {TranslationHelperService} from 'pcs-commons/http';

@Component({
  selector: 'pcs-invoice-order-items-table-component',
  templateUrl: './invoice-order-items-table.component.html',
  styleUrls: ['../data-table.component.css',
    '../action-column-style.css',
    '../scrollable-table/scrollable-table.component.css',
    './invoice-order-items-table.component.css']
})
export class InvoiceOrderItemsTableComponent extends OptimizedTableBaseComponent implements AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public readonly pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS;

  constructor(renderer: Renderer2,
              private translateHelper: TranslationHelperService) {
    super(renderer);
  }

  public ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator;
  }

  public translateDate(date: string): string {
    return this.translateHelper.translateDateTimeSafe(date);
  }

  public translateCountry(country: unknown): unknown {
    return this.translateHelper.translateCountryNotFromBE(country);
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {WorkSheetDto} from './work-sheet-dto';
import {DateUtils} from 'pcs-commons/utils';
import {HeaderEnabled} from 'pcs-commons/http';
import {HttpClient} from '@angular/common/http';
import {Parameters} from "pcs-commons/global";
import {DateTime} from "luxon";

@Injectable({
  providedIn: 'root'
})
export class StatisticService extends HeaderEnabled {
  constructor(
    private http: HttpClient,
    @Inject('env') private env: { [key: string]: string | boolean; }
  ) {
    super();
  }

  public getStatisticsV2(
    query: string, fromDate: Date | DateTime, toDate: Date | DateTime, offset: number, pageSize: number): Observable<WorkSheetDto[]> {
    let startDate: string;
    if (fromDate instanceof DateTime) {
      startDate = fromDate.toFormat('yyyy-LL-dd');
    } else {
      console.log("TODO: initialize the datepicker with DateTime instead of date");
      startDate = !fromDate ? null : fromDate.toISOString().split('T')[0];
    }
    let endDate: string;
    if (toDate instanceof DateTime) {
      endDate = toDate.toFormat('yyyy-LL-dd');
    } else {
      console.log("TODO: initialize the datepicker with DateTime instead of date");
      endDate = !toDate ? null : toDate.toISOString().split('T')[0];
    }
    const options = {
      headers: this.headers,
      params: {
        start: startDate,
        end: endDate,
        offset: offset.toString(),
        pageSize: pageSize.toString()
      }
    };
    return this.http.get<WorkSheetDto[]>(this.env.statisticV2Url + query, options);
  }

  public getBilledCdrStatistics(
    fleetId: string, invoiceId: number, offset: number, pageSize: number): Observable<WorkSheetDto[]> {
    const url = this.env.invoiceEndpoint + '/' + fleetId + '/billed-cdrs/' + invoiceId;
    const options = {
      headers: this.headers,
      params: {
        offset: offset.toString(),
        pageSize: pageSize.toString()
      }
    };
    return this.http.get<WorkSheetDto[]>(url, options);
  }


}

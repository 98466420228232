<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div class="scrollable-table-container">
  <mat-table #table [dataSource]="tableDataSource" class="padding-lr-24">
    <ng-container *ngFor="let col of dataColumns; let columnIndex = index">
      <ng-container *ngIf="!colsWithCustomColDef.includes(col)" matColumnDef="{{ col }}">
        <mat-header-cell
          *matHeaderCellDef
          [styledColumn]="col"
        >
          {{ getI18nBase(col) | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [styledColumn]="col" class="cpoTableCell">
          {{ element[col] }}
        </mat-cell>
      </ng-container>

      <ng-container *ngIf="col === displayNameColumn" matColumnDef="{{ col }}">
        <mat-header-cell *matHeaderCellDef [styledColumn]="col">
          {{ getI18nBase(col) | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" [styledColumn]="col" class="cpoTableCell">
          <div *ngIf="!subOperatorIsInEdit(element)">
            <div class="displayNameLabel">
              <span>{{ element[col] }}</span>
            </div>
            <button
              (click)="enableEditForSubOperator(element)"
              *ngIf="editAllowed"
              [disabled]="updateOnProcess"
              class="tableActionBtn"
              mat-mini-fab
            >
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <div *ngIf="subOperatorIsInEdit(element)">
            <mat-form-field class="displayNameInput">
              <input
                [formControl]="displayNameControl"
                [maxlength]="displayNameMaxLength"
                [readonly]="disableEditUpdateName()"
                matInput
              />
              <mat-error *ngIf="displayNameControl.invalid">
                {{ getError(displayNameControl) | async }}
              </mat-error>
            </mat-form-field>
            <button
              (click)="updateDisplayName(element)"
              [class.spinner]="updateDisplayNameOngoing(element)"
              [disabled]="disableEditUpdateName()"
              class="icon-only tableActionBtn push-down"
              mat-mini-fab
            >
              <mat-icon [class.hidden]="updateDisplayNameOngoing(element)">done</mat-icon>
            </button>
            <button
              (click)="cancelUpdateDisplayName()"
              [disabled]="disableEditUpdateName()"
              class="tableActionBtn push-down"
              mat-mini-fab
            >
              <mat-icon>clear</mat-icon>
            </button>
          </div>
        </mat-cell>
      </ng-container>
    </ng-container>

    <ng-container matColumnDef="{{ energySourceColumn }}">
      <mat-header-cell *matHeaderCellDef class="center energy-cell">
        {{ getI18nBase(energySourceColumn) | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center energy-cell cpoTableCell">
        <button
          (click)="toggleRenewableEnergy(element)"
          [disabled]="!editAllowed"
          class="cpo-table-button tableActionBtn"
          mat-mini-fab
          matTooltip="{{
            (element.renewableEnergySource ? 'operators.renewableEnergy.tooltip' : 'operators.nonrenewableEnergy.tooltip')
              | translate
          }}"
        >
          <svg-icon *ngIf="element.renewableEnergySource" src="/assets/images/renewable_energy.svg"></svg-icon>
          <svg-icon *ngIf="!element.renewableEnergySource" src="/assets/images/non_renewable_energy.svg"></svg-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="{{ showStationName }}">
      <mat-header-cell *matHeaderCellDef class="center station-name-cell ">
        {{ getI18nBase(showStationName) | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center station-name-cell cpoTableCell">
        <button
          (click)="toggleShowStationName(element)"
          [disabled]="!editAllowed"
          class="cpo-table-button tableActionBtn"
          mat-mini-fab
          matTooltip="{{
            (element.showStationName ? 'operators.notShowStationName.tooltip' : 'operators.showStationName.tooltip') | translate
          }}"
        >
          <mat-icon [ngClass]="element.showStationName ? 'green-icon' : 'grey-icon'">ev_station</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="{{ pncColumn }}">
      <mat-header-cell *matHeaderCellDef class="center pnc-cell ">
        {{ getI18nBase(pncColumn) | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center pnc-cell cpoTableCell">
        <button
          (click)="togglePlugAndCharge(element)"
          [disabled]="!editAllowed"
          class="pnc-button tableActionBtn"
          mat-mini-fab
          matTooltip="{{
            (element.enablePlugAndCharge ? 'operators.disablePlugAndCharge.tooltip' : 'operators.enablePlugAndCharge.tooltip')
              | translate
          }}"
        >
          <svg-icon
            *ngIf="element.enablePlugAndCharge"
            [svgClass]="'pnc-icon'"
            [viewBox]="'0 0 24 24'"
            src="/assets/images/ic_plugandcharge_green.svg"
          >
          </svg-icon>
          <svg-icon
            *ngIf="!element.enablePlugAndCharge"
            [svgClass]="'pnc-icon'"
            [viewBox]="'0 0 24 24'"
            src="/assets/images/ic_plugandcharge_grey.svg"
          >
          </svg-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="{{ rfidColumn }}">
      <mat-header-cell *matHeaderCellDef class="center rfid-cell ">
        {{ getI18nBase(rfidColumn) | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center rfid-cell cpoTableCell">
        <button
          (click)="toggleRfidAuth(element)"
          [disabled]="!editAllowed"
          class="cpo-table-button tableActionBtn"
          mat-mini-fab
          matTooltip="{{
            (element.enableRfidAuth ? 'operators.disableRfidAuth.tooltip' : 'operators.enableRfidAuth.tooltip') | translate
          }}"
        >
          <mat-icon [ngClass]="element.enableRfidAuth ? 'green-icon' : 'grey-icon'">branding_watermark</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="{{ remoteColumn }}">
      <mat-header-cell *matHeaderCellDef class="center remote-cell ">
        {{ getI18nBase(remoteColumn) | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center remote-cell cpoTableCell">
        <button
          (click)="toggleRemoteAuth(element)"
          [disabled]="!editAllowed"
          class="cpo-table-button tableActionBtn"
          mat-mini-fab
          matTooltip="{{
            (element.enableRemoteAuth ? 'operators.disableRemoteAuth.tooltip' : 'operators.enableRemoteAuth.tooltip') | translate
          }}"
        >
          <mat-icon [ngClass]="element.enableRemoteAuth ? 'green-icon' : 'grey-icon'">smartphone</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="{{ preferredColumn }}">
      <mat-header-cell *matHeaderCellDef class="center preferred-cell ">
        {{ getI18nBase(preferredColumn) | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="center preferred-cell cpoTableCell">
        <button
          (click)="togglePreferred(element)"
          [disabled]="!editAllowed"
          class="cpo-table-button tableActionBtn"
          mat-mini-fab
          matTooltip="{{ 'operators.togglePreferred.tooltip' | translate }}"
        >
          <mat-icon [ngClass]="element.preferred ? 'green-icon' : 'grey-icon'">wb_sunny</mat-icon>
        </button>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns"></mat-row>
  </mat-table>
</div>

import { QueryHistoryState } from './query-history-state';

import { DatabaseName } from '../database-queries/DatabaseName';

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
export class QueryHistoryEntryDto {
  public userId: string;
  public userLogin: string;
  public timestamp: string;
  public resultCount: number;
  public query: string;
  public state: QueryHistoryState;
  public durationInMs: number;
  public dbName: DatabaseName;

  public static readonly USER_ID = 'userId';
  public static readonly USER_LOGIN = 'userLogin';
  public static readonly TIMESTAMP = 'timestamp';
  public static readonly RESULT_COUNT = 'resultCount';
  public static readonly QUERY = 'query';
  public static readonly STATE = 'state';
  public static readonly DURATION_IN_MS = 'durationInMs';
  public static readonly DATABASENAME = 'database';

  public static readonly dataColumns: string[] = [
    QueryHistoryEntryDto.USER_LOGIN,
    QueryHistoryEntryDto.TIMESTAMP,
    QueryHistoryEntryDto.DATABASENAME,
    QueryHistoryEntryDto.RESULT_COUNT,
    QueryHistoryEntryDto.STATE,
    QueryHistoryEntryDto.DURATION_IN_MS,
    QueryHistoryEntryDto.QUERY
  ];
}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AbstractNotificationHandler } from 'pcs-commons/notification';
import { PermissionAware } from '../permission-aware';
import { AccessRights } from '../datatypes/access-rights.enum';
import { ConfirmationDialogComponent } from '../dialog/confirmation-dialog/confirmation-dialog.component';
import { Message } from '../datatypes/message';
import { MatDialog } from '@angular/material/dialog';
import { KeyTagImportService } from '../services/http/key-tag-import.service';
import { ImportFormComponent } from '../import-form/import-form.component';

@Component({
  selector: 'key-tag-import',
  templateUrl: './key-tag-import.component.html',
  styleUrls: ['./key-tag-import.component.css']
})
export class KeyTagImportComponent extends AbstractNotificationHandler implements PermissionAware {
  @ViewChild(ImportFormComponent) importFormComponent!: ImportFormComponent;
  public readonly reqEditPermission: AccessRights[] = [AccessRights.CUSTOMERMANAGEMENT_EDIT_WEB];
  public keyTagImportForm: FormGroup;
  public importing: boolean;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly keyTagImportService: KeyTagImportService,
    private readonly dialog: MatDialog) {
    super();
    this.keyTagImportForm = this.formBuilder.group({});
  }

  private handleImportResult(success: boolean): void {
    const msg = new Message();
    if (success) {
      msg.message = 'keyTagImport.response.success';
      this.showInfo(msg);
      this.importFormComponent.clearFileInput()
    } else {
      msg.message = 'keyTagImport.response.error';
      this.showError(msg);
    }
  }

  public handleImport(importFile: File): void {
    if (!importFile) {
      return;
    }

    let msg = 'keyTagImport.confirm.message';
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: new Message(msg),
          actionKey: 'global.button.yes'
        }
      }
    );
    dialogRef.afterClosed().subscribe((positive) => {
      if (positive) {
        this.importFile(importFile);
      }
    });
  }

  public importFile(importFile: File): void {
    console.log('Importing attributes from file "%s"', importFile.name);
    this.importing = true;

    this.keyTagImportService.importKeyTagFile(importFile).subscribe({
      next: () => {
        this.importing = false;
        console.log('successfully imported');
        this.handleImportResult(true);
      },
      error: (error) => {
        this.importing = false;
        console.log('error on file import: ', error);
        this.handleImportResult(false);
      }
    });
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
export class CardInventoryImportRecord {
  public static readonly tableColumns: string[] = ['row', 'card', 'uid', 'action', 'validationIssue'];
  public row: number;
  public card: string;
  public uid: string;
  public action: string;
  public validationIssue: string;
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data" class="div-container">
  <div class="scrollable-table-container">
    <mat-table [dataSource]="tableDataSource">
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
          <mat-header-cell [styledColumn]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
          <mat-cell [styledColumn]="col" *matCellDef="let element">
            <ng-container
              *ngIf="col !== 'sessionStart' && col !== 'sessionEnd' && col !== 'price' && col !== 'financeEnablerStatus'">{{element[col]}}</ng-container>
            <ng-container
              *ngIf="col === 'sessionStart' || col === 'sessionEnd'">{{element[col] | translateDateTime: update}}</ng-container>
            <ng-container
              *ngIf="col === 'price' && element[col]">{{element[col]}} {{element['currency']}}</ng-container>
            <ng-container
              *ngIf="col === 'financeEnablerStatus' && element[col]">{{'cdrs-for-revocation.financeEnablerStatus.' + element[col] | translate}}</ng-container>
          </mat-cell>
        </ng-container>
      </div>

      <ng-container matColumnDef="tableAction" stickyEnd>
        <mat-header-cell *matHeaderCellDef
                         class="cdrsActionCell actionCell center details-column"> {{'cdrs-for-revocation.tableHeader.action' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="cdrsActionCell actionCell center details-column">
          <button mat-mini-fab class="delete tableActionBtn"
                  matTooltip="{{'cdrs-for-revocation.dialog.revoke.tooltip' | translate}}"
                  (click)="deleteElement(element)" [disabled]="disableDelete || !revocationPossible(element)">
            <mat-icon>delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </div>
</div>

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'pcs-select-column-filter',
  templateUrl: './select-column-filter.component.html',
  styleUrl: './select-column-filter.component.css'
})
export class SelectColumnFilterComponent implements OnInit {
  @Input() public withEmptyOption: boolean = true;
  @Input() public withUppercaseOptions: boolean = true;
  @Input() public translationKey: string = null;
  @Input() public options: string[] = [];
  @Input() public defaultOption: string = '';
  @Input() public preSelectedOption: string = '';
  @Output() public handleSelectChange: EventEmitter<string> = new EventEmitter<string>();

  public selectControl: FormControl;

  public ngOnInit(): void {
    this.selectControl = new FormControl(this.preSelectedOption || '');
    const selectChanges = this.selectControl.valueChanges;
    selectChanges.subscribe((value) => this.handleSelectChange.emit(value));
  }
}

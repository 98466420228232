<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-table [dataSource]="userData" *ngIf="userData" class="mat-elevation-z0">
  <ng-container matColumnDef="login">
    <mat-header-cell *matHeaderCellDef> {{'fleet.manager.login' | translate}} </mat-header-cell>
    <mat-cell *matCellDef="let manager"> {{manager.login}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="password">
    <mat-header-cell *matHeaderCellDef> {{'fleet.manager.password' | translate}}
      <mat-icon class="hidePass" (click)="hidePass = !hidePass">{{hidePass ? 'visibility' : 'visibility_off'}}</mat-icon>
    </mat-header-cell>
    <mat-cell *matCellDef="let manager"> {{getPasswordHidden(manager.password)}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="disclaimer">
    <mat-footer-cell *matFooterCellDef class="empty-pass-hint">
      {{'fleet.tablefooter.passwordempty' | translate}}
    </mat-footer-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  <mat-footer-row *matFooterRowDef="['disclaimer']"></mat-footer-row>
</mat-table>
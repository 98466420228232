<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-toolbar class="headerToolbar mat-elevation-z1">
  <span class="headerSpan">{{'contractHistory.header' | translate }}</span>
  <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
</mat-toolbar>

<div class="section-container" editKeyEvents>
  <mat-accordion>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <div class="expansion-panel-header">
          <span class="headerSpan headerToolbarSub">{{'customerinfo.filter.header' | translate}}</span>
        </div>
      </mat-expansion-panel-header>
      <mat-action-row>
        <button [disabled]="filterFormGroup.invalid" okAction class="normalButton" mat-stroked-button
                (click)="loadHistory()">
          <span class="mat-button-wrapper">
            <span class="filter-button-span">{{ "contractHistory.button.filter" | translate }}</span>
            <i class="fa fa-refresh" aria-hidden="true"></i>
          </span>
        </button>
      </mat-action-row>
      <form class="filter-form" [formGroup]="filterFormGroup">
        <div class="multi-column-div">
          <mat-form-field class="filter-form-field">
            <mat-label>{{'contractHistory.vin' | translate}}</mat-label>
            <input matInput maxlength="{{inputMaxLength}}" formControlName="vinFilter">
            <mat-hint align="end" *ngIf="vinFilter.value">{{vinFilter.value.length}}
              / {{inputMaxLength}}</mat-hint>
          </mat-form-field>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>
<mat-divider class="section-divider"></mat-divider>
<div class="section-container" *ngIf="(history$ | async)?.length > 0" id="cdrs-for-revocation-table-area">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true" hideToggle>
      <pcs-contract-history-table [data]="history$"
                                  [inputHasChanged]="history$ | async"></pcs-contract-history-table>
    </mat-expansion-panel>
  </mat-accordion>
</div>

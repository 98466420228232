/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {PaginatedTableBaseComponent} from './paginated-table-base.component';
import {Component, Input, OnChanges, OnDestroy, Renderer2, SimpleChanges} from '@angular/core';
import {SelectConfig} from 'pcs-commons/datatypes';
import {Subscription} from 'rxjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {TranslationHelperService} from 'pcs-commons/http';

@Component({
  selector: 'pcs-filterable-table-base',
  template: ''
})
export class FilterableTableBaseComponent extends PaginatedTableBaseComponent implements OnDestroy, OnChanges {
  @Input() public nonSearchableColumns: string[];
  @Input() public translateColumns: string[];
  @Input() public selectColumnConfigs: Map<string, SelectConfig>;

  public selectColumns: string[] = [];
  public update = new Date();
  private langChangeSub: Subscription;
  private filterValueChangeSub: Subscription;

  public filterForm: FormGroup;

  constructor(
    renderer: Renderer2,
    public translateService: TranslationHelperService,
    public formBuilder: FormBuilder) {
    super(renderer);
    this.langChangeSub =
      this.translateService.onLangChange.subscribe(() => this.update = new Date());
  }

  public ngOnDestroy(): void {
    this.langChangeSub?.unsubscribe();
    this.filterValueChangeSub?.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.dataColumns) {
      this.setupFilterPredicate(changes.dataColumns.currentValue);
      this.setupFilterForm(changes.dataColumns.currentValue);
    }

    if (changes.selectColumnConfigs && changes.selectColumnConfigs.currentValue) {
      console.log('select column configs: ', this.selectColumnConfigs);
      this.selectColumns = [];
      for (const key of this.selectColumnConfigs.keys()) {
        this.selectColumns.push(key);
      }
      console.log('columns with select filter: ', this.selectColumns);
    }
  }

  private setupFilterPredicate(dataColumns: string[]): void {
    console.log('setting up filter predicate...');
    this.tableDataSource.filterPredicate = (data, filter) => {
      let match = true;
      for (const col of dataColumns) {
        if (this.actionColumn === col) {
          continue;
        }

        const colFilter = filter[col];
        let colDataMatch: boolean;
        if (this.selectColumns.includes(col)) {
          colDataMatch = !colFilter || data[col] && (data[col] === colFilter);
        } else {
          colDataMatch = !colFilter || data[col] && data[col].toLowerCase().includes(colFilter.toLowerCase());
        }
        match = match && colDataMatch;
      }
      return match;
    };
  }

  private setupFilterForm(dataColumns: string[]): void {
    this.filterForm = this.formBuilder.group({});
    for (const col of dataColumns) {
      if (this.actionColumn === col) {
        continue;
      }

      const initialVal = this.selectColumnConfigs.has(col) ? this.selectColumnConfigs.get(col).defaultOption : null;
      this.filterForm.addControl(col, new FormControl(initialVal));
    }
    this.tableDataSource.filter = this.filterForm.value;
    this.filterValueChangeSub?.unsubscribe();
    this.filterValueChangeSub = this.filterForm.valueChanges.subscribe((newFilter) => {
      console.log('new filter value: ', newFilter);
      this.tableDataSource.filter = newFilter as string;
      this.paginator?.firstPage();
    });
  }
}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductsComponent } from './products/products.component';
import { ProductService } from './services/http/product.service';
import { ProductDetailComponent } from './products/product-details/product-detail.component';
import { StatisticComponent } from './statistic/statistic.component';
import { TaxService } from './services/http/tax.service';
import { ChargepointService } from './services/http/chargepoint.service';
import { OperatorsComponent } from './chargepoint-operators/operators.component';
import { TaxesComponent } from './taxes/taxes.component';

import { ArraySortPipe } from './pipes/array-sort.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuardService } from './auth/authguard.service';
import { TokenComponent } from './token.component';
import { FleetsComponent } from './fleets/fleets.component';
import { FleetService } from './services/http/fleet.service';
import { UserService } from './services/http/user.service';
import { FleetProductSelectComponent } from './fleets/fleet-product-select/fleet-product-select.component';
import { FleetCreateResponseComponent } from './fleets/fleet-create-response/fleet-create-response.component';
import { WebconfigHttpInterceptor } from './webconfig.httpinterceptor';
import { FleetEditComponent } from './fleets/fleet-edit/fleet-edit.component';
import { AddFleetManagerComponent } from './fleets/add-manager/add-fleet-manager.component';
import { UserCredTableComponent } from './fleets/user-cred-table/user-cred-table.component';
import { SharedDataService } from './services/shared-data.service';
import { FleetProductEditComponent } from './fleets/product-edit/fleet-product-edit.component';
import { IdCardsComponent } from './fleets/id-cards/id-cards.component';
import { DataTableComponent } from './data-table/data-table.component';
import { TableBaseComponent } from './data-table/table-base/table-base.component';
import { DeleteDialogComponent } from './dialog/delete-dialog/delete-dialog.component';
import { PasswordSecuredTableComponent } from './data-table/password-secured-table/password-secured-table.component';
import { FleetsTableComponent } from './data-table/fleets-table/fleets-table.component';
import { FleetActionsComponent } from './fleets/fleet-actions/fleet-actions.component';
import { ConfirmationDialogComponent } from './dialog/confirmation-dialog/confirmation-dialog.component';
import { TruncatePipe } from './common/pipes/truncate.pipe';
import { ImportFormComponent } from './import-form/import-form.component';
import { ImportFormBaseComponent } from './import-form/import-form-base.component';
import { EditKeyEventsDirective } from './directives/edit-key-events.directive';
import { ConfigurationsComponent } from './configurations/configurations.component';
import { DragDropListBaseComponent } from './common/drag-drop-list/drag-drop-list-base.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PlatformListCardComponent } from './configurations/platform-list-card/platform-list-card.component';
import { ChargepointsComponent } from './chargepoints/chargepoints.component';
import { ChargepointFilterComponent } from './chargepoints/filter/chargepoint-filter.component';
import { ChargepointsTableComponent } from './data-table/chargepoints-table/chargepoints-table.component';
import { LocationDetailsComponent } from './chargepoints/location-details/location-details.component';
import { AttributeTableBaseComponent } from './data-table/attribute-table/attribute-table-base.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
// eslint-disable-next-line max-len
import { LocationAttributesComponent } from './chargepoints/location-attributes/location-attributes.component';
import { ChargepointDetailsComponent } from './chargepoints/chargepoint-details/chargepoint-details.component';
import { RatingComponent } from './common/rating/rating.component';
import { AutofocusDirective } from './common/directives/autofocus.directive';
import { CpoTableComponent } from './data-table/cpo-table/cpo-table.component';
import { ScrollableTableComponent } from './data-table/scrollable-table/scrollable-table.component';
import { CardManagementComponent } from './card-management/card-management.component';
import { CardFilterComponent } from './card-management/card-filter/card-filter.component';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { ChargingCardTableComponent } from './data-table/charging-card-table/charging-card-table.component';
import { ChargingCardService } from './services/http/charging-card.service';
import { PaginatorIntlService } from './services/paginator-intl.service';
import { ChargepointFilterFormComponent } from './chargepoints/filter/chargepoint-filter-form/chargepoint-filter-form.component';
import { FilterToggleGroupComponent } from './chargepoints/filter/filter-toggle-group/filter-toggle-group.component';
import { UnusedChargepointFilterFormComponent } from './chargepoints/filter/unused-chargerpoint-filter-form/unused-chargepoint-filter-form.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatSortModule } from '@angular/material/sort';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { OcpiPartnersComponent } from './ocpi-partners/ocpi-partners.component';
import { OcpiPartnerTableComponent } from './data-table/ocpi-partner-table/ocpi-partner-table.component';
import { OcpiPartnerDetailsComponent } from './ocpi-partners/ocpi-partner-details/ocpi-partner-details.component';
import { ActivatePartnerComponent } from './ocpi-partners/activate-partner/activate-partner.component';
import { OcpiPartnerTranslatePipe } from './pipes/ocpi-partner-translate.pipe';
import { ProductTableComponent } from './data-table/product-table/product-table.component';
import { BasicFeeTableComponent } from './data-table/basic-fee-table/basic-fee-table.component';
import { EditBasicFeeComponent } from './products/product-details/edit-basic-fee/edit-basic-fee.component';
import { CountryPricesTableComponent } from './data-table/country-prices-table/country-prices-table.component';
import { BasicFeeColumnValuePipe } from './pipes/basic-fee-column-value.pipe';
import { CountryPriceColumnValuePipe } from './pipes/country-price-column-value.pipe';
import { ClassPricesTableComponent } from './data-table/class-prices-table/class-prices-table.component';
import { ClassPriceColumnValuePipe } from './pipes/class-price-column-value.pipe';
import { PriceTableCellDirective } from './directives/price-table-cell.directive';
import { StyledColumnDirective } from './directives/styled-column.directive';
import { CustomerinfoComponent } from './customerinfo/customerinfo.component';
import { CustomerinfoFilterComponent } from './customerinfo/customerinfo-filter/customerinfo-filter.component';
import { CustomerinfoTableComponent } from './data-table/customerinfo-table/customerinfo-table.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserGroupTableComponent } from './data-table/user-group-table/user-group-table.component';
import { UserGroupsComponent } from './user-management/user-groups/user-groups.component';
import { UsersComponent } from './user-management/users/users.component';
import { UserTableComponent } from './data-table/user-table/user-table.component';
import { EditUserGroupComponent } from './user-management/user-groups/edit-user-group/edit-user-group.component';
import { DatabaseComponent } from './database/database.component';
import { QueryInputComponent } from './database/query-input/query-input.component';
import { DatabaseQueryResultTableComponent } from './data-table/database-query-result-table/database-query-result-table.component';
import { MenuItemComponent } from './menu-item/menu-item.component';
import { MatMenuModule } from '@angular/material/menu';
import { LanguageAndUserBarComponent } from './language-and-user-bar/language-and-user-bar.component';
import { ResponsiveSidenavDirective } from './directives/responsive-sidenav.directive';
import { HideAtDirective } from './directives/hide-at.directive';
import { AccessAllowedPipe } from './pipes/access-allowed.pipe';
import { IfAnyPermDirective } from './directives/if-any-perm.directive';
import { PermissionTranslationKeyPipe } from './pipes/permission-translation-key.pipe';
import { InlineEditableTableBaseComponent } from './data-table/table-base/inline-editable-table-base.component';
import { AddAdminUserComponent } from './user-management/users/add-user/add-admin-user.component';
import { TaxesTableComponent } from './data-table/taxes-table/taxes-table.component';
import { EditTaxComponent } from './taxes/edit-tax/edit-tax.component';
import { EditCountryPriceComponent } from './products/product-details/edit-country-price/edit-country-price.component';
import { ImpureClassPriceColumnValuePipe } from './pipes/impure_class-price-column-value.pipe';
import { AddQueryComponent } from './database/add-query/add-query.component';
import { PurchaseConditionsComponent } from './purchase-conditions/purchase-conditions.component';
import { PurchaseConditionDetailsComponent } from './purchase-conditions/purchase-condition-details/purchase-condition-details.component';
import { PcElementTableComponent } from './data-table/pc-element-table/pc-element-table.component';
import { EditPcElementComponent } from './purchase-conditions/edit-pc-element/edit-pc-element.component';
import { PriceComponentEditableTableComponent } from './data-table/price-component-editable-table/price-component-editable-table.component';
import { PurchaseConditionsTableComponent } from './data-table/purchase-conditions-table/purchase-conditions-table.component';
import { TranslateWeekdaysPipe } from './pipes/translate-weekdays.pipe';
import { PriceComponentTableComponent } from './data-table/price-component-table/price-component-table.component';
import { PurchaseConditionsToggleGroupComponent } from './purchase-conditions/purchase-conditions-toggle-group/purchase-conditions-toggle-group.component';
import { ActivePurchaseConditionsTableComponent } from './data-table/active-purchase-conditions-table/active-purchase-conditions-table.component';
import { EditTaxesTableComponent } from './data-table/edit-taxes-table/edit-taxes-table.component';

import { ClassPricesEditableTableComponent } from './data-table/class-prices-editable-table/class-prices-editable-table.component';
import { EditBasicFeeTableComponent } from './data-table/edit-basic-fee-table/edit-basic-fee-table.component';
import { EditableClassPriceColumnValuePipe } from './pipes/editable-class-price-column-value.pipe';
import { TranslateCountryPipe } from './pipes/translate-country.pipe';
import { CurrencyFactorsComponent } from './currency-factors/currency-factors.component';
import { CurrencyFactorTableComponent } from './data-table/currency-factor-table/currency-factor-table.component';
import { AddCurrencyFactorComponent } from './currency-factors/add-currency-factor/add-currency-factor.component';
import { EditCurrencyFactorsComponent } from './currency-factors/edit-currency-factors/edit-currency-factors.component';
import { EditCurrencyFactorsTableComponent } from './data-table/edit-currency-factors-table/edit-currency-factors-table.component';
import { TranslatePowerClassesPipe } from './pipes/translate-power-classes.pipe';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TariffImportResultTableComponent } from './data-table/tariff-import-result-table/tariff-import-result-table.component';
import { TariffImportResultComponent } from './purchase-conditions/tariff-import-result/tariff-import-result.component';
import { TariffImportComponent } from './purchase-conditions/tariff-import/tariff-import.component';
import { AutocompleteCpoComponent } from './autocomplete/cpo/autocomplete-cpo.component';
import { QuotaUsageTableComponent } from './data-table/quota-usage-table/quota-usage-table.component';
import { MultiHeaderTableBaseComponent } from './data-table/table-base/multi-header-table-base.component';
import { AssociateTotpDialogComponent } from './dialog/associate-totp/associate-totp-dialog.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ConfirmTotpDialogComponent } from './dialog/confirm-totp-dialog/confirm-totp-dialog.component';
import { CdrClearingPoolComponent } from './cdr/cdr-clearing-pool/cdr-clearing-pool.component';
import { CdrClearingEntryDialogComponent } from './cdr/cdr-clearing-entry-dialog/cdr-clearing-entry-dialog.component';
import { TranslateDatePipe } from './pipes/translate-date.pipe';
import { FilterableTableComponent } from './data-table/filterable-table/filterable-table.component';
import { TranslateColumnPipe } from './pipes/translate-column.pipe';
import { InvoicesComponent } from './invoices/invoices.component';
import { InvoicesTableComponent } from './data-table/invoices-table/invoices-table.component';
import { InvoicesFilterComponent } from './invoices/invoices-filter/invoices-filter.component';
import { InvoiceTransactionTableComponent } from './data-table/invoice-transaction-table/invoice-transaction-table.component';
import { InvoiceTransactionDialogComponent } from './invoices/invoice-transaction-dialog/invoice-transaction-dialog.component';
import { PasswordChangeDialogComponent } from './dialog/password-change-dialog/password-change-dialog.component';
import { TranslateDateTimePipe } from './pipes/translate-date-time.pipe';
import { environment } from '../environments/environment';
import { StatisticsModule } from 'pcs-commons/statistics';
import { UtilsModule } from 'pcs-commons/utils';
import { ValidationModule } from 'pcs-commons/validation';
import { DatatypesModule } from 'pcs-commons/datatypes';
import { NotificationModule } from 'pcs-commons/notification';
import { HttpModule } from 'pcs-commons/http';
import { ErrorManagementModule } from 'pcs-commons/error-management';
import { ElectricVehicleComponent } from './electric-vehicle/electric-vehicle.component';
import { ElectricVehicleFilterComponent } from './electric-vehicle/electric-vehicle-filter/electric-vehicle-filter.component';
import { ElectricVehicleTableComponent } from './data-table/electric-vehicle-table/electric-vehicle-table.component';
import { ChargepointAttributeImportComponent } from './chargepoint-attribute-import/chargepoint-attribute-import.component';
import { AttributeImportFormComponent } from './chargepoint-attribute-import/attribute-import-form/attribute-import-form.component';
import { ChargepointAttributeImportService } from './services/http/chargepoint-attribute-import.service';
import { ChargepointAttributeImportResultComponent } from './chargepoint-attribute-import/chargepoint-attribute-import-result/chargepoint-attribute-import-result.component';
import { ChargepointAttributeImportResultTableComponent } from './data-table/chargepoint-attribute-import-result-table/chargepoint-attribute-import-result-table.component';
import { ChargepointRawDataDialogComponent } from './chargepoints/chargepoint-details/chargepoint-raw-data-dialog/chargepoint-raw-data-dialog.component';
import { ComponentsModule } from 'pcs-commons/components';
import { FetchMenusPipe } from './pipes/fetch-menus.pipe';
import { VinCheckResultComponent } from './electric-vehicle/vin-check-result/vin-check-result.component';
import { DataTablesModule } from 'pcs-commons/data-tables';
import { PaginatedTableComponent } from './data-table/paginated-table/paginated-table.component';
import { RFIDCardsTableComponent } from './data-table/rfidcards-table/rfidcards-table.component';
import { CdrRevocationComponent } from './cdr-revocation/cdr-revocation.component';
import { CdrsForRevocationFilterComponent } from './cdr-revocation/cdrs-for-revocation-filter/cdrs-for-revocation-filter.component';
import { CdrRevocationRequestDialogComponent } from './cdr-revocation/cdr-revocation-request-dialog/cdr-revocation-request-dialog.component';
import { CdrsForRevocationTableComponent } from './data-table/cdrs-for-revocation-table/cdrs-for-revocation-table.component';
import { LocalLoginPageComponent } from './local-login-page/local-login-page.component';
import { IonityContractDataComponent } from './ionity-contract-data/ionity-contract-data.component';
import { IonityCharmContractDataDialogComponent } from './ionity-contract-data/ionity-charm-contract-data-dialog/ionity-charm-contract-data-dialog.component';
import { CodeEditorModule } from '@ngstack/code-editor';
import { AdminOnlyDirective } from './directives/admin-only.directive';
import { FleetInvoicesOverviewComponent } from './fleets/fleet-invoices-overview/fleet-invoices-overview.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ContractHistoryComponent } from './contract-history/contract-history.component';
import { ContractHistoryTableComponent } from './data-table/contract-history-table/contract-history-table.component';
import { InboxComponent } from './inbox/inbox.component';
import { InboxTableComponent } from './data-table/inbox-table/inbox-table.component';
import { CreateChangeRequestDialogComponent } from './dialog/create-change-request-dialog/create-change-request-dialog.component';
import { ChangeRequestDetailsComponent } from './inbox/change-request-details/change-request-details.component';
import { ChangeRequestInfoComponent } from './inbox/change-request-info/change-request-info.component';
import { ChangeRequestHistoryTableComponent } from './data-table/change-request-history-table/change-request-history-table.component';
import { ChangeRequestInfoCpoComponent } from './inbox/change-request-info-cpo/change-request-info-cpo.component';
import { ChangeRequestSharedDataService } from './services/change-request-shared-data.service';
import { ChangeRequestInfoJsonComponent } from './inbox/change-request-info-json/change-request-info-json.component';
import { JsonDiffComponent } from './inbox/json-diff/json-diff.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxObjectDiffComponent } from './object-diff/ngx-object-diff.component';
import { ChangeRequestsOverviewComponent } from './change-requests-overview/change-requests-overview.component';
import { InvoiceOrderItemsDialogComponent } from './invoices/invoice-order-items-dialog/invoice-order-items-dialog.component';
import { InvoiceOrderItemsTableComponent } from './data-table/invoice-order-items-table/invoice-order-items-table.component';
import { InvoiceOrderItemUpdateDialogComponent } from './invoices/invoice-order-item-update-dialog/invoice-order-item-update-dialog.component';
import { ChangeRequestInfoCommonComponent } from './inbox/change-request-info-common/change-request-info-common.component';
import { ChangeRequestReimbursementTableComponent } from './data-table/change-request-reimbursement-table/change-request-reimbursement-table.component';
import { ChangeRequestInfoAsTableComponent } from './inbox/change-request-info-as-table/change-request-info-as-table.component';
import { ChangeRequestActionDescriptionComponent } from './inbox/change-request-action-description-json/change-request-action-description.component';
import {
  LuxonDateModule,
  MAT_LUXON_DATE_ADAPTER_OPTIONS,
  MAT_LUXON_DATE_FORMATS,
  MatLuxonDateModule
} from '@angular/material-luxon-adapter';
import { DatePickerMonthFormatDirective } from './statistic/date-picker-month-format.directive';
import { PlanTableComponent } from './data-table/plan-table/plan-table.component';
import { EnrollmentEaEcComponent } from './enrollment-ea-ec/enrollment-ea-ec.component';
import { EnrollmentEaEcFilterComponent } from './enrollment-ea-ec/enrollment-ea-ec-filter/enrollment-ea-ec-filter.component';
import { EnrollmentEaEcTableComponent } from './data-table/enrollment-ea-ec-table/enrollment-ea-ec-table.component';
import { PcsTrimMatInputValueDirective } from './directives/pcs-trim-mat-input-value.directive';
import { InputColumnFilterComponent } from './column-filter/input-column-filter/input-column-filter.component';
import { SelectColumnFilterComponent } from './column-filter/select-column-filter/select-column-filter.component';
import { ConditionalUppercasePipe } from './pipes/conditional-uppercase.pipe';
import { IconColumnFilterComponent } from './column-filter/icon-column-filter/icon-column-filter.component';
import { QueryHistoryOverviewComponent } from './query-history-overview/query-history-overview.component';
import { QueryHistoryTableComponent } from './data-table/query-history-table/query-history-table.component';
import { DateRangeColumnFilterComponent } from './column-filter/date-range-column-filter/date-range-column-filter.component';
import { SubOperatorsComponent } from './chargepoint-operators/sub-operators/sub-operators.component';
import { SubCpoTableComponent } from './data-table/sub-cpo-table/sub-cpo-table.component';
import { KeyTagImportComponent } from './key-tag-import/key-tag-import.component';
import { KeyTagImportService } from './services/http/key-tag-import.service';

@NgModule({
  imports: [
    MatGridListModule,
    DragDropModule,
    MatButtonModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatDialogModule,
    FormsModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatTabsModule,
    MatTableModule,
    MatCardModule,
    MatSortModule,
    MatListModule,
    MatIconModule,
    MatToolbarModule,
    MatButtonToggleModule,
    MatSnackBarModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatProgressBarModule,
    StatisticsModule.forRoot(environment),
    UtilsModule,
    ValidationModule,
    DatatypesModule,
    NotificationModule,
    ComponentsModule,
    DataTablesModule,
    HttpModule.forRoot(environment),
    ErrorManagementModule.forRoot({ errorMsgBase: 'validation.error.' }),
    AngularSvgIconModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatRadioModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatMenuModule,
    MatAutocompleteModule,
    QRCodeModule,
    NgxChartsModule,
    CodeEditorModule.forRoot({
      baseUrl: 'assets/monaco'
    }),
    GoogleMapsModule,
    MatSlideToggleModule,
    LuxonDateModule,
    MatLuxonDateModule,
    ComponentsModule
  ],
  declarations: [
    PcsTrimMatInputValueDirective,
    DatePickerMonthFormatDirective,
    AppComponent,
    ProductsComponent,
    ProductDetailComponent,
    OperatorsComponent,
    TaxesComponent,
    StatisticComponent,
    ArraySortPipe,
    TokenComponent,
    FleetsComponent,
    FleetProductSelectComponent,
    FleetCreateResponseComponent,
    FleetEditComponent,
    AddFleetManagerComponent,
    UserCredTableComponent,
    FleetProductEditComponent,
    IdCardsComponent,
    TableBaseComponent,
    DataTableComponent,
    DeleteDialogComponent,
    PasswordSecuredTableComponent,
    FleetsTableComponent,
    FleetActionsComponent,
    ConfirmationDialogComponent,
    TruncatePipe,
    ImportFormComponent,
    ImportFormBaseComponent,
    EditKeyEventsDirective,
    ConfigurationsComponent,
    DragDropListBaseComponent,
    PlatformListCardComponent,
    ChargepointsComponent,
    ChargepointFilterComponent,
    ChargepointsTableComponent,
    LocationDetailsComponent,
    AttributeTableBaseComponent,
    LocationAttributesComponent,
    ChargepointDetailsComponent,
    RatingComponent,
    AutofocusDirective,
    CpoTableComponent,
    ScrollableTableComponent,
    CardManagementComponent,
    CardFilterComponent,
    ChargingCardTableComponent,
    ChargepointFilterFormComponent,
    FilterToggleGroupComponent,
    UnusedChargepointFilterFormComponent,
    OcpiPartnersComponent,
    OcpiPartnerTableComponent,
    OcpiPartnerDetailsComponent,
    ActivatePartnerComponent,
    OcpiPartnerTranslatePipe,
    ProductTableComponent,
    BasicFeeTableComponent,
    EditBasicFeeComponent,
    CountryPricesTableComponent,
    EditCountryPriceComponent,
    BasicFeeColumnValuePipe,
    CountryPriceColumnValuePipe,
    ClassPricesTableComponent,
    ClassPriceColumnValuePipe,
    ImpureClassPriceColumnValuePipe,
    PriceTableCellDirective,
    StyledColumnDirective,
    CustomerinfoComponent,
    CustomerinfoFilterComponent,
    CustomerinfoTableComponent,
    UserManagementComponent,
    UserGroupsComponent,
    UserGroupTableComponent,
    EditUserGroupComponent,
    UsersComponent,
    UserTableComponent,
    DatabaseComponent,
    QueryInputComponent,
    DatabaseQueryResultTableComponent,
    MenuItemComponent,
    LanguageAndUserBarComponent,
    ResponsiveSidenavDirective,
    HideAtDirective,
    AccessAllowedPipe,
    IfAnyPermDirective,
    PermissionTranslationKeyPipe,
    InlineEditableTableBaseComponent,
    AddAdminUserComponent,
    TaxesTableComponent,
    EditTaxComponent,
    AddQueryComponent,
    PurchaseConditionsComponent,
    PurchaseConditionDetailsComponent,
    PcElementTableComponent,
    EditPcElementComponent,
    PriceComponentEditableTableComponent,
    PurchaseConditionsTableComponent,
    PriceComponentEditableTableComponent,
    TranslateWeekdaysPipe,
    PriceComponentTableComponent,
    PurchaseConditionsToggleGroupComponent,
    ActivePurchaseConditionsTableComponent,
    EditTaxesTableComponent,
    ClassPricesEditableTableComponent,
    EditBasicFeeTableComponent,
    EditableClassPriceColumnValuePipe,
    TranslateCountryPipe,
    CurrencyFactorsComponent,
    CurrencyFactorTableComponent,
    AddCurrencyFactorComponent,
    EditCurrencyFactorsComponent,
    EditCurrencyFactorsTableComponent,
    TranslateCountryPipe,
    TranslatePowerClassesPipe,
    TariffImportResultTableComponent,
    TariffImportResultComponent,
    TariffImportComponent,
    AutocompleteCpoComponent,
    QuotaUsageTableComponent,
    MultiHeaderTableBaseComponent,
    AssociateTotpDialogComponent,
    ConfirmTotpDialogComponent,
    CdrClearingPoolComponent,
    CdrClearingEntryDialogComponent,
    TranslateDatePipe,
    FilterableTableComponent,
    TranslateColumnPipe,
    InvoicesComponent,
    InvoicesTableComponent,
    InvoicesFilterComponent,
    InvoiceTransactionDialogComponent,
    InvoiceTransactionTableComponent,
    PasswordChangeDialogComponent,
    TranslateDateTimePipe,
    ElectricVehicleComponent,
    ElectricVehicleFilterComponent,
    ElectricVehicleTableComponent,
    ChargepointAttributeImportComponent,
    AttributeImportFormComponent,
    ChargepointAttributeImportResultComponent,
    ChargepointAttributeImportResultTableComponent,
    ChargepointRawDataDialogComponent,
    FetchMenusPipe,
    VinCheckResultComponent,
    PaginatedTableComponent,
    RFIDCardsTableComponent,
    CdrRevocationComponent,
    CdrsForRevocationFilterComponent,
    CdrRevocationRequestDialogComponent,
    CdrsForRevocationTableComponent,
    IonityContractDataComponent,
    IonityCharmContractDataDialogComponent,
    LocalLoginPageComponent,
    AdminOnlyDirective,
    FleetInvoicesOverviewComponent,
    ContractHistoryComponent,
    ContractHistoryTableComponent,
    FleetInvoicesOverviewComponent,
    InboxComponent,
    InboxTableComponent,
    CreateChangeRequestDialogComponent,
    ChangeRequestDetailsComponent,
    ChangeRequestInfoComponent,
    ChangeRequestHistoryTableComponent,
    ChangeRequestInfoCpoComponent,
    ChangeRequestInfoJsonComponent,
    JsonDiffComponent,
    NgxObjectDiffComponent,
    ChangeRequestsOverviewComponent,
    InvoiceOrderItemsDialogComponent,
    InvoiceOrderItemsTableComponent,
    InvoiceOrderItemUpdateDialogComponent,
    ChangeRequestInfoCommonComponent,
    ChangeRequestInfoAsTableComponent,
    ChangeRequestReimbursementTableComponent,
    ChangeRequestActionDescriptionComponent,
    PlanTableComponent,
    EnrollmentEaEcComponent,
    EnrollmentEaEcFilterComponent,
    EnrollmentEaEcTableComponent,
    InputColumnFilterComponent,
    SelectColumnFilterComponent,
    IconColumnFilterComponent,
    ConditionalUppercasePipe,
    QueryHistoryOverviewComponent,
    QueryHistoryTableComponent,
    DateRangeColumnFilterComponent,
    SubOperatorsComponent,
    SubCpoTableComponent,
    DateRangeColumnFilterComponent,
    KeyTagImportComponent
  ],
  providers: [
    ProductService,
    TaxService,
    ChargepointService,
    AuthGuardService,
    FleetService,
    UserService,
    SharedDataService,
    ChargingCardService,
    ChargepointAttributeImportService,
    ChangeRequestSharedDataService,
    KeyTagImportService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WebconfigHttpInterceptor,
      multi: true
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_LUXON_DATE_FORMATS
    },
    {
      provide: MatPaginatorIntl,
      useClass: PaginatorIntlService
    },
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ChargingCardFilter} from '../../datatypes/charging-card-filter';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Parameters} from '../../global/parameters';
import {ValidatorList} from 'pcs-commons/validation';
import {FormHelper} from '../../utils/form-helper';
import {Observable} from 'rxjs';

@Component({
  selector: 'card-filter',
  templateUrl: './card-filter.component.html',
  styleUrls: ['./card-filter.component.css']
})
export class CardFilterComponent implements OnInit {
  @Output() public filterEvent = new EventEmitter<ChargingCardFilter>();
  @Input() public loading;
  @Input() public collapseView: Observable<boolean>;
  public expandView = true;
  public inputMaxLength = Parameters.DEFAULT_MAX_LENGTH;

  // form controls
  public filterFormGroup: FormGroup;
  public contractIdFilter: FormControl;
  public uidFilter: FormControl;
  public vinFilter: FormControl;
  public serialFilter: FormControl;

  constructor(
    private formBuilder: FormBuilder) {
    this.defineFromControls();
  }

  public ngOnInit(): void {
    this.collapseView.subscribe((collapse) => {
      this.expandView = !collapse;
    });
  }

  private defineFromControls(): void {
    this.contractIdFilter = new FormControl('', ValidatorList.STRING_DEFAULT);
    this.uidFilter = new FormControl('', ValidatorList.STRING_DEFAULT);
    this.vinFilter = new FormControl('', ValidatorList.STRING_DEFAULT);
    this.serialFilter = new FormControl('', ValidatorList.STRING_DEFAULT);
    this.filterFormGroup = this.formBuilder.group({
      contractIdFilter: this.contractIdFilter,
      uidFilter: this.uidFilter,
      vinFilter: this.vinFilter,
      serialFilter: this.serialFilter
    });
  }

  public isFilterValid(): boolean {
    return this.filterFormGroup && this.filterFormGroup.valid;
  }

  public onDisplayCards(): void {
    const filter = new ChargingCardFilter();
    filter.contractId = FormHelper.getStringValue(this.filterFormGroup, 'contractIdFilter');
    filter.uid = FormHelper.getStringValue(this.filterFormGroup, 'uidFilter');
    filter.vin = FormHelper.getStringValue(this.filterFormGroup, 'vinFilter');
    filter.serial = FormHelper.getStringValue(this.filterFormGroup, 'serialFilter');
    this.filterEvent.emit(filter);
  }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div class="mat-elevation-z1">
  <mat-toolbar class="headerToolbar">
    <span class="headerSpan">{{ 'ocpi.partner.list' | translate }}</span>
    <button mat-raised-button class="headerActionBtn icon-only" (click)="onConfigureNewPartner()"
            *ifAnyPerm="reqEditPermission">
      <mat-icon> add</mat-icon>
    </button>
    <pcs-loading-spinner [component]="this"></pcs-loading-spinner>
  </mat-toolbar>
  <pcs-ocpi-partner-table
    [actionButtons]="reqEditPermission | accessAllowed"
    [data]="allPartners$"
    [dataColumns]="dataColumns"
    (activateEvent)="onActivatePartner($event)"
    (editEvent)="onEditPartner($event)"
    (pushRfidTokensToPartner)="pushRfidTokensToPartner($event)">
  </pcs-ocpi-partner-table>
</div>

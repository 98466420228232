/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {Parameters} from './global/parameters';
import {Directive, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {BehaviorSubject} from 'rxjs';
import {PageResponse} from "./datatypes/PageResponse";

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class PaginationHandlerComponent extends AbstractNotificationHandler {
  public pageSizeOptions: number[] = Parameters.PAGE_SIZE_OPTIONS;
  public resultLength = 0;
  public pageEventOnProcess = false;

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public abstract handlePageChange(pageEvent: PageEvent): void;

  public handleNewResults<T>(pageIndex: number, pageSize: number, results: T[], resultSource: BehaviorSubject<T[]>): void {
    this.resultLength = pageIndex * pageSize + results.length;
    // there should be one extra element requested to know if there are more results available, so we remove it before showing
    if (results.length > pageSize) {
      results.pop();
    }
    console.log('updating result source for results: ', results.length, ' pageIndex: ', pageIndex, ' pageSize: ', pageSize);
    resultSource.next(results);
    if (results.length <= 0) {
      this.handleEmptyResult();
    }
  }

  public handlePagedResponse<T>(pageIndex: number, pageSize: number, response: PageResponse<T>, resultSource: BehaviorSubject<T[]>): void {
    if (!response || !response.data) {
      return;
    }
    const data = response?.data;
    this.resultLength = pageIndex * pageSize + data.length;
    if (response.hasNext) {
      this.resultLength += 1;
    }

    console.log('updating result source for results: ', data.length, ' pageIndex: ', pageIndex, ' pageSize: ', pageSize);
    resultSource.next(data);

    if (data.length <= 0) {
      this.handleEmptyResult();
    }
  }

  public abstract handleEmptyResult(): void;
}

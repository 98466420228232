<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data && filterForm">
  <form [formGroup]="filterForm">
    <mat-table #table [dataSource]="tableDataSource">
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
          <mat-header-cell *matHeaderCellDef>
            <div class="filter-header-cell">
              <div class="header-name">
                {{getI18nBase(col) | translate}}
              </div>
              <mat-form-field *ngIf="!selectColumns.includes(col)">
                <input matInput class="header-filter" [formControlName]="col"
                       (click)="$event.stopPropagation()"
                       (keydown.space)="$event.stopPropagation()">
              </mat-form-field>
              <mat-form-field *ngIf="selectColumns.includes(col)">
                <mat-select [formControlName]="col" class="select-filter">
                  <mat-option [value]=""></mat-option>
                  <div *ngIf="selectColumnConfigs.get(col) as selectProps">
                    <mat-option *ngFor="let option of selectProps.options" [value]="option">
                      {{selectProps.translationKey ? ((selectProps.translationKey + option)| translate) : option}}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <div>{{element[col]}}</div>
          </mat-cell>
        </ng-container>
      </div>
      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </form>
</div>
<mat-paginator [pageSizeOptions]="pageSizeOptions" showFirstLastButtons></mat-paginator>

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {AbstractControl, ValidationErrors, Validator} from '@angular/forms';
import {SharedDataService} from '../services/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyValidatorService implements Validator {
  private currencyList: string[] = [];

  constructor(private dataSource: SharedDataService) {
    dataSource.currentCurrencyList$.subscribe(newList => this.currencyList = newList);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return null;
    }

    const isValid = this.currencyList.includes(control.value);
    return isValid ? null : {invalidCurrency: true};
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Output, Renderer2} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {Product, ProductDataColumnType} from '../../datatypes/product';
import {PermissionAware} from '../../permission-aware';
import {AccessRights} from '../../datatypes/access-rights.enum';
import {ProductStatus} from '../../datatypes/product-status.enum';
import {AuthorizationService} from "../../auth/authorization.service";
import {Observable, of} from "rxjs";
import {TranslationHelperService} from "pcs-commons/http";

@Component({
  selector: 'product-table',
  templateUrl: './product-table.component.html',
  styleUrls: [
    '../data-table.component.css',
    './product-table.component.css'
  ]
})
export class ProductTableComponent extends OptimizedTableBaseComponent implements PermissionAware {

  @Output() public showDetailsEvent = new EventEmitter<Product>();

  public readonly reqEditPermission: AccessRights[] = [AccessRights.PRODUCT_EDIT_WEB, AccessRights.FLEET_EDIT_WEB];

  constructor(renderer: Renderer2,
              private authorizationService: AuthorizationService,
              public translationHelperService: TranslationHelperService) {
    super(renderer);
  }

  public showDetails(element: Product): void {
    this.showDetailsEvent.emit(element);
  }

  public isEditable(product: Product): boolean {
    let editable = (ProductStatus.DRAFT === product.status || ProductStatus.ACTIVE === product.status);
    editable &&= !product.hasActiveChangeRequest;
    editable &&= this.authorizationService.hasPermission(this.reqEditPermission);
    return editable;
  }

  public getText(col: string, rawText: string): Observable<string> {
    if ((<ProductDataColumnType>col) === "channel") {
      const key = 'product.channel.' + rawText;
      return this.translationHelperService.get(key);
    }
    return of(rawText);
  }

  public isBoolean(col: string): boolean {
    return (<ProductDataColumnType>col) === "hasActiveChangeRequest";
  }

}

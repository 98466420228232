import { Component, EventEmitter, Output, Renderer2 } from '@angular/core';
import { TableBaseComponent } from '../table-base/table-base.component';
import { ValidationMessagePipe } from 'pcs-commons/validation';
import { QueryHistoryEntryDto } from '../../datatypes/query-history/query-history-entry-dto';
import { TranslateService } from '@ngx-translate/core';
import { HistoryFilter } from '../../datatypes/query-history/query-history-filter';
import { QueryHistoryState } from '../../datatypes/query-history/query-history-state';
import { DatabaseName } from '../../datatypes/database-queries/DatabaseName';

@Component({
  selector: 'pcs-query-history-table',
  templateUrl: './query-history-table.component.html',
  styleUrls: ['../scrollable-table/scrollable-table.component.css', './query-history-table.component.css']
})
export class QueryHistoryTableComponent extends TableBaseComponent {
  public readonly userLoginColumn = QueryHistoryEntryDto.USER_LOGIN;
  public readonly timestampColumn = QueryHistoryEntryDto.TIMESTAMP;
  public readonly stateColumn = QueryHistoryEntryDto.STATE;
  public readonly databaseColumn = QueryHistoryEntryDto.DATABASENAME;
  public readonly resultCountColumn = QueryHistoryEntryDto.RESULT_COUNT;
  public readonly queryColumn = QueryHistoryEntryDto.QUERY;

  public readonly colsWithCustomColDef = [
    this.timestampColumn,
    this.stateColumn,
    this.resultCountColumn,
    this.databaseColumn
  ];

  public readonly stateColumnValues = Object.values(QueryHistoryState);
  public readonly databaseNameColumnValues = Object.values(DatabaseName);

  @Output() public filterEvent = new EventEmitter<HistoryFilter>();

  public filterObj: HistoryFilter = new HistoryFilter();
  public updated: Date = null;

  constructor(
    public validationPipe: ValidationMessagePipe,
    public translateService: TranslateService,
    renderer: Renderer2
  ) {
    super(renderer);

    this.toUnsubscribe.push(this.translateService.onLangChange.subscribe(() => (this.updated = new Date())));
  }

  public handleFilterValueChange(col: string, filterValue: string | boolean): void {
    const oldVal = this.filterObj[col];
    let newVal = filterValue;
    if (typeof filterValue !== 'boolean') {
      newVal = filterValue?.trim() || null;
    }
    if (oldVal !== newVal) {
      this.filterObj[col] = newVal;
      this.filterEvent.emit(this.filterObj);
    }
  }

  public handleDateRangeValueChange(dateRangeFilter: object): void {
    let newFilter = false;
    const oldValFromDate = this.filterObj['fromDate'];
    const oldValToDate = this.filterObj['toDate'];
    const newValFromDate = dateRangeFilter['fromDate'];
    const newValToDate = dateRangeFilter['toDate'];

    if (oldValFromDate !== newValFromDate) {
      newFilter = true;
      this.filterObj['fromDate'] = newValFromDate;
    }
    if (oldValToDate !== newValToDate) {
      newFilter = true;
      this.filterObj['toDate'] = newValToDate;
    }
    if (newFilter) {
      this.filterEvent.emit(this.filterObj);
    }
  }
}

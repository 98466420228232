/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Injectable } from '@angular/core';
import { ChargepointDtoAttributeName } from '../datatypes/location/chargepoint/chargepoint-attribute-name.enum';
import { LocationDtoAttributeName } from '../datatypes/location/location-dto-attribute-name.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AttributeTranslationHelperService {
  public readonly ATTRIBUTE_BASE: Map<string, string> = new Map<string, string>([
    [ChargepointDtoAttributeName.VISIBILITY, 'chargepoint.visibility.'],
    [ChargepointDtoAttributeName.ACCESS, 'chargepoint.access.'],
    [ChargepointDtoAttributeName.AUTH_MODES, 'chargepoint.authMode.'],
    [LocationDtoAttributeName.COUNTRY_CODE, 'global.country.'],
    [ChargepointDtoAttributeName.PLATFORM_COMPATIBLE, 'global.label.'],
    [ChargepointDtoAttributeName.PROVIDES_LIVE_STATUS, 'global.label.'],
    [ChargepointDtoAttributeName.CHARGEPOINT_CLASS, 'global.chargeClass.'],
    [ChargepointDtoAttributeName.FREEBIE_CHARGING, 'global.label.'],
    [ChargepointDtoAttributeName.ADHOC_CAPABLE, 'global.label.'],
    [ChargepointDtoAttributeName.STATUS, 'global.label.'],
    [ChargepointDtoAttributeName.PLUGS, 'chargepoint.plug.'],
    [ChargepointDtoAttributeName.CHARGING_CATEGORY, 'chargepoint.chargingCategory.'],
    // the following two are included only to notify out table that these two need translation
    [ChargepointDtoAttributeName.LAST_CHARGED, 'global.dateFormat'],
    [ChargepointDtoAttributeName.ADMIN_LAST_UPDATED_DATE, 'global.dateFormat'],
    [LocationDtoAttributeName.ADMIN_LAST_UPDATED_DATE, 'global.dateFormat'],
    [LocationDtoAttributeName.GEO_POSITION_STATUS, 'location.geoPositionStatus.']
  ]);

  constructor(private translateService: TranslateService) {}

  public getInstantTranslationForAttribute(attribute: string, key: string): any {
    if (!key) {
      return '';
    }
    const base = this.ATTRIBUTE_BASE.get(attribute);
    return this.translateService.instant(base + key);
  }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content editKeyEvents>
  <h2 mat-dialog-title>
    {{'product-detail.title.chargeClass'|translate}}
  </h2>
  <div>
    <form class="dialog-form" [formGroup]="basicInfoForm" *ngIf="basicInfoForm">
      <autocomplete-string-select *ngIf="this.countries.length>0"
                                  [inputControl]="country" class="autocomplete-select"
                                  [label]="'product-detail.label.country'"
                                  [optionList]="countries" [optionI18nBase]="'global.country.'" required>
      </autocomplete-string-select>
      <mat-form-field appearance="outline">
        <mat-label>{{'product-detail.label.currency' | translate}}</mat-label>
        <mat-select required formControlName="currency">
          <mat-option *ngFor="let currency of currencies"
                      [value]="currency">{{currency}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-header-cell class="class-price-header-cell" *matHeaderCellDef>
        {{'product-detail.label.priceData' | translate}}
      </mat-header-cell>
      <mat-tab-group [selectedIndex]="selectedPriceTab" [mat-stretch-tabs]="false">
        <mat-tab *ngFor="let priceForm of currCountryPricesFormList; let index = index">
          <ng-template mat-tab-label>
            <div>{{getTabLabel(index)}}</div>
            <button *ngIf="currCountryPricesFormList.length > 1" mat-icon-button class="delete tab-action-btn"
                    (click)="onRemovePriceConfig(index)">
              <mat-icon>delete_outline</mat-icon>
            </button>
          </ng-template>
          <mat-error class="tab-error"
                     *ngIf="parentClassPricesForm.invalid && parentClassPricesForm.errors">{{parentClassPricesForm | validationMessage | async}}</mat-error>
          <class-prices-editable-table class="class-price-table"
                                       [pricesForm]="priceForm"
                                       [data]="observableOfClassPrices(index)"
                                       [dataColumns]="classPriceTableColumns"
                                       [country]="getCountry()"
                                       [i18nBase]="'product-detail.label.chargeClass'">
          </class-prices-editable-table>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <button mat-raised-button class="icon-only tab-action-btn add-new-price-btn" (click)="onAddNewPriceConfig()">
              <mat-icon>add</mat-icon>
            </button>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </form>
    <mat-dialog-actions>
      <button okAction mat-raised-button class="dialogueButton dialogueButtonRight"
              [disabled]="!isSaveAllowed()"
              (click)="onSave()">
        {{'button.save' | translate}}
      </button>
      <button cancelAction mat-raised-button class="dialogueButton"
              (click)="onCancel()">
        {{'button.cancel' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</mat-dialog-content>

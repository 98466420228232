/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
export enum FinanceEnablerStatus {
  IGNORED = 'IGNORED',
  INITIAL = 'INITIAL',
  SENT = 'SENT',
  SENT_FAIL = 'SENT_FAIL',
  BILLED = 'BILLED',
  BILLED_FAIL = 'BILLED_FAIL',
  REIMBURSEMENT_PENDING = 'REIMBURSEMENT_PENDING',
  REIMBURSEMENT_FAILED = 'REIMBURSEMENT_FAILED',
  REIMBURSED = 'REIMBURSED',
  REMOVED = 'REMOVED'
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data" class="scrollable-table-container">
  <mat-table #table [dataSource]="tableDataSource" class="padding-lr-24">
    <div *ngFor="let col of dataColumns; let columnIndex = index">
      <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
        <mat-header-cell *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
        <mat-cell [styledColumn]="col" *matCellDef="let element">
          <ng-container
            *ngIf="col !== 'validFrom' && col !== 'validUntil'">{{element[col]}}</ng-container>
          <ng-container
            *ngIf="col === 'validFrom' || col === 'validUntil'">{{getDateTimeTranslation(element[col])}}</ng-container>
        </mat-cell>
      </ng-container>
    </div>
    <ng-container matColumnDef="tableAction">
      <mat-header-cell *matHeaderCellDef class="actionCell"> {{'tableHeader.action' | translate}}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="actionCell">
        <button mat-mini-fab class="tableActionBtn" (click)="onEdit(element)"  matTooltip="{{'currencyfactor.edit'|translate}}">
          <mat-icon> edit</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
  </mat-table>
</div>

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {BehaviorSubject, Subscription} from "rxjs";
import {ChangeRequestOverviewDto, ChangeRequestsHistoryColumns} from "../datatypes/inbox/change-request-overview-dto";
import {WebConfigRouterService} from "../services/web-config-router.service";
import {InboxService} from "../services/http/audit/inbox.service";
import {Message, SelectConfig} from 'pcs-commons/datatypes';
import {BaseUrls} from "../datatypes/base-urls.enum";
import {ChangeRequestPayloadType} from "../datatypes/inbox/change-request-payload-type";
import {ChangeRequestDetailsMode} from "../datatypes/inbox/change-request-details-mode";
import {SharedDataService} from "../services/shared-data.service";
import {ChangeRequestOverviewFilterData} from "../datatypes/ChangeRequestOverviewFilterData";
import {PaginationHandlerComponent} from "../pagination-handler.component";
import {ActivatedRoute} from "@angular/router";
import {Parameters} from "pcs-commons/global";

@Component({
  selector: 'pcs-change-requests-overview',
  templateUrl: './change-requests-overview.component.html',
  styleUrls: ['./change-requests-overview.component.css']
})
export class ChangeRequestsOverviewComponent extends PaginationHandlerComponent implements OnInit, OnDestroy {
  public readonly translateColumns = [ChangeRequestsHistoryColumns.STATUS];
  public readonly nonSearchableColumns = [ChangeRequestsHistoryColumns.STATUS, ChangeRequestsHistoryColumns.TITLE, ChangeRequestsHistoryColumns.UPDATE];
  public readonly dateColumns = [ChangeRequestsHistoryColumns.UPDATE];

  public selectColumnConfigs = new Map<string, SelectConfig>();
  public loading = false;

  public pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS;
  public resultLength = 0;
  @ViewChild(MatPaginator, {static: true}) public paginator: MatPaginator;

  public changeRequestsSource = new BehaviorSubject<ChangeRequestOverviewDto[]>([]);
  public changeRequests$ = this.changeRequestsSource.asObservable();

  public dataColumns: string[] = Object.values(ChangeRequestsHistoryColumns);

  public currFilter = null;

  public toUnsubscribe: Subscription[] = [];

  constructor(
    private router: WebConfigRouterService,
    private inboxService: InboxService,
    private sharedDataService: SharedDataService,
    private route: ActivatedRoute,
  ) {
    super();
    const selectConf = new SelectConfig();
    selectConf.options = Object.values(ChangeRequestPayloadType);
    this.selectColumnConfigs.set(ChangeRequestsHistoryColumns.TYPE, selectConf);
  }

  public ngOnInit(): void {
    const loadFilterData = this.route.snapshot.queryParamMap.get('loadFilterData');
    this.toUnsubscribe.push(this.sharedDataService.getChangeRequestOverviewFilterData().subscribe(value => {
      if (!loadFilterData) {
        return;
      }
      if (value) {
        this.applyFilter(value);
        if (this.paginator) {
          this.paginator.pageIndex = value.pageIndex || 0;
          this.paginator.pageSize = value.pageSize || this.pageSizeOptions[0];
        }
      }
    }));
    this.retrieveChangeRequests(this.paginator?.pageIndex || 0, this.paginator?.pageSize || this.pageSizeOptions[0]);
  }

  private applyFilter(value: ChangeRequestOverviewFilterData): void {
    this.currFilter = value.filterType || null;
    const selectConf = new SelectConfig();
    selectConf.defaultOption = this.currFilter;
    selectConf.options = this.selectColumnConfigs.get(ChangeRequestsHistoryColumns.TYPE).options;
    selectConf.translationKey = this.selectColumnConfigs.get(ChangeRequestsHistoryColumns.TYPE).translationKey;
    this.selectColumnConfigs.set(ChangeRequestsHistoryColumns.TYPE, selectConf);
  }

  private retrieveChangeRequests(pageIndex: number, pageSize: number): void {
    this.loading = true;
    this.inboxService.getChangeRequests(pageIndex, pageSize, this.currFilter === null ? '' : this.currFilter).subscribe({
      next: (response) => {
        this.loading = false;
        this.handlePagedResponse(pageIndex, pageSize, response, this.changeRequestsSource);
      },
      error: () => this.loading = false
    });
  }

  public handlePageChange(pageEvent: PageEvent): void {
    this.retrieveChangeRequests(pageEvent.pageIndex, pageEvent.pageSize);
  }

  public onFilterChange(filter: any): void {
    this.currFilter = filter.payloadObjectType;
    this.paginator.pageIndex = 0;
    this.retrieveChangeRequests(this.paginator.pageIndex, this.paginator.pageSize || this.pageSizeOptions[0]);
  }

  public navigateToDetailsView(cr: ChangeRequestOverviewDto): void {
    console.log('navigating to details view of change request: ', cr);
    this.publishFilterData();
    this.router.navigate([BaseUrls.CHANGE_REQUEST_DETAILS], {
      queryParams: {
        changeRequestId: cr.id,
        mode: ChangeRequestDetailsMode.INFO
      }
    })
    ;
  }

  private publishFilterData(): void {
    const filterData = {
      pageIndex: this.paginator.pageIndex,
      pageSize: this.paginator.pageSize,
      filterType: this.currFilter,
    }
    this.sharedDataService.updateChangeRequestOverviewFilterData(filterData);
  }

  public handleEmptyResult(): void {
    const msg = new Message();
    msg.message = 'inbox.noRecordsFound';
    this.showInfo(msg);
  }

  public ngOnDestroy(): void {
    this.toUnsubscribe.forEach((sub) => sub.unsubscribe());
  }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div>
  <div class="mat-elevation-z1">
    <mat-toolbar class="headerToolbar mat-elevation-z1">
      <span class="headerSpan">{{'fleets.header.create.fleet' | translate }}</span>
    </mat-toolbar>
  </div>
  <mat-expansion-panel [expanded]="true" hideToggle disabled="true" class="fleetResponseTable">
    <div class="section-container">
      <div *ngIf="users" class="managerTable">
        <mat-label>{{
          'fleets.added.manager'| translate :{fleetId: fleetId}
          }}</mat-label>
        <user-cred-table [users]="users"></user-cred-table>
      </div>
      <button mat-stroked-button id="okBtn" class="normalButton" (click)="goToFleetsPage()">
        <span>{{"fleets.button.ok" | translate}}</span>
      </button>
    </div>
  </mat-expansion-panel>
</div>

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ValidationMessagePipe} from './src/validation-message.pipe';

@NgModule({
  declarations: [
    ValidationMessagePipe
  ],
  imports: [
    CommonModule
  ],
  providers: [
    ValidationMessagePipe
  ],
  exports: [
    ValidationMessagePipe
  ]
})
export class ValidationModule {
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnDestroy, OnInit} from '@angular/core';
import {FleetService} from '../../services/http/fleet.service';
import {ActivatedRoute} from '@angular/router';
import {SharedDataService} from '../../services/shared-data.service';
import {MatDialog} from '@angular/material/dialog';
import {Message} from '../../datatypes/message';
import {Utils} from '../../utils/utils';
import {AddFleetManagerComponent} from '../add-manager/add-fleet-manager.component';
import {User} from '../../datatypes/user';
import {Product} from '../../datatypes/product';
import {ProductService} from '../../services/http/product.service';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {Action} from '../../datatypes/action.enum';
import {DeleteDialogComponent} from '../../dialog/delete-dialog/delete-dialog.component';
import {AbstractFleetManager} from '../abstract-fleet-manager';
import {Observable} from 'rxjs';
import {ProductQuota} from '../../datatypes/product-quota';
import {WebConfigRouterService} from '../../services/web-config-router.service';
import {Fleet} from '../../datatypes/fleet';
import {FleetCreateResponse} from '../../datatypes/fleet-create-response';
import {TranslateService} from '@ngx-translate/core';
import {FleetAddressList} from 'pcs-commons/datatypes';
import {ConfirmationDialogComponent} from "../../dialog/confirmation-dialog/confirmation-dialog.component";
import {ValidatorList} from "pcs-commons/validation";

@Component({
    selector: 'fleet-edit',
    templateUrl: './fleet-edit.component.html',
    styleUrls: ['./fleet-edit.component.css']
})
export class FleetEditComponent extends AbstractFleetManager implements OnInit, OnDestroy {
    public mode: Action;
    public fleetManagerTableColumns: string[] = User.fleetManagerColumns;
    public productTableColumnMap: Map<string, string[]> = ProductQuota.columnGroupMap;
    public currentManagers$: Observable<User[]>;
    public currentProducts$: Observable<ProductQuota[]>;
    public productTableColumns: string[] = ProductQuota.createFleetTableColumns;
    public fleetCreateResponse: FleetCreateResponse;
    public viewResponse = false;
    public fleetTypes: string[] = [];
    private availProducts: Product[] = [];

    constructor(
        fleetService: FleetService,
        router: WebConfigRouterService,
        dataSource: SharedDataService,
        translateService: TranslateService,
        private productService: ProductService,
        private route: ActivatedRoute,
        private formBuilder: FormBuilder,
        dialog: MatDialog) {
        super(router, fleetService, dataSource, dialog, translateService);
        this.currentManagers$ = dataSource.currentManagers$;
        this.currentProducts$ = dataSource.currentProductData$;
        this.fleetService.getFleetTypes().subscribe(types => {
            this.fleetTypes = types;
        });
    }

    public get isEditMode(): boolean {
        return Action.EDIT === this.mode;
    }

    public get isCreateMode(): boolean {
        return Action.CREATE === this.mode;
    }

    public ngOnDestroy(): void {
        this.dataSource.updateProductData([]);
        this.dataSource.updateFleetManagers([]);
    }

    public ngOnInit(): void {
        this.loading = true;
        this.mode = Action.CREATE;
        const id = this.route.snapshot.paramMap.get('id');
        this.getProducts().then(() => {

            this.fleet = new Fleet();
            this.fleet.products = [];
            this.fleet.fleetManagers = [];
            this.currentFleetAddresses = new FleetAddressList();

            this.prepareFormGroups();
            if (id) { // then it is an edit event
                this.mode = Action.EDIT;
                this.getFleetDetails(id);
                this.basicInfoForm.disable();
                this.addressListForm.disable();
            } else {
                this.loading = false;
            }
        });
    }

    public async getProducts(): Promise<void> {
        const products = await this.productService.getProducts();
        this.availProducts = products.filter((prod) => (prod.channel === 'PORSCHE_FLEET') && (prod.status === 'ACTIVE'));
    }

    public getProductsNotInFleet(): string[] {
        const productCodes = [];
        for (const item of this.availProducts) {
            const toAdd = item.code;
            // if the product already exist in the fleet we do not need it
            if (this.fleet.products.find((p) => p.productCode === toAdd)) {
                continue;
            }
            productCodes.push(item.code);
        }
        return productCodes;
    }

    public addManagerDialog(): void {
        const dialogRef = this.dialog.open(AddFleetManagerComponent, {
            width: '600px',
            data: {
                fleetId: this.fleet.fleetId,
                mode: this.mode
            }
        });

        dialogRef.afterClosed().subscribe((result) => {
            console.log(`Dialog result: ${result}`);
            if (result === true) {
                this.getFleetDetails(this.fleet.fleetId);
            } else if (result instanceof User) {
                this.addFleetManager(result);
            }
        });
    }

    public onBack(): void {
        this.router.navigate(['fleets']);
    }

    public handleManagerDelete(managerToDelete: User): void {
        const dialogRef = this.dialog.open(DeleteDialogComponent, {
            data: Utils.deleteManagerConfirmMessage(managerToDelete.login)
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (this.isCreateMode) {
                    this.removeManager(managerToDelete);
                    return;
                }
                this.doDeleteManager(managerToDelete);
            }
        });
    }

    public removeProduct(productToDelete: ProductQuota): void {
        Utils.removeArrayItem(this.fleet.products, productToDelete);
        this.dataSource.updateProductData(this.fleet.products);
    }

    public removeManager(managerToDelete: User): void {
        Utils.removeArrayItem(this.fleet.fleetManagers, managerToDelete);
        this.dataSource.updateFleetManagers(this.fleet.fleetManagers);
    }

    public addProductInput(product): void {
        if (!product) {
            // it's a close event
            this.productToEdit = null;
            this.showProductEditPanel = false;
            return;
        }

        // if product exist, delete and add the new one
        const exist = this.fleet.products.findIndex((p) => p.productCode === product.productCode);
        if (exist >= 0) {
            this.fleet.products.splice(exist, 1);
        }
        this.fleet.products.push(product);
        this.dataSource.updateProductData(this.fleet.products);
        if (this.productToEdit) { // this mean edit mode
            this.productToEdit = null;
        }
        this.showProductEditPanel = false;
    }

    public addFleetManager(managerToAdd): void {
        if (!managerToAdd || !managerToAdd.login) {
            return;
        }

        const exist = this.fleet.fleetManagers.findIndex((fm) => fm.login === managerToAdd.login);
        if (exist >= 0) {
            this.fleet.fleetManagers.splice(exist, 1);
        }
        this.fleet.fleetManagers.push(managerToAdd);
        this.dataSource.updateFleetManagers(this.fleet.fleetManagers);
    }

    public createFleet(): void {
        this.fleet.fleetId = this.basicInfoForm.value.fleetId;
        this.fleet.name = this.basicInfoForm.value.fleetName;
        this.fleet.fleetType = this.basicInfoForm.value.fleetType;
        this.fleet.sapDebtorNumber = this.basicInfoForm.value.sapDebtorNumber;
        this.fleet.doNotSendToFinanceEnabler = this.basicInfoForm.value.doNotSendToFinanceEnabler;
        this.fleet.fleetAddresses = this.fetchFleetAddressList();
        if (this.fleet.fleetId && this.fleet.name && this.fleet.products.length > 0 && this.fleet.fleetManagers.length > 0) {
            this.loading = true;
            this.fleetService.createFleet(this.fleet).subscribe({
                next: (response) => {
                    this.fleetCreateResponse = response;
                    this.viewResponse = true;
                    this.loading = false;
                },
                error: () => {
                    this.loading = false;
                }
            });
        }
    }

    public createFleetNotAllowed(): boolean {
        return this.loading ||
            !(this.fleet.products.length > 0 && this.fleet.fleetManagers.length > 0 && this.basicInfoForm.valid && this.addressListForm.valid);
    }

    private getFleetDetails(id: string): void {
        this.loading = true;
        this.fleetService.getFleetById(id).subscribe({
            next: (response) => {
                console.log(response);
                this.updateFleetData(response);
                this.loading = false;
            },
            error: () => this.loading = false
        });
    }

    private doDeleteManager(managerToDelete: User): void {
        this.fleetService.deleteFleetManager(this.fleet.fleetId, managerToDelete.id)
            .subscribe(() => {
                    const msg = new Message();
                    msg.message = 'fleet.manager.delete.success';
                    this.showInfo(msg);
                    // remove the manager from list
                    Utils.removeArrayItem(this.fleet.fleetManagers, managerToDelete);
                    this.dataSource.updateFleetManagers(this.fleet.fleetManagers);
                }
            );
    }

    private prepareFormGroups(): void {
        // basicInfoForm , fleeName, fleetId
        this.fleetId = new FormControl(this.fleet.fleetId, ValidatorList.FLEET_ID);
        this.fleetName = new FormControl(this.fleet.name, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]);
        this.fleetType = new FormControl(this.fleet.fleetType, [Validators.required]);
        this.sapDebtorNumber = new FormControl(this.fleet.sapDebtorNumber, [Validators.required, Validators.maxLength(10)]);
        this.doNotSendToFinanceEnabler = new FormControl(this.fleet.doNotSendToFinanceEnabler);
        this.basicInfoForm = this.formBuilder.group({
            fleetName: this.fleetName,
            fleetId: this.fleetId,
            fleetType: this.fleetType,
            sapDebtorNumber: this.sapDebtorNumber,
            doNotSendToFinanceEnabler: this.doNotSendToFinanceEnabler
        });

        this.useBillingAsShippingAddress = new FormControl(this.currentFleetAddresses.useBillingAsShippingAddress);
        this.addressListForm = this.formBuilder.group({
            useBillingAsShippingAddress: this.useBillingAsShippingAddress
        });
        this.useBillingAsShippingAddress.valueChanges.subscribe((value) => {
            if (value && this.addressListForm.contains(this.shippingAddAttr)) {
                this.addressListForm.removeControl(this.shippingAddAttr);
            }
        });

        this.doNotSendToFinanceEnabler.valueChanges.subscribe(newValue => {
            if (this.fleet.doNotSendToFinanceEnabler && newValue === false) {
                console.log("doNotSendToFinanceEnabler value: " + newValue);
                this.confirmFleetDataShouldBeSendToFinanceEnabler();
            }
        })
    }

    private confirmFleetDataShouldBeSendToFinanceEnabler(): void {
        const matDialogRef = this.dialog.open(ConfirmationDialogComponent, {
            maxWidth: 800,
            data: {
                message: new Message('fleet.doNotSendToFinanceEnabler.change.confirm'),
                actionKey: 'button.ok'
            }
        });
        matDialogRef.afterClosed().subscribe(value => {
            if (!value) {
                this.doNotSendToFinanceEnabler.setValue(true);
            }
        });
    }
}

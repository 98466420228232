/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {AfterViewInit, Component, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {SelectionModel} from '@angular/cdk/collections';
import {MatPaginator} from '@angular/material/paginator';
import {Parameters} from '../../global/parameters';
import {SharedDataService} from '../../services/shared-data.service';
import {TranslationHelperService} from 'pcs-commons/http';
import {ReimbursementBilledItemDto} from '../../datatypes/invoices/ReimbursementBilledItemDto';
import {ReimbursementStatus} from '../../datatypes/invoices/ReimbursementStatus';

@Component({
  selector: 'invoice-transaction-table',
  templateUrl: './invoice-transaction-table.component.html',
  styleUrls: ['../data-table.component.css',
    '../action-column-style.css',
    '../scrollable-table/scrollable-table.component.css',
    './invoice-transaction-table.component.css']
})
export class InvoiceTransactionTableComponent extends OptimizedTableBaseComponent implements AfterViewInit, OnInit, OnDestroy {
  private initialSelection = [];
  private allowMultiSelect = true;
  public selection = new SelectionModel<ReimbursementBilledItemDto>(this.allowMultiSelect, this.initialSelection);

  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public readonly pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS;

  constructor(renderer: Renderer2,
              private sharedDataService: SharedDataService,
              private translateHelper: TranslationHelperService) {
    super(renderer);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.selection.changed.subscribe(() => {
      this.sharedDataService.updateSelectedInvoiceTransactions(this.selection.selected);
    });
    this.sharedDataService.getClearTransactionSelectionObservable().subscribe((clear) => {
      console.log('clear selection because of new data');
      if (clear) {
        this.selection.clear();
      }
    });
  }

  public ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    let numRows = 0;
    this.tableDataSource.data.forEach((row) => {
        if (this.readyToStartReimbursementProcess(row)) {
          numRows++;
        }
      }
    );
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle(): void {
    this.isAllSelected() ?
      this.selection.clear() :
      this.tableDataSource.data.forEach((row) => this.readyToStartReimbursementProcess(row) && this.selection.select(row));
  }

  public translateDate(date: any): any {
    return this.translateHelper.translateDateTimeSafe(date);
  }

  public translateCountry(country: any): any {
    return this.translateHelper.translateCountryNotFromBE(country);
  }

  public readyToStartReimbursementProcess(item: ReimbursementBilledItemDto): boolean {
    return item &&
      (item.reimbursementStatus === ReimbursementStatus.BILLED || item.reimbursementStatus === ReimbursementStatus.REIMBURSEMENT_FAILED);
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.selection.clear();
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {HeaderEnabled} from './header-enabled.abstract';
import {HttpClient} from '@angular/common/http';
import {IopPlatformDto} from '../../datatypes/iop-platform-dto';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoamingPlatformService extends HeaderEnabled {

  constructor(private http: HttpClient) {
    super();
  }

  public getRoamingPlatforms(): Observable<IopPlatformDto[]> {
    return this.http.get<IopPlatformDto[]>(environment.platformServiceEndpoint, {headers: this.headers});
  }

  public updateRoamingPlatform(toUpdate: IopPlatformDto): Observable<IopPlatformDto[]> {
    return this.http.put<IopPlatformDto[]>(environment.platformServiceEndpoint, toUpdate, {headers: this.headers});
  }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content>
  <h2 mat-dialog-title>
    {{
    'invoice-orderitem.edit.dialog.header'| translate :{ param0: itemId }
    }}
    <mat-spinner class="header-spinner" [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
  </h2>

  <mat-error *ngIf="invalidJson">{{'invoice-orderitem.edit.invalidJson' | translate}}</mat-error>
  <div class="order-item-editor-div">
    <ngs-code-editor *ngIf="codeModel" theme="vs" class="order-item-editor"
                     [codeModel]="codeModel"
                     [readOnly]="!(reqEditPermission | accessAllowed)"
                     (valueChanged)="onEditorValueChange($event)">
    </ngs-code-editor>
  </div>
  <mat-dialog-actions>
    <button mat-raised-button class="dialogueButton dialogueButtonRight saveOrderItemBtn" id="actionBtn"
            *ngIf="(reqEditPermission | accessAllowed)"
            [disabled]="invalidJson"
            (click)="saveOrderItemChanges()"
            matTooltip="{{'invoice-orderitem.save.tooltip' | translate}}">
      {{ 'invoice-orderitem.save' | translate}}
    </button>
    <button mat-raised-button class="dialogueButton dialogueButtonRight" id="cancelBtn" (click)="closeDialog()">
      {{(!(reqEditPermission | accessAllowed) ? 'button.close' : 'button.cancel')| translate}}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>


/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {ElectricVehicleStatus} from './electric-vehicle-status.enum';

export class ElectricVehicle {
  id: number;
  pcid: string;
  vin: string;
  emaid: string;
  modelType: string;
  modelDescription: string;
  status: ElectricVehicleStatus;
  jobStatus: string;
  pncCertificateValidity: string;
  contractId: string;
  plugAndChargeEnabled: boolean;
  errorMessage: string;
  readJobRequestId: string;
}

export enum ElectricVehicleColumns {
  VIN = 'vin',
  PCID = 'pcid',
  EMAID = 'emaid',
  MODEL_TYPE = 'modelType',
  MODEL_DESCRIPTION = 'modelDescription',
  STATUS = 'status',
  JOB_STATUS = 'jobStatus',
  ERROR_MSG = 'errorMessage',
  PNC_CERTIFICATE_VALIDITY = 'pncCertificateValidity',
  CONTRACT_ID = 'contractId',
  PLUG_AND_CHARGE_ENABLED = 'plugAndChargeEnabled',
}

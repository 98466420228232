/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {ChangeRequestMessageType} from "./change-request-message-type";

export class ChangeRequestMessageDto {
  public messageType: ChangeRequestMessageType;
  public senderUserId: string;
  public senderLogin: string;
  public comment: string;
  public created: string;
}

export enum ChangeRequestMessageColumns {
  CREATED = 'created',
  SENDER = 'senderLogin',
  MESSAGE_TYPE = 'messageType',
  COMMENT = 'comment',
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Inject} from '@angular/core';
import {Notification} from 'pcs-commons/datatypes';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';
import {NotificationStore} from '../notification-store';

@Component({
  selector: 'pcs-notification-snack-bar',
  templateUrl: './notification-snack-bar.component.html',
  styleUrls: ['./notification-snack-bar.component.css']
})
export class NotificationSnackBarComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: Notification) {
  }

  public onClose(): void {
    NotificationStore.instance.clear();
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {ChangeRequestSubscriberType} from "./change-request-subscriber-type";

export class ChangeRequestSubscriberDto {
  public userId: string;
  public login: string;
  public type: ChangeRequestSubscriberType;
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {FormHelper} from '../../utils/form-helper';
import {ElectricVehicleFilter} from '../../datatypes/electric-vehicle-filter';

@Component({
  selector: 'electric-vehicle-filter',
  templateUrl: './electric-vehicle-filter.component.html',
  styleUrls: ['./electric-vehicle-filter.component.css']
})
export class ElectricVehicleFilterComponent {
  @Output() public filterEvent = new EventEmitter<ElectricVehicleFilter>();
  @Input() public loading;
  public inputMaxLength = 100;

  public filterFormGroup: FormGroup;
  public vinFilter: FormControl;

  constructor(
    private formBuilder: FormBuilder) {
    this.vinFilter = new FormControl('', [Validators.required, Validators.maxLength(this.inputMaxLength)]);
    this.filterFormGroup = this.formBuilder.group({
      vinFilter: this.vinFilter
    });
  }

  public isFilterValid(): boolean {
    return this.filterFormGroup && this.filterFormGroup.valid;
  }

  public onDisplayEVs(): void {
    const vin = FormHelper.getStringValue(this.filterFormGroup, 'vinFilter');
    const filter = new ElectricVehicleFilter();
    filter.vin = vin;
    this.filterEvent.emit(filter);
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {ErrorStateMatcher} from '@angular/material/core';
import {FormControl, FormGroupDirective, NgForm} from '@angular/forms';

/**
 * This ErrorMatcher can be used for a FormControl to identify if the control itself has an error or the FormGroup containing this
 * control has an error (e.g. the group has validation that has to satisfy multiple controls inside the group).
 * If the other individual FromControls in the group are invalid, the error matcher will not the affected
 */
export class MandatoryCrossFieldFormErrorMatcher implements ErrorStateMatcher {
  public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if (form.invalid && form.errors) {
      console.log('form errors:', form.errors);
    }
    // form can be invalid if an individual control is invalid.
    return control.touched && (form.invalid && form.errors !== null || control.invalid);
  }
}

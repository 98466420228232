/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {LocationAdminAttributeDto} from './location-admin-attribute-dto';
import {ChargepointAdminAttributeDto} from './chargepoint/chargepoint-admin-attribute-dto';

export class LocationAndChargepointAdminAttributeDto {
  public locationAttributes: LocationAdminAttributeDto[];
  public locationLastUpdated: string;
  public chargepointAttributes: ChargepointAdminAttributeDto[];
  public chargepointLastUpdated: string;
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable, OnDestroy} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatisticDownloadStatusService implements OnDestroy {
  public currentChunk: number | undefined = undefined;

  public downloadInProgress = false;
  public currentQuery: string | undefined = undefined;

  constructor() {
    console.log("StatisticDownloadStatusService initialized");
  }

  public start(query: string): void {
    this.currentQuery = query;
    console.time(this.currentQuery);
    this.downloadInProgress = true;
    this.currentChunk = 0;
  }

  public stop(): void {
    this.downloadInProgress = false;
    this.currentChunk = undefined;
    console.timeEnd(this.currentQuery);
    this.currentQuery = undefined;
  }

  public tick(): void {
    if (this.currentChunk !== undefined) {
      this.currentChunk++;
    }
  }

  public ngOnDestroy(): void {
    console.log("StatisticDownloadStatusService destroyed");
  }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data">
  <div class="scrollable-table-container">
    <mat-table #table [dataSource]="tableDataSource" class="padding-lr-24">
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container *ngIf="col !== actionColumn && col !== pricesColumn" matColumnDef="{{col}}">
          <mat-header-cell *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let element ">
            <div *ngIf="!columnsNeedConversion.includes(col)">
              {{element[col]}}
            </div>
            <div *ngIf="col === daysOfWeekColumn">
              {{element[col] | translateWeekdays}}
            </div>
            <div *ngIf="col === powerClassesColumn">
              {{element[col] | translatePowerClasses}}
            </div>
            <div *ngIf="col === startDateColumn || col === endDateColumn">
              {{translateDate(element[col])}}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container *ngIf="col !== actionColumn && col === pricesColumn" matColumnDef="{{col}}">
          <mat-header-cell class="prices-cell" *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
          <mat-cell class="prices-cell" *matCellDef="let element ">
            <price-component-table
              class="price-component-table"
              [i18nBase]="'purchaseCondition.element'"
              *ngIf="hasPrices(element)"
              [data]="observableOfPrices(element)"
              [dataColumns]="pricesTableColumns">
            </price-component-table>
          </mat-cell>
        </ng-container>
      </div>
      <ng-container matColumnDef="tableAction" stickyEnd>
        <mat-header-cell *matHeaderCellDef class="actionCell"> {{'fleet.tableheader.action' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="actionCell">
          <button mat-mini-fab class="edit mat-elevation-z0" (mouseenter)="changeStyle($event)"
                  (mouseleave)="changeStyle($event)" (click)="editElement(element)" *ngIf="!disableEdit">
            <mat-icon> edit</mat-icon>
          </button>
          <button mat-mini-fab class="delete mat-elevation-z0" (mouseenter)="changeStyle($event)"
                  (mouseleave)="changeStyle($event)" (click)="deleteElement(element)" *ngIf="!disableDelete">
            <mat-icon> delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </div>
</div>

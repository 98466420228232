<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content>
  <h2 mat-dialog-title>
    {{
    'ionityContractData.dialog.header'| translate :{param0: contractId}
    }}
  </h2>
  <div class="ionity-data-editor-div">
    <ngs-code-editor *ngIf="codeModel"
                     theme="vs"
                     class="ionity-data-editor"
                     [codeModel]="codeModel"
                     readOnly="true">
    </ngs-code-editor>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="margin-right no-padding">
  <button mat-stroked-button id="cancelBtn" class="dialogueButton" [mat-dialog-close]="false">
    {{'button.close'| translate}}
  </button>
</mat-dialog-actions>

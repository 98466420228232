/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Message } from '../../datatypes/message';
import { FocusMonitor } from '@angular/cdk/a11y';

@Component({
  selector: 'delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements AfterViewInit {

  constructor(@Inject(MAT_DIALOG_DATA) public message: Message, private _focusMonitor: FocusMonitor) { }

  ngAfterViewInit() {
    this._focusMonitor.stopMonitoring(document.getElementById('deleteBtn'));
    this._focusMonitor.stopMonitoring(document.getElementById('cancelBtn'));
  }
}

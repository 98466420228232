/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {ChargepointClassPrice} from './chargepoint-class-price';
import {Identifiable} from 'pcs-commons/datatypes';

export class CountryPriceRecord extends Identifiable {
  public country: string;
  public currency: string;
  public validFrom: string;
  public validUntil: string;
  public priceDtos: ChargepointClassPrice[];
}

/**
 * please keep the order
 */
export enum EditCountryPriceRecordColumns {
  COUNTRY = 'country',
  CURRENCY = 'currency',
  TAX = 'tax',
  VALID_FROM_DATE = 'validFromDate',
  VALID_FROM_TIME = 'validFromTime',
  VALID_UNTIL_DATE = 'validUntilDate',
  VALID_UNTIL_TIME = 'validUntilTime',
  PRICE_DTOS = 'priceDtos'
}

export enum CountryPriceRecordColumns {
  COUNTRY = 'country',
  CURRENCY = 'currency',
  TAX = 'tax',
  VALID_FROM = 'validFrom',
  VALID_UNTIL = 'validUntil',
  PRICE_DTOS = 'priceDtos'
}

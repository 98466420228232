<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div class="mat-elevation-z1">
  <mat-toolbar class="header-toolbar">
    <span>{{'fleet.invoices.header' | translate :{param0: fleetId} }}</span>
    <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
  </mat-toolbar>
  <pcs-fleet-invoice-table *ngIf="fleetId" [data]="invoices$" [dataColumns]="invoicesColumns" [i18nBase]="i18nBase"
                           [downloadButtons]="downloadButtons"
                           (downloadInvoiceEvent)="onDownloadInvoice($event)"
                           (downloadBilledItemsFromFEEvent)="onDownloadBilledItemsFromFE($event)"
                           (downloadBilledItemsFromPCSEvent)="onDownloadBilledItemsFromPCS($event)">
  </pcs-fleet-invoice-table>
</div>

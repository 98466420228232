/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Identifiable} from "./identifiable";
import {DateTime} from "luxon";

export class InvoiceOverview extends Identifiable {
  public invoiceName: string;
  public invoiceNumber: string;
  public invoiceAmount: string;
  public invoiceDateTime: DateTime;
  public syncStatus: string;
  public country: string;
}

export enum InvoiceOverviewColumns {
  INVOICE_NAME = 'invoiceName',
  INVOICE_NUMBER = 'invoiceNumber',
  INVOICE_AMOUNT = 'invoiceAmount',
  INVOICE_DATE_TIME = 'invoiceDateTime',
  DOWNLOAD_INVOICE = 'downloadInvoice',
  DOWNLOAD_BILLEDITEMS_FE = 'downloadBilleditemsFE',
  DOWNLOAD_BILLEDITEMS_PCS = 'downloadBilleditemsPCS'
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Inject} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SharedDataService} from "../../services/shared-data.service";
import {BehaviorSubject, firstValueFrom} from "rxjs";
import {Message} from "../../datatypes/message";
import {OrderItemColumns, OrderItemDto} from "../../datatypes/invoices/OrderItemDto";
import {AccessRights} from "../../datatypes/access-rights.enum";
import {
  InvoiceOrderItemUpdateDialogComponent
} from "../invoice-order-item-update-dialog/invoice-order-item-update-dialog.component";
import {AbstractNotificationHandler} from "pcs-commons/notification";
import {InvoiceService} from "../../services/http/invoice.service";
import {AuditService} from "../../services/audit.service";
import {InvoiceAdminDto} from "../../datatypes/invoices/InvoiceAdminDto";

@Component({
  selector: 'pcs-invoice-order-items-dialog-component',
  templateUrl: './invoice-order-items-dialog.component.html',
  styleUrls: [
    '../../dialog/dialog-common-styles.css', './invoice-order-items-dialog.component.css']
})
export class InvoiceOrderItemsDialogComponent extends AbstractNotificationHandler {
  public readonly reqEditPermission = [AccessRights.INVOICE_EDIT_WEB];
  private originalOrderItems: OrderItemDto[] = [];
  private orderItems = new BehaviorSubject<OrderItemDto[]>([]);
  public orderItems$ = this.orderItems.asObservable();
  public selectedInvoice: InvoiceAdminDto;

  public dataColumns: string[] = Object.values(OrderItemColumns);

  public loading = false;

  public selectedInvoiceTransactions: OrderItemDto[] = [];


  constructor(
    private dialogRef: MatDialogRef<InvoiceOrderItemsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    formBuilder: FormBuilder,
    private sharedDataService: SharedDataService,
    private invoiceService: InvoiceService,
    private auditService: AuditService,
    private orderItemUpdateDialog: MatDialog) {
    super();
    this.selectedInvoice = data.selectedInvoice;
    this.originalOrderItems = JSON.parse(JSON.stringify(data.originalOrderItems));
    this.sharedDataService.getOrderItemList().subscribe(orderItems => {
      console.log('new OrderItems', orderItems);
      this.orderItems.next(orderItems);
    });
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  public onOpenEditDialog(toShow: OrderItemDto): void {
    this.showOrderItemDetails(toShow)
  }

  private showOrderItemDetails(toShow: OrderItemDto): void {
    const dialogRef = this.orderItemUpdateDialog.open(InvoiceOrderItemUpdateDialogComponent,
      {
        width: '750px',
        data: {
          orderItem: toShow
        }
      });

    dialogRef.afterClosed().subscribe((response) => {
      if (response.update) {
        this.sharedDataService.updateOrderItemList(this.orderItems.value.map(item => response.itemId === item.item_id ? response.orderItem : item));
        this.showInfo(new Message('invoice-orderitem.updated'));
      }
    });
  }

  public async updateOrderItems(): Promise<void> {
    console.log('orderItems to be sent to backend: ', this.orderItems.value);
    await firstValueFrom(this.invoiceService.validateOrderItemsUpdate(this.selectedInvoice.id, this.orderItems.value));
    await this.auditService.initiateChangeRequestForInvoiceCorrection(this.selectedInvoice, this.originalOrderItems, this.orderItems.value).then((crCreated) => {
      if (crCreated) {
        this.dialogRef.close(true);
      }
    });
  }
}

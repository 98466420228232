/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input} from '@angular/core';
import {Observable} from "rxjs";
import {take, tap} from "rxjs/operators";

export const activeTaskCounter: { [key: string]: number } = {};

function countTask(component: any): void {
  const key = component.constructor.name;
  if (!activeTaskCounter[key]) {
    activeTaskCounter[key] = 1;
  } else {
    activeTaskCounter[key]++;
  }
}

function releaseTask(component: any): void {
  const key = component.constructor.name;
  activeTaskCounter[key]--;
}

function isTaskActive(component: any): boolean {
  if (!component) {
    return false;
  }
  const key = component.constructor.name;
  return !!activeTaskCounter[key];
}

export function WithLoadingSpinner() {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  return function (target: unknown, propertyName: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    descriptor.value = async function (...args: unknown[]) {
      try {
        countTask(this);
        const result = originalMethod.apply(this, args);
        if (result instanceof Promise) {
          await result;
        } else if (result instanceof Observable) {
          countTask(this);
          result.pipe(
            take(1),
            tap(() => releaseTask(this))
          ).subscribe();
        }
        return result;
      } finally {
        releaseTask(this);
      }
    };
    return descriptor;
  };
}

@Component({
  selector: 'pcs-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.css']
})
export class LoadingSpinnerComponent {
  @Input()
  public component: unknown;

  public get loading(): boolean {
    return isTaskActive(this.component);
  }
}

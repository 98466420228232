/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {ImportAction} from './import-action.enum';

export class TariffImportRecord {
  public static readonly tableColumns: string[] = ['row', 'evseid', 'tariffId', 'action', 'importResult'];
  public action: ImportAction;
  public importResult: string;
  public row: number;
  public evseid: string;
  public tariffId: string;
}

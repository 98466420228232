/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
export enum AccessRights {
  PRODUCT_READ_WEB = 'PRODUCT_READ_WEB',
  PRODUCT_EDIT_WEB = 'PRODUCT_EDIT_WEB',
  CPO_AND_PARTNER_READ_WEB = 'CPO_AND_PARTNER_READ_WEB',
  CPO_AND_PARTNER_EDIT_WEB = 'CPO_AND_PARTNER_EDIT_WEB',
  CHARGEPOINTS_READ_WEB = 'CHARGEPOINTS_READ_WEB',
  CHARGEPOINTS_EDIT_WEB = 'CHARGEPOINTS_EDIT_WEB',
  CUSTOMERMANAGEMENT_READ_WEB = 'CUSTOMERMANAGEMENT_READ_WEB',
  CUSTOMERMANAGEMENT_EDIT_WEB = 'CUSTOMERMANAGEMENT_EDIT_WEB',
  STATISTICS_READ_WEB = 'STATISTICS_READ_WEB',
  STATISTICS_EDIT_WEB = 'STATISTICS_EDIT_WEB',
  MASTERDATA_READ_WEB = 'MASTERDATA_READ_WEB',
  MASTERDATA_EDIT_WEB = 'MASTERDATA_EDIT_WEB',
  USERMANAGEMENT_READ_WEB = 'USERMANAGEMENT_READ_WEB',
  USERMANAGEMENT_EDIT_WEB = 'USERMANAGEMENT_EDIT_WEB',
  DATABASE_READ_WEB = 'DATABASE_READ_WEB',
  DATABASE_EDIT_WEB = 'DATABASE_EDIT_WEB',
  PURCHASE_READ_WEB = 'PURCHASE_READ_WEB',
  PURCHASE_EDIT_WEB = 'PURCHASE_EDIT_WEB',
  ADMIN_WEB = 'ADMIN_WEB',
  CDR_READ_WEB = 'CDR_READ_WEB',
  CDR_EDIT_WEB = 'CDR_EDIT_WEB',
  INVOICE_READ_WEB = 'INVOICE_READ_WEB',
  INVOICE_EDIT_WEB = 'INVOICE_EDIT_WEB',
  VEHICLE_READ_WEB = 'VEHICLE_READ_WEB',
  VEHICLE_EDIT_WEB = 'VEHICLE_EDIT_WEB',
  IONITYDATA_READ_WEB = 'IONITYDATA_READ_WEB',
  IONITYDATA_EDIT_WEB = 'IONITYDATA_EDIT_WEB',
  FLEET_READ_WEB = 'FLEET_READ_WEB',
  FLEET_EDIT_WEB = 'FLEET_EDIT_WEB',
  PLAN_HISTORY_READ_WEB = 'PLAN_HISTORY_READ_WEB',
  PLAN_HISTORY_EDIT_WEB = 'PLAN_HISTORY_EDIT_WEB',
  CHANGE_REQUEST_READ_WEB = 'CHANGE_REQUEST_READ_WEB',
  JOB_READ_WEB = 'JOB_READ_WEB',
  JOB_EDIT_WEB = 'JOB_EDIT_WEB'
}

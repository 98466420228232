<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-toolbar class="headerToolbar mat-elevation-z1">
  <span class="headerSpan">{{'customerinfo.header' | translate }}</span>
  <mat-spinner [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
</mat-toolbar>

<div class="section-container">
  <pcs-customerinfo-filter (filterEvent)="applyFilter($event)" editKeyEvents></pcs-customerinfo-filter>
</div>

<div class="section-container" *ngIf="resultLength > 0" id="customerinfo">
  <mat-accordion>
    <mat-expansion-panel [expanded]="resultLength > 0" hideToggle>
      <pcs-customerinfo-table
        [dataColumns]="dataColumns"
        [i18nBase]="'customerinfo.tableHeader'"
        [data]="customerinfoSearchResult$" [actionButtons]="reqEditPermission |accessAllowed"
        (downloadCustomerinfoEvent)="onDownloadCustomerinfo($event)">
      </pcs-customerinfo-table>
      <mat-paginator [pageSizeOptions]="pageSizeOptions"
                     (page)="handlePageChange($event)" [length]="resultLength">
      </mat-paginator>
    </mat-expansion-panel>
  </mat-accordion>
</div>

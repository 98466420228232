/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {UserInfo} from '../../datatypes/user-info';
import {User} from '../../datatypes/user';
import {HeaderEnabled} from './header-enabled.abstract';
import {HttpClient} from '@angular/common/http';
import {SoftwareTokenAssociateResult} from '../../datatypes/software-token-associate-result';
import {VerifySoftwareTokenRequest} from '../../datatypes/verify-software-token-request';
import {SetPreferredMFARequest} from '../../datatypes/set-preferred-mfarequest';
import {PasswordChangeRequest} from '../../datatypes/password-change-request';
import {firstValueFrom, Observable} from 'rxjs';

@Injectable()
export class UserService extends HeaderEnabled {

  constructor(private http: HttpClient) {
    super();
  }

  public getUserInfo(): Observable<UserInfo> {
    return this.http
      .get<UserInfo>(environment.userServiceEndpoint,
        {headers: this.headers}
      );
  }

  public createUser(user: User): Observable<User> {
    return this.http
      .post<User>(environment.userServiceEndpoint,
        user,
        {headers: this.headers}
      );
  }

  public updateUser(user: User): Observable<User> {
    return this.http
      .put<User>(environment.userServiceEndpoint + user.id as string,
        user,
        {headers: this.headers}
      );
  }

  public deleteUser(userId: number): Observable<any> {
    return this.http
      .delete(environment.userServiceEndpoint + userId,
        {headers: this.headers}
      );
  }

  public async getUsers(): Promise<User[]> {
    return firstValueFrom(this.http
      .get<User[]>(environment.userServiceEndpoint + 'all',
        {headers: this.headers}
      ));
  }

  public getUsersByRight(accessRight: string): Observable<User[]> {
    return this.http
      .get<User[]>(environment.userServiceEndpoint + 'by-rights',
        {headers: this.headers, params: {accessRight}}
      );
  }

  public associateTotpMFA(): Observable<SoftwareTokenAssociateResult> {
    return this.http
      .post<SoftwareTokenAssociateResult>(environment.userServiceEndpoint + 'associate-totp',
        {headers: this.headers}
      );
  }

  public verifyTotpToken(verifySoftwareTokenRequest: VerifySoftwareTokenRequest): Observable<any> {
    return this.http
      .post<any>(environment.userServiceEndpoint + 'verify-totp', verifySoftwareTokenRequest, {headers: this.headers});
  }

  public setPreferredMFA(setPreferredMFARequest: SetPreferredMFARequest): Observable<any> {
    return this.http
      .put<any>(environment.userServiceEndpoint + 'set-preferred-mfa', setPreferredMFARequest, {headers: this.headers});
  }

  public changePassword(request: PasswordChangeRequest): Observable<any> {
    return this.http.post<any>(environment.userServiceEndpoint + 'change-password', request, {headers: this.headers});
  }

  public validateUserEditRequest(user: User): Observable<void> {
    return this.http
      .post<void>(environment.userServiceEndpoint + user.id as string + '/validate-update',
        user,
        {headers: this.headers}
      );
  }

  public validateUserDeleteRequest(user: User): Observable<void> {
    return this.http
      .get<void>(environment.userServiceEndpoint + user.id as string + '/validate-deletion',
        {headers: this.headers}
      );
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, OnChanges, OnDestroy, Renderer2, SimpleChanges, ViewChild} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {Subscription} from "rxjs";
import {OptimizedTableBaseComponent} from "pcs-commons/data-tables";
import {TranslationHelperService} from "pcs-commons/http";

@Component({
  selector: 'pcs-contract-history-table',
  templateUrl: './contract-history-table.component.html',
  styleUrls: ['./contract-history-table.component.css']
})
export class ContractHistoryTableComponent extends OptimizedTableBaseComponent implements OnDestroy, OnChanges {

  @Input()
  public inputHasChanged: unknown;

  private _sort: MatSort;

  public langChangeSub: Subscription;

  constructor(renderer: Renderer2,
              private translateHelper: TranslationHelperService
  ) {
    super(renderer);
    this.dataColumns = ['status', 'enrollmentStatus', 'newPlan', 'oldPlan', 'lastUpdated', 'contractId'];
    this.i18nBase = "contractHistory";
    this.langChangeSub = this.translateHelper.onLangChange.subscribe(() => {
      this.tableDataSource.sort = this.sort;
    });
  }

  public get sort(): MatSort {
    return this._sort;
  }

  @ViewChild(MatSort, {static: false})
  public set sort(s: MatSort) {
    const initial = !this.tableDataSource.sort;
    this.tableDataSource.sort = s;
    if (initial) {
      this.sortByChangeDate();
    }
  }

  private sortByChangeDate(): void {
    this.tableDataSource.sort?.sort({id: null, start: "asc", disableClear: false});
    this.tableDataSource.sort?.sort({id: "changed", start: "asc", disableClear: false});
  }

  private sortAgain(): void {
    const sort = this.tableDataSource.sort;
    if (sort) {
      const id = sort.active;
      const start = sort.direction;
      sort.sort({id: null, start: "asc", disableClear: false});
      sort.sort({id, start, disableClear: false});
    }
  }

  public getDateTimeTranslation(value: string): string {
    return this.translateHelper.translateDateTimeUTCSafe(value);
  }

  public ngOnDestroy(): void {
    this.langChangeSub?.unsubscribe();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this.sortAgain();
  }
}

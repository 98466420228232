/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Validators } from '@angular/forms';
import { Parameters } from '../global/parameters';
import { CountryUtil } from '../utils/country-util';

export class ChargepointValidatorList {
  public static DEFAULT = [Validators.maxLength(Parameters.CP_ATTRIBUTE_MAX_LENGTH)];

  public static COUNTRY_CODE = [Validators.maxLength(Parameters.CP_ATTRIBUTE_MAX_LENGTH), CountryUtil.validateCountryByAlpha2];
}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { AccessRights } from './access-rights.enum';

export class Domain {
  constructor(
    private readonly _name: string,
    private readonly _key: string,
    private readonly _readPermission: AccessRights,
    private readonly _editPermission: AccessRights
  ) {}

  public get name(): string {
    return this._name;
  }

  public get key(): string {
    return this._key;
  }

  public get readPermission(): AccessRights {
    return this._readPermission;
  }

  public get editPermission(): AccessRights {
    return this._editPermission;
  }

  public get permissions(): AccessRights[] {
    const permissions = [];
    if (this._readPermission) {
      permissions.push(this._readPermission);
    }
    if (this._editPermission) {
      permissions.push(this._editPermission);
    }
    return permissions;
  }

  // domain list
  public static readonly PRODUCTS = new Domain(
    'PRODUCTS',
    'domain.PRODUCTS',
    AccessRights.PRODUCT_READ_WEB,
    AccessRights.PRODUCT_EDIT_WEB
  );

  public static readonly CPO_AND_PARTNER = new Domain(
    'CPO_AND_PARTNER',
    'domain.CPO_AND_PARTNER',
    AccessRights.CPO_AND_PARTNER_READ_WEB,
    AccessRights.CPO_AND_PARTNER_EDIT_WEB
  );
  public static readonly CHARGEPOINTS = new Domain(
    'CHARGEPOINTS',
    'domain.CHARGEPOINTS',
    AccessRights.CHARGEPOINTS_READ_WEB,
    AccessRights.CHARGEPOINTS_EDIT_WEB
  );

  public static readonly CUSTOMER_MANAGEMENT = new Domain(
    'CUSTOMER_MANAGEMENT',
    'domain.CUSTOMER_MANAGEMENT',
    AccessRights.CUSTOMERMANAGEMENT_READ_WEB,
    AccessRights.CUSTOMERMANAGEMENT_EDIT_WEB
  );

  public static readonly STATISTICS = new Domain(
    'STATISTICS',
    'domain.STATISTICS',
    AccessRights.STATISTICS_READ_WEB,
    AccessRights.STATISTICS_EDIT_WEB
  );

  public static readonly MASTERDATA = new Domain(
    'MASTERDATA',
    'domain.MASTERDATA',
    AccessRights.MASTERDATA_READ_WEB,
    AccessRights.MASTERDATA_EDIT_WEB
  );

  public static readonly USER_MANAGEMENT = new Domain(
    'USER_MANAGEMENT',
    'domain.USER_MANAGEMENT',
    AccessRights.USERMANAGEMENT_READ_WEB,
    AccessRights.USERMANAGEMENT_EDIT_WEB
  );

  public static readonly DATABASE = new Domain(
    'DATABASE',
    'domain.DATABASE',
    AccessRights.DATABASE_READ_WEB,
    AccessRights.DATABASE_EDIT_WEB
  );
  public static readonly PURCHASE_CONDITIONS = new Domain(
    'PURCHASE_CONDITIONS',
    'domain.PURCHASE_CONDITIONS',
    AccessRights.PURCHASE_READ_WEB,
    AccessRights.PURCHASE_EDIT_WEB
  );
  public static readonly CDR = new Domain('CDR', 'domain.CDR', AccessRights.CDR_READ_WEB, AccessRights.CDR_EDIT_WEB);
  public static readonly INVOICES = new Domain(
    'INVOICES',
    'domain.INVOICES',
    AccessRights.INVOICE_READ_WEB,
    AccessRights.INVOICE_EDIT_WEB
  );
  public static readonly ELECTRIC_VEHICLES = new Domain(
    'ELECTRIC_VEHICLES',
    'domain.ELECTRIC_VEHICLES',
    AccessRights.VEHICLE_READ_WEB,
    AccessRights.VEHICLE_EDIT_WEB
  );
  public static readonly IONITY_CONTRACT_DATA = new Domain(
    'IONITY_CONTRACT_DATA',
    'domain.IONITY_CONTRACT_DATA',
    AccessRights.IONITYDATA_READ_WEB,
    AccessRights.IONITYDATA_EDIT_WEB
  );
  public static readonly FLEETS = new Domain('FLEETS', 'domain.FLEETS', AccessRights.FLEET_READ_WEB, AccessRights.FLEET_EDIT_WEB);
  public static readonly CHANGE_REQUESTS_OVERVIEW = new Domain(
    'CHANGE_REQUESTS_OVERVIEW',
    'domain.CHANGE_REQUESTS_OVERVIEW',
    AccessRights.CHANGE_REQUEST_READ_WEB,
    undefined
  );
  public static readonly ENROLLMENT_OVERVIEW = new Domain(
    'ENROLLMENT_OVERVIEW',
    'domain.ENROLLMENT_OVERVIEW',
    AccessRights.CUSTOMERMANAGEMENT_READ_WEB,
    undefined
  );
  public static readonly JOB_EXECUTION = new Domain(
    'JOB_EXECUTION',
    'domain.JOB_EXECUTION',
    AccessRights.JOB_READ_WEB,
    AccessRights.JOB_EDIT_WEB
  );
  public static readonly QUERY_HISTORY_OVERVIEW = new Domain(
    'QUERY_HISTORY_OVERVIEW',
    'domain.QUERY_HISTORY_OVERVIEW',
    AccessRights.ADMIN_WEB,
    AccessRights.ADMIN_WEB
  );
  public static readonly KEY_TAG = new Domain(
    'KEY_TAG',
    'domain.KEY_TAG',
    AccessRights.CUSTOMERMANAGEMENT_READ_WEB,
    AccessRights.CUSTOMERMANAGEMENT_EDIT_WEB
  );

  public static readonly ALL_DOMAINS: Domain[] = [
    Domain.PRODUCTS,
    Domain.CHARGEPOINTS,
    Domain.CPO_AND_PARTNER,
    Domain.CUSTOMER_MANAGEMENT,
    Domain.STATISTICS,
    Domain.MASTERDATA,
    Domain.USER_MANAGEMENT,
    Domain.DATABASE,
    Domain.PURCHASE_CONDITIONS,
    Domain.CDR,
    Domain.INVOICES,
    Domain.ELECTRIC_VEHICLES,
    Domain.IONITY_CONTRACT_DATA,
    Domain.FLEETS,
    Domain.CHANGE_REQUESTS_OVERVIEW,
    Domain.ENROLLMENT_OVERVIEW,
    Domain.JOB_EXECUTION
  ];
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Renderer2, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {TranslationHelperService} from 'pcs-commons/http';
import {MatSort} from "@angular/material/sort";
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';

@Component({
  selector: 'pcs-change-request-history-table',
  templateUrl: './change-request-history-table.component.html',
  styleUrls: ['../scrollable-table/scrollable-table.component.css', './change-request-history-table.component.css']
})
export class ChangeRequestHistoryTableComponent extends OptimizedTableBaseComponent {
  @ViewChild(MatSort, {static: false}) public sort: MatSort;

  constructor(private dialog: MatDialog,
              private translateHelper: TranslationHelperService,
              renderer: Renderer2) {
    super(renderer);
  }

  public ngAfterViewInit(): void {
    // has to be done in ngAfterViewInit, else sorting would not work, because of ngIf
    this.tableDataSource.sort = this.sort;
  }

  public getDateTimeTranslation(value: string): string {
    return this.translateHelper.translateDateTimeToLocal(value);
  }
}

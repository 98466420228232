/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Renderer2} from '@angular/core';
import {PaginatedTableBaseComponent} from 'pcs-commons/data-tables';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'paginated-table',
  templateUrl: './paginated-table.component.html',
  styleUrls: [
    '../action-column-style.css',
    '../data-table.component.css'
  ]
})
export class PaginatedTableComponent extends PaginatedTableBaseComponent {
  public update =  new Date();
  constructor(
    renderer: Renderer2,
    private translate: TranslateService
  ) {
    super(renderer);
    this.translate.onLangChange.subscribe(() => this.update =  new Date());
  }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="changeRequestInfoOperatorData && changeRequest">
  <div class="changeRequest-info-description">
  <span>
    {{
      'changeRequest.info.description'| translate :{ param0: changeRequest.comment }
    }}
  </span>
  </div>
  <div>
    <span *ngIf="changeRequestInfoOperatorData.isSubCpo">
      {{
        'changeRequest.info.subcponame'| translate :{ param0: changeRequestInfoOperatorData.displayName || changeRequestInfoOperatorData.name }
      }}
    </span>
    <span *ngIf="!changeRequestInfoOperatorData.isSubCpo">
      {{
        'changeRequest.info.cponame'| translate :{ param0: changeRequestInfoOperatorData.displayName || changeRequestInfoOperatorData.name }
      }}
    </span>
  </div>
  <div>
    <p>{{ actionText | translate: { param0: changeRequestInfoOperatorData.displayName || changeRequestInfoOperatorData.name } }}</p>
  </div>
  <div>
    <table class="no-padding no-border space-between">
      <tr>
        <td>{{ currentStatusText| translate }}</td>
      </tr>
      <tr>
        <td>{{ newStatusText| translate }}</td>
      </tr>
    </table>
  </div>
  <div class="changeRequest-info-reviewer">
        <span>{{
            'changeRequest.info.reviewer'| translate :{ param0: getReviewers() }
          }}</span>
  </div>
</div>

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {ChangeRequestSubscriberType} from "../datatypes/audit-flow/change-request-subscriber-type";
import {ChangeRequestDto} from "../datatypes/audit-flow/change-request-dto";
import {Component, Input} from "@angular/core";

@Component({
  template: ''
})
export abstract class ChangeRequestInfoManagerAbstract {
  @Input()
  public changeRequest: ChangeRequestDto;

  public getReviewers(): string {
    const reviewers = this.changeRequest?.subscribers?.filter(subscriber => subscriber.type === ChangeRequestSubscriberType.REVIEWER).map(sub => sub.login);
    return reviewers?.join(', ');
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Identifiable} from 'pcs-commons/datatypes';

/**
 * this class is to help tables to distinguish elements when there is no unique property present
 * e.g. When creating a list of items together (in this case, their IDs are available only after they are created in backend)
 */
export abstract class IdentifiableTableElement extends Identifiable {
  public tElemId: string;
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Inject} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OcpiPartner} from '../../datatypes/ocpi-partner';
import {OcpiPartnerService} from '../../services/http/ocpi-partner.service';

@Component({
  selector: 'pcs-activate-partner',
  templateUrl: './activate-partner.component.html',
  styleUrls: [
    '../../dialog/dialog-common-styles.css',
    './activate-partner.component.css'
  ]
})
export class ActivatePartnerComponent {
  public loading = false;

  public token: FormControl<string>;

  constructor(
    private partnerService: OcpiPartnerService,
    public dialogRef: MatDialogRef<ActivatePartnerComponent>,
    @Inject(MAT_DIALOG_DATA) public ocpiPartner: OcpiPartner
  ) {
    this.token = new FormControl<string>('', Validators.required);
  }

  public allInputsValid(): boolean {
    return this.token.valid;
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  public onActivate(): void {
    this.loading = true;
    this.partnerService.enablePartner(this.ocpiPartner, this.token.value).subscribe({
      next: () => {
        this.loading = false;
        console.log('successfully enabled');
        this.dialogRef.close(true);
      },
      error: () => this.loading = false
    });
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {OptimizedTableBaseComponent} from './optimized-table-base.component';

@Component({
  selector: 'pcs-paginated-table-base',
  template: ''
})
export class PaginatedTableBaseComponent extends OptimizedTableBaseComponent implements OnInit, OnDestroy {
  public pageSizeOptions = [10, 20, 50, 100];
  @ViewChild(MatPaginator, {static: true}) public paginator: MatPaginator;

  constructor(
    renderer: Renderer2) {
    super(renderer);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.tableDataSource.paginator = this.paginator;
  }
}

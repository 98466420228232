/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  DateInPastError,
  DateInvalidError,
  DateRangeError,
  InvalidEmailError,
  InvalidMinMaxCombination,
  InvalidPatternError,
  MaxlengthError,
  MaxValueError,
  MinlengthError,
  MinValueError,
  NameNotUniqueError,
  NoUppercaseError,
  NoWhitespace,
  PasswordMismatch,
  PositiveInteger,
  RequiredError,
  TaxRateError,
  UrlError,
  ValidationError
} from './validation-error';
import { ValidationType } from './validation-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

/**
 * this pipe gets a from control and a list of validationError and returns the error related message object for translator to translate
 */
@Injectable()
@Pipe({
  name: 'validationMessage',
  pure: false
})
export class ValidationMessagePipe implements PipeTransform {
  private static errorMap: Map<string, ValidationError> = new Map([
    [ValidationType.REQUIRED, new RequiredError()],
    [ValidationType.EMAIL, new InvalidEmailError()],
    [ValidationType.MIN_VALUE, new MinValueError()],
    [ValidationType.MAX_VALUE, new MaxValueError()],
    [ValidationType.MIN_LENGTH, new MinlengthError()],
    [ValidationType.MAX_LENGTH, new MaxlengthError()],
    [ValidationType.PATTERN, new InvalidPatternError()],
    [ValidationType.NO_UPPERCASE, new NoUppercaseError()],
    [ValidationType.URL, new UrlError()],
    [ValidationType.DATE_INVALID, new DateInvalidError()],
    [ValidationType.DATE_IN_PAST, new DateInPastError()],
    [ValidationType.NAME_NOT_UNIQUE, new NameNotUniqueError()],
    [ValidationType.TAX_RATE, new TaxRateError()],
    [ValidationType.INVALID_MIN_MAX_COMBINATION, new InvalidMinMaxCombination()],
    [ValidationType.DATE_RANGE_INVALID, new DateRangeError()],
    [ValidationType.POSITIVE_INTEGER, new PositiveInteger()],
    [ValidationType.PASSWORD_MISMATCH, new PasswordMismatch()],
    [ValidationType.NO_WHITESPACE, new NoWhitespace()]
  ]);

  constructor(private translateService: TranslateService) {}

  public transform(value: UntypedFormControl | UntypedFormGroup): Observable<any> | null {
    if (!value.invalid) {
      return null;
    }
    const errorType: string = Object.values(ValidationType).find((error) => value.hasError(error));
    if (!errorType) {
      return null;
    }

    const validationErr = ValidationMessagePipe.errorMap.get(errorType);

    if (ValidationType.MAX_LENGTH === errorType || ValidationType.MIN_LENGTH === errorType) {
      // we need to deliver the max or min allowed length along with the message
      validationErr.param0 = value.getError(errorType).requiredLength;
    }
    if (ValidationType.MIN_VALUE === errorType) {
      console.log(value.getError(errorType));
      validationErr.param0 = value.getError(errorType).min;
    }
    if (ValidationType.MAX_VALUE === errorType) {
      console.log(value.getError(errorType));
      validationErr.param0 = value.getError(errorType).max;
    }
    if (ValidationType.DATE_RANGE_INVALID === errorType) {
      validationErr.param0 = value.getError(errorType).message;
    }

    return this.translateService.get(validationErr.message, validationErr);
  }
}

import { QueryHistoryState } from './query-history-state';
import { DatabaseName } from '../database-queries/DatabaseName';

export class HistoryFilter {
  public userId: string;
  public userLogin: string;
  public fromDate: string;
  public toDate: string;
  public query: string;
  public state: QueryHistoryState;
  public database: DatabaseName;
}

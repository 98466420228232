/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {firstValueFrom, Observable} from 'rxjs';
import {Product} from '../../datatypes/product';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HeaderEnabled} from './header-enabled.abstract';
import {Parameters} from '../../global/parameters';


@Injectable()
export class ProductService extends HeaderEnabled {
  constructor(private http: HttpClient) {
    super();
    this.headers = this.headers.append(Parameters.HEADER_ERROR_MSG_BASE, 'product.error.');
  }

  public async getProducts(): Promise<Product[]> {
    return firstValueFrom(this.http.get<Product[]>(environment.productUrl, {headers: this.headers}));
  }

  public getProduct(code: string): Observable<Product> {
    const url = `${environment.productUrl}bycode?code=${encodeURIComponent(code)}`;
    return this.http.get<Product>(url, {headers: this.headers});
  }

  public create(product: Product): Observable<Product> {
    const url = `${environment.productUrl}`;
    return this.http
      .post<Product>(url, product, {headers: this.headers});
  }

  public update(product: Product): Promise<Product> {
    const url = `${environment.productUrl}`;
    return firstValueFrom(this.http
      .put<Product>(url, product, {headers: this.headers}));
  }

  public validate(product: Product): Promise<void> {
    const url = `${environment.productUrl}validate`;
    return firstValueFrom(this.http
      .post<void>(url, product, {headers: this.headers}));
  }
}


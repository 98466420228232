/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {Directive, OnDestroy, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {BehaviorSubject, Subscription} from 'rxjs';
import {ChargepointOverviewAdminDto} from '../datatypes/location/chargepoint/chargepoint-overview-admin-dto';
import {ChargepointFilterEventService} from '../services/chargepoint-filter-event.service';
import {ChargepointFilterType} from '../datatypes/chargepoint-filter-type.enum';
import {Message} from '../datatypes/message';
import {UnusedChargepointFilter} from '../datatypes/unused-chargepoint-filter';
import {LocationService} from '../services/http/location.service';
import {ChargepointFilter} from '../datatypes/chargepoint-filter';
import {Parameters} from '../global/parameters';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractChargepointFilterManager extends AbstractNotificationHandler implements OnDestroy {
  // configurations for pagination
  @ViewChild(MatPaginator) public paginator: MatPaginator;
  public readonly pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS_EXTENDED;
  public pageEventOnProcess = false;
  public filterOnProcess = false;

  public currentFilterType = ChargepointFilterType.DEFAULT;
  public currentFilter: any;

  public chargepointSearchResultSubject = new BehaviorSubject<ChargepointOverviewAdminDto[]>([]);
  public cpSearchResult$ = this.chargepointSearchResultSubject.asObservable();
  public resultLength = 0;

  public subscriptions: Subscription[] = [];
  public filterEventSubscription: Subscription;

  constructor(
    public locationService: LocationService,
    public filterEventService: ChargepointFilterEventService
  ) {
    super();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.unsubscribeFilterSubscription();
  }

  public unsubscribeFilterSubscription(): void {
    if (this.filterEventSubscription) {
      this.filterEventSubscription.unsubscribe();
    }
  }

  public handleFilterChange(filterType: ChargepointFilterType): void {
    console.log('configuring for filter change to: ', filterType);
    this.resetResultData();
    this.unsubscribeFilterSubscription();
    this.filterEventService.refreshFilterSources();
    this.currentFilterType = filterType;
    if (filterType === ChargepointFilterType.UNUSED_CHARGEPOINTS) {
      this.filterEventSubscription = this.filterEventService.unusedCPFilterEvent$.subscribe((f) => this.applyFilter(f));
    } else {
      this.filterEventSubscription = this.filterEventService.defaultCPFilterEvent$.subscribe((f) => this.applyFilter(f));
    }
  }

  public applyUnusedChargepointFilter(filter: UnusedChargepointFilter): void {
    console.log('processing unused chargepoint search event: ', filter);
    this.currentFilter = JSON.parse(JSON.stringify(filter)); // clone it to avoid mutation
    // set offset and pageSize
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
    const pageSize = this.paginator ? this.paginator.pageSize : this.pageSizeOptions[0];
    this.filterOnProcess = true;
    this.getUnusedChargepointsByFilter(filter, 0, pageSize);
  }

  public abstract applyFilter(filter: any): void ;

  public handlePageChange(pageEvent: PageEvent): void {
    console.log('new page event: ', pageEvent);
    this.pageEventOnProcess = true;
    if (this.currentFilterType === ChargepointFilterType.UNUSED_CHARGEPOINTS) {
      this.getUnusedChargepointsByFilter(this.currentFilter, pageEvent.pageIndex, pageEvent.pageSize);
    } else {
      this.getDefaultChargepointsByFilter(this.currentFilter, pageEvent.pageIndex, pageEvent.pageSize);
    }
  }

  public isDefaultFilter(): boolean {
    return this.currentFilterType === ChargepointFilterType.DEFAULT;
  }

  protected getDefaultChargepointsByFilter(filter: ChargepointFilter, pageIndex: number, pageSize: number): void {
    filter.offset = pageIndex * pageSize;
    filter.pageSize = pageSize + 1;
    this.locationService.getChargepointsByFilter(filter).subscribe({
      next: (chargepoints) => {
        this.handleForFilterResult(chargepoints, pageIndex, pageSize);
        if (chargepoints.length > 0) {
          this.showInfoMsg('chargepoint.adaptFilterInfo');
        }
        this.filterOnProcess = false;
        this.pageEventOnProcess = false;
      },
      error: () => {
        this.filterOnProcess = false;
        this.pageEventOnProcess = false;
      }
    });
  }

  protected handleForFilterResult(chargepoints: ChargepointOverviewAdminDto[], pageIndex: number, pageSize: number): void {
    console.log('updating chargepoint result for chargepoints:  ', chargepoints.length, ' pageIndex: ', pageIndex, ' pageSize: ', pageSize);
    this.resultLength = pageIndex * pageSize + chargepoints.length;
    // since we requested one extra element, we remove it before showing
    if (chargepoints.length > pageSize) {
      chargepoints.splice(chargepoints.length - 1, 1);
    }
    this.chargepointSearchResultSubject.next(chargepoints);
    if (chargepoints.length <= 0) {
      this.showInfoMsg('chargepoint.noRecordsFound');
    }
  }

  private resetResultData(): void {
    this.chargepointSearchResultSubject.next([]);
    this.resultLength = 0;
  }

  private getUnusedChargepointsByFilter(filter: UnusedChargepointFilter, pageIndex: number, pageSize: number): void {
    filter.offset = pageIndex * pageSize;
    filter.pageSize = pageSize + 1;
    this.locationService.getUnusedChargepointsByFilter(filter).subscribe({
      next: (chargepoints) => {
        this.handleForFilterResult(chargepoints, pageIndex, pageSize);
        this.filterOnProcess = false;
        this.pageEventOnProcess = false;
      },
      error: () => {
        this.filterOnProcess = false;
        this.pageEventOnProcess = false;
      }
    });
  }

  private showInfoMsg(message: string): void {
    const msg = new Message();
    msg.message = message;
    this.showInfo(msg);
  }
}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, OnInit } from '@angular/core';
import {
  StatisticCsvDownloaderService,
  StatisticDownloaderService,
  StatisticDownloadStatusService,
  Statistics
} from 'pcs-commons/statistics';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { PermissionAware } from '../permission-aware';
import { AccessRights } from '../datatypes/access-rights.enum';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AbstractNotificationHandler, NotificationStore } from 'pcs-commons/notification';
import { Message } from '../datatypes/message';
import { ValidationMessagePipe, ValidatorList } from 'pcs-commons/validation';
import { DateTime } from 'luxon';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'statistic',
  templateUrl: './statistic.component.html',
  styleUrls: ['./statistic.component.css']
})
export class StatisticComponent extends AbstractNotificationHandler implements OnInit, PermissionAware {
  public readonly reqEditPermission = [AccessRights.STATISTICS_EDIT_WEB, AccessRights.FLEET_EDIT_WEB];
  public readonly minPageSize = 10;

  public filename: string;
  public fromDate: DateTime;
  public toDate: DateTime;
  public queries: string[];
  public selectedQuery: string;
  public timeIndependentQueries: string[] = [];
  public monthlyQueries: string[] = [];
  public minDate: DateTime;
  public maxDate: DateTime;
  public today: DateTime;
  public lastMonth: DateTime;
  public sixFinishedMonthsAgo: DateTime;
  public pageSize: FormControl<number>;
  public fromMonthAndYear: DateTime;

  constructor(
    public statisticDownloader: StatisticDownloaderService,
    public statisticCsvDownloader: StatisticCsvDownloaderService,
    public statusService: StatisticDownloadStatusService,
    private validationPipe: ValidationMessagePipe
  ) {
    super();
    this.timeIndependentQueries = statisticDownloader.TIME_INDEPENDENT_QUERIES;
    this.monthlyQueries = statisticDownloader.MONTHLY_QUERIES;
  }

  public get queryHasChunkSize(): boolean {
    return !this.monthlyQueries.includes(this.selectedQuery);
  }

  public get onlyMonthAndYear(): boolean {
    return this.monthlyQueries.includes(this.selectedQuery);
  }

  public get isTimeIndependentQuery(): boolean {
    return this.timeIndependentQueries.includes(this.selectedQuery);
  }

  public async getStatistics(): Promise<void> {
    const pageSizeInput = Number(this.pageSize.value);
    if (pageSizeInput < this.minPageSize) {
      this.pageSize.setValue(this.minPageSize);
    }
    if (this.selectedQuery === Statistics.CHARGEPOINT_STATISTIC) {
      await this.statisticCsvDownloader.getStatisticV2(this.pageSize.value, this.selectedQuery);
    } else if (this.monthlyQueries.includes(this.selectedQuery)) {
      const fromDate = this.fromMonthAndYear.toJSDate();
      // add one month to the fromDate:
      const month = fromDate.getMonth();
      const year = fromDate.getFullYear();
      const toDate = new Date(year, month + 1, 1);
      await this.statisticDownloader.getStatisticV2(this.pageSize.value, this.selectedQuery, toDate, fromDate);
    } else {
      await this.statisticDownloader.getStatisticV2(
        this.pageSize.value,
        this.selectedQuery,
        this.toDate.toJSDate(),
        this.fromDate.toJSDate()
      );
    }
  }

  public ngOnInit(): void {
    this.today = DateTime.now();
    this.fromDate = DateTime.now();
    this.toDate = DateTime.now();
    this.minDate = DateTime.fromObject({ year: 2015, month: 1, day: 1 }, { zone: 'utc' });
    this.maxDate = DateTime.fromObject({ year: this.toDate.year + 5, month: 12, day: 31 }, { zone: 'utc' });
    this.lastMonth = DateTime.now().minus({ month: 1 }).startOf('month');
    this.fromMonthAndYear = this.lastMonth;
    this.sixFinishedMonthsAgo = DateTime.now().minus({ month: 6 }).startOf('month');

    console.log(`lastMonth: ${this.lastMonth}, sixFinishedMonthsAgo: ${this.sixFinishedMonthsAgo}`);
    this.pageSize = new FormControl<number>(5000, ValidatorList.STATISTIC_CHUNK_SIZE);

    // List of queries
    this.queries = Object.values(Statistics);
    this.selectedQuery = Statistics.CHARGE_STATISTIC;
  }

  public async exportReport(): Promise<void> {
    NotificationStore.instance.clear();
    if (!this.queries.includes(this.selectedQuery)) {
      this.showError(new Message('ValidationReportRequired'));
      return;
    }
    await this.getStatistics();
  }

  public changeToDate(event: MatDatepickerInputEvent<DateTime>): void {
    console.log('changeMinDate', event.value);
    if (event.value > this.toDate) {
      this.toDate = event.value;
    }
  }

  public getError(control: FormControl<number>): Observable<unknown> {
    return this.validationPipe.transform(control);
  }

  public setMonthAndYear(eventData: DateTime, dp?: MatDatepicker<DateTime>): void {
    // only take the month and the year from the selected date
    // (the day is always the first of the month)
    const month = eventData.month;
    const year = eventData.year;
    this.fromMonthAndYear = DateTime.fromObject({ year: year, month: month, day: 1 }, { zone: 'utc' });
    dp.close();
  }

  public onQuerySelectionChanged($event: MatSelectChange): void {
    console.log('onQuerySelectionChanged', $event);
    this.selectedQuery = $event.value;
    console.log('this.selectedQuery', this.selectedQuery);
    if (this.selectedQuery === Statistics.CHARGEPOINT_STATISTIC) {
      this.pageSize.setValue(25000);
      return;
    }
    this.pageSize.setValue(5000);
  }
}

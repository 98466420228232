<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data" class="invoice-transaction-table scrollable-table-container">
  <div>
    <mat-table [dataSource]="tableDataSource">
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container *ngIf="col === 'referenceItemId'" matColumnDef="{{col}}" sticky>
        <mat-header-cell [styledColumn]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
        <mat-cell [styledColumn]="col" *matCellDef="let element">
          <ng-container>{{element[col]}}</ng-container>
        </mat-cell>
      </ng-container>
        <ng-container *ngIf="col !== actionColumn && col !== 'referenceItemId'" matColumnDef="{{col}}">
          <mat-header-cell [styledColumn]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
          <mat-cell [styledColumn]="col" *matCellDef="let element">
            <ng-container
              *ngIf="col !== 'billedDateTime' && col !== 'reimbursementStatus' && col !== 'serviceCountry'">{{element[col]}}</ng-container>
            <ng-container
              *ngIf="col === 'billedDateTime'">{{translateDate(element[col])}}</ng-container>
            <ng-container
              *ngIf="col === 'serviceCountry'">{{translateCountry(element[col])}}</ng-container>
            <ng-container
              *ngIf="col === 'reimbursementStatus' && element[col]">{{'invoice.transaction.reimbursementStatus.' + element[col] | translate}}</ng-container>
          </mat-cell>
        </ng-container>
      </div>

      <ng-container matColumnDef="tableAction" stickyEnd>
        <mat-header-cell *matHeaderCellDef
                         class="actionCell">
          <mat-checkbox id="masterCheckBox" (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
          </mat-checkbox>
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="actionCell">
          <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(element) : null"
                        [checked]="selection.isSelected(element)"
                        [disabled]="!readyToStartReimbursementProcess(element)">
          </mat-checkbox>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </div>
</div>
<mat-paginator [showFirstLastButtons]="true" [pageSizeOptions]="pageSizeOptions">
</mat-paginator>

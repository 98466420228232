/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {PermissionAware} from '../permission-aware';
import {AccessRights} from '../datatypes/access-rights.enum';
import {BaseUrls} from '../datatypes/base-urls.enum';
import {ChargepointAttributeImportService} from '../services/http/chargepoint-attribute-import.service';
import {SharedDataService} from '../services/shared-data.service';
import {WebConfigRouterService} from '../services/web-config-router.service';
import {ChargepointAttributeImportType} from '../datatypes/ChargepointAttributeImportType';
import {ManualChargepointDataImportResultDto} from '../datatypes/manual-chargepoint-data-import-result-dto';
import {ConfirmationDialogComponent} from '../dialog/confirmation-dialog/confirmation-dialog.component';
import {Message} from '../datatypes/message';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'chargepoint-attribute-import',
  templateUrl: './chargepoint-attribute-import.component.html',
  styleUrls: ['./chargepoint-attribute-import.component.css']
})
export class ChargepointAttributeImportComponent extends AbstractNotificationHandler implements PermissionAware, OnInit {

  public readonly reqEditPermission: AccessRights[] = [AccessRights.CHARGEPOINTS_EDIT_WEB];
  public cpAttributeImportForm: FormGroup;
  public importing: boolean;
  @Output() public resetEvent = new EventEmitter<File>();
  public readonly IMPORT = ChargepointAttributeImportType.IMPORT;
  public readonly RESET = ChargepointAttributeImportType.RESET;
  public radioButtonSelection: FormControl;

  constructor(
    private formBuilder: FormBuilder,
    private chargepointAttributeImportService: ChargepointAttributeImportService,
    private dataService: SharedDataService,
    private router: WebConfigRouterService,
    private dialog: MatDialog) {
    super();
    this.radioButtonSelection = new FormControl();
    this.cpAttributeImportForm = this.formBuilder.group({});
    this.radioButtonSelection.valueChanges.subscribe((newValue) => {
      console.log('new value for importType radioButtonSelection: ', newValue);
    });
  }

  public ngOnInit(): void {
    this.radioButtonSelection.setValue(this.IMPORT);
  }

  public handleAttributeReset(readerResult: string): void {
    this.chargepointAttributeImportService.resetAttributes(new Blob([readerResult.split(',')[1]]))
      .subscribe({
        next: (importResult) => {
          this.handleImportResult(importResult, this.RESET);
        },
        error: (err) => this.importing = false
      });
  }

  private handleImportResult(importResult: ManualChargepointDataImportResultDto, importType: ChargepointAttributeImportType): void {
    this.importing = false;
    this.dataService.updateCpAttributeImportResults(importResult);
    if (importResult.importErrors?.length > 0) {
      this.router.navigate([BaseUrls.CHARGEPOINT_ATTRIBUTE_IMPORT_RESULT, importType]);
    } else {
      const msg = new Message();
      msg.message = 'cpAttributeImport.import.success';
      msg.param0 = '' + importResult.successfullyImportedRecordsCount;
      msg.param1 = '' + importResult.allRecordsCount;
      this.showInfo(msg);
    }
  }

  public isResetSelected(): boolean {
    return this.radioButtonSelection.value === this.RESET;
  }

  public handleImport(importFile: File): void {
    if (!importFile || !this.radioButtonSelection.value ||
      (this.radioButtonSelection.value !== this.IMPORT && this.radioButtonSelection.value !== this.RESET)) {
      return;
    }

    let msg = 'cpAttributeImport.confirm.attributeImport';
    if (this.isResetSelected()) {
      msg = 'cpAttributeImport.confirm.attributeReset';
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          message: new Message(msg),
          actionKey: 'global.button.yes'
        }
      }
    );
    dialogRef.afterClosed().subscribe((positive) => {
      if (positive) {
        this.importFile(importFile);
      }
    });
  }

  public importFile(importFile: File): void {
    console.log('Importing attributes from file "%s"', importFile.name);
    const reader = new FileReader();
    // set the reader to make the import rest call when the file is loaded
    reader.onloadstart = () => {
      this.importing = true;
      console.log('[%s] import start', new Date().toLocaleDateString());
    };
    reader.onloadend = () => {
      console.log('[%s] import end', new Date().toLocaleDateString());
      const readerResult = reader.result as string;
      console.log('readerResult', readerResult);
      if (this.isResetSelected()) {
        this.handleAttributeReset(readerResult);
        return;
      }
      this.handleAttributeImport(readerResult);
    };
    reader.readAsDataURL(importFile);
  }

  public handleAttributeImport(readerResult: string): void {
    this.chargepointAttributeImportService.importAttributes(new Blob([readerResult.split(',')[1]]))
      .subscribe({
        next: (importResult) => {
          this.handleImportResult(importResult, this.IMPORT);
        },
        error: (err) => this.importing = false
      });
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ProductQuota} from '../../datatypes/product-quota';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Parameters} from '../../global/parameters';
import {Utils} from '../../utils/utils';

@Component({
  selector: 'pcs-fleet-product-edit',
  templateUrl: './fleet-product-edit.component.html',
  styleUrls: ['./fleet-product-edit.component.css']
})
export class FleetProductEditComponent implements OnInit, OnChanges {
  @Input() public productsNotInFleet: string[] = [];
  @Input() public productToEdit: ProductQuota;
  @Output() public productSaveEvent = new EventEmitter<ProductQuota>();


  public maxAmountAllowed = Parameters.MAX_QUOTA_ALLOWED;

  public productCode: FormControl;
  public maxAmount: FormControl;
  public productForm: FormGroup;

  constructor(private formBuilder: FormBuilder) {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.productToEdit) {
      console.log('ngOnChanges', changes.productToEdit.currentValue, changes.productToEdit.previousValue);
      if (this.productCode) {
        this.productCode.setValue(changes.productToEdit.currentValue ? changes.productToEdit.currentValue.productCode : '');
      }
      if (this.maxAmount) {
        this.maxAmount.setValue(changes.productToEdit.currentValue ? changes.productToEdit.currentValue.maxAmount : '');
      }
    }
  }

  public ngOnInit(): void {
    if (!this.productToEdit) {
      console.log('ngOnInit', 'initiating empty product');
      this.productToEdit = new ProductQuota();
    }
    console.log('ngOnInit', this.productToEdit);

    this.defineFormControls();
    this.productForm = this.formBuilder.group({
      productCode: this.productCode,
      maxAmount: this.maxAmount
    });
  }

  public validateNumeric(event: any): void {
    Utils.validateNumeric(event);
  }

  public onSave(): void {
    if (this.productForm.invalid) {
      return;
    }
    this.productToEdit.productCode = this.productForm.value.productCode;
    this.productToEdit.maxAmount = this.productForm.value.maxAmount;
    this.productSaveEvent.emit(this.productToEdit);
    console.log('saving product', this.productToEdit);
    this.productForm.reset();
  }

  public onClose(): void {
    this.productSaveEvent.emit(null);
    this.productForm.reset();
    console.log('canceling product edit', this.productToEdit);
  }

  protected getMaxAmountError(): string {
    return this.maxAmount.hasError('required') ? 'validation.input.required' :
      this.maxAmount.hasError('min') ? 'validation.product.maxquota.minvalue' :
        this.maxAmount.hasError('max') ? 'validation.product.maxquota.maxvalue' :
          '';
  }

  protected getProductCodeError(): string {
    return this.productCode.hasError('required') ? 'validation.input.required' : '';
  }

  protected getProductChoiceList(): string[] {
    const tempArray = Array.from(this.productsNotInFleet);
    if (this.productToEdit && this.productToEdit.productCode) {
      // then this is edit mode so we need to add the product code in the choice list too
      tempArray.push(this.productToEdit.productCode);
    }
    return tempArray.sort();
  }

  private defineFormControls(): void {
    this.productCode = new FormControl(
      this.productToEdit.productCode,
      [Validators.required]
    );
    this.maxAmount = new FormControl(
      this.productToEdit.maxAmount,
      [Validators.required, Validators.max(this.maxAmountAllowed), Validators.min(1)]
    );
  }
}

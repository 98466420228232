/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Inject, OnInit} from '@angular/core';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ValidationMessagePipe, ValidatorList} from 'pcs-commons/validation';
import {WebConfigRouterService} from '../../services/web-config-router.service';
import {TaxService} from '../../services/http/tax.service';
import {TaxRecord} from '../../datatypes/taxrecord';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, Observable} from 'rxjs';
import {Utils} from '../../utils/utils';
import {DateRangeValidatorService} from '../../validation/date-range-validator.service';
import {DateUtils} from 'pcs-commons/utils';
import {FormUtils} from 'pcs-commons/utils';

@Component({
  selector: 'app-edit-tax',
  templateUrl: './edit-tax.component.html',
  styleUrls: ['../../dialog/dialog-common-styles.css',
    './edit-tax.component.css']
})
export class EditTaxComponent extends AbstractNotificationHandler implements OnInit {
  public loading: boolean;

  public editTaxesForm: FormGroup;
  public control: FormArray;
  public touchedRows: any;

  public taxesToEdit: TaxRecord[];
  public currencies: string[];
  public country: string;
  public dataColumns = TaxRecord.editDataColumns;

  private taxes = new BehaviorSubject<TaxRecord[]>([]);
  public taxes$ = this.taxes.asObservable();

  constructor(
    router: WebConfigRouterService,
    private taxService: TaxService,
    public dialogRef: MatDialogRef<EditTaxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private validationPipe: ValidationMessagePipe,
    public dateRangeValidator: DateRangeValidatorService
  ) {
    super();
    this.taxesToEdit = this.data.taxesToEdit;
    this.currencies = this.data.currencyList;
    this.country = this.data.country;
    this.loading = false;
  }

  public ngOnInit(): void {
    this.touchedRows = [];
    this.editTaxesForm = this.formBuilder.group({},
      {validators: [this.dateRangeValidator.validate.bind(this.dateRangeValidator)]}
    );
    this.defineTaxesForm();
    this.taxes.next(this.taxesToEdit);
  }

  public allInputsValid(): boolean {
    return this.editTaxesForm.valid;
  }

  public showDateRangeError(): boolean {
    return this.editTaxesForm?.invalid && this.editTaxesForm?.hasError('invalidDateRange');
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  public onSave(): void {
    this.loading = true;

    const taxes = this.formatTaxesToEdit();

    this.taxService
      .updateTaxRecords(taxes)
      .subscribe({
        next: () => {
          this.loading = false;
          this.dialogRef.close(true);
        },
        error: () => {
          this.loading = false;
        }
      });
  }

  private formatTaxesToEdit(): TaxRecord[] {
    const taxes = [...this.taxesToEdit];
    taxes.forEach((record, index) => {
      const taxRate = FormUtils.getNullableNumber(this.editTaxesForm, `taxRate-${index}`);
      record.taxRate = taxRate === null ? 0 : Math.round(taxRate * 100) / 10000;
      record.currency = FormUtils.getStringValue(this.editTaxesForm, `currency-${index}`);

      const fromDate = this.editTaxesForm.value[`validFromDate-${index}`];
      const fromTime = this.editTaxesForm.value[`validFromTime-${index}`];
      record.validFrom = DateUtils.buildDateTimeFromSeparateFields(fromDate, fromTime, false)?.toISO();

      const untilDate = this.editTaxesForm.value[`validUntilDate-${index}`];
      const untilTime = this.editTaxesForm.value[`validUntilTime-${index}`];
      record.validUntil = DateUtils.buildDateTimeFromSeparateFields(untilDate, untilTime, true)?.toISO();
    });
    return taxes;
  }

  public getError(fg: FormGroup): Observable<any> {
    return this.validationPipe.transform(fg);
  }

  private defineTaxesForm(): void {
    if (!this.editTaxesForm) {
      this.editTaxesForm = this.formBuilder.group(
        {},
        {validators: [this.dateRangeValidator.validate.bind(this.dateRangeValidator)]});
    }
    Object.keys(this.editTaxesForm.controls).forEach((name) => this.editTaxesForm.removeControl(name));

    const taxesFormControls = new Map<string, FormControl>();
    this.taxesToEdit.forEach((record, index) => {
      record.index = index;

      const taxRate = new FormControl(record.taxRate ? (Math.round(10000 * record.taxRate) / 100) : null, ValidatorList.TAX_RATE);
      taxesFormControls.set('taxRate-' + index, taxRate);

      const currency = new FormControl(record.currency, Validators.required);
      taxesFormControls.set('currency-' + index, currency);

      const validFrom = DateUtils.convertToDateTimeWithUTC(record.validFrom);
      const validFromDate = new FormControl(validFrom?.startOf("day"));
      const validFromTime = validFrom?.toFormat("HH:mm:ss");
      taxesFormControls.set('validFromDate-' + index, validFromDate);
      taxesFormControls.set('validFromTime-' + index, new FormControl(validFromTime));

      const validUntil = DateUtils.convertToDateTimeWithUTC(record.validUntil);
      const validUntilDate = new FormControl(validUntil?.startOf("day"));
      const validUntilTime = validUntil?.toFormat("HH:mm:ss");
      taxesFormControls.set('validUntilDate-' + index, validUntilDate);
      taxesFormControls.set('validUntilTime-' + index, new FormControl(validUntilTime));
    });
    taxesFormControls.forEach((fc, name) => this.editTaxesForm.addControl(name, fc));
  }

  public deleteRecord(recordToDelete: TaxRecord): void {
    this.taxesToEdit = this.formatTaxesToEdit();
    Utils.removeArrayItem(this.taxesToEdit, recordToDelete);
    this.defineTaxesForm();
    this.taxes.next(this.taxesToEdit);
  }

  public addTaxRecord(): void {
    const newTaxRecord = new TaxRecord();
    newTaxRecord.country = this.country;
    this.taxesToEdit = this.formatTaxesToEdit();
    Utils.addArrayItem(this.taxesToEdit, newTaxRecord);
    this.taxes.next(this.taxesToEdit);
    this.defineTaxesForm();
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {ChangeRequestAction} from "./change-request-action";
import {User} from "../user";
import {ChangeRequestPayloadType} from "../inbox/change-request-payload-type";
import {Message} from "pcs-commons/datatypes";

export class CreateChangeRequestDialogData {
  public reviewerChoices: User[];
  public message: Message;
  public objectType: ChangeRequestPayloadType;
  public objectId: number;
  public action: ChangeRequestAction;
  public originalValueAsJson: string;
  public updatedValueAsJson: string;
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="changeRequest">
  <div class="changeRequest-info-common-text">
    <span>
      {{
      getInfoText()| translate: { param0: getParam(0), param1: getParam(1) }
      }}
    </span>
  </div>
  <div class="changeRequest-info-common-description">
    <span>
      {{
      'changeRequest.info.description'| translate :{ param0: changeRequest.comment }
      }}
    </span>
  </div>
  <div>
    <span>
      {{
      'changeRequest.info.reviewer'| translate :{ param0: getReviewers() }
      }}
    </span>
  </div>
</div>

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content editKeyEvents>
  <h2 mat-dialog-title>
    {{'mfa.associateTotp'|translate}}
    <mat-spinner class="header-spinner" [diameter]="30" [strokeWidth]="5" *ngIf="processing"></mat-spinner>
  </h2>
  <div class="content-div">
    <span>{{'mfa.associateTotp.qrCode.info'| translate}}</span>
    <div *ngIf="associating && !secretCodeUri">
      <mat-spinner class="qrcode-section" [diameter]="128" [strokeWidth]="5"></mat-spinner>
    </div>
    <div *ngIf="!associating && secretCodeUri" class="qrcode-image center">
      <qrcode [qrdata]="secretCodeUri"
              [width]="194"
              [errorCorrectionLevel]="'M'"
              [elementType]="'img'">
      </qrcode>
    </div>

    <mat-expansion-panel class="mat-elevation-z0 secret-key-section" [(expanded)]="showSecretCode">
      <mat-expansion-panel-header class="secret-key-section-header">
        <div *ngIf="showSecretCode">{{'mfa.associateTotp.secretCode.hide' |translate}}</div>
        <div *ngIf="!showSecretCode">{{'mfa.associateTotp.secretCode.show' |translate}}</div>
      </mat-expansion-panel-header>
      <mat-toolbar class="center secret-key">{{secretCode}}</mat-toolbar>
    </mat-expansion-panel>

    <span>{{'mfa.associateTotp.verificationCode.info'| translate}}</span>
    <mat-grid-list cols="5" rowHeight="100px">
      <mat-grid-tile colspan="3">
        <mat-form-field>
          <input matInput [formControl]="userCode" maxlength="6" minlength="6">
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="2">
        <button okAction mat-raised-button class="normalButton verify-btn"
                (click)="verifyUserCode()" [disabled]="processing || !userCode.value || !userCode.valid">
          {{'mfa.associateTotp.verify' | translate}}
        </button>
      </mat-grid-tile>
    </mat-grid-list>

  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button class="dialogueButton prefer-totp-btn" (click)="setTotpAsPreferred()"
          [disabled]="!verified || processing">
    {{'mfa.associateTotp.setPreferred' | translate}}
  </button>
  <button mat-stroked-button id="closeBtn" class="dialogueButton" [mat-dialog-close]="false">
    <mat-icon class="button-icon">cancel_presentation</mat-icon>
    {{'button.close' | translate}}
  </button>
</mat-dialog-actions>

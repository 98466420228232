<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-toolbar class="headerToolbar mat-elevation-z1">
    <span class="headerSpan">{{'enrollment.eaec.header' | translate }}</span>
    <pcs-loading-spinner [component]="this"></pcs-loading-spinner>
</mat-toolbar>

<div class="section-container">
    <pcs-enrollment-ea-ec-filter (emaIdFilterEvent)="applyEmaIdFilter($event)"
                                 (vinFilterEvent)="applyVinFilter($event)"
                                 editKeyEvents></pcs-enrollment-ea-ec-filter>
</div>

<div class="section-container" *ngIf="resultLength > 0" id="enrollment">
    <mat-accordion>
        <mat-expansion-panel [expanded]="resultLength > 0" hideToggle>
            <pcs-enrollment-ea-ec-table
                    [dataColumns]="dataColumns"
                    [i18nBase]="'enrollment.eaec.tableHeader'"
                    [data]="enrollmentinfoSearchResult$">
            </pcs-enrollment-ea-ec-table>
        </mat-expansion-panel>
    </mat-accordion>
</div>

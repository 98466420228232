/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
export class StringUtils {
  private constructor() {
  }

  public static trim(data: any): string {
    return data ? (data as string).trim() : data;
  }

  public static stringToEnumValue<ET, T>(enumObj: ET, str: string): T {
    return (enumObj as any)[Object.keys(enumObj).filter((k) => (enumObj as any)[k] === str)[0]];
  }

  public static concat(separator: string, ...parts: string[]): string {
    let result = '';
    let addedAtLeastOne = false;
    for (const item of parts) {

      if (item && result) {
        result += separator;
      }

      if (item) {
        result += item;
        addedAtLeastOne = true;
      }
    }
    if (!addedAtLeastOne) { // if all parts were empty, just return empty
      return '';
    }
    return result;
  }

}

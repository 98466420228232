/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {BehaviorSubject, Subscription} from "rxjs";
import {ChangeRequestDtoColumns, ChangeRequestOverviewDto} from "../datatypes/inbox/change-request-overview-dto";
import {InboxService} from "../services/http/audit/inbox.service";
import {WebConfigRouterService} from "../services/web-config-router.service";
import {BaseUrls} from "../datatypes/base-urls.enum";
import {ChangeRequestDetailsMode} from "../datatypes/inbox/change-request-details-mode";
import {PaginationHandlerComponent} from "../pagination-handler.component";
import {Message} from "pcs-commons/datatypes";
import {Parameters} from "pcs-commons/global";

@Component({
  selector: 'pcs-inbox',
  templateUrl: './inbox.component.html',
  styleUrls: ['./inbox.component.css']
})
export class InboxComponent extends PaginationHandlerComponent implements OnInit, OnDestroy {

  public loading = false;

  public pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS;
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public changeRequestsSource = new BehaviorSubject<ChangeRequestOverviewDto[]>([]);
  public changeRequests$ = this.changeRequestsSource.asObservable();

  public dataColumns: string[] = Object.values(ChangeRequestDtoColumns);
  public selectedPageSize = this.pageSizeOptions[0];
  public toUnsubscribe: Subscription[] = [];

  constructor(
    private router: WebConfigRouterService,
    private inboxService: InboxService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.retrieveChangeRequests(0, this.selectedPageSize);
  }

  private retrieveChangeRequests(pageIndex: number, pageSize: number): void {
    // send one more so see if we have more records than requested
    this.loading = true;

    this.inboxService.getInboxChangeRequests(pageIndex, pageSize).subscribe({
      next: (response) => {
        this.loading = false;

        this.handlePagedResponse(pageIndex, pageSize, response, this.changeRequestsSource);
      },
      error: () => this.loading = false
    });
  }

  public handlePageChange(pageEvent: PageEvent): void {
    this.selectedPageSize = pageEvent.pageSize;
    this.retrieveChangeRequests(pageEvent.pageIndex, pageEvent.pageSize);
  }

  public ngOnDestroy(): void {
    this.toUnsubscribe.forEach((sub) => sub.unsubscribe());
  }

  public navigateToDetailsView(cr: ChangeRequestOverviewDto): void {
    console.log('navigating to details view of change request: ', cr);
    this.router.navigate([BaseUrls.CHANGE_REQUEST_DETAILS], {
      queryParams: {
        changeRequestId: cr.id,
        mode: ChangeRequestDetailsMode.EDIT
      }
    })
    ;
  }

  public handleEmptyResult(): void {
    const msg = new Message();
    msg.message = 'inbox.noRecordsFound';
    this.showInfo(msg);
  }
}

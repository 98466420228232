/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
export enum ChargepointClassNames {
  UNKNOWN = 'UNKNOWN',
  THREE = 'THREE',
  ELEVEN = 'ELEVEN',
  TWENTY = 'TWENTY',
  TWENTY_TWO = 'TWENTY_TWO',
  FOURTY_THREE = 'FOURTY_THREE',
  FIFTY = 'FIFTY',
  HUNDRED_FIFTY = 'HUNDRED_FIFTY',
  THREE_HUNDRED_FIFTY = 'THREE_HUNDRED_FIFTY',
  THREE_HUNDRED_FIFTY_ION = 'THREE_HUNDRED_FIFTY_ION'
}

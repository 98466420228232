/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {ReimbursementStatus} from "./ReimbursementStatus";

export interface ReimbursementBilledItemDto {
  itemId: number;
  invoiceIdent: string;
  productNumber: string;
  grossPrice: string;
  currency: string;
  serviceCountry: string;
  billedDateTime: string;
  referenceItemId: string;
  reimbursementStatus: ReimbursementStatus;
}

export enum ReimbursementBilledItemColumns {
  REFERENCE_ITEM_ID = 'referenceItemId',
  INVOICE_IDENT = 'invoiceIdent',
  PRODUCT_NUMBER = 'productNumber',
  GROSS_PRICE = 'grossPrice',
  CURRENCY = 'currency',
  SERVICE_COUNTRY = 'serviceCountry',
  BILLED_DATE_TIME = 'billedDateTime',
  REIMBURSEMENT_STATUS = 'reimbursementStatus'
}

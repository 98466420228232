/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ValidationMessagePipe, ValidatorList } from 'pcs-commons/validation';
import { FormHelper } from '../../utils/form-helper';
import { DatabaseService } from '../../services/http/database.service';
import { AbstractNotificationHandler } from 'pcs-commons/notification';
import { SavedQueryDto } from '../../datatypes/database-queries/SavedQueryDto';
import { SharedDataService } from '../../services/shared-data.service';
import { Utils } from '../../utils/utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { CodeModel } from '@ngstack/code-editor';

@Component({
  selector: 'pcs-add-query',
  templateUrl: './add-query.component.html',
  styleUrls: ['../../dialog/dialog-common-styles.css'
    , './add-query.component.css']
})
export class AddQueryComponent extends AbstractNotificationHandler implements OnInit {

  public loading = false;
  public addQueryForm: FormGroup;
  public name: FormControl;
  public query: FormControl;
  public queryDto: SavedQueryDto;
  private editorCodeModelUri = 'add-query.ts';
  private editorCodeModelLanguage = 'sql';
  public codeModelSource = new BehaviorSubject<CodeModel>({
    language: this.editorCodeModelLanguage,
    uri: this.editorCodeModelUri,
    value: ''
  });
  public codeModel$ = this.codeModelSource.asObservable();

  constructor(
    private formBuilder: FormBuilder,
    private databaseService: DatabaseService,
    private dataService: SharedDataService,
    private dialogRef: MatDialogRef<AddQueryComponent>,
    private validationPipe: ValidationMessagePipe,
    @Inject(MAT_DIALOG_DATA) public data: SavedQueryDto) {
    super();
    this.queryDto = data;
  }

  public ngOnInit(): void {
    this.name = new FormControl(this.queryDto.name, ValidatorList.QUERY_NAME);
    this.query = new FormControl(this.queryDto.query || '', Validators.required);
    this.addQueryForm = this.formBuilder.group({ name: this.name, query: this.query });
    this.codeModelSource.next({
      language: this.editorCodeModelLanguage,
      uri: this.editorCodeModelUri,
      value: this.queryDto.query
    });
  }

  public allInputsValid(): boolean {
    return this.addQueryForm.valid;
  }

  public onQueryInputChange(newValue: string): void {
    this.query.setValue(newValue);
  }

  public onExecute(): void {
    this.loading = true;
    this.queryDto.name = FormHelper.getStringValue(this.addQueryForm, 'name');
    this.queryDto.query = FormHelper.getStringValue(this.addQueryForm, 'query');
    if (!isNaN(this.queryDto.id)) {
      this.databaseService.updateQuery(this.queryDto).subscribe({
        next: (response) => {
          this.processResponse(response, 'database.query.updated');
        },
        error: () => {
          this.loading = false;
        }
      });
    } else {
      this.databaseService.saveQuery(this.queryDto).subscribe({
        next: (response) => {
          this.processResponse(response, 'database.query.created');
        },
        error: () => {
          this.loading = false;
        }
      });
    }
  }

  private processResponse(queries: SavedQueryDto[], message: string): void {
    Utils.sortArrayByStringProperty(queries, 'name');
    this.dataService.updateDatabaseQueries(queries);
    this.dataService.updateCurrentQueryOnEdit(this.queryDto);
    this.loading = false;
    this.dialogRef.close({ success: true, message });
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  public getError(fc: FormControl): Observable<any> {
    return this.validationPipe.transform(fc);
  }
}

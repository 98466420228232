<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data && columnGroupMap && dataColumns" class="scrollable-table-container">
  <table mat-table [dataSource]="tableDataSource" class="padding-lr-24" >
    <ng-container *ngFor="let col of dataColumns;">
      <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
        <th [ngStyle]="{'display': calcRowSpan(col)>1?'none':'table-cell'}" mat-header-cell *matHeaderCellDef
            [attr.rowspan]="calcRowSpan(col)">
          {{getI18nBase(col) | translate}}
        </th>
        <td mat-cell *matCellDef="let element "> {{element[col]}}</td>
        <td mat-footer-cell
            *matFooterCellDef> {{ productCode === col ? (('fleet.product.' + accumulatedStats[col]) | translate) : accumulatedStats[col]}}
        </td>
      </ng-container>
    </ng-container>
    <ng-container *ngFor="let group of groupNames">
      <ng-container *ngIf="group !== groupActionColumn" matColumnDef="{{group}}">
        <th mat-header-cell *matHeaderCellDef [attr.rowspan]="calcGroupRowSpan(group)"
            [attr.colspan]="calcColSpan(group)">
          {{getI18nBase(group) | translate}}
        </th>
      </ng-container>
    </ng-container>
    <ng-container matColumnDef="{{groupActionColumn}}" stickyEnd>
      <th mat-header-cell *matHeaderCellDef class="actionCell" [attr.rowspan]="2"
          [attr.colspan]="1">
        {{'fleet.tableheader.action' | translate}}
      </th>
    </ng-container>
    <ng-container matColumnDef="tableAction" stickyEnd>
      <th mat-header-cell *matHeaderCellDef class="actionCell" [attr.rowspan]="2" [attr.colspan]="1"
          [ngStyle]="{'display': 'none'}">
        {{'fleet.tableheader.action' | translate}}
      </th>
      <td mat-cell *matCellDef="let element" class="actionCell">
        <button mat-mini-fab class="edit mat-elevation-z0" (mouseenter)="changeStyle($event)"
                (mouseleave)="changeStyle($event)" (click)="editElement(element)" *ngIf="!disableEdit">
          <mat-icon> edit</mat-icon>
        </button>
        <button mat-mini-fab class="delete mat-elevation-z0" (mouseenter)="changeStyle($event)"
                (mouseleave)="changeStyle($event)" (click)="deleteElement(element)" *ngIf="!disableDelete">
          <mat-icon> delete_outline</mat-icon>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="groupNames"></tr>
    <tr mat-header-row *matHeaderRowDef="dataColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: dataColumns;"></tr>
    <tr mat-footer-row *matFooterRowDef="dataColumns" class="footer-row"></tr>
  </table>
</div>

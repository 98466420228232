/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Renderer2} from '@angular/core';
import {TableBaseComponent} from '../table-base/table-base.component';

@Component({
  selector: 'scrollable-table',
  templateUrl: './scrollable-table.component.html',
  styleUrls: ['./scrollable-table.component.css']
})
export class ScrollableTableComponent extends TableBaseComponent {
  constructor(renderer: Renderer2) {
    super(renderer);
  }
}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, Input } from '@angular/core';
import { NavMenu } from '../datatypes/nav-menu';

@Component({
  selector: 'menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent {
  @Input() public menu: NavMenu;

  public get key(): string {
    return this.menu ? this.menu.key : '';
  }

  public get url(): string {
    return this.menu ? this.menu.homeUrl : '';
  }

  public get matIcon(): string {
    return this.menu ? this.menu.matIcon : '';
  }

  public get faIconClass(): string {
    return this.menu ? this.menu.faIconClass : '';
  }
}

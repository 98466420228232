/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {PurchaseConditionStatus} from './purchase-conditions/purchase-condition-status.enum';

export class PurchaseConditionFilterDto {
  status: PurchaseConditionStatus;
  elementDataNeeded: boolean;
  validOnDate: string;
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div class="local-login-div">
  <mat-grid-list cols="4" rowHeight="110px">
    <mat-grid-tile colspan="3" class="input-field-tile">
      <mat-form-field appearance="outline" class="input-field">
        <input matInput [formControl]="login">
      </mat-form-field>
    </mat-grid-tile>
    <mat-grid-tile colspan="1" class="login-button-tile">
      <div class="login-button-div">
        <button mat-button class="local-login-button" (click)="doMockLogin()">login</button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>

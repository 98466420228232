/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {TableBaseComponent} from './table-base.component';
import {Component, EventEmitter, Input, Output, Renderer2} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {Action} from '../../datatypes/action.enum';
import {FormControl} from '@angular/forms';
import {ValidationMessagePipe} from 'pcs-commons/validation';

@Component({
  selector: 'editable-table-base',
  template: ''
})
export class InlineEditableTableBaseComponent extends TableBaseComponent {

  @Input() public actionResult$: Observable<boolean>;

  @Output() public createEvent = new EventEmitter<any>();
  @Output() public cancelEvent = new EventEmitter<any>();
  @Output() public resultAcknowledgeEvent = new EventEmitter<any>();

  public mode: Action = null;
  public currElementOnProcess: any = null;
  public resultSubscription: Subscription;

  constructor(
    renderer: Renderer2,
    private validationPipe: ValidationMessagePipe) {
    super(renderer);
  }

  public get editMode(): boolean {
    return this.mode && Action.EDIT === this.mode;
  }

  public get createMode(): boolean {
    return this.mode && Action.CREATE === this.mode;
  }

  public getError<T>(fc: FormControl<T>): Observable<any> {
    return this.validationPipe.transform(fc);
  }

  public elementIsOnProcess(element: any): boolean {
    return (this.editMode || this.createMode) && this.currElementOnProcess && this.currElementOnProcess.id === element.id;
  }

  public observeActionResult(): void {
    if (!this.actionResult$) {
      return;
    }
    this.resultSubscription =
      this.actionResult$.subscribe((success) => {
        if (success) {
          this.mode = null;
          this.currElementOnProcess = null;
          this.emitResultAcknowledged();
          if (this.resultSubscription) {
            this.resultSubscription.unsubscribe();
          }
        }
      });
  }

  public enableEditForElement(toPut: any): void {
    /*
    * maybe user clicked on create and then without cancelling clicked on edit
    * we allow this, but cancel the create event
    */
    if (this.createMode) {
      this.onCancel();
    }
    this.enableModeForElement(toPut, Action.EDIT);
  }

  protected enableModeForElement(toProcess: any, mode: Action): void {
    this.mode = mode;
    this.currElementOnProcess = toProcess;
    this.resetAllControlValues();
    if (Action.EDIT === this.mode) {
      this.setControlValues(toProcess);
    }
  }

  public onCancel(): void {
    if (Action.CREATE === this.mode) {
      this.tableDataSource.data.pop();
    }

    if (Action.EDIT === this.mode) {
      this.resetToOriginalValues();
    }

    this.mode = null;
    this.currElementOnProcess = null;
    this.cancelEvent.emit();
  }

  private emitResultAcknowledged(): void {
    this.resultAcknowledgeEvent.emit();
  }

  public resetAllControlValues(): void {
    throw new Error('Method not implemented.');
  }

  public resetToOriginalValues(): void {
    throw new Error('Method not implemented.');
  }

  public setControlValues(source: any): void {
    throw new Error('Method not implemented.');
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {ChangeRequestAction} from "./change-request-action";
import {ChangeRequestSubscriberDto} from "./change-request-subscriber-dto";
import {ChangeRequestPayloadType} from "../inbox/change-request-payload-type";

export class CreateChangeRequest {
  public payloadObjectType: ChangeRequestPayloadType;
  public action: ChangeRequestAction;
  public payloadObjectId: number;
  public updatedValue: string;
  public originalValue: string;
  public subscribers: ChangeRequestSubscriberDto[];
  public title: string;
  public comment: string;
}

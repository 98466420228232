/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {HeaderEnabled} from './header-enabled.abstract';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CDRClearing} from '../../datatypes/cdrclearing';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {CDRBillingDto} from "../../datatypes/CDRBillingDto";

@Injectable({
  providedIn: 'root'
})
export class CDRService extends HeaderEnabled {
  private readonly CLEARING_BASE: string = environment.cdrServiceUrl + '/clearing';

  constructor(private http: HttpClient) {
    super();
  }

  public getCdrClearingEntries(offset: number, pageSize: number, filter: any): Observable<CDRClearing[]> {
    const url = this.CLEARING_BASE;
    const options = {
      headers: this.headers,
      params: {
        offset: offset.toString(),
        pageSize: pageSize.toString()
      }
    };
    return this.http.post<CDRClearing[]>(url, filter, options);
  }

  public getCdrClearingDetails(id: number, platform: string): Observable<string> {
    const params = new HttpParams().append('platform', platform);
    return this.http.get<string>(this.CLEARING_BASE + '/' + id, {headers: this.headers, params: params});
  }

  public retryCdrProcessing(id: number, cdrClearingEntry: string, ignoreConsumedEnergyValidation = false): Observable<void> {
    const params = new HttpParams().append('ignoreConsumedEnergyValidation', ignoreConsumedEnergyValidation);
    const url = this.CLEARING_BASE + '/' + id;
    return this.http.post<void>(url, cdrClearingEntry, {headers: this.headers, params: params});
  }

  public retrieveNotBilledCdrs(sessionId: string, contractId: string, offset: number, pageSize: number): Observable<CDRBillingDto[]> {
    const url = environment.cdrServiceUrl + '/notBilled';
    const options = {
      headers: this.headers,
      params: {
        offset: offset.toString(),
        pageSize: pageSize.toString(),
        sessionId,
        contractId
      }
    };
    return this.http.get<CDRBillingDto[]>(url, options);
  }

  public getCdr(id: number): Observable<CDRBillingDto> {
    return this.http.get<CDRBillingDto>(environment.cdrServiceUrl + '/' + id, {headers: this.headers});
  }

}

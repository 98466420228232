<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-accordion>
  <mat-expansion-panel [expanded]="expandView">
    <mat-expansion-panel-header>
      <div class="expansion-panel-header">
        <span class="headerSpan headerToolbarSub">{{'cards.filter.header' | translate}}</span>
        <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
      </div>
    </mat-expansion-panel-header>
    <mat-action-row>
      <button [disabled]="!isFilterValid()" okAction class="normalButton" mat-stroked-button
              (click)="onDisplayCards()">
        <span class="filter-button-span">{{ "cards.button.filter" | translate }}</span>
        <i class="fa fa-refresh" aria-hidden="true"></i>
      </button>
    </mat-action-row>
    <form class="filter-form" [formGroup]="filterFormGroup" *ngIf="filterFormGroup">
      <div class="multi-column-div">
        <mat-form-field class="filter-form-field">
          <mat-label>{{'card.contractId' | translate}}</mat-label>
          <input matInput maxlength="{{inputMaxLength}}" formControlName="contractIdFilter">
          <mat-hint align="end" *ngIf="contractIdFilter.value">{{contractIdFilter.value.length}}
            / {{inputMaxLength}}</mat-hint>
        </mat-form-field>
        <mat-form-field class="filter-form-field">
          <mat-label>{{'card.uid' | translate}}</mat-label>
          <input matInput maxlength="{{inputMaxLength}}" formControlName="uidFilter">
          <mat-hint align="end" *ngIf="uidFilter.value">{{uidFilter.value.length}}
            / {{inputMaxLength}}</mat-hint>
        </mat-form-field>
      </div>
      <div class="multi-column-div">
        <mat-form-field class="filter-form-field">
          <mat-label>{{'card.vin' | translate}}</mat-label>
          <input matInput maxlength="{{inputMaxLength}}" formControlName="vinFilter">
          <mat-hint align="end" *ngIf="vinFilter.value">{{vinFilter.value.length}}
            / {{inputMaxLength}}</mat-hint>
        </mat-form-field>
        <mat-form-field class="filter-form-field">
          <mat-label>{{'card.serial' | translate}}</mat-label>
          <input matInput maxlength="{{inputMaxLength}}" formControlName="serialFilter">
          <mat-hint align="end" *ngIf="serialFilter.value">{{serialFilter.value.length}}
            / {{inputMaxLength}}</mat-hint>
        </mat-form-field>
      </div>
    </form>
  </mat-expansion-panel>
</mat-accordion>

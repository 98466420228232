/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class StatisticsModule {
  public static forRoot(environment: { [key: string]: string | boolean; }): ModuleWithProviders<StatisticsModule> {
    return {
      ngModule: StatisticsModule,
      providers: [
        {provide: 'env', useValue: environment}
      ]
    };
  }
}

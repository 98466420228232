/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {FleetStatus} from './fleet-status.enum';

export class FleetSummary {
  public static readonly tableColumns: string[] = ['name', 'fleetId', 'status'];
  public name: string;
  public fleetId: string;
  public status: FleetStatus;
  public fleetType: string;
  public sapDebtorNumber: string;
  public doNotSendToFinanceEnabler: boolean;
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Renderer2} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {PurchasePriceComponent, PurchasePriceComponentAttributes} from '../../datatypes/purchase-conditions/purchase-price-component';
import {PurchaseConditionElement, PurchaseConditionElementAttributes} from '../../datatypes/purchase-conditions/purchase-condition-element';
import {Observable, of} from 'rxjs';
import {TranslationHelperService} from 'pcs-commons/http';

@Component({
  selector: 'pc-element-table',
  templateUrl: './pc-element-table.component.html',
  styleUrls: [
    '../action-column-style.css',
    '../scrollable-table/scrollable-table.component.css',
    './pc-element-table.component.css'
  ]
})
export class PcElementTableComponent extends OptimizedTableBaseComponent {
  public readonly pricesColumn = PurchaseConditionElementAttributes.PRICES;
  public readonly daysOfWeekColumn = PurchaseConditionElementAttributes.DAYS_OF_WEEK;
  public readonly startDateColumn = PurchaseConditionElementAttributes.START_DATE;
  public readonly endDateColumn = PurchaseConditionElementAttributes.END_DATE;
  public readonly powerClassesColumn = PurchaseConditionElementAttributes.POWER_CLASSES;
  public readonly pricesTableColumns: string[] = Object.values(PurchasePriceComponentAttributes);
  public readonly columnsNeedConversion: string[] = [this.powerClassesColumn, this.daysOfWeekColumn, this.startDateColumn, this.endDateColumn];

  constructor(
    renderer: Renderer2,
    private translationHelper: TranslationHelperService
  ) {
    super(renderer);
  }

  public hasPrices(element: PurchaseConditionElement): boolean {
    return element && element.prices && element.prices.length > 0;
  }

  public observableOfPrices(element: PurchaseConditionElement): Observable<PurchasePriceComponent[]> {
    return of(element.prices);
  }

  public valueConversionRequired(col: string) {
    return false;
  }

  public translateDate(date: any) {
    return this.translationHelper.translateDateSafe(date);
  }
}

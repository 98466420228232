/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import {Component} from '@angular/core';
import {AbstractNotificationHandler} from "pcs-commons/notification";
import {BehaviorSubject, firstValueFrom} from "rxjs";
import {EnrollmentStatusColumns, EnrollmentStatusDto} from "../datatypes/enrollment/enrollment-status-dto";
import {CustomerinfoService} from "../services/http/customerinfo.service";
import {WithLoadingSpinner} from "pcs-commons/components";
import {Message} from "pcs-commons/datatypes";

@Component({
    selector: 'pcs-enrollment-eaec',
    templateUrl: './enrollment-ea-ec.component.html',
    styleUrls: ['./enrollment-ea-ec.component.css']
})
export class EnrollmentEaEcComponent extends AbstractNotificationHandler {
    public enrollmentinfoSearchResultSubject = new BehaviorSubject<EnrollmentStatusDto[]>([]);
    protected enrollmentinfoSearchResult$ = this.enrollmentinfoSearchResultSubject.asObservable();

    protected dataColumns: string[] = Object.values(EnrollmentStatusColumns);

    public resultLength = 0;

    constructor(
        private customerinfoService: CustomerinfoService) {
        super();
    }

    @WithLoadingSpinner()
    public async applyVinFilter(vin: string): Promise<void> {
        try {
            const enrollmentStatusDtos = await firstValueFrom((this.customerinfoService.retrieveEnrollmentStatusByVin(vin)));
            this.handleResponse(enrollmentStatusDtos);
        } catch (exception) {
            this.handleError();
        }
    }

    @WithLoadingSpinner()
    public async applyEmaIdFilter(emaId: string): Promise<void> {
        try {
            const enrollmentStatusDtos = await firstValueFrom((this.customerinfoService.retrieveEnrollmentStatusByEmaId(emaId)));
            this.handleResponse(enrollmentStatusDtos);
        } catch (exception) {
            this.handleError();
        }
    }

    private handleResponse(enrollmentStatusDtos: EnrollmentStatusDto[]): void {
        this.resultLength = enrollmentStatusDtos?.length || 0;
        this.enrollmentinfoSearchResultSubject.next(enrollmentStatusDtos);
        if (!enrollmentStatusDtos || enrollmentStatusDtos?.length === 0) {
            const msg = new Message();
            msg.message = 'enrollment.eaec.noEntries';
            this.showInfo(msg);
        }
    }

    private handleError(): void {
        this.resultLength = 0;
        this.enrollmentinfoSearchResultSubject.next([]);
    }
}

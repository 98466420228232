/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnDestroy, ViewChild} from '@angular/core';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {AccessRights} from '../datatypes/access-rights.enum';
import {Parameters} from '../global/parameters';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {Message} from '../datatypes/message';
import {CDRService} from '../services/http/cdr.service';
import {CDRBillingColumns, CDRBillingDto} from '../datatypes/CDRBillingDto';
import {BehaviorSubject, firstValueFrom, Subscription} from 'rxjs';
import {InvoiceService} from "../services/http/invoice.service";
import {AuditService} from "../services/audit.service";

@Component({
  selector: 'pcs-cdr-revocation',
  templateUrl: './cdr-revocation.component.html',
  styleUrls: ['./cdr-revocation.component.css']
})
export class CdrRevocationComponent extends AbstractNotificationHandler implements OnDestroy {
  public readonly reqEditPermission = [AccessRights.INVOICE_EDIT_WEB];
  public loading = false;

  public pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS;
  public resultLength = 0;
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  public cdrSource = new BehaviorSubject<CDRBillingDto[]>([]);
  public cdrs$ = this.cdrSource.asObservable();

  public dataColumns: string[] = Object.values(CDRBillingColumns);
  public selectedPageSize = this.pageSizeOptions[0];
  public toUnsubscribe: Subscription[] = [];

  public selectedFilter: { sessionId: string, contractId: string };

  constructor(
    private cdrService: CDRService,
    private invoiceService: InvoiceService,
    private auditService: AuditService
  ) {
    super();
  }

  public retrieveCdrs(filter: { sessionId: string, contractId: string }, pageIndex: number, pageSize: number): void {
    const offset = pageIndex * pageSize;
    // send one more so see if we have more records than requested
    this.loading = true;

    this.cdrService.retrieveNotBilledCdrs(filter.sessionId, filter.contractId, offset, pageSize + 1).subscribe({
      next: (cdrs) => {
        this.loading = false;

        this.handleResult(pageIndex, pageSize, cdrs);
      },
      error: () => this.loading = false
    });
  }

  private handleResult(pageIndex: number, pageSize: number, cdrs: CDRBillingDto[]): void {
    this.resultLength = pageIndex * pageSize + cdrs.length;

    // remove extra element before showing
    if (cdrs.length > pageSize) {
      cdrs.splice(cdrs.length - 1, 1);
    }

    this.cdrSource.next(cdrs);

    if (cdrs.length <= 0) {
      const msg = new Message();
      msg.message = 'cdrs-for-revocation.noRecordsFound';
      this.showInfo(msg);
    }
  }

  public onSearchCdrsForRevocation(filter: { sessionId: string, contractId: string }): void {
    this.selectedFilter = filter;
    this.retrieveCdrs(filter, 0, this.selectedPageSize);
  }

  public handlePageChange(pageEvent: PageEvent): void {
    this.selectedPageSize = pageEvent.pageSize;
    this.retrieveCdrs(this.selectedFilter, pageEvent.pageIndex, pageEvent.pageSize);
  }

  public ngOnDestroy(): void {
    this.toUnsubscribe.forEach((sub) => sub.unsubscribe());
  }

  public async openRevocationRequestDialog(selectedCdr: CDRBillingDto): Promise<void> {
    await firstValueFrom(this.invoiceService.validateCdrRemovalFromBillrun(selectedCdr.id));
    await this.auditService.initiateChangeRequestForCDRBillRunRevocation(selectedCdr);
  }
}


/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Inject, Injectable} from '@angular/core';
import {Router} from "@angular/router";
import {ConfigService} from './config.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private logoutUrl: string;
  private clientId: string;

  constructor(@Inject('env') private env: any,
              private router: Router,
              private configService: ConfigService) {
  }

  public toCognitoLogin(): void {
    if (this.env.local) {
      this.router.navigate([this.env.localLoginUrl]);
      return;
    }
    if (this.env.webapp == 'WEBCONFIG') {
      this.redirectToCognito(this.env.webapp);
    } else if (this.env.webapp == 'FLEETUI') {
      this.redirectToCognito(this.env.webapp);
    } else {
      this.router.navigate(["error", 500]);
      return;
    }
  }

  private redirectToCognito(webappName: string): void {
    this.configService.getConfig(webappName).subscribe({
      next: (config) => {
        const authorization = config.authorization;
        const baseUri = authorization.loginUrl;
        this.logoutUrl = authorization.logoutUrl;
        this.clientId = authorization.clientId;
        // eslint-disable-next-line max-len
        const loginUri = baseUri + '?redirect_uri=' + this.env.redirectURI + '&response_type=token&client_id=' + this.clientId + '&scope=openid+aws.cognito.signin.user.admin';

        window.open(loginUri, '_self');
      },
      error: () => {
        this.router.navigate(["error", 500]);
      },
    });
  }

  private retrieveCognitoConfig(webappName: string): void {
    this.configService.getConfig(webappName).subscribe({
      next: (config) => {
        const authorization = config.authorization;
        this.logoutUrl = authorization.logoutUrl;
        this.clientId = authorization.clientId;
        this.redirectToCognitoLogout();
      },
      error: () => {
        this.router.navigate(["error", 500]);
      },
    });
  }

  public logout(): void {
    if (this.env.local) {
      this.router.navigate([this.env.localLoginUrl]);
      return;
    }
    if (this.logoutUrl === undefined || this.clientId === undefined) {
      this.retrieveCognitoConfig(this.env.webapp);
      return;
    }
    this.redirectToCognitoLogout();
  }

  private redirectToCognitoLogout(): void {
    const uri = this.logoutUrl + '?client_id=' + this.clientId + '&response_type=token&redirect_uri=' + this.env.redirectURI + '&scope=openid+aws.cognito.signin.user.admin';
    window.open(uri, '_self');
  }
}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HeaderEnabled } from './header-enabled.abstract';
import { Observable } from 'rxjs';
import { SavedQueryDto } from '../../datatypes/database-queries/SavedQueryDto';
import { Parameters } from '../../global/parameters';
import { QueryDto } from '../../datatypes/database-queries/QueryDto';

@Injectable({
  providedIn: 'root'
})
export class DatabaseService extends HeaderEnabled {
  constructor(private http: HttpClient) {
    super();
    this.headers = this.headers.append(Parameters.HEADER_ERROR_MSG_BASE, 'database.error.');
  }

  public getQueryResults(queryInput: QueryDto): Observable<Map<string, object>[]> {
    return this.http.post<Map<string, object>[]>(environment.databaseEndpoint + '/execute', queryInput, {
      headers: this.headers
    });
  }

  public retrieveSavedQueries(): Observable<SavedQueryDto[]> {
    return this.http.get<SavedQueryDto[]>(environment.databaseEndpoint, { headers: this.headers });
  }

  public saveQuery(queryInput: SavedQueryDto): Observable<SavedQueryDto[]> {
    return this.http.post<SavedQueryDto[]>(environment.databaseEndpoint, queryInput, { headers: this.headers });
  }

  public updateQuery(queryInput: SavedQueryDto): Observable<SavedQueryDto[]> {
    return this.http.put<SavedQueryDto[]>(environment.databaseEndpoint, queryInput, { headers: this.headers });
  }

  public deleteQuery(queryInput: SavedQueryDto): Observable<SavedQueryDto[]> {
    return this.http.delete<SavedQueryDto[]>(environment.databaseEndpoint + '/' + queryInput.id, { headers: this.headers });
  }
}

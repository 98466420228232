<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data" class="div-container">
  <div class="scrollable-table-container">
    <mat-table [dataSource]="tableDataSource">
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
          <div *ngIf="col !== 'validFrom' && col !== 'validUntil' && col !== 'cardUids' && col !== 'vins'">
            <mat-header-cell *matHeaderCellDef
                             [ngClass]="getColumnClass(col)">{{getI18nBase(col) | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element " [ngClass]="getColumnClass(col)"> {{element[col]}}</mat-cell>
          </div>
          <div *ngIf="col === 'validFrom' || col === 'validUntil'">
            <mat-header-cell *matHeaderCellDef
                             [ngClass]="getColumnClass(col)">{{getI18nBase(col) | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element "
                      [ngClass]="getColumnClass(col)"> {{translateDate(element[col])}}</mat-cell>
          </div>
          <div *ngIf="col === 'cardUids' || col === 'vins'">
            <mat-header-cell *matHeaderCellDef
                             [ngClass]="getColumnClass(col)">{{getI18nBase(col) | translate}}</mat-header-cell>
            <mat-cell *matCellDef="let element "
                      [ngClass]="getColumnClass(col)"> {{element[col].join(', ')}}</mat-cell>
          </div>
        </ng-container>
      </div>

      <ng-container matColumnDef="tableAction" stickyEnd>
        <mat-header-cell *matHeaderCellDef
                         class="actionCell center details-column"> {{'customerinfo.tableHeader.action' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="actionCell center details-column">
          <button mat-mini-fab class="download-btn tableActionBtn" (click)="onDownloadCustomerinfo(element)" matTooltip="{{'customerinfo.download' | translate}}">
            <mat-icon>move_to_inbox</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </div>
</div>

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-toolbar class="headerToolbar mat-elevation-z1">
  <span class="headerSpan">{{'chargepoints.header' | translate }}</span>
</mat-toolbar>

<div class="section-container">
  <chargepoint-filter [loading]="filterOnProcess" editKeyEvents></chargepoint-filter>
</div>

<div *ngIf="resultLength > 0" class="section-container">
  <mat-accordion>
    <mat-expansion-panel [expanded]="resultLength > 0" hideToggle>
      <div class="expansion-table-header">
        <span class="headerSpan">{{'chargepoint.listHeader' | translate }}</span>
      </div>
      <chargepoints-table (editEvent)="navigateToDetailsView($event)"
                          [actionButtons]="true"
                          [dataColumns]="cpDataColumns"
                          [data]="cpSearchResult$" [i18nBase]="'chargepoint.tableHeader'"
                          [isLoadingResults]="pageEventOnProcess">
      </chargepoints-table>
      <mat-paginator (page)="handlePageChange($event)"
                     *ngIf="!isDefaultFilter()" [length]="resultLength"
                     [pageSizeOptions]="pageSizeOptions"></mat-paginator>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content editKeyEvents>
  <h2 mat-dialog-title>{{'database.query.dialogue.header'|translate}}
    <mat-spinner [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
  </h2>
  <div>
    <form class="dialog-form" [formGroup]="addQueryForm" *ngIf="addQueryForm">
      <mat-form-field appearance="outline">
        <mat-label>{{'database.query.name' | translate}}</mat-label>
        <input matInput required formControlName="name">
        <mat-error *ngIf="name.invalid">{{getError(name) | async}}</mat-error>
      </mat-form-field>
      <div class="editor-div">
        <ngs-code-editor theme="vs" class="editor"
                         [codeModel]="codeModel$ | async"
                         (valueChanged)="onQueryInputChange($event)">
        </ngs-code-editor>
      </div>
      <mat-error class="editor-error" *ngIf="query.invalid">{{getError(query) | async}}</mat-error>

    </form>
    <mat-dialog-actions>
      <button okAction mat-raised-button class="dialogueButton"
              [disabled]="!allInputsValid()"
              (click)="onExecute()">
        {{'button.save' | translate}}
      </button>
      <button cancelAction mat-raised-button class="dialogueButton dialogueButtonRight"
              (click)="onCancel()">
        {{'button.cancel' | translate}}
      </button>
    </mat-dialog-actions>
  </div>
</mat-dialog-content>

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Directive, Input, OnDestroy, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BreakpointObserver} from '@angular/cdk/layout';
import {MatSidenav} from '@angular/material/sidenav';
import {Subject} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';

@Directive({
  selector: '[permanentAt]'
})
export class ResponsiveSidenavDirective implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    private breakpoint: BreakpointObserver,
    private sidenav: MatSidenav
  ) {
  }

  @Input() permanentAt: number;

  private destroy$ = new Subject();
  @Input() canOpen = () => true;

  ngOnInit() {
    const permanent$ = this.breakpoint
      .observe(`(min-width: ${this.permanentAt}px)`)
      .pipe(
        takeUntil(this.destroy$),
        map(({matches}) => matches)
      );

    permanent$.subscribe(permanent => {
      this.sidenav.mode = permanent ? 'side' : 'over';
      this.sidenav.opened = permanent && this.canOpen();
    });

    // when user navigates to a new page, the sidenav will be automatically closed if it's on over mode
    this.router.events
      .pipe(
        takeUntil(this.destroy$),
        filter(() => this.sidenav.mode === 'over'),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => this.sidenav.close());
  }

  ngOnDestroy() {
    this.destroy$.next(undefined);
    this.destroy$.complete();
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {ChargepointVisibility} from '../../../datatypes/chargepoint-visibility.enum';
import {ChargepointFilter} from '../../../datatypes/chargepoint-filter';
import {FormArray, FormBuilder, FormControl} from '@angular/forms';
import {InputValidationService} from '../../../services/input-validation.service';
import {ValidatorList} from 'pcs-commons/validation';
import {FormHelper} from '../../../utils/form-helper';
import {Parameters} from '../../../global/parameters';
import {ChargepointFilterEventService} from '../../../services/chargepoint-filter-event.service';
import {AbstractFilterFormComponent} from '../abstract-filter-form-component';

@Component({
  selector: 'chargepoint-filter-form',
  templateUrl: './chargepoint-filter-form.component.html',
  styleUrls: ['./chargepoint-filter-form.component.css']
})
export class ChargepointFilterFormComponent extends AbstractFilterFormComponent implements OnInit {

  private readonly DEFAULT_MAX_AMOUNT = '10';
  private readonly EVSEID: string = 'evseidFilter';
  private readonly ADDRESS = 'addressFilter';
  private readonly VISIBILITY_FILTER = 'visibilityFilter';
  private readonly MAX_AMOUNT = 'maxamount';
  private readonly MAX_AMOUNT_CHOICES = [
    '5', '10', '25', '50', '100', '500'
  ];
  public visibilityFilter: string[] = [
    ChargepointVisibility.NORMAL,
    ChargepointVisibility.RARELY_USED,
    ChargepointVisibility.HIDDEN
  ];

  public evseidFilter: FormControl;
  public addressFilter: FormControl;
  public maxamount: FormControl;
  public offset: string;

  constructor(
    private filterService: ChargepointFilterEventService,
    private formBuilder: FormBuilder,
    private inputValidationService: InputValidationService) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.offset = '0';
  }

  public defineFromControls(): void {
    console.log('defining chargepoint filter form group, form-builder: ', this.formBuilder);
    this.evseidFilter = new FormControl('', ValidatorList.FILTER_EVSEID);
    this.addressFilter = new FormControl('', ValidatorList.FILTER_ADDRESS);
    this.maxamount = new FormControl(this.DEFAULT_MAX_AMOUNT);
    this.filterFormGroup = this.formBuilder.group({
      evseidFilter: this.evseidFilter,
      addressFilter: this.addressFilter,
      visibilityFilter: this.generateVisibilityFilterArray(),
      maxamount: this.maxamount
    });

  }

  private generateVisibilityFilterArray(): FormArray {
    const visibilityFilterArray = new FormArray([]);
    this.visibilityFilter.forEach((v) => {
      visibilityFilterArray.push(new FormControl());
    });
    return visibilityFilterArray;
  }

  public triggerDisplayChargepoints(): void {
    const filter = new ChargepointFilter();
    this.fillFilterData(filter);
    console.log('emitting chargepoint filter event with filter: ', filter);
    this.filterService.triggerDefaultChargepointFilterEvent(filter);
  }

  private fillFilterData(filter: ChargepointFilter): void {
    filter.evseidFilter = FormHelper.getStringValue(this.filterFormGroup, this.EVSEID);
    filter.addressFilter = FormHelper.getStringValue(this.filterFormGroup, this.ADDRESS);
    filter.visibilityFilter = this.toVisibilityFilterString(FormHelper.getValue(this.filterFormGroup, this.VISIBILITY_FILTER));
    const tempMaxAmount = FormHelper.getStringValueSafe(this.filterFormGroup, this.MAX_AMOUNT, this.DEFAULT_MAX_AMOUNT);
    // we always ask backend to deliver one CP more than the maxamount to see if there are more CP's
    filter.pageSize = (Number(tempMaxAmount) + 1);
    filter.offset = Number(this.offset);
  }

  // since we get array of booleans representing if the checkbox is selected or not,
  // we need to convert them to visibilityAttribute filter strings
  private toVisibilityFilterString(selections: any[]): string[] {
    const filters: Array<string> = [];
    this.visibilityFilter.forEach((visibility, i) => {
      if (visibility && selections[i]) {
        filters.push(visibility);
      }
    });
    return filters;
  }

  public validateEvseidPattern(event: any): void {
    this.inputValidationService.allowIfPatternMatch(event, Parameters.EVSEID_PATTERN);
  }

  get evseidMaxLength(): number {
    return Parameters.DEFAULT_MAX_LENGTH;
  }

  get addressMaxLength(): number {
    return Parameters.ADDRESS_FILTER_MAX_LENGTH;
  }
}

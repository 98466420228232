/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { User } from '../../../datatypes/user';
import { ValidationMessagePipe, ValidatorList } from 'pcs-commons/validation';
import { MatDialogRef } from '@angular/material/dialog';
import { Utils } from '../../../utils/utils';
import { Role } from '../../../datatypes/role.enum';
import { UserService } from '../../../services/http/user.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'pcs-add-admin-user',
  templateUrl: './add-admin-user.component.html',
  styleUrls: ['./add-admin-user.component.css']
})
export class AddAdminUserComponent implements OnInit {
  public addManagerForm: FormGroup;
  public userResponse: User[] = [];
  public hidePass = true;
  public loading = false;

  public login = new FormControl('', ValidatorList.LOGIN);
  public phone = new FormControl('+49', ValidatorList.PHONE_WITH_COUNTRY_CODE);
  public password = new FormControl('', ValidatorList.PASSWORD);

  constructor(
    public dialogRef: MatDialogRef<AddAdminUserComponent>,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private validationPipe: ValidationMessagePipe
  ) {
    this.dialogRef.disableClose = true;
  }

  public get showUserCred(): boolean {
    return this.userResponse.length > 0;
  }

  public ngOnInit(): void {
    this.addManagerForm = this.formBuilder.group({
      login: this.login,
      phone: this.phone,
      password: this.password
    });
  }

  public getError(fc: FormControl): Observable<unknown> {
    return this.validationPipe.transform(fc);
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public validatePhoneNumber(event: KeyboardEvent): void {
    Utils.validatePhoneNumber(event);
  }

  public onAdd(): void {
    this.loading = true;
    console.log('creating new user with login: ' + this.addManagerForm.value.login);
    const manager = new User();
    manager.role = Role.PCS_ADMIN;
    manager.login = this.addManagerForm.value.login;
    manager.password = this.addManagerForm.value.password;
    manager.phone = this.addManagerForm.value.phone;
    // if phone number contains any / or - , remove them
    manager.phone = manager.phone.replace(/[/-]/g, '');

    this.addWebConfigUser(manager);
  }

  public onOk(): void {
    /*send true as an indication to refresh*/
    this.dialogRef.close(true);
  }

  public allInputsValid(): boolean {
    return this.addManagerForm.valid;
  }

  private addWebConfigUser(user: User): void {
    this.userService.createUser(user).subscribe({
      next: (response) => {
        console.log('Create user response: ' + response);
        this.loading = false;
        this.userResponse.push(response);
      },
      error: () => (this.loading = false)
    });
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, Renderer2} from '@angular/core';
import {ChargepointClassPrice, ChargepointClassPriceColumn} from '../../chargepoint-class-price';
import {FormGroup} from '@angular/forms';
import {Utils} from '../../utils/utils';
import {ClassPricesTableComponent} from '../class-prices-table/class-prices-table.component';

@Component({
  selector: 'class-prices-editable-table',
  templateUrl: './class-prices-editable-table.component.html',
  styleUrls: [
    '../price-table-styles.css',
    '../data-table.component.css',
    '../class-prices-table/class-prices-table.component.css',
    './class-prices-editable-table.component.css'
  ]
})
export class ClassPricesEditableTableComponent extends ClassPricesTableComponent {
  @Input() pricesForm: FormGroup;

  PRICE_PER_MIN_COLUMN = ChargepointClassPriceColumn.PRICE_PER_MIN;
  PRICE_PER_KWH_COLUMN = ChargepointClassPriceColumn.PRICE_PER_KWH;

  constructor(
    renderer: Renderer2) {
    super(renderer);
  }

  calcFormControlName(element: ChargepointClassPrice, postfix: string): string {
    return Utils.calcClassPriceFormControlName(element.chargepointClass, postfix);
  }
}

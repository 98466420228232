<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <div class="expansion-panel-header">
        <span class="headerSpan headerToolbarSub">{{'cdrs-for-revocation.filter.header' | translate}}</span>
      </div>
    </mat-expansion-panel-header>
    <form class="cdrs-filter-form" [formGroup]="filterFormGroup" *ngIf="filterFormGroup">
      <div class="multi-column-div">
        <mat-form-field class="filter-form-field">
          <mat-label>{{'cdrs-for-revocation.filter.sessionId' | translate}}</mat-label>
          <input matInput formControlName="sessionId">
        </mat-form-field>

        <mat-form-field class="filter-form-field">
          <mat-label>{{'cdrs-for-revocation.filter.contractId' | translate}}</mat-label>
          <input matInput formControlName="contractId">
        </mat-form-field>
      </div>
    </form>
    <mat-action-row>
      <button okAction class="normalButton" mat-stroked-button (click)="triggerFetchCdrs()">
        <span class="filter-button-span">{{ "cdrs-for-revocation.button.filter" | translate }}</span>
        <i class="fa fa-refresh" aria-hidden="true"></i>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>

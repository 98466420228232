/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {ActivatedRoute} from '@angular/router';
import {FleetService} from '../../services/http/fleet.service';
import {CardInventory, CardInventoryAttributes} from '../../datatypes/card-inventory';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {CardInventoryImportRecord} from '../../datatypes/card-inventory-import-record';
import {WebConfigRouterService} from '../../services/web-config-router.service';
import {CardInventoryStatus} from '../../datatypes/card-inventory-status.enum';
import {SelectConfig} from 'pcs-commons/datatypes';

@Component({
  selector: 'id-cards',
  templateUrl: './id-cards.component.html',
  styleUrls: ['./id-cards.component.css']
})
export class IdCardsComponent extends AbstractNotificationHandler implements OnInit {
  public currFleetId;
  public loading = false;
  public importOnProcess = false;
  public cardImportResult: CardInventoryImportRecord[] = [];
  private cardInventorySource = new BehaviorSubject<CardInventory[]>([]);
  public cardInventory$ = this.cardInventorySource.asObservable();
  public cardsTableColumns = CardInventory.tableColumns;
  public importResultTableColumns = CardInventoryImportRecord.tableColumns;
  public selectColumnConfigs = new Map<string, SelectConfig>();

  constructor(
    private router: WebConfigRouterService,
    private route: ActivatedRoute,
    private fleetService: FleetService) {
    super();
    const statusSelectConf = new SelectConfig();
    statusSelectConf.options = Object.values(CardInventoryStatus);
    statusSelectConf.defaultOption = CardInventoryStatus.OK;
    this.selectColumnConfigs.set(CardInventoryAttributes.STATUS, statusSelectConf);
  }

  public ngOnInit(): void {
    this.currFleetId = this.route.snapshot.paramMap.get('fleetId');
    this.getCards();
  }

  private getCards(): void {
    this.loading = true;
    this.fleetService.cardInventory(this.currFleetId)
      .subscribe({
        next: (cards) => {
          this.cardInventorySource.next(cards);
          this.loading = false;
        },
        error: () => {
          this.loading = false;
        }
      });
  }

  public observableOfCardImportResult(): Observable<CardInventoryImportRecord[]> {
    return of(this.cardImportResult);
  }

  public onBack(): void {
    if (this.cardImportResult.length > 0) {
      this.cardImportResult = [];
      this.getCards();
      return;
    }
    this.router.navigate(['fleet-edit', this.currFleetId]);
  }

  public importCards(file: any): void {
    if (!file) {
      return;
    }
    const reader = new FileReader();
    // set the reader to make the import rest call when the file is loaded
    reader.onloadstart = () => {
      this.importOnProcess = true;
      console.log('[%s] import start', new Date().toLocaleDateString());
    };
    reader.onloadend = () => {
      this.handleImport(reader);
      console.log('[%s] import end', new Date().toLocaleDateString());
    };
    reader.readAsDataURL(file);
  }

  private handleImport(reader: FileReader): void {
    const readerResult = reader.result as string;
    this.fleetService
      .addCardsToInventory(this.currFleetId, new Blob([readerResult.split(',')[1]]))
      .subscribe({
        next: (iResponse) => {
          this.cardImportResult = iResponse.records;
          console.log('import response', this.cardImportResult);
          this.importOnProcess = false;
        },
        error: () => {
          this.importOnProcess = false;
        }
      });
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {CodeModel} from "@ngstack/code-editor";

@Component({
  selector: 'pcs-ionity-charm-contract-data-dialog-component',
  templateUrl: './ionity-charm-contract-data-dialog.component.html',
  styleUrls: [
    '../../dialog/dialog-common-styles.css', './ionity-charm-contract-data-dialog.component.css']
})
export class IonityCharmContractDataDialogComponent {
  public dataToShow = '';
  public contractId = '';
  private editorCodeModelUri = 'ionity-data.ts';
  private editorCodeModelLanguage = 'json';
  public codeModel: CodeModel = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log('Ionity and Charm contract data: ', data.dataToShow);
    this.dataToShow = JSON.stringify(data.dataToShow, null, '\t');
    this.codeModel = {
      language: this.editorCodeModelLanguage,
      uri: this.editorCodeModelUri,
      value: this.dataToShow
    }
    this.contractId = data.contractId;
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Output} from '@angular/core';
import {ChargepointFilterType} from '../../../datatypes/chargepoint-filter-type.enum';

@Component({
  selector: 'filter-toggle-group',
  templateUrl: './filter-toggle-group.component.html',
  styleUrls: ['./filter-toggle-group.component.css']
})
export class FilterToggleGroupComponent {
  @Output() public filterSelectionEvent = new EventEmitter<string>();

  public currentFilterType: string = ChargepointFilterType.DEFAULT;
  public filterTypes = Object.values(ChargepointFilterType);

  constructor() {
  }


  public handleFilterChange(filter: string): void {
    this.currentFilterType = filter;
    this.filterSelectionEvent.emit(filter);
  }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="location">
  <mat-expansion-panel *ngIf="location && sortedPlatformList" class="expansion-panel-tile" [expanded]="true"
                       hideToggle>
    <div class="expansion-table-header">
      <span class="headerSpan headerToolbarSub">{{ 'chargepoint.locationdetails.edit.header' | translate }}</span>
      <button id="editLocation" mat-raised-button class="action-button icon-only"
              *ngIf="!inEditMode && (reqEditPermission | accessAllowed)"
              (click)="enableDataEdit()">
        <mat-icon> edit</mat-icon>
      </button>
      <div id="saveLocation" *ngIf="inEditMode">
        <button mat-raised-button class="action-button icon-only"
                (click)="onSave()">
          <mat-icon> save</mat-icon>
        </button>
        <button mat-raised-button class="action-button icon-only"
                (click)="onCancel()">
          <mat-icon> close</mat-icon>
        </button>
      </div>
    </div>
    <div class="scrollable-table-container">
      <mat-table [dataSource]="tableDataSource" *ngIf="tableDataSource">
        <div *ngFor="let col of dataColumns; let columnIndex = index">
          <ng-container matColumnDef="{{col}}">
            <mat-header-cell *matHeaderCellDef
                             [ngClass]="determineColumnClass(col)">{{ getI18nBase(col) | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let element" [ngClass]="determineColumnClass(col)">
              <div *ngIf="!elementOnEdit(element, col)">
                {{
                  (element.name === countryCode && col !== attributeNameColumn)
                    ? (getCellValue(element, col) | translateCountry) : getCellData(col, element)
                }}
              </div>
              <div class="form-div" *ngIf="elementOnEdit(element, col)">
                <mat-form-field
                  *ngIf="element.name !== countryCode && element.name!== geoPositionStatus && getFromControl(element) as fCtrl">
                  <input matInput [formControl]="fCtrl" (keydown)="validateInput($event, element.name)">
                  <mat-error *ngIf="fCtrl.invalid">{{ getError(fCtrl) | async }}</mat-error>
                </mat-form-field>
                <autocomplete-string-select *ngIf="element.name === countryCode && getFromControl(element) as fCtrl"
                                            [inputControl]="fCtrl" class="cp-details-autocomplete-select"
                                            [label]="'chargepoint.country.empty'"
                                            [optionList]="countryCodeChoiceList" [optionI18nBase]="'global.country.'"
                                            [appearance]="'fill'">
                </autocomplete-string-select>
                <mat-form-field *ngIf="element.name === geoPositionStatus">
                  <mat-select [formControl]="getFromControl(element)">
                    <mat-option *ngFor="let choice of geoPositionStatusChoices"
                                [value]="choice">{{ getTranslation(element.name, choice) }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-cell>
          </ng-container>
        </div>
        <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
      </mat-table>
    </div>
    <div class="info-row">
      <mat-label class="comments-label">{{ getCellData(attributeNameColumn, lastUpdateDateRow) }}</mat-label>
      <div>{{ getCellData(manualDataColumn, lastUpdateDateRow) }}</div>
    </div>
    <div class="info-row">
      <mat-label class="comments-label">{{ getCellData(attributeNameColumn, updatedByRow) }}</mat-label>
      <div>{{ getCellData(manualDataColumn, updatedByRow) }}</div>
    </div>
    <div class="comments-row">
      <mat-label class="comments-label">{{ getCellData(attributeNameColumn, commentRow) }}</mat-label>
      <mat-form-field appearance="outline" *ngIf="getFromControl(commentRow) as fCtrl">
        <textarea cdkTextareaAutosize matInput [formControl]="getFromControl(commentRow)"></textarea>
        <mat-error *ngIf="fCtrl.invalid">{{ getError(fCtrl) | async }}</mat-error>
      </mat-form-field>
    </div>

  </mat-expansion-panel>
</div>

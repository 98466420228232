/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'pcs-input-column-filter',
  templateUrl: './input-column-filter.component.html',
  styleUrl: './input-column-filter.component.css'
})
export class InputColumnFilterComponent implements OnInit {
  @Input() public withDebounce: boolean = true;
  @Input() public preSelectedValue: string = '';
  @Output() public handleInputChange: EventEmitter<string> = new EventEmitter<string>();

  public inputControl: FormControl;

  public ngOnInit(): void {
    this.inputControl = new FormControl(this.preSelectedValue || '');
    const inputChanges = this.inputControl.valueChanges;
    if (this.withDebounce) {
      inputChanges.pipe(debounceTime(300)).subscribe((value) => this.handleInputChange.emit(value));
    } else {
      inputChanges.subscribe((value) => this.handleInputChange.emit(value));
    }
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {LocationAndChargepointAdminAttributeDto} from "../location-and-chargepoint-admin-attribute-dto";

export class ChargepointUpdateData {
    public updatedValue: LocationAndChargepointAdminAttributeDto;
    public originalValue: LocationAndChargepointAdminAttributeDto;
    public containsCostSensitiveAttributeChanges: boolean;
    public chargepointGroupId: number;
    public evseid: string;
}

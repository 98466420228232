/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Message} from "pcs-commons/datatypes";
import {AbstractNotificationHandler} from "pcs-commons/notification";

@Component({
    selector: 'pcs-enrollment-ea-ec-filter',
    templateUrl: './enrollment-ea-ec-filter.component.html',
    styleUrls: ['./enrollment-ea-ec-filter.component.css']
})
export class EnrollmentEaEcFilterComponent extends AbstractNotificationHandler implements OnInit {

    @Output() public emaIdFilterEvent = new EventEmitter<string>();
    @Output() public vinFilterEvent = new EventEmitter<string>();

    protected filterFormGroup: FormGroup;
    public emaId: FormControl;
    public vin: FormControl;

    constructor(private formBuilder: FormBuilder) {
        super();
    }

    public ngOnInit(): void {
        this.defineFormControls();
    }

    private defineFormControls(): void {
        this.emaId = new FormControl('');
        this.vin = new FormControl('');
        this.filterFormGroup = this.formBuilder.group({
            emaId: this.emaId,
            vin: this.vin
        });
    }

    public triggerFetchEnrollmentInfo(): void {
        if (this.emaId.value.trim().length !== 0 && this.vin.value.trim().length === 0) {
            this.emaIdFilterEvent.emit(this.emaId.value);
        } else if (this.vin.value.trim().length !== 0 && this.emaId.value.trim().length === 0) {
            this.vinFilterEvent.emit(this.vin.value);
        } else {
            const msg = new Message();
            msg.message = 'enrollment.eaec.filter.invalid';
            this.showError(msg);
        }
    }

    public onlyOneFieldIsSet(): boolean {
        return (this.emaId.value.trim().length !== 0 && this.vin.value.trim().length === 0) || (this.emaId.value.trim().length === 0 && this.vin.value.trim().length !== 0);
    }
}

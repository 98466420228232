<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div class="mat-elevation-z1" id="sub-operators">
  <mat-toolbar class="headerToolbar">
    <span class="headerSpan">{{
        'operators.subOperators.header' | translate: {
          param0: state?.selectedCpoCode
        }
      }}</span>
  </mat-toolbar>
  <pcs-sub-cpo-table [dataColumns]="subOperatorTableColumns"
                     [data]="subOperators$"
                     [i18nBase]="'operators'"
                     (togglePlugAndChargeEvent)="togglePlugAndCharge($event)"
                     (toggleRemoteAuthEvent)="toggleRemoteAuth($event)"
                     (toggleRenewableEnergyEvent)="toggleEnergySource($event)"
                     (toggleRfidAuthEvent)="toggleRfidAuth($event)"
                     (toggleShowStationNameEvent)="toggleShowStationName($event)"
                     (updateDisplayNameEvent)="updateDisplayName($event)"
                     (togglePreferredEvent)="togglePreferred($event)"
  ></pcs-sub-cpo-table>
</div>
<mat-divider style="height: 10px;"></mat-divider>
<button (click)="onBack()" class="normalButton back-button" mat-raised-button>
  {{ 'button.back' | translate }}
</button>

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HeaderEnabled} from "./header-enabled.abstract";
import {Config} from 'pcs-commons/datatypes';

@Injectable({
  providedIn: 'root'
})
export class ConfigService extends HeaderEnabled {

  constructor(private http: HttpClient,
              @Inject('env') private env: any) {
    super();
  }

  public getConfig(application: string): Observable<Config> {
    const url = this.env.configEndpoint + '/' + application;
    return this.http.get<Config>(url, {headers: this.headers});
  }
}

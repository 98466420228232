<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div class="mat-elevation-z1">
  <mat-toolbar class="headerToolbar">
    <span class="headerSpan">{{'electricVehicle.check.result.header' | translate }}</span>
    <button mat-raised-button class="headerActionBtn icon-only" (click)="onDownloadResult()">
      <i class="fa fa-download" aria-hidden="true"></i>
    </button>
  </mat-toolbar>
  <paginated-table [data]="resultList$" [dataColumns]="resultTableColumns" class="padding-lr-24"></paginated-table>
</div>
<div class="action-buttons-bottom">
  <button mat-raised-button class="dialogueButton" (click)="goBack()">
    <mat-icon class="button-icon">arrow_back</mat-icon>
    <span>{{ 'button.back' | translate }}</span>
  </button>
</div>

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div id="cpAttributeImport">
  <mat-toolbar class="headerToolbar mat-elevation-z1">
    <span class="headerSpan">{{ "cpAttributeImport.title" | translate }}</span>
    <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="importing"></mat-spinner>
  </mat-toolbar>
  <div class="mat-elevation-z1 cpAttributeImportForm">
    <mat-radio-group [formControl]="radioButtonSelection" class="radio-button-group">
      <mat-radio-button class="radio-button"
                        value="{{IMPORT}}">{{ 'cpAttributeImport.button.import' | translate }}</mat-radio-button>
      <mat-radio-button class="radio-button"
                        value="{{RESET}}">{{ 'cpAttributeImport.button.reset' | translate }}</mat-radio-button>
    </mat-radio-group>
    <attribute-import-form (importEvent)="handleImport($event)" [importAllowed]="reqEditPermission |accessAllowed"
                           [disabled]="importing || !this.radioButtonSelection.value"></attribute-import-form>
  </div>
</div>

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Platform } from './platform.enum';

export class OperatorFilter {
  public active: string;
  public cpoCode: string;
  public name: string;
  public displayName: string;
  public platform: Platform;
  public renewableEnergySource: boolean;
  public showStationName: boolean;
  public enablePlugAndCharge: boolean;
  public enableRfidAuth: boolean;
  public enableRemoteAuth: boolean;
  public preferred: boolean;

  public toOperatorFilterDto(): OperatorFilterDto {
    const mappedDto: OperatorFilterDto = {
      active: this.convertActiveColumn(),
      cpoCode: this.cpoCode,
      name: this.name,
      displayName: this.displayName,
      platform: this.platform,
      renewableEnergySource: this.convertTrueFalseColumn(this.renewableEnergySource),
      showChargingStationNameEnabled: this.convertTrueFalseColumn(this.showStationName),
      pncAuthenticationEnabled: this.convertTrueFalseColumn(this.enablePlugAndCharge),
      rfidAuthenticationEnabled: this.convertTrueFalseColumn(this.enableRfidAuth),
      remoteAuthenticationEnabled: this.convertTrueFalseColumn(this.enableRemoteAuth),
      preferred: this.convertTrueFalseColumn(this.preferred)
    };
    return Object.keys(mappedDto).reduce((acc, key) => {
      const value = mappedDto[key];
      if (value !== undefined && value !== null && value !== '') {
        acc[key] = value;
      }
      return acc;
    }, {} as OperatorFilterDto);
  }

  private convertActiveColumn(): boolean {
    return ['active', 'inactive'].includes(this.active) ? this.active == 'active' : null;
  }

  private convertTrueFalseColumn(value): boolean {
    return ['true', 'false'].includes(value) ? value == 'true' : null;
  }
}

export interface OperatorFilterDto {
  active: boolean;
  cpoCode: string;
  name: string;
  displayName: string;
  platform: string;
  renewableEnergySource: boolean;
  showChargingStationNameEnabled: boolean;
  pncAuthenticationEnabled: boolean;
  rfidAuthenticationEnabled: boolean;
  remoteAuthenticationEnabled: boolean;
  preferred: boolean;
}

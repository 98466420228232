/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { IopPlatformDto } from './iop-platform-dto';

export interface SubOperator {
  id: number;
  operatorId: number;
  subOperatorId: string;
  name: string;
  displayName: string;
  chargepointCount: number;
  renewableEnergySource: boolean;
  enablePlugAndCharge: boolean;
  showStationName: boolean;
  iopPlatformDto: IopPlatformDto;
  enableRfidAuth: boolean;
  enableRemoteAuth: boolean;
  preferred: boolean;
}

export enum SubOperatorColumns {
  SUB_OPERATOR_ID = 'subOperatorId', // NOTE
  NAME = 'name',
  DISPLAY_NAME = 'displayName',
  CHARGEPOINT_COUNT = 'chargepointCount',
  RENEWABLE_ENERGY = 'renewableEnergySource',
  ENABLE_PNC = 'enablePlugAndCharge',
  SHOW_STATION_NAME = 'showStationName',
  ENABLE_RFID_AUTH = 'enableRfidAuth',
  ENABLE_REMOTE_AUTH = 'enableRemoteAuth',
  PREFERRED = 'preferred'
}

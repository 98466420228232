<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div class="mat-elevation-z1">
  <mat-toolbar class="header-toolbar">
    <span class="headerSpan">{{'usermanagement.groups.userGroups' | translate }}</span>
    <button mat-raised-button class="headerActionBtn icon-only" (click)="onCreateButtonClick()"
            *ngIf="(reqEditPermission | accessAllowed)">
      <mat-icon> add</mat-icon>
    </button>
    <pcs-loading-spinner [component]="this"></pcs-loading-spinner>
  </mat-toolbar>
  <div *ngIf="userGroupList$">
    <pcs-user-group-table (deleteEvent)="onGroupDelete($event)"
                          (editEvent)="onEditGroup($event)"
                          (readEvent)="onReadGroupDetails($event)"
                          [actionButtons]="true"
                          [dataColumns]="userGroupTableColumns"
                          [data]="userGroupList$">
    </pcs-user-group-table>
  </div>
</div>

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'edit-handler',
  templateUrl: './edit-handler.component.html',
  styleUrls: ['./edit-handler.component.css']
})
export class EditHandlerComponent {
  @Output() public editEvent = new EventEmitter<void>();
  @Output() public saveEvent = new EventEmitter<void>();
  @Output() public cancelEvent = new EventEmitter<void>();
  @Input() public forForm: AbstractControl;

  public isEditMode = false;

  constructor() {
  }

  public enableEdit(): void {
    this.isEditMode = true;
    this.editEvent.emit();
  }

  public save(): void {
    this.saveEvent.emit();
    this.isEditMode = false;
  }

  public cancel(): void {
    this.cancelEvent.emit();
    this.isEditMode = false;
  }

  public saveNotApplicable(): boolean {
    return !this.forForm || !this.forForm.dirty || this.forForm.invalid;
  }
}

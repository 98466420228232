/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {AfterViewInit, Component, EventEmitter, OnDestroy, Output, Renderer2, ViewChild} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {TaxRecord} from '../../datatypes/taxrecord';
import {MatSort} from '@angular/material/sort';
import {Subscription} from 'rxjs';
import {TranslationHelperService} from 'pcs-commons/http';

@Component({
  selector: 'app-taxes-table',
  templateUrl: './taxes-table.component.html',
  styleUrls: [
    '../data-table.component.css',
    '../action-column-style.css',
    './taxes-table.component.css']
})
export class TaxesTableComponent extends OptimizedTableBaseComponent implements AfterViewInit, OnDestroy {

  @Output() public editEvent = new EventEmitter<TaxRecord[]>();
  @ViewChild(MatSort, {static: false}) public sort: MatSort;

  public langChangeSub: Subscription;

  constructor(renderer: Renderer2,
              private translateHelper: TranslationHelperService) {
    super(renderer);
    this.langChangeSub = this.translateHelper.onLangChange.subscribe((e: Event) => {
      this.tableDataSource.sort = this.sort;
    });
  }

  public ngOnDestroy(): void {
    if (this.langChangeSub) {
      this.langChangeSub.unsubscribe();
    }
  }

  public ngAfterViewInit(): void {
    // has to be done in ngAfterViewInit, else sorting would not work, because of ngIf
    this.tableDataSource.sort = this.sort;
    this.tableDataSource.sortingDataAccessor = (item, property) => {
      if (property === 'country') {
        let countryTranslation: string;
        this.translateHelper.get('global.country.sort.' + item[property]).subscribe(
          (sortTranslation) => {
            if (!sortTranslation.startsWith('global.country.sort.')) {
              countryTranslation = sortTranslation;
              return;
            }
            this.translateHelper.get('global.country.' + item[property]).subscribe(
              (translation) => {
                countryTranslation = translation;
              }
            );
          }
        );
        return countryTranslation;
      }
      return item[property];
    };
  }

  public toPercent(taxRate: number): number {
    return Math.round(10000 * taxRate) / 100;
  }

  public onEdit(toEdit: TaxRecord): void {
    this.editEvent.emit(this.getAllTaxesForCountry(toEdit.country));
  }

  public getAllTaxesForCountry(country: string): TaxRecord[] {
    const records = [];
    this.tableDataSource.data.forEach((entry) => {
      if (entry.country === country) {
        records.push({...entry});
      }
    });
    return records;
  }

  public getDateTimeTranslation(value: string): string {
    return this.translateHelper.translateDateTimeUTCSafe(value);
  }
}

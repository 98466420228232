/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
export class ManualChargepointDataImportErrorDto {
  public row: number;
  public evseId: string;
  public errorMessage: string;
}

export enum ManualChargepointDataImportErrorColumns {
  ROW = 'row',
  EVSEID = 'evseId',
  ERROR_MSG = 'errorMessage'
}

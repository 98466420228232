/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
export enum AttributeTableAction {
  ENABLE_EDIT = 'ENABLE_EDIT',
  SAVE = 'SAVE',
  CANCEL = 'CANCEL'
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {HeaderEnabled} from '../header-enabled.abstract';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {PageResponse} from "../../../datatypes/PageResponse";
import {ChangeRequestOverviewDto} from "../../../datatypes/inbox/change-request-overview-dto";

@Injectable({
  providedIn: 'root'
})
export class InboxService extends HeaderEnabled {

  constructor(private http: HttpClient) {
    super();
  }

  public getInboxChangeRequests(page: number, pageSize: number): Observable<PageResponse<ChangeRequestOverviewDto>> {
    return this.http.get<PageResponse<ChangeRequestOverviewDto>>(environment.inboxServiceUrl + '/my', {
        headers: this.headers,
        params: {
          page,
          pageSize
        }
      }
    );
  }

  public getChangeRequests(page: number, pageSize: number, objectType: string): Observable<PageResponse<ChangeRequestOverviewDto>> {
    return this.http.get<PageResponse<ChangeRequestOverviewDto>>(environment.inboxServiceUrl, {
        headers: this.headers,
        params: {
          page,
          pageSize,
          objectType
        }
      }
    );
  }
}

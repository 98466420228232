/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
export enum ChargepointDtoAttributeName {
  NAME = 'NAME',
  EVSE_ID = 'EVSE_ID',
  STREET = 'STREET',
  HOUSE_NO = 'HOUSE_NO',
  AVAILABILITY = 'AVAILABILITY',
  AVAILABILITY_CHANGED_ON = 'AVAILABILITY_CHANGED_ON',
  FLOOR = 'FLOOR',
  ZIP = 'ZIP',
  CITY = 'CITY',
  COUNTRY_CODE = 'COUNTRY_CODE',
  HOTLINE = 'HOTLINE',
  LATITUDE = 'LATITUDE',
  LONGITUDE = 'LONGITUDE',
  ACCESS_LATITUDE = 'ACCESS_LATITUDE',
  ACCESS_LONGITUDE = 'ACCESS_LONGITUDE',
  LOCATION = 'LOCATION',
  CHARGING_MODE = 'CHARGING_MODE',
  PLUGS = 'PLUGS',
  AUTH_MODES = 'AUTH_MODES',
  RELIABILITY_RATING = 'RELIABILITY_RATING',
  CAPPED_RATING = 'CAPPED_RATING',
  IMAGE_URI = 'IMAGE_URI',
  MANUFACTURER = 'MANUFACTURER',
  LAST_CHARGED = 'LAST_CHARGED',
  CHARGEPOINT_CLASS = 'CHARGEPOINT_CLASS',
  CHARGING_FACILITY = 'CHARGING_FACILITY',
  CHARGE_TIME_LIMIT = 'CHARGE_TIME_LIMIT',
  VISIBILITY = 'VISIBILITY',
  PROVIDES_LIVE_STATUS = 'PROVIDES_LIVE_STATUS',
  STATUS = 'STATUS',
  PLATFORM_ID = 'PLATFORM_ID',
  PLATFORM_COMPATIBLE = 'PLATFORM_COMPATIBLE',
  FREEBIE_CHARGING = 'FREEBIE_CHARGING',
  ADHOC_CAPABLE = 'ADHOC_CAPABLE',
  ACCESS = 'ACCESS',
  ADDITIONAL_INFORMATION = 'ADDITIONAL_INFORMATION',
  EN_ADDITIONAL_INFORMATION = 'EN_ADDITIONAL_INFORMATION',
  ADMIN_LAST_UPDATED_BY = 'ADMIN_LAST_UPDATED_BY',
  ADMIN_LAST_UPDATED_DATE = 'ADMIN_LAST_UPDATED_DATE',
  ADMIN_COMMENTS = 'ADMIN_COMMENTS',
  MAX_POWER = 'MAX_POWER',
  POWER_TYPE = 'POWER_TYPE',
  ID = 'ID',
  CHARGING_CATEGORY = 'CHARGING_CATEGORY'
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Operator} from '../../datatypes/operator';
import {Observable, of, Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {SharedDataService} from '../../services/shared-data.service';
import {FormUtils} from 'pcs-commons/utils';

@Component({
  selector: 'autocomplete-cpo',
  templateUrl: './autocomplete-cpo.component.html',
  styleUrls: ['./autocomplete-cpo.component.css']
})
export class AutocompleteCpoComponent implements OnInit, OnDestroy {

  @Input() public parentFormGroup: FormGroup;
  public cpoList: Operator[] = [];
  public filteredCpoList: Observable<Operator[]> = of([]);
  public cpo: FormControl;
  public subscription: Subscription;

  constructor(
    private dataSource: SharedDataService) {
  }

  public ngOnInit(): void {
    this.cpo = this.getFormControl();
    this.subscription = this.dataSource.getCurrentCpoListObservable().subscribe((nextCpoList) => {
      this.cpoList = nextCpoList;
      if (!this.cpo.value) {
        this.cpo.setValue('');
      }
      console.log('new cpoList', this.cpoList);
    });

    this.filteredCpoList = this.cpo.valueChanges.pipe(
      startWith(''),
      map((value) => {
        return value ? this._filterCpoList(value) : this.cpoList;
      })
    );
  }

  public getFormControl(): FormControl {
    const control = FormUtils.getFormControl(this.parentFormGroup, 'cpo');
    if (control instanceof FormControl) {
      return control;
    }
    return null;
  }

  public cpoDisplayText(cpo: Operator): string {
    if (!cpo) {
      return '';
    }
    return cpo.cpoCode ? cpo.cpoCode + ' [' + cpo.name + ']' : '[' + cpo.name + ']';
  }

  private _filterCpoList(value: any): Operator[] {
    console.log('filtering for value: ', value);
    const filterVal = String(value).toLowerCase();
    // check if cpoCode or cpoName contains the text
    return this.cpoList.filter((cpo) => this.cpoDisplayText(cpo).toLowerCase().includes(filterVal));
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data && country">
  <mat-table [dataSource]="tableDataSource">
    <div *ngFor="let col of dataColumns; let columnIndex = index">
      <ng-container *ngIf="col !== actionColumn" matColumnDef="{{col}}">
        <mat-header-cell priceTableCell [column]="col"
                         *matHeaderCellDef>{{getI18nBase(col) | translate}}</mat-header-cell>
        <mat-cell priceTableCell [column]="col" *matCellDef="let element">
          <div *ngIf="col===CHARGEPOINT_CLASS_COLUMN">{{'global.chargeClass.' + element[col] | translate}}</div>
          <div
            *ngIf="col!==CHARGEPOINT_CLASS_COLUMN">
            {{element | impureClassPriceColumnValue: col : country: taxDateTime}}
          </div>
        </mat-cell>
      </ng-container>
    </div>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
  </mat-table>
</div>

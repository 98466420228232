/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { AbstractNotificationHandler } from 'pcs-commons/notification';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ValidatorList } from 'pcs-commons/validation';
import { Action } from 'pcs-commons/datatypes';
import { UserGroup } from '../../../datatypes/user-group';
import { AccessRights } from '../../../datatypes/access-rights.enum';
import { FormUtils } from 'pcs-commons/utils';
import { UniqueGroupNameValidator } from '../../../validation/unique-group-name-validator.service';
import { Domain } from '../../../datatypes/domain';

export abstract class UserGroupFormManager extends AbstractNotificationHandler {
  public domainList = Domain.ALL_DOMAINS;

  public mode = Action.CREATE;
  public groupOnProcess: UserGroup;
  public selectedGroupName: string;

  public userGroupForm: FormGroup;
  public name: FormControl;
  public rights: Map<string, FormControl>;
  public users: FormControl;

  protected constructor(
    public formBuilder: FormBuilder,
    public uniqueGroupNameValidator: UniqueGroupNameValidator
  ) {
    super();
  }

  public defineFormGroup(): void {
    const nameValidatorList = [this.uniqueGroupNameValidator.validate.bind(this.uniqueGroupNameValidator)];
    nameValidatorList.push(...ValidatorList.USER_GROUP_NAME);
    this.name = new FormControl('', nameValidatorList);
    this.users = new FormControl([]);
    this.rights = new Map<string, FormControl>();
    // each tab will have it's own form array containing all rights
    this.domainList.forEach((domain) => {
      this.rights.set(domain.name, new FormControl());
    });

    this.userGroupForm = this.formBuilder.group({
      name: this.name,
      users: this.users
    });
    this.rights.forEach((fc, domainName) => this.userGroupForm.addControl(domainName, fc));
  }

  public updateFormValuesForGroupData(): void {
    if (this.isReadOrEditMode() && this.groupOnProcess) {
      this.name.setValue(this.groupOnProcess.name);
      this.users.setValue(this.groupOnProcess.users);
      this.setUserRights();
    }
    if (Action.READ === this.mode) {
      this.disableAllFromControls();
    }
  }

  private setUserRights(): void {
    console.log('setting values for rights set to the group. the group has the following rights: ', this.groupOnProcess.rights);
    this.domainList.forEach((domain) => {
      console.log('checking for domain: ', domain.name);
      let tabsPermission = null;
      if (this.groupOnProcess.rights.includes(AccessRights.ADMIN_WEB)) {
        tabsPermission = domain.editPermission || domain.readPermission;
      } else if (this.groupOnProcess.rights.includes(domain.editPermission)) {
        tabsPermission = domain.editPermission;
      } else if (this.groupOnProcess.rights.includes(domain.readPermission)) {
        tabsPermission = domain.readPermission;
      }
      console.log('setting tab permission to: ', tabsPermission);
      this.userGroupForm.controls[domain.name].setValue(tabsPermission);
    });
  }

  public prepareUserGroupData(): UserGroup {
    if (!this.groupOnProcess) {
      this.groupOnProcess = {} as UserGroup;
    }
    this.groupOnProcess.name = FormUtils.getStringValue(this.userGroupForm, 'name');
    const allSelectedRights: string[] = [];
    this.domainList.forEach((tab) => {
      const tabPermission = FormUtils.getValue(this.userGroupForm, tab.name);
      if (tabPermission) {
        allSelectedRights.push(tabPermission);
      }
    });
    this.groupOnProcess.rights = allSelectedRights;
    console.log('prepared user group data: ', this.groupOnProcess);
    return this.groupOnProcess;
  }

  public isReadOrEditMode(): boolean {
    return Action.EDIT === this.mode || Action.READ === this.mode;
  }

  private disableAllFromControls(): void {
    console.log('disabling form');
    this.userGroupForm.disable();
  }
}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HeaderEnabled } from './header-enabled.abstract';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';
import { Parameters } from '../../global/parameters';

@Injectable()
export class KeyTagImportService extends HeaderEnabled {

  constructor(private readonly http: HttpClient) {
    super();
  }

  public importKeyTagFile(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file);
    const url = environment.keyTagUrl + '/upload';
    const headers = new HttpHeaders({
      'Accept': 'application/json'
    });
    return this.http.post<any>(
      url,
      formData,
      { headers }
    ).pipe(
      timeout(Parameters.KEY_TAG_IMPORT_TIMEOUT_MILLIS)
    );
  }
}

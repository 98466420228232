/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { AttributeRow } from '../../data-table/attribute-table/attribute-row';
import { LocationAdminDto } from '../../datatypes/location/location-admin-dto';
import { AttributeTableAction } from '../../datatypes/attribute-table-action.enum';
import { FormBuilder } from '@angular/forms';
import { Utils } from '../../utils/utils';
import { LocationAndChargepointAdminAttributeDto } from '../../datatypes/location/location-and-chargepoint-admin-attribute-dto';
import { FormHelper } from '../../utils/form-helper';
import { TableConfigurations } from '../../data-table/table-configurations';
import { ChargepointDtoAttributeName } from '../../datatypes/location/chargepoint/chargepoint-attribute-name.enum';
import { ChargepointAdminDto } from '../../datatypes/location/chargepoint/chargepoint-admin-dto';
import { ChargepointAdminAttributeDto } from '../../datatypes/location/chargepoint/chargepoint-admin-attribute-dto';
import { AttributeTableBaseComponent } from '../../data-table/attribute-table/attribute-table-base.component';
import { AuthenticationMode } from '../../datatypes/authentication-mode.enum';
import { AccessibilityType } from '../../datatypes/accessibility-type.enum';
import { TranslationHelperService } from 'pcs-commons/http';
import { ChargingPlug } from '../../datatypes/charging-plug.enum';
import { ChargepointClassNames } from '../../datatypes/chargepoint-class-names.enum';
import { WeekDays } from '../../datatypes/week-days.enum';
import { InputValidationService } from '../../services/input-validation.service';
import { AttributeTableEventService } from '../../services/attribute-table-event.service';
import { MatTableDataSource } from '@angular/material/table';
import { AttributeTranslationHelperService } from '../../services/attribute-translation-helper.service';
import { LocationService } from '../../services/http/location.service';
import { MatDialog } from '@angular/material/dialog';
import { ChargepointRawDataDialogComponent } from './chargepoint-raw-data-dialog/chargepoint-raw-data-dialog.component';
import { ChargepointUpdateData } from '../../datatypes/location/chargepoint/chargepoint-update-data';
import { ChargingCategoryWithoutPowertype } from '../../datatypes/charging-category-without-powertype';

@Component({
  selector: 'pcs-chargepoint-details',
  templateUrl: './chargepoint-details.component.html',
  styleUrls: ['./chargepoint-details.component.css']
})
export class ChargepointDetailsComponent extends AttributeTableBaseComponent implements OnChanges {
  @Input() public chargepoint: ChargepointAdminDto;
  @Input() public chargepointGroupId: number;

  // read only
  public readonly visibilityAttribute = ChargepointDtoAttributeName.VISIBILITY;
  public readonly authModesAttribute = ChargepointDtoAttributeName.AUTH_MODES;
  public readonly accessAttribute = ChargepointDtoAttributeName.ACCESS;
  public readonly cpClassAttribute = ChargepointDtoAttributeName.CHARGEPOINT_CLASS;
  public readonly plugsAttribute = ChargepointDtoAttributeName.PLUGS;
  public readonly freeChargingAttribute = ChargepointDtoAttributeName.FREEBIE_CHARGING;
  public readonly adhocCapableAttribute = ChargepointDtoAttributeName.ADHOC_CAPABLE;
  public readonly reliabilityRating = ChargepointDtoAttributeName.RELIABILITY_RATING;
  public readonly cappedRating = ChargepointDtoAttributeName.CAPPED_RATING;
  public readonly yesNoChoiceList: string[] = ['', 'TRUE', 'FALSE'];
  public readonly chargingCategoryAttribute = ChargepointDtoAttributeName.CHARGING_CATEGORY;
  public readonly hours = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12',
    '13',
    '14',
    '15',
    '16',
    '17',
    '18',
    '19',
    '20',
    '21',
    '22',
    '23'
  ];
  public readonly costSensitiveAttributes: string[] = [
    ChargepointDtoAttributeName.CHARGEPOINT_CLASS,
    ChargepointDtoAttributeName.FREEBIE_CHARGING,
    ChargepointDtoAttributeName.CHARGING_CATEGORY
  ];

  public now = new Date();
  public days: string[] = Object.values(WeekDays);
  public authModeMultiChoiceList: string[] = [];
  public plugsMultiChoiceList: string[] = [];
  public accessChoiceList: string[] = [];
  public cpClassChoiceList: string[] = [];
  public chargingCategoryChoiceList: string[] = [];

  public chartData: any[] = [];

  public hourlyDistributionMax = 0.0;

  public colorScheme = {
    domain: ['#42A5F5']
  };
  private loading = false;

  public cpIdRow: AttributeRow;

  constructor(
    renderer: Renderer2,
    private tableEventSource: AttributeTableEventService,
    private locationService: LocationService,
    inputValidationService: InputValidationService,
    attrTranslateService: AttributeTranslationHelperService,
    translateService: TranslationHelperService,
    formBuilder: FormBuilder,
    private dialog: MatDialog
  ) {
    super(renderer, inputValidationService, attrTranslateService, translateService, formBuilder);
    this.authModeMultiChoiceList = Object.values(AuthenticationMode);
    this.plugsMultiChoiceList = Object.values(ChargingPlug);
    this.cpClassChoiceList = Object.values(ChargepointClassNames);
    this.cpClassChoiceList.unshift('');
    this.accessChoiceList = Object.values(AccessibilityType);
    this.accessChoiceList.unshift('');
    this.chargingCategoryChoiceList = Object.values(ChargingCategoryWithoutPowertype);
    this.chargingCategoryChoiceList.unshift('');
    this.subscribeTabHeaderCommand();
  }

  public async onSave(): Promise<void> {
    let containsCostSensitiveAttributeChanges = false;
    const manualDataDto = new LocationAndChargepointAdminAttributeDto();
    manualDataDto.chargepointAttributes = [];
    const chargepointAttributes: ChargepointAdminAttributeDto[] = [];
    manualDataDto.chargepointLastUpdated = this.chargepoint.lastUpdated;
    Object.keys(this.manualDataFromGroup.controls).forEach((attribute) => {
      const value = this.getFormattedAttributeValue(attribute);
      const attributeDto = new ChargepointAdminAttributeDto();
      attributeDto.chargepointGroupId = this.chargepointGroupId;
      attributeDto.name = attribute;
      attributeDto.value = value;
      console.log('adding attribute as manual data: ', attributeDto);
      chargepointAttributes.push(attributeDto);
      if (!containsCostSensitiveAttributeChanges) {
        containsCostSensitiveAttributeChanges = this.costSensitiveAttributeChanges(attribute, value);
        console.log('containsCostSensitiveAttributeChanges', containsCostSensitiveAttributeChanges);
      }
    });
    Utils.sortArrayByStringProperty(chargepointAttributes, 'name');
    if (!containsCostSensitiveAttributeChanges) {
      manualDataDto.chargepointAttributes = this.deleteCostSensitiveAttributes(chargepointAttributes);
    } else {
      manualDataDto.chargepointAttributes = chargepointAttributes;
    }
    const chargepointUpdateData = this.generateChargepointUpdateData(manualDataDto, containsCostSensitiveAttributeChanges);
    this.editElement(chargepointUpdateData);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.chargepoint && changes.chargepoint.currentValue) {
      this.setChargepointData(changes.chargepoint.currentValue);
      this.resetDataColumns(changes.chargepoint.currentValue.attributes, this.inEditMode);
      this.switchDay(changes.chargepoint.currentValue, this.now.getDay());
    }
  }

  public switchDay(chargepoint: ChargepointAdminDto, day: number): void {
    this.hourlyDistributionMax = 0.0;
    this.hours.forEach((x) => {
      const distribution = this.chargepoint.occupancyDistribution
        ? this.chargepoint.occupancyDistribution.weekdays[day].hourlyDistribution[x]
        : 0;
      if (distribution && distribution > this.hourlyDistributionMax) {
        this.hourlyDistributionMax = distribution;
      }
      this.chartData.push({
        name: x,
        value: distribution,
        extra: {
          day: this.translateService.instant('message.day.' + this.days[day])
        }
      });
    });
    this.hourlyDistributionMax = Math.round(this.hourlyDistributionMax * 1000) / 1000;
  }

  public showPlainText(element: AttributeRow, col: string): boolean {
    return (
      col === this.attributeNameColumn ||
      (!this.elementOnEdit(element, col) && element.name !== this.reliabilityRating && element.name !== this.cappedRating)
    );
  }

  public shouldShowInputField(element: AttributeRow): boolean {
    return (
      element.name !== this.visibilityAttribute &&
      element.name !== this.authModesAttribute &&
      element.name !== this.accessAttribute &&
      element.name !== this.plugsAttribute &&
      element.name !== this.freeChargingAttribute &&
      element.name !== this.adhocCapableAttribute &&
      element.name !== this.cpClassAttribute &&
      element.name !== this.chargingCategoryAttribute
    );
  }

  private generateOriginalValue(): LocationAndChargepointAdminAttributeDto {
    const chargepointAttributes = [];
    Object.keys(this.chargepoint.attributes).forEach((attribute) => {
      if (TableConfigurations.editableChargepointAttributes.includes(attribute)) {
        const attributeDto = new ChargepointAdminAttributeDto();
        attributeDto.chargepointGroupId = this.chargepointGroupId;
        attributeDto.name = attribute;
        attributeDto.value = this.determineOriginalAttributeValue(attribute);
        console.log('adding attribute as original data: ', attributeDto);
        chargepointAttributes.push(attributeDto);
      }
    });
    Utils.sortArrayByStringProperty(chargepointAttributes, 'name');
    const originalValue = new LocationAndChargepointAdminAttributeDto();
    originalValue.chargepointAttributes = chargepointAttributes;
    originalValue.chargepointLastUpdated = this.chargepoint.lastUpdated;
    return originalValue;
  }

  private determineOriginalAttributeValue(attribute: string): string {
    const platformValueMap = this.chargepoint?.attributes[attribute];
    if (platformValueMap[this.manualDataColumn]?.value) {
      return platformValueMap[this.manualDataColumn]?.value;
    } else if (this.platformsIncludedInData.length > 0) {
      return platformValueMap[this.platformsIncludedInData[0]]?.value;
    } else {
      return platformValueMap[this.manualDataColumn]?.value;
    }
  }

  private getFormattedAttributeValue(attribute: string): string {
    let value: string;
    if (AttributeTableBaseComponent.multipleValueColumns.includes(attribute)) {
      const authList = FormHelper.getValue(this.manualDataFromGroup, attribute);
      value = authList ? authList.join(';') : '';
    } else {
      value = FormHelper.getStringValue(this.manualDataFromGroup, attribute);
    }
    return value;
  }

  public enableDataEdit(): void {
    this.manualDataFromGroup.enable();
    this.currentCommand = AttributeTableAction.ENABLE_EDIT;
    this.resetDataColumns(this.chargepoint.attributes, true);
  }

  public onCancel(): void {
    this.currentCommand = undefined;
    if (this.chargepoint) {
      this.resetDataColumns(this.chargepoint.attributes, false);
    }
    this.manualDataFromGroup = this.formBuilder.group({});
  }

  public attributeEditable(element: AttributeRow): boolean {
    return TableConfigurations.editableChargepointAttributes.includes(element.name);
  }

  public retrieveRawData(platform: string): void {
    const cpId = this.getCellData(platform, this.cpIdRow);
    if (!cpId) {
      console.log('no chargepoint id');
      return;
    }
    this.loading = true;
    this.locationService.retrieveChargepointRawData(cpId).subscribe({
      next: (rawData) => {
        this.loading = false;
        console.log('cp raw data: ', rawData);
        this.showRawDataDialog(rawData, platform);
      },
      error: () => (this.loading = false)
    });
  }

  public costSensitiveAttributeChanges(attribute: string, newValue: string): boolean {
    if (!this.costSensitiveAttributes.includes(attribute)) {
      return false;
    }
    const platformValueMap = this.chargepoint?.attributes[attribute];
    const originalValue = platformValueMap[this.manualDataColumn]?.value;
    return !(Utils.isEmpty(originalValue) && Utils.isEmpty(newValue)) && originalValue !== newValue;
  }

  public isReliabilityRatingData(element: AttributeRow, col: string): boolean {
    return col !== this.attributeNameColumn && col !== this.manualDataColumn && element.name === this.reliabilityRating;
  }

  public isCappedRatingData(element: AttributeRow, col: string): boolean {
    return col !== this.attributeNameColumn && col !== this.manualDataColumn && element.name === this.cappedRating;
  }

  public deleteCostSensitiveAttributes(chargepointAttributes: ChargepointAdminAttributeDto[]): ChargepointAdminAttributeDto[] {
    return chargepointAttributes.filter((attribute) => !this.costSensitiveAttributes.includes(attribute.name));
  }

  private subscribeTabHeaderCommand(): void {
    this.toUnsubscribe.push(
      this.tableEventSource.getChargepointEditCommand().subscribe((command) => {
        const currCommand = command;
        if (!this.chargepoint || this.chargepoint.evseId !== currCommand.forEvseId) {
          return;
        }
        console.log(this.chargepoint.evseId, '- got new command: ', command);
        switch (currCommand.command) {
          case AttributeTableAction.ENABLE_EDIT:
            this.enableDataEdit();
            break;
          case AttributeTableAction.SAVE:
            this.onSave();
            break;
          case AttributeTableAction.CANCEL:
          default:
            this.onCancel();
        }
      })
    );
  }

  private setChargepointData(currentChargepoint: LocationAdminDto): void {
    console.log('Setting chargepoint Data', currentChargepoint);
    let allAttributeRows = AttributeRow.convert(currentChargepoint.attributes);
    TableConfigurations.cpAttributesToHide.forEach((attr) => this.extractRowsNotShownInTable(allAttributeRows, attr));
    this.commentRow = this.extractRowsNotShownInTable(allAttributeRows, ChargepointDtoAttributeName.ADMIN_COMMENTS);
    this.updatedByRow = this.extractRowsNotShownInTable(allAttributeRows, ChargepointDtoAttributeName.ADMIN_LAST_UPDATED_BY);
    this.lastUpdateDateRow = this.extractRowsNotShownInTable(
      allAttributeRows,
      ChargepointDtoAttributeName.ADMIN_LAST_UPDATED_DATE
    );
    this.cpIdRow = this.extractRowsNotShownInTable(allAttributeRows, ChargepointDtoAttributeName.ID);
    if (this.commentRow) {
      Utils.removeArrayItem(allAttributeRows, this.commentRow);
    }
    allAttributeRows = Utils.sortRowByReferencedArray(allAttributeRows, Object.keys(ChargepointDtoAttributeName));
    this.tableDataSource = new MatTableDataSource(allAttributeRows);
  }

  private generateChargepointUpdateData(
    manualDataDto: LocationAndChargepointAdminAttributeDto,
    containsCostSensitiveAttributeChanges: boolean
  ): ChargepointUpdateData {
    const chargepointUpdateData = new ChargepointUpdateData();

    if (containsCostSensitiveAttributeChanges) {
      const originalValue = this.generateOriginalValue();
      const modifiedUpdatedValue = this.deleteNotUpdatedAttributes(manualDataDto);
      manualDataDto.chargepointAttributes = modifiedUpdatedValue;
      chargepointUpdateData.updatedValue = manualDataDto;
      const modifiedFieldNames = modifiedUpdatedValue.map((attr) => attr.name);
      const originalValuesWithoutModifiedFields = originalValue.chargepointAttributes.filter((attr) =>
        modifiedFieldNames.includes(attr.name)
      );
      chargepointUpdateData.originalValue = {
        ...originalValue,
        chargepointAttributes: originalValuesWithoutModifiedFields
      };
    } else {
      chargepointUpdateData.originalValue = null;
      chargepointUpdateData.updatedValue = manualDataDto;
    }
    chargepointUpdateData.chargepointGroupId = this.chargepointGroupId;
    chargepointUpdateData.evseid = this.chargepoint.evseId;
    chargepointUpdateData.containsCostSensitiveAttributeChanges = containsCostSensitiveAttributeChanges;
    return chargepointUpdateData;
  }

  private showRawDataDialog(rawData: string, platform: string): void {
    this.dialog.open(ChargepointRawDataDialogComponent, {
      width: '850px',
      data: {
        evseId: this.chargepoint.evseId,
        platform: this.translateService.instant('chargepoint.tableHeader.' + platform),
        rawData
      }
    });
  }

  private deleteNotUpdatedAttributes(totalUpdatedValue: LocationAndChargepointAdminAttributeDto): ChargepointAdminAttributeDto[] {
    const modifiedFields = totalUpdatedValue.chargepointAttributes.filter((attr) => {
      const platformValueMap = this.chargepoint?.attributes[attr.name];
      const originalValue = platformValueMap[this.manualDataColumn]?.value;
      return !(Utils.isEmpty(originalValue) && Utils.isEmpty(attr.value)) && originalValue !== attr.value;
    });
    return modifiedFields;
  }
}

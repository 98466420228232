/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { AttributeValueDto } from '../../datatypes/location/attribute-value-dto';

export class AttributeRow {
  public static readonly ATTRIBUTE_NAME = 'name';
  public name: string;
  public values: Map<string, AttributeValueDto>;

  public static convert(attributes: Map<string, Map<string, AttributeValueDto>>): AttributeRow[] {
    const attributeRows: AttributeRow[] = [];
    Object.keys(attributes).forEach((attributeName) => {
      const values = attributes[attributeName];
      const attribute = new AttributeRow();
      attribute.name = attributeName;
      attribute.values = values;
      attributeRows.push(attribute);
    });
    return attributeRows;
  }

  public toString(): string {
    return this.name;
  }
}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { FormControl, UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { Parameters } from 'pcs-commons/global';
import { CountryUtil } from 'pcs-commons/utils';
import { CountryCode, LanguageCode } from 'pcs-commons/datatypes';

export class ValidatorList {
  public static LOGIN = [
    Validators.required,
    Validators.email,
    Validators.minLength(Parameters.LOGIN_MIN_LENGTH),
    Validators.maxLength(Parameters.LOGIN_MAX_LENGTH),
    noUppercase
  ];
  public static PASSWORD = [Validators.minLength(Parameters.PASS_MIN_LENGTH), Validators.maxLength(Parameters.PASS_MAX_LENGTH)];
  public static PHONE_WITH_COUNTRY_CODE = [
    Validators.required,
    Validators.pattern(Parameters.INTERNATIONAL_PHONE_PATTERN),
    Validators.minLength(Parameters.PHONE_MIN_LENGTH),
    Validators.maxLength(Parameters.PHONE_MAX_LENGTH)
  ];
  public static FLEETMANAGER_PHONE_WITH_COUNTRY_CODE = [
    Validators.pattern(Parameters.INTERNATIONAL_PHONE_PATTERN),
    Validators.minLength(Parameters.PHONE_MIN_LENGTH),
    Validators.maxLength(Parameters.PHONE_MAX_LENGTH)
  ];

  public static FILTER_EVSEID = [Validators.maxLength(Parameters.DEFAULT_MAX_LENGTH)];
  public static FILTER_ADDRESS = [Validators.maxLength(Parameters.ADDRESS_FILTER_MAX_LENGTH)];

  public static STRING_DEFAULT = [Validators.maxLength(Parameters.DEFAULT_MAX_LENGTH)];

  public static PARTNER_ID = [Validators.required, Validators.maxLength(3)];

  public static PARTNER_TOKEN = [Validators.required, Validators.maxLength(64)];

  public static PARTNER_URL = [Validators.required, validateURL];
  public static PRODUCT_NAME = [Validators.required, Validators.maxLength(100)];
  public static PRODUCT_TERM = [Validators.pattern(Parameters.NUMERIC_PATTERN), Validators.min(1), Validators.max(1200)];

  /* user group */
  public static USER_GROUP_NAME = [Validators.required, Validators.maxLength(Parameters.GROUP_NAME_MAX_LENGTH)];

  /* taxes */
  public static TAX_RATE = [Validators.required, Validators.maxLength(Parameters.TAX_RATE_MAX_LENGTH), validateTaxRate];

  /* price */
  public static PRICE = [Validators.required, validatePrice];

  /* price optional */
  public static PRICE_OPTIONAL = [validatePrice];
  /* database query*/
  public static QUERY_NAME = [Validators.required, Validators.maxLength(50)];

  public static POSITIVE_INTEGER_OPTIONAL = [validatePositiveInteger];

  public static NOT_NEGATIVE_INTEGER_OPTIONAL = [validateNotNegativeInteger];

  public static POSITIVE_DECIMAL_OPTIONAL = [validatePositiveDecimal];

  public static POSITIVE_DECIMAL_REQUIRED = [Validators.required, Validators.maxLength(10), validatePositiveDecimal];
  public static ALPHA2_COUNTRY = [CountryUtil.validateCountryByAlpha2];
  public static ALPHA2_COUNTRY_REQUIRED = [Validators.required, CountryUtil.validateCountryByAlpha2];

  public static PRODUCT_QUOTA = [Validators.required, Validators.min(1), Validators.max(10000), validatePositiveInteger];

  public static STATISTIC_CHUNK_SIZE = [Validators.required, Validators.min(10), Validators.max(25000), validatePositiveInteger];

  public static NO_CARD = [];
  public static CARD_UID = [];
  public static PRODUCT_CODE = [Validators.required];

  public static VIN = [Validators.minLength(Parameters.VIN_MIN_LENGTH), Validators.maxLength(Parameters.VIN_MAX_LENGTH)];

  public static COUNTRY = [Validators.required];
  public static VALID_UNTIL = [Validators.required];
  public static VALID_FROM = [Validators.required];
  public static FROM_DATE = [Validators.required];
  public static TO_DATE = [Validators.required];

  /* contract custom field */
  public static CUSTOM_FIELD_NAME = [Validators.required, Validators.maxLength(Parameters.CUSTOM_FIELD_NAME_MAX_LENGTH)];
  public static CUSTOM_FIELD_DATA = [Validators.maxLength(Parameters.CUSTOM_FIELD_DATA_MAX_LENGTH)];

  public static FLEET_ID = [Validators.required, Validators.minLength(3), Validators.maxLength(18), validateNoWhitespace];

  /* fleet address attributes */
  public static ADDRESS_DEFAULT = [Validators.maxLength(100)];
  public static ADDRESS_TITLE = [Validators.maxLength(50)];
  public static ADDRESS_LANGUAGE = [Validators.required, validateLanguageCode];
  public static ADDRESS_COUNTRY = [Validators.required, validateCountryByAlpha2];
  public static ADDRESS_SUPPLEMENT = [Validators.maxLength(300)];
  public static ADDRESS_EMAIL = [Validators.required, Validators.email, Validators.maxLength(100)];
  public static ADDRESS_DEFAULT_REQUIRED = [Validators.required, Validators.maxLength(100)];
  public static ADDRESS_EMAIL_NOT_REQUIRED = [Validators.email, Validators.maxLength(100)];
}

function noUppercase(c: FormControl<string>): ValidationErrors | null {
  const isValid = !Parameters.LOGIN_FILTER_UPPERCASE.test(c.value);
  console.log('checking value: %s for pattern %s. Valid = %s', c.value, Parameters.LOGIN_FILTER_UPPERCASE, isValid);
  return isValid
    ? null
    : {
      noUppercase: {
        valid: false
      }
    };
}

function validateURL(c: FormControl<string>): ValidationErrors | null {
  const urlRegex = RegExp('(http|https):\\/\\/[a-zA-Z0-9\\-\\.\\:]+(\\/\\S*)?');
  const isValid = urlRegex.test(c.value);
  return isValid
    ? null
    : {
      url: {
        valid: false
      }
    };
}

function validateTaxRate(c: FormControl<string>): ValidationErrors | null {
  const taxRateRegex = RegExp('(^[0-9]{1,2}(\\.[0-9]{0,2})?$)|(^(\\.[0-9]{0,2})$)');
  const isValid = taxRateRegex.test(c.value);
  return isValid
    ? null
    : {
      taxRate: {
        valid: false
      }
    };
}

function validatePrice(c: FormControl<string>): ValidationErrors | null {
  const priceRegex = RegExp('^[+]?\\d*\\.?\\d*$');
  if (!c.value) {
    return null;
  }
  const isValid = priceRegex.test(c.value);
  return isValid
    ? null
    : {
      price: {
        valid: false
      }
    };
}

function validatePositiveInteger(c: UntypedFormControl): ValidationErrors | null {
  const positiveInteger = RegExp('^[1-9]\\d*$');
  return validateInteger(c, positiveInteger);
}

function validateNotNegativeInteger(c: UntypedFormControl): ValidationErrors | null {
  const positiveInteger = RegExp('^[0-9]\\d*$');
  return validateInteger(c, positiveInteger);
}

function validateInteger(c: UntypedFormControl, pattern: RegExp): ValidationErrors | null {
  if (!c.value) {
    return null;
  }
  const isValid = pattern.test(c.value);
  return isValid
    ? null
    : {
      positiveInteger: {
        valid: false
      }
    };
}

function validatePositiveDecimal(c: UntypedFormControl): ValidationErrors | null {
  const positiveDecimal = RegExp('^[+]?(\\d*\\.)?\\d+$');
  if (!c.value) {
    return null;
  }
  const isValid = positiveDecimal.test(c.value);
  return isValid
    ? null
    : {
      positiveDecimal: {
        valid: false
      }
    };
}

function validateCountryByAlpha2(c: FormControl<string>): ValidationErrors | null {
  if (!c.value) {
    return null;
  }
  console.log('validating country for value: ', c.value);
  const countries: string[] = Object.values(CountryCode);
  return countries.includes(c.value)
    ? null
    : {
      validCountry: {
        valid: false
      }
    };
}

function validateLanguageCode(c: FormControl<string>): ValidationErrors | null {
  if (!c.value) {
    return null;
  }
  console.log('validating language code for value: ', c.value);
  const countries: string[] = Object.values(LanguageCode);
  return countries.includes(c.value)
    ? null
    : {
      validCountry: {
        valid: false
      }
    };
}

function validateNoWhitespace(c: FormControl<string>): ValidationErrors | null {
  if (!c.value) {
    return null;
  }
  return c.value.indexOf(' ') < 0
    ? null
    : {
      whitespace: {
        valid: false
      }
    };
}

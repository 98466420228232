/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SharedDataService } from '../../../services/shared-data.service';
import { Product } from '../../../datatypes/product';
import { TaxData } from '../../../datatypes/taxdata';
import { TaxRecord } from '../../../datatypes/taxrecord';
import { Utils } from '../../../utils/utils';
import { BasicFeeRecord, BasicFeeTableColumns } from '../../../datatypes/basicfee-record';
import { EditBasicFeeFormManager } from './edit-basic-fee-form.manager';
import { combineLatest, Observable } from 'rxjs';
import { DateRangeValidator2Service } from '../../../validation/date-range-validator2.service';
import { PriceUtils } from '../../../utils/price-utils';
import { FormUtils } from 'pcs-commons/utils';
import { ValidationMessagePipe, ValidatorList } from 'pcs-commons/validation';

@Component({
  selector: 'pcs-edit-basic-fee',
  templateUrl: './edit-basic-fee.component.html',
  styleUrls: ['../../../dialog/dialog-common-styles.css', './edit-basic-fee.component.css']
})
export class EditBasicFeeComponent extends EditBasicFeeFormManager implements OnInit {
  public loading = false;

  public readonly basicFeeEditorTableColumns = [
    BasicFeeTableColumns.VALID_FROM_DATE,
    BasicFeeTableColumns.VALID_FROM_TIME,
    BasicFeeTableColumns.VALID_UNTIL_DATE,
    BasicFeeTableColumns.VALID_UNTIL_TIME,
    BasicFeeTableColumns.TAX,
    BasicFeeTableColumns.BASIC_FEE,
    BasicFeeTableColumns.BASIC_FEE_NETTO,
    BasicFeeTableColumns.DEFAULT_PRICE_MIN,
    BasicFeeTableColumns.DEFAULT_PRICE_MIN_NETTO,
    BasicFeeTableColumns.DEFAULT_PRICE_KWH,
    BasicFeeTableColumns.DEFAULT_PRICE_KWH_NETTO
  ];

  constructor(
    formBuilder: FormBuilder,
    dateRangeValidator2Service: DateRangeValidator2Service,
    private dialogRef: MatDialogRef<EditBasicFeeComponent>,
    private validationPipe: ValidationMessagePipe,
    private translate: TranslateService,
    private dataSource: SharedDataService
  ) {
    super(dateRangeValidator2Service, formBuilder);
  }

  public ngOnInit(): void {
    this.fetchDataAndDefineForms();
  }

  public defineBaseInfoForm(): void {
    this.country = new FormControl('', ValidatorList.ALPHA2_COUNTRY_REQUIRED);
    this.currency = new FormControl('');

    this.basicFeeInfoForm = this.formBuilder.group({
      country: this.country,
      currency: this.currency
    });
  }

  public fetchDataAndDefineForms(): void {
    this.loading = true;
    const observables = [this.dataSource.productToEdit$, this.dataSource.currentTaxData$, this.dataSource.basicFeeToEdit$];
    this.dataSourceSubscription = combineLatest(observables).subscribe({
      next: (data) => {
        this.currentProduct = data[0] as Product;
        this.taxData = data[1] as TaxData;
        this.basicFeeOnProcess = data[2] as BasicFeeRecord;

        this.fetchAllBasicFeeConfigsForCountry();
        this.defineBaseInfoForm();
        this.defineBasicFeeConfigsForms();
        this.registerValueChangeListeners();

        // retrieve information from Tax Service
        this.countries = this.getCountryList();
        this.currencies = this.getCurrencyList();
        if (this.basicFeeOnProcess) {
          this.updateFormValues();
        }
        this.loading = false;
      },
      error: () => (this.loading = false)
    });
  }

  public isSaveAllowed(): boolean {
    const hasBasicFees = this.currentBasicFeeForms.getValue().length > 0;
    return (
      (!this.basicFeeInfoForm.pristine || !this.basicFeeParentForm.pristine) &&
      this.basicFeeInfoForm.valid &&
      this.basicFeeParentForm.valid &&
      hasBasicFees
    );
  }

  public updateFormValues(): void {
    if (this.basicFeeOnProcess) {
      this.country.setValue(this.basicFeeOnProcess.country);
      this.currency.setValue(this.basicFeeOnProcess.currency);
    }
  }

  public updateVat(country: string): void {
    // for all basicFeeConfig form, check it's validFrom/validUntil value, and set the vat accordingly
    const basicFeeConfigForms = this.currentBasicFeeForms.getValue();
    basicFeeConfigForms.forEach((bForm) => {
      let taxDateTime = FormUtils.getValue(bForm, BasicFeeTableColumns.VALID_FROM);
      if (!taxDateTime) {
        taxDateTime = FormUtils.getValue(bForm, BasicFeeTableColumns.VALID_UNTIL);
      }
      const vat = PriceUtils.getTaxForTime(country, this.taxData, taxDateTime);
      bForm.controls[BasicFeeTableColumns.TAX].setValue(vat * 100);
    });
  }

  public onSave(): void {
    if (this.basicFeeInfoForm.pristine && this.basicFeeParentForm.pristine) {
      this.onOk();
      return;
    }

    const currCountry = this.country.value;
    const newBasicFeesForCountry: BasicFeeRecord[] = [];
    Object.values(this.basicFeeParentForm.controls).forEach((bFeeForm) => {
      if (bFeeForm instanceof FormGroup) {
        const bFee = this.prepareBasicFee(bFeeForm);
        newBasicFeesForCountry.push(bFee);
      }
    });
    console.log('new basic fees for country: ', currCountry, ' -> ', newBasicFeesForCountry);

    // get all basic fees of product and remove the basic fee for the country
    const filteredBasicFees = this.currentProduct.basicFees.filter((bFee) => bFee.country !== currCountry);
    filteredBasicFees.push(...newBasicFeesForCountry);

    this.currentProduct.basicFees = filteredBasicFees;

    // now detach the listeners of data source changes
    this.dataSourceSubscription.unsubscribe();
    this.dataSource.updateCurrentProductOnEdit(this.currentProduct);
    this.onOk();
  }

  private registerValueChangeListeners(): void {
    this.toUnsubscribe.push(
      this.country.valueChanges.subscribe((newCountry) => {
        // check there are existing fees for country
        this.checkAndUpdateFormsForExistingFees(newCountry);
        PriceUtils.updateCurrencyForCountry(newCountry, this.taxData, this.currency);
        this.updateVat(newCountry);
      })
    );
    // this.registerValueChangeListenerForVat();
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  public onOk(): void {
    this.dialogRef.close(true);
  }

  public getError(fc: FormControl): Observable<any> {
    return this.validationPipe.transform(fc);
  }

  public getCountryList(): string[] {
    const countries = [];
    let j: number;
    const taxes: TaxRecord[] = this.taxData.data;
    for (j = taxes.length - 1; j >= 0; j--) {
      Utils.addArrayItem(countries, taxes[j].country);
    }
    return countries;
  }

  public getCurrencyList(): string[] {
    const currencies = [];
    let j: number;
    const taxes: TaxRecord[] = this.taxData.data;
    for (j = taxes.length - 1; j >= 0; j--) {
      Utils.addArrayItem(currencies, taxes[j].currency);
    }
    return currencies;
  }

  private fetchAllBasicFeeConfigsForCountry(): void {
    if (!this.basicFeeOnProcess) {
      // it's a new basic fee config, nothing to fetch
      return;
    }
    this.currentBasicFees = this.currentProduct.basicFees.filter((value) => value.country === this.basicFeeOnProcess.country);
    console.log('all basic fee for country: ', this.basicFeeOnProcess.country, ' -> ', this.currentBasicFees);
  }

  public addNewBasicFee(): void {
    const basicInfoForms = this.currentBasicFeeForms.getValue();
    const newBFee = new BasicFeeRecord();
    newBFee.country = this.country.value;
    const newBasicFeeForm = this.defineFormGroupForBasicFee(newBFee);
    basicInfoForms.push(newBasicFeeForm);
    this.addToParentBasicFeeForm(true, basicInfoForms);
    this.updateBasicFeeForms(basicInfoForms);
    console.log('updated basic fee parent form: ', this.basicFeeParentForm);
  }

  public onRemoveBasicFee(toDelete: FormGroup): void {
    const basicFeeConfigForms = this.currentBasicFeeForms.getValue();
    const toDelIndex = basicFeeConfigForms.indexOf(toDelete, 0);
    console.log('FormGroup index to delete: ', toDelIndex);
    Utils.removeArrayItem(basicFeeConfigForms, toDelete);
    this.addToParentBasicFeeForm(true, basicFeeConfigForms);
    this.updateBasicFeeForms(basicFeeConfigForms);
    console.log('updated basic fee parent form: ', this.basicFeeParentForm);
  }

  private checkAndUpdateFormsForExistingFees(country: string): void {
    const existingFees: BasicFeeRecord[] = this.currentProduct.basicFees.filter((fee) => fee.country === country);
    if (!existingFees || existingFees.length < 1) {
      return;
    }
    this.currentBasicFees = existingFees;
    console.log('all basic fee for country: ', country, ' -> ', this.currentBasicFees);
    this.defineBasicFeeConfigsForms();
  }
}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './src/error/error.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IModuleOptions } from './i-module-options';

@NgModule({
  declarations: [ErrorComponent],
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [ErrorComponent]
})
export class ErrorManagementModule {
  public static forRoot(options?: IModuleOptions): ModuleWithProviders<ErrorManagementModule> {
    return {
      ngModule: ErrorManagementModule,
      providers: [{ provide: 'ERROR_MSG_BASE', useValue: options?.errorMsgBase }]
    };
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input} from '@angular/core';
import {AddressOverview} from 'pcs-commons/datatypes';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'fleet-address-overview',
  templateUrl: './fleet-address-overview.component.html',
  styleUrls: ['./fleet-address-overview.component.css']
})
export class FleetAddressOverviewComponent  {
  @Input() public address: AddressOverview;

  constructor() {
  }
}

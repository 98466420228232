/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {Statistics} from './statistics.enum';
import {firstValueFrom, Observable} from 'rxjs';
import {MetaDataDto} from './meta-data-dto';
import * as XLSX from 'xlsx';
import {WorkSheet} from 'xlsx';
import {WorkSheetDto} from './work-sheet-dto';
import {DateUtils, Utils} from 'pcs-commons/utils';
import {CellFormat} from './cell-format.enum';
import {ExcelService} from './excel.service';
import {StatisticService} from './statistic.service';
import {StatisticDownloadStatusService} from "./statistic-download-status.service";
import {InvoiceOverview} from "pcs-commons/datatypes";

@Injectable({
  providedIn: 'root'
})
export class StatisticDownloaderService {
  public readonly TIME_INDEPENDENT_QUERIES: string[] =
    [Statistics.ACTIVE_CONTRACTS, Statistics.CHARGEPOINT_STATISTIC, Statistics.ACTIVE_CONTRACTS_PER_COUNTRY];
  public readonly MONTHLY_QUERIES: string[] =
    [Statistics.ACTIVE_HUBJECT_CONTRACTS_PER_PRODUCT, Statistics.REGISTERED_IONITY_CONTRACTS_PER_MONTH];
  private readonly SETTINGS = 'Settings';
  private readonly BILLED_CDR_STATISTICS = 'billed-cdrs';
  private readonly MAX_COLUMN_WIDTH = 255; // chars
  private columnWidths = new Map<string, Map<string, number>>();
  private columns = new Map<string, MetaDataDto[]>();
  private currentPageSize: number;
  private currentQuery: string;
  private downloadFileName: string;
  private currentFromDate: Date;
  private currentToDate: Date;

  constructor(
    private statisticService: StatisticService,
    private excelService: ExcelService,
    public statusService: StatisticDownloadStatusService
  ) {
  }

  public get isTimeIndependentQuery(): boolean {
    return this.TIME_INDEPENDENT_QUERIES.includes(this.currentQuery);
  }

  private static parseValueToType(val: string, columnFormat: string): string | number | Date {
    if (Utils.isEmpty(val)) {
      return val;
    }
    switch (columnFormat) {
      case CellFormat.NUMBER:
        val = val.replace(/,/g, '.');
        return Number(val);
      case CellFormat.DATE:
        return DateUtils.convertToDateTimeWithUTC(val).toJSDate();
      case CellFormat.TIME:
      default:
        return val;
    }
  }

  public async getStatisticV2(pageSize: number, selectedQuery: string, toDate: Date, fromDate: Date): Promise<void> {
    this.statusService.start(selectedQuery);
    try {
      this.resetVariables(pageSize, selectedQuery, toDate, fromDate);

      const dataProvider = (currentPage: number): Observable<WorkSheetDto[]> => this.statisticService.getStatisticsV2(
        this.currentQuery,
        this.isTimeIndependentQuery ? null : this.currentFromDate,
        this.isTimeIndependentQuery ? null : this.currentToDate,
        currentPage * pageSize, pageSize + 1);

      await this.readRawDataAndConvertItToExcel(0, this.currentPageSize, dataProvider, new Map<string, XLSX.WorkSheet>());
    } finally {
      this.statusService.stop();
    }
  }

  public async getBilledCDRStatistic(fleetId: string, invoice: InvoiceOverview, pageSize: number): Promise<void> {
    this.resetVariables(pageSize, this.BILLED_CDR_STATISTICS, null, null);
    this.downloadFileName = this.getDownloadFileNameForBilledCDRStatistic(invoice, fleetId);
    console.time(this.currentQuery);

    const dataProvider = (currentPage: number): Observable<WorkSheetDto[]> => this.statisticService.getBilledCdrStatistics(fleetId, invoice.id,
      currentPage * pageSize, pageSize + 1);

    await this.readRawDataAndConvertItToExcel(0, pageSize, dataProvider, new Map<string, XLSX.WorkSheet>());
  }

  public updateColumnsWidths(data: Map<string, string>[], sheetName: string): void {
    let columnWidthsForSheet = this.columnWidths.get(sheetName);
    if (!columnWidthsForSheet) {
      columnWidthsForSheet = this.initializeColumnWidthsMap(sheetName);
    }
    for (const rowData of data) {
      Object.keys(rowData).forEach(
        (column) => {
          const currValWidth = String(rowData[column]).length;
          let currMaxWidth = columnWidthsForSheet.get(column);
          if (!currMaxWidth || currMaxWidth < currValWidth) {
            currMaxWidth = currValWidth > this.MAX_COLUMN_WIDTH ? this.MAX_COLUMN_WIDTH : currValWidth;
            columnWidthsForSheet.set(column, currMaxWidth);
          }
        }
      );
    }
    this.columnWidths.set(sheetName, columnWidthsForSheet);
  }

  private getDownloadFileNameForBilledCDRStatistic(invoice: InvoiceOverview, fleetId: string): string {
    return invoice.invoiceDateTime.toFormat('yyyy_LL') + '_' + fleetId + '_' + invoice.country + '_' + invoice.invoiceNumber;
  }

  private resetVariables(pageSize: number, selectedQuery: string, toDate: Date, fromDate: Date): void {
    this.currentPageSize = pageSize;
    this.currentQuery = selectedQuery;
    this.downloadFileName = selectedQuery;
    this.currentToDate = toDate;
    this.currentFromDate = fromDate;
    this.columnWidths.clear();
    this.columns.clear();
  }

  private async readRawDataAndConvertItToExcel(currPage: number, pageSize: number, observableToCall: (currentPage: number) => Observable<WorkSheetDto[]>, worksheets: Map<string, WorkSheet>): Promise<void> {
    let fetchMore: boolean;
    do {
      fetchMore = false;
      console.log(`fetching data for ${this.currentQuery}. Current page: ${currPage}, chunk size: ${pageSize}`);
      const workSheetDtos = await firstValueFrom(observableToCall(currPage));

      workSheetDtos.forEach((wsDto) => {
        console.log('processing data for sheet: ', wsDto);
        const sheetHasMore = this.appendDataToWorksheet(
          wsDto, pageSize, worksheets
        );

        if (Statistics.SESSION_STATISTIC === this.currentQuery) {
          fetchMore = fetchMore || wsDto.name.toLowerCase() !== this.SETTINGS.toLowerCase() && wsDto.data.length > 0;
        } else if (Statistics.ACTIVE_HUBJECT_CONTRACTS_PER_PRODUCT !== this.currentQuery) {
          fetchMore = fetchMore || sheetHasMore;
        }
      });

      this.statusService.tick();
      currPage++;
    } while (fetchMore)

    this.storeWorkSheets(worksheets);
  }

  private appendDataToWorksheet(wsDto: WorkSheetDto, pageSize: number, worksheets: Map<string, XLSX.WorkSheet>): boolean {
    const sheetName = wsDto.name.length > 31 ? wsDto.name.substring(0, 31) : wsDto.name;
    const sheetData = wsDto.data;

    const sheetHasMore = sheetData.length > pageSize;
    // if sheet has more data than page size, we remove the last entry as we originally asked for one extra data
    if (sheetHasMore) {
      sheetData.pop();
    }

    let worksheet: XLSX.WorkSheet = worksheets.get(sheetName);
    if (!worksheet) {
      console.log('creating new worksheet: ', sheetName);
      const columns: MetaDataDto[] = wsDto.metaData
        .sort((a, b) => a.ordinal - b.ordinal);
      this.columns.set(sheetName, columns);
      console.log('column meta data: ', wsDto.metaData, ' sorted columns: ', columns);
      worksheet = XLSX.utils.json_to_sheet([], {header: columns.map((cData) => cData.columnName)});
      worksheets.set(sheetName, worksheet);
    }

    this.updateColumnsWidths(sheetData, sheetName);
    this.parseDataAccordingToColumnType(sheetData, this.columns.get(sheetName));
    console.log('adding ', sheetData.length, ' data to sheet ', sheetName);
    XLSX.utils.sheet_add_json(worksheet, sheetData, {origin: -1, skipHeader: true, cellDates: true});
    return sheetHasMore;
  }

  private storeWorkSheets(worksheets: Map<string, XLSX.WorkSheet>): void {
    let fileName = this.downloadFileName;
    let addDateToFileName = true;
    if (this.BILLED_CDR_STATISTICS === this.currentQuery) {
      addDateToFileName = false;
    } else if (this.MONTHLY_QUERIES.includes(this.currentQuery)) {
      const monthAndYear = this.currentFromDate.toLocaleString('en-US', { month: 'long', year: 'numeric' });
      fileName = this.downloadFileName + '-' + monthAndYear;
      addDateToFileName = false;
    }
    console.log('finished fetching data! Storing in excel file: ', fileName);
    console.log('columns widths: ', this.columnWidths);
    console.log('worksheets: ', worksheets);
    this.setColumnsWidths(worksheets);
    this.excelService.exportSheetsToExcel(worksheets, fileName, addDateToFileName);
  }

  private initializeColumnWidthsMap(sheetName: string): Map<string, number> {
    const columnWidthsForSheet = new Map<string, number>();
    // calculate the header widths
    const columns = this.columns.get(sheetName);
    columns.forEach(
      (columnData, index) =>
        columnWidthsForSheet.set(index.toString(), columnData.columnName.length)
    );
    return columnWidthsForSheet;
  }

  private setColumnsWidths(worksheets: Map<string, XLSX.WorkSheet>): void {
    worksheets.forEach(
      (sheet, name) => {
        if (!sheet) {
          console.log('no worksheet prepared for sheet: ', name);
          return;
        }
        console.log('setting columns width for sheet: ', name);
        const wsCols = [];
        const colWidthsForSheet = this.columnWidths.get(name);
        if (colWidthsForSheet) {
          colWidthsForSheet.forEach((width) => wsCols.push({wch: width}));
          sheet['!cols'] = wsCols;
        }
      }
    );
  }

  private parseDataAccordingToColumnType(sheetData: Map<string, string>[], columnMetaData: MetaDataDto[]): void {
    sheetData.forEach((row) => {
      columnMetaData.forEach((cData) => {
        if (CellFormat.STRING === cData.columnFormat) {
          return;
        }
        const val = row[cData.ordinal.toString()];
        row[cData.ordinal.toString()] = StatisticDownloaderService.parseValueToType(val, cData.columnFormat);
      });
    });
  }
}

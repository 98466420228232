/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import {HeaderEnabled} from './header-enabled.abstract';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {SelfInformationFilterDto} from '../../datatypes/self-information-filter-dto';
import {SelfInformationDto} from '../../datatypes/self-information-dto';
import {Injectable} from '@angular/core';
import {EnrollmentStatusDto} from "../../datatypes/enrollment/enrollment-status-dto";

@Injectable({
    providedIn: 'root'
})
export class CustomerinfoService extends HeaderEnabled {

    constructor(private http: HttpClient) {
        super();
    }

    public getCustomerInfo(filter: SelfInformationFilterDto, pageIndex: number, pageSize: number): Observable<SelfInformationDto[]> {
        const url = environment.mycontractServiceUrl + '/selfinformation';
        return this.http.post<SelfInformationDto[]>(url,
            filter,
            {
                headers: this.headers,
                params: {
                    offset: pageIndex,
                    pageSize: pageSize
                }
            });
    }

    public getStatisticCustomerData(customerinfo: SelfInformationDto): Observable<Blob> {
        const url = environment.mycontractServiceUrl + '/selfinformation';

        const options = {
            headers: new HttpHeaders({
                'Accept': [
                    'application/json',
                    'text/html',
                    'application/octet-stream'
                ]
            }),
            params: {
                contractId: customerinfo.contractId
            },
            responseType: 'blob' as 'json',
        };
        return this.http.get<Blob>(url, options);
    }

    public retrieveEnrollmentStatusByEmaId(emaId: string): Observable<EnrollmentStatusDto[]> {
        const url = environment.mycontractServiceUrl + '/enrollmentStatus/byEmaId/' + emaId;
        return this.http.get<EnrollmentStatusDto[]>(url, {
            headers: this.headers,
        });
    }

    public retrieveEnrollmentStatusByVin(vin: string): Observable<EnrollmentStatusDto[]> {
        const url = environment.mycontractServiceUrl + '/enrollmentStatus/byVin/' + vin;
        return this.http.get<EnrollmentStatusDto[]>(url, {
            headers: this.headers,
        });
    }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit, ViewChild} from '@angular/core';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {PermissionAware} from '../permission-aware';
import {AccessRights} from '../datatypes/access-rights.enum';
import {WebConfigRouterService} from '../services/web-config-router.service';
import {BaseUrls} from '../datatypes/base-urls.enum';
import {Action} from '../datatypes/action.enum';
import {PurchaseConditionService} from '../services/http/purchase-condition.service';
import {BehaviorSubject, of} from 'rxjs';
import {Parameters} from '../global/parameters';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {PurchaseConditionOverviewElement} from '../datatypes/purchase-conditions/purchase-condition-overview-element';
import {PurchaseConditionFilterType} from '../datatypes/purchase-conditions/purchase-condition-filter-type';
import {PurchaseConditionFilterDto} from '../datatypes/PurchaseConditionFilterDto';
import {SharedDataService} from '../services/shared-data.service';
import {MatDialog} from '@angular/material/dialog';
import {Message} from '../datatypes/message';
import {DeleteDialogComponent} from '../dialog/delete-dialog/delete-dialog.component';
import {Utils} from '../utils/utils';
import {PurchaseConditionElementAttributes} from '../datatypes/purchase-conditions/purchase-condition-element';
import {PurchaseConditionStatus} from '../datatypes/purchase-conditions/purchase-condition-status.enum';
import {catchError, finalize, tap} from "rxjs/operators";

@Component({
  selector: 'purchase-conditions',
  templateUrl: './purchase-conditions.component.html',
  styleUrls: ['./purchase-conditions.component.css']
})
export class PurchaseConditionsComponent extends AbstractNotificationHandler implements OnInit, PermissionAware {

  public readonly reqEditPermission: AccessRights[] = [AccessRights.PURCHASE_EDIT_WEB];
  private purchaseConditionsSource = new BehaviorSubject<PurchaseConditionOverviewElement[]>([]);
  public purchaseConditions$ = this.purchaseConditionsSource.asObservable();
  public purchaseConditionsTableColumns = PurchaseConditionOverviewElement.dataColumns;
  public loading = false;
  public importing = false;

  public pageSizeOptions = Parameters.PAGE_SIZE_OPTIONS;
  public resultLength = 0;
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  private currentFilterTypeSource = new BehaviorSubject<PurchaseConditionFilterType>(PurchaseConditionFilterType.OVERVIEW);

  private currentFilterDto = new PurchaseConditionFilterDto();
  public selectedFilter = PurchaseConditionFilterType.OVERVIEW;

  constructor(
    private router: WebConfigRouterService,
    private purchaseConditionService: PurchaseConditionService,
    private dataService: SharedDataService,
    private dialog: MatDialog) {
    super();
  }

  public ngOnInit(): void {
    this.getPurchaseConditions(0, this.pageSizeOptions[0]);
  }

  public addNewPurchaseCondition(): void {
    this.router.navigate([BaseUrls.PURCHASE_CONDITION], {queryParams: {mode: Action.CREATE}});
  }

  private getPurchaseConditions(pageIndex: number, pageSize: number): void {
    const offset = pageIndex * pageSize;
    // send one more so see if we have more records than requested
    this.loading = true;

    this.purchaseConditionService.getPurchaseConditions(this.currentFilterDto, offset, pageSize + 1).pipe(
      tap((purchaseConditions) => this.handleResult(pageIndex, pageSize, purchaseConditions)),
      catchError(() => of(null)),
      finalize(() => this.loading = false)
    ).subscribe();
  }

  private handleResult(pageIndex: number, pageSize: number, purchaseConditions: PurchaseConditionOverviewElement[]): void {
    this.resultLength = pageIndex * pageSize + purchaseConditions.length;

    if (this.selectedFilter === PurchaseConditionFilterType.ACTIVE_PURCHASE_CONDITIONS) {
      // for active purchase condition overview pcs have to be formatted,
      // because a row represents a purchase condition element/restriction
      const formattedPCs = this.formatPC(purchaseConditions);
      this.purchaseConditionsSource.next(formattedPCs);
    } else {
      // remove extra element before showing
      if (purchaseConditions.length > pageSize) {
        purchaseConditions.splice(purchaseConditions.length - 1, 1);
      }
      this.purchaseConditionsSource.next(purchaseConditions);
    }

    if (purchaseConditions.length <= 0) {
      const msg = new Message();
      msg.message = 'purchaseConditions.noRecordsFound';
      this.showInfo(msg);
    }
  }

  public handlePageChange(pageEvent: PageEvent): void {
    this.getPurchaseConditions(pageEvent.pageIndex, pageEvent.pageSize);
  }

  public onEditPurchaseCondition(toEdit: PurchaseConditionOverviewElement): void {
    this.router.navigate([BaseUrls.PURCHASE_CONDITION, toEdit.id], {queryParams: {mode: Action.EDIT}});
  }

  public onReadPurchaseCondition(toRead: PurchaseConditionOverviewElement): void {
    this.router.navigate([BaseUrls.PURCHASE_CONDITION, toRead.id], {queryParams: {mode: Action.READ}});
  }

  public onDeletePurchaseCondition(toDelete: PurchaseConditionOverviewElement): void {
    console.log('received delete request for purchase condition: ', toDelete.id);
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: Utils.deletePurchaseConditionConfirmMessage(toDelete.cpoName)
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.doDelete(toDelete);
      }
    });

  }

  private doDelete(toDelete: PurchaseConditionOverviewElement): void {
    this.loading = true;
    this.purchaseConditionService.delete(toDelete.id).pipe(
      tap(() => {
        this.showInfo(new Message('purchaseCondition.deleteSuccess'));
        // refresh page data
        this.getPurchaseConditions(this.paginator.pageIndex, this.paginator.pageSize);
      }),
      catchError(() => of(null)),
      finalize(() => this.loading = false)
    ).subscribe();
  }

  public onCreateByTemplate(templatePC: PurchaseConditionOverviewElement): void {
    this.router.navigate(
      [BaseUrls.PURCHASE_CONDITION],
      {
        queryParams:
          {
            mode: Action.CREATE,
            templateId: templatePC.id
          }
      });
  }

  public handleFilterChange(filter: string): void {
    this.selectedFilter = PurchaseConditionFilterType[filter];
    console.log('updating current purchase condition filter selection to: ', this.selectedFilter);
    this.currentFilterTypeSource.next(this.selectedFilter);

    // adapt filter and table columns, when other overview was selected
    if (this.selectedFilter === PurchaseConditionFilterType.OVERVIEW) {
      this.purchaseConditionsTableColumns = PurchaseConditionOverviewElement.dataColumns;
      this.currentFilterDto = new PurchaseConditionFilterDto();
    } else if (this.selectedFilter === PurchaseConditionFilterType.ACTIVE_PURCHASE_CONDITIONS) {
      this.purchaseConditionsTableColumns = PurchaseConditionOverviewElement.dataColumnsActiveTable
        .concat(Object.values(PurchaseConditionElementAttributes));
      this.currentFilterDto.elementDataNeeded = true;
      this.currentFilterDto.validOnDate = new Date().toISOString().split('T')[0];
      this.currentFilterDto.status = PurchaseConditionStatus.ACTIVE;
    }

    this.getPurchaseConditions(0, this.pageSizeOptions[0]);
  }

  private formatPC(purchaseConditions: PurchaseConditionOverviewElement[]): any[] {
    let formattedPCs = [];
    purchaseConditions.forEach((pc) => {
      if (pc.elements && pc.elements.length > 1) {
        const pcs = this.flattenPCs(pc);
        formattedPCs = formattedPCs.concat(pcs);
      } else {
        formattedPCs.push(pc);
      }
    });
    return formattedPCs;
  }

  public flattenPCs(pc: PurchaseConditionOverviewElement): PurchaseConditionOverviewElement[] {
    const formattedPCs = [];
    pc.elements.forEach((element) => {
      const newPC = {...pc};
      newPC.elements = [{...element}];
      formattedPCs.push(newPC);
    });
    return formattedPCs;
  }

  public importTariffs(importData: any): void {
    console.log('importData', importData);
    const file = importData.file;
    const cpoid = importData.additionalArg;
    const validFrom = importData.additionalArg2;

    console.log('file', file);
    console.log('cpoid', cpoid);
    console.log('validFrom', validFrom);

    if (!file || !cpoid || !validFrom) {
      return;
    }
    console.log('Importing tariffs from file "%s"', file.name);
    const reader = new FileReader();
    // set the reader to make the import rest call when the file is loaded
    reader.onloadstart = (): void => {
      this.importing = true;
      console.log('[%s] import start', new Date().toLocaleDateString());
    };
    reader.onloadend = (): void => {
      console.log('[%s] import end', new Date().toLocaleDateString());
      const readerResult = reader.result as string;
      console.log('readerResult', readerResult);
      this.purchaseConditionService.importTariffsForEVSE(cpoid, validFrom, new Blob([readerResult.split(',')[1]]))
        .pipe(
          tap((importResult) => {
            const tariffImportResult = [].concat(importResult);
            this.dataService.updateTariffImportResults(tariffImportResult);
            this.router.navigate([BaseUrls.TARIFF_IMPORT_RESULT]);
          }),
          catchError(() => of(null)),
          finalize(() => this.importing = false)
        ).subscribe();
    };
    reader.readAsDataURL(file);
  }
}

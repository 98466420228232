/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Input, OnChanges, Output, Renderer2, SimpleChanges} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {TranslateService} from '@ngx-translate/core';
import {SelectConfig} from 'pcs-commons/datatypes';
import {TranslationHelperService} from "pcs-commons/http";

@Component({
  selector: 'filterable-table',
  templateUrl: './filterable-table.component.html',
  styleUrls: [
    '../data-table.component.css',
    '../action-column-style.css',
    './filterable-table.component.css'
  ]
})
export class FilterableTableComponent extends OptimizedTableBaseComponent implements OnChanges {
  @Input() public nonSearchableColumns: string[];
  @Input() public translateColumns: string[];
  @Input() public dateColumns: string[];
  @Input() public selectColumnConfigs: Map<string, SelectConfig>;
  @Input() public readonly;

  @Output() public filterEvent = new EventEmitter<any>();

  public selectColumns: string[] = [];
  public update = new Date();
  public filterObj: { [k: string]: any } = {};

  constructor(
    renderer: Renderer2,
    private translate: TranslateService,
    private translateHelper: TranslationHelperService,
  ) {
    super(renderer);
    this.translate.onLangChange.subscribe(() => this.update = new Date());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.selectColumnConfigs && changes.selectColumnConfigs.currentValue) {
      console.log('select column configs: ', this.selectColumnConfigs);
      this.selectColumns = [];
      for (const key of this.selectColumnConfigs.keys()) {
        this.selectColumns.push(key);
      }
      console.log('columns with select filter: ', this.selectColumns);
    }
  }

  public handleFilterInputs(input: any, col: string): void {
    console.log('input: ', input.target.value);
    const filterValue = input.target.value;
    this.handleFilterValueChange(col, filterValue);
  }

  public handleFilterValueChange(col: string, filterValue): void {
    const oldVal = this.filterObj[col];
    const newVal = filterValue ? filterValue.trim() : null;
    if (oldVal !== newVal) {
      this.filterObj[col] = newVal;
      console.log('filter object: ', this.filterObj);
      this.filterEvent.emit(this.filterObj);
    }
  }

  public handleFilterSelectChange($event: any, col: string): void {
    console.log('new filter selection event: ', $event);
    const filterValue = $event.value;
    this.handleFilterValueChange(col, filterValue);
  }

  public  getDefaultOption(col: string): string {
    return this.selectColumnConfigs.get(col)?.defaultOption;
  }

  public translateDate(date: string): string {
    return this.translateHelper.translateDateTimeToLocal(date);
  }
}

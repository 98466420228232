/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {ChargepointClassNames} from './chargepoint-class-names.enum';
import {ChargepointClass} from './chargepoint-class';

export class ChargepointClasses {

  private constructor() {
  }

  public static UNKNOWN = new ChargepointClass(ChargepointClassNames.UNKNOWN, 0, true);
  public static THREE = new ChargepointClass(ChargepointClassNames.THREE, 3, true);
  public static ELEVEN = new ChargepointClass(ChargepointClassNames.ELEVEN, 11, true);
  public static TWENTY = new ChargepointClass(ChargepointClassNames.TWENTY, 20, false);
  public static TWENTY_TWO = new ChargepointClass(ChargepointClassNames.TWENTY_TWO, 22, true);
  public static FOURTY_THREE = new ChargepointClass(ChargepointClassNames.FOURTY_THREE, 43, false);
  public static FIFTY = new ChargepointClass(ChargepointClassNames.FIFTY, 50, false);
  public static HUNDRED_FIFTY = new ChargepointClass(ChargepointClassNames.HUNDRED_FIFTY, 150, false);
  public static THREE_HUNDRED_FIFTY = new ChargepointClass(ChargepointClassNames.THREE_HUNDRED_FIFTY, 350, false);
  public static THREE_HUNDRED_FIFTY_ION = new ChargepointClass(ChargepointClassNames.THREE_HUNDRED_FIFTY_ION, 350, false);

  public static ALL_CP_CLASSES = [
    ChargepointClasses.UNKNOWN,
    ChargepointClasses.THREE,
    ChargepointClasses.ELEVEN,
    ChargepointClasses.TWENTY,
    ChargepointClasses.TWENTY_TWO,
    ChargepointClasses.FOURTY_THREE,
    ChargepointClasses.FIFTY,
    ChargepointClasses.HUNDRED_FIFTY,
    ChargepointClasses.THREE_HUNDRED_FIFTY,
    ChargepointClasses.THREE_HUNDRED_FIFTY_ION
  ];
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
export class ChargepointClassPrice {
  public chargepointClass: string;
  public pricePerKwh: number;
  public pricePerMin: number;

  public clone(from: ChargepointClassPrice): void {
    this.chargepointClass = from.chargepointClass;
    this.pricePerKwh = from.pricePerKwh;
    this.pricePerMin = from.pricePerMin;
  }
}

export enum ChargepointClassPriceColumn {
  CHARGEPOINT_CLASS = 'chargepointClass',
  PRICE_PER_KWH = 'pricePerKwh',
  PRICE_PER_MIN = 'pricePerMin',
  PRICE_PER_KWH_NETTO = 'pricePerKwhNetto',
  PRICE_PER_MIN_NETTO = 'pricePerMinNetto'
}


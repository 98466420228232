/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, Renderer2} from '@angular/core';
import {PurchasePriceComponentAttributes} from '../../datatypes/purchase-conditions/purchase-price-component';
import {FormGroup} from '@angular/forms';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {Utils} from '../../utils/utils';
import {PurchasePriceComponentType} from '../../datatypes/purchase-conditions/purchase-price-component-type.enum';

@Component({
  selector: 'price-component-editable-table',
  templateUrl: './price-component-editable-table.component.html',
  styleUrls: [
    '../data-table.component.css',
    './price-component-editable-table.component.css'
  ]
})
export class PriceComponentEditableTableComponent extends OptimizedTableBaseComponent {
  PRICE_TYPE_COLUMN: string = PurchasePriceComponentAttributes.TYPE;
  STEP_SIZE_COLUMN: string = PurchasePriceComponentAttributes.STEP_SIZE;
  FLAT_PRICE = PurchasePriceComponentType.FLAT;
  @Input() pricesForm: FormGroup;

  constructor(
    renderer: Renderer2) {
    super(renderer);
  }

  getFromControlName(element: string, col: string): string {
    return Utils.purchasePriceFormControlName(element, col);
  }
}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
export class OperatorExport {
  public static readonly tableColumns: string[] = [
    'code',
    'name',
    'platform',
    'active',
    'last_activation_date',
    'renewable_energy',
    'show_station_name',
    'pnc_enabled',
    'chargepoints',
    'rfid_auth_enabled',
    'remote_auth_enabled',
    'preferred'
  ];
  public code: string;
  public name: string;
  public platform: string;
  public active: string;
  public last_activation_date: string;
  public chargepoints: number;
  public renewable_energy: string;
  public pnc_enabled: string;
  public show_station_name: string;
  public rfid_auth_enabled: string;
  public remote_auth_enabled: string;
  public preferred: string;
}

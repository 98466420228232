/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {FleetService} from '../services/http/fleet.service';
import {FleetSummary} from '../datatypes/fleet-summary';
import {FleetStatus} from '../datatypes/fleet-status.enum';
import {Observable, of} from 'rxjs';
import {WebConfigRouterService} from '../services/web-config-router.service';

@Component({
  selector: 'app-fleets',
  templateUrl: './fleets.component.html',
  styleUrls: ['./fleets.component.css']
})
export class FleetsComponent extends AbstractNotificationHandler implements OnInit {
  public loading = false;
  public fleets: FleetSummary[];
  public fleetTableColumns = FleetSummary.tableColumns;
  public selectedFleetId: string;
  public fleetEditView = false;

  constructor(
    private fleetService: FleetService,
    private router: WebConfigRouterService) {
    super();
  }

  public ngOnInit(): void {
    this.loading = true;
    this.getFleets();
  }

  private getFleets(): void {
    this.fleetService.getFleets().subscribe({
      next: (response) => {
        console.log(response);
        // filter out the deleted ones
        this.fleets = response.filter((fleet) => FleetStatus.DELETED !== fleet.status);
        this.loading = false;
      },
      error: () => {
        this.loading = false;
      }
    });
  }

  public toCreateFleet(): void {
    this.router.navigate(['fleet-edit']);
  }

  public onEditFleet(fleet: FleetSummary): void {
    this.selectedFleetId = fleet.fleetId;
    this.router.navigate(['fleet-edit', this.selectedFleetId]);
  }

  public observableOfFleets(): Observable<FleetSummary[]> {
    return of(this.fleets);
  }
}

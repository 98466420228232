/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {CountryCode} from 'pcs-commons/datatypes';
import {FormControl} from '@angular/forms';

export class CountryUtil {
  private constructor() {
  }

  public static toCountryCodeName(value: string): string {
    for (const c in CountryCode) {
      if (CountryCode[c] === value) {
        return c;
      }
    }
    return null;
  }

  public static validateCountryByAlpha2(c: FormControl) {
    if (!c.value) {
      return null;
    }
    console.log('validating country for value: ', c.value);
    const countries: string[] = Object.values(CountryCode);
    const isValid = countries.includes(c.value);
    return isValid ? null : {
      validCountry: {
        valid: false
      }
    };
  }
}



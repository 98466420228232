/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { TranslateDateFromDateTimePipe } from './src/translate-date-from-date-time.pipe';


@NgModule({
  declarations: [
    TranslateDateFromDateTimePipe
  ],
  imports: [
    CommonModule
  ],
  exports: [TranslateDateFromDateTimePipe]
})
export class PipesModule {
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<form class="cp-filter-form" [formGroup]="filterFormGroup" *ngIf="filterFormGroup">
  <div class="multi-column-div">
    <mat-form-field class="filter-form-field">
      <mat-label>{{'chargepoint.evseidFilter' | translate}}</mat-label>
      <input matInput maxlength="{{evseidMaxLength}}" formControlName="evseidFilter"
             (keypress)="validateEvseidPattern($event)">
      <mat-hint align="end" *ngIf="evseidFilter.value">{{evseidFilter.value.length}}
        / {{evseidMaxLength}}</mat-hint>
    </mat-form-field>

    <mat-form-field class="filter-form-field">
      <mat-label>{{'chargepoint.addressFilter' | translate}}</mat-label>
      <input matInput maxlength="{{addressMaxLength}}" formControlName="addressFilter">
      <mat-hint align="end" *ngIf="addressFilter.value">{{addressFilter.value.length}}
        / {{addressMaxLength}}</mat-hint>
    </mat-form-field>
  </div>
  <mat-grid-list cols="5" rowHeight="50px" >
    <mat-grid-tile colspan="1">
      <label class="normal-no-margin">{{ "chargepoint.visibilityFilter" | translate }}</label>
    </mat-grid-tile>
    <mat-grid-tile class="visibility-select-grid-tile" colspan="4">
      <ng-container formArrayName="visibilityFilter">
        <div *ngFor="let visibility of visibilityFilter; let i = index">
          <mat-checkbox [formControlName]="i" [value]="visibility" class="visibility-checkbox standard-label-font" color="primary" >
            {{'chargepoint.visibility.' + visibility| translate }}
          </mat-checkbox>
        </div>
      </ng-container>
    </mat-grid-tile>
  </mat-grid-list>
</form>

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div id="inbox">

  <mat-toolbar class="headerToolbar mat-elevation-z1">
    <span class="headerSpan">{{'inbox.list' | translate }}</span>
    <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
  </mat-toolbar>

  <div class="section-container" *ngIf="resultLength > 0" id="communicationEntries-table-area">
    <mat-accordion>
      <mat-expansion-panel [expanded]="resultLength > 0" hideToggle>
        <pcs-inbox-table
          [dataColumns]="dataColumns"
          [i18nBase]="'inbox.tableHeader'"
          [data]="changeRequests$"
          [actionButtons]="true"
          (editEvent)="navigateToDetailsView($event)">
        </pcs-inbox-table>
        <mat-paginator [pageSizeOptions]="pageSizeOptions"
                       (page)="handlePageChange($event)" [length]="resultLength">
        </mat-paginator>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Router} from '@angular/router';
import {FleetStatus} from '../../datatypes/fleet-status.enum';
import {MatDialog} from '@angular/material/dialog';
import {Utils} from '../../utils/utils';
import {DeleteDialogComponent} from '../../dialog/delete-dialog/delete-dialog.component';
import {ConfirmationDialogComponent} from '../../dialog/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'fleet-actions',
  templateUrl: './fleet-actions.component.html',
  styleUrls: ['./fleet-actions.component.css']
})
export class FleetActionsComponent {
  @Input() public fleetId: string;
  @Input() public fleetStatus: FleetStatus;
  @Output() public deleteEvent = new EventEmitter<string>();
  @Output() public deactivateEvent = new EventEmitter<string>();
  @Output() public activateEvent = new EventEmitter<string>();

  constructor(private router: Router, private dialog: MatDialog) {
  }

  public toIdCardsView(): void {
    this.router.navigate(['id-cards', this.fleetId]);
  }

  public onDeactivate(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {
        data: {
          message: Utils.deactivateFleetConfirmMessage(this.fleetId),
          actionKey: 'button.deactivate'
        }
      }
    );

    const dialogSub = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deactivateEvent.emit();
      }
    });
  }

  public onActivate(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {
        data: {
          message: Utils.activateFleetConfirmMessage(this.fleetId),
          actionKey: 'button.activate'
        }
      }
    );

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.activateEvent.emit();
      }
    });
  }

  public onDelete(): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: Utils.deleteFleetConfirmMessage(this.fleetId)
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteEvent.emit();
      }
    });
  }

  public get fleetInactive(): boolean {
    return FleetStatus.INACTIVE === this.fleetStatus;
  }

  public onSelectInvoices(): void {
    this.router.navigate(['fleet-invoices', this.fleetId]);
  }
}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, OnChanges, OnDestroy } from '@angular/core';
import { ChangeRequestAction } from '../../datatypes/audit-flow/change-request-action';
import { ChangeRequestSharedDataService } from '../../services/change-request-shared-data.service';
import { ChangeRequestInfoManagerAbstract } from '../change-request-info-manager.abstract';
import { ChangeRequestInfoOperatorData } from '../../datatypes/audit-flow/change-request-info-operator-data';
import { Subscription } from 'rxjs';

@Component({
  selector: 'pcs-change-request-info-cpo',
  templateUrl: './change-request-info-cpo.component.html',
  styleUrls: ['./change-request-info-cpo.component.css']
})
export class ChangeRequestInfoCpoComponent extends ChangeRequestInfoManagerAbstract implements OnChanges, OnDestroy {
  public changeRequestInfoOperatorData: ChangeRequestInfoOperatorData;
  public actionText = '';
  public currentStatusText = '';
  public newStatusText = '';
  public toUnsub: Subscription[] = [];

  constructor(private changeRequestSharedDataService: ChangeRequestSharedDataService) {
    super();
    this.toUnsub.push(
      this.changeRequestSharedDataService
        .getCurrentOperatorObservable()
        .subscribe((changeRequestInfoOperatorData: ChangeRequestInfoOperatorData) => {
          this.changeRequestInfoOperatorData = changeRequestInfoOperatorData;
        })
    );
  }

  public ngOnChanges(/*changes: SimpleChanges*/): void {
    const action = this.changeRequest?.action?.toLowerCase();
    const payloadObjectType = this.changeRequest?.payloadObjectType?.toLowerCase();
    this.actionText = `audit.${payloadObjectType}.${action}.message`;
    if (this.changeRequest?.action === ChangeRequestAction.SET_PREFERRED_ATTRIBUTE) {
      const original = JSON.parse(this.changeRequest.originalValue);
      this.currentStatusText =
        original.preferred === true ? 'changeRequest.info.preferred.active' : 'changeRequest.info.preferred.inactive';
      const updated = JSON.parse(this.changeRequest.updatedValue);
      this.newStatusText =
        updated.preferred === true ? 'changeRequest.info.preferred.activate' : 'changeRequest.info.preferred.deactivate';
    } else {
      const currentStatus = this.changeRequestInfoOperatorData?.active ? 'active' : 'inactive';
      this.currentStatusText = `changeRequest.info.currentStatus.${currentStatus}`;
      this.newStatusText =
        this.changeRequest?.action === ChangeRequestAction.ACTIVATE
          ? 'changeRequest.info.newStatus.activate'
          : 'changeRequest.info.newStatus.deactivate';
    }
  }

  public ngOnDestroy(): void {
    this.toUnsub.forEach((sub: Subscription) => sub.unsubscribe());
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, EventEmitter, Output} from '@angular/core';
import {PurchaseConditionFilterType} from '../../datatypes/purchase-conditions/purchase-condition-filter-type';

@Component({
  selector: 'pcs-purchase-conditions-toggle-group',
  templateUrl: './purchase-conditions-toggle-group.component.html',
  styleUrls: ['./purchase-conditions-toggle-group.component.css']
})
export class PurchaseConditionsToggleGroupComponent {
  @Output() private filterSelectionEvent = new EventEmitter<string>();

  public currentFilterType: string = PurchaseConditionFilterType.OVERVIEW;
  public filterTypes = Object.values(PurchaseConditionFilterType);

  public handleFilterChange(filter: string): void {
    this.currentFilterType = filter;
    this.filterSelectionEvent.emit(filter);
  }
}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';
import { OptimizedTableBaseComponent } from 'pcs-commons/data-tables';
import { OcpiPartner, OcpiPartnerTableColumns } from '../../datatypes/ocpi-partner';
import { OcpiPartnerStatus } from '../../datatypes/ocpi-partner-status.enum';
import { TranslationHelperService } from 'pcs-commons/http';
import { OcpiPartnerService } from '../../services/http/ocpi-partner.service';
import { Message } from '../../datatypes/message';
import { showError, showInfo } from 'pcs-commons/notification';
import { AccessRights } from '../../datatypes/access-rights.enum';

@Component({
  selector: 'pcs-ocpi-partner-table',
  templateUrl: './ocpi-partner-table.component.html',
  styleUrls: [
    '../action-column-style.css',
    './ocpi-partner-table.component.css',
    '../scrollable-table/scrollable-table.component.css'
  ]
})
export class OcpiPartnerTableComponent extends OptimizedTableBaseComponent implements OnInit {
  @Output() public activateEvent = new EventEmitter<OcpiPartner>();
  @Output() public updateLocationDataForPartnerEvent = new EventEmitter<OcpiPartner>();
  @Output() public pushRfidTokensToPartner = new EventEmitter<OcpiPartner>();
  public reqPermForJobStart = [AccessRights.JOB_EDIT_WEB];

  public booleanValueColumns: string[] = [
    OcpiPartnerTableColumns.CERT_CN,
    OcpiPartnerTableColumns.AUTH_CHECK_LOCATION,
    OcpiPartnerTableColumns.REQUEST_AUTHORIZATION_ID,
    OcpiPartnerTableColumns.ALLOW_OFFLINE_AUTH
  ];

  constructor(
    renderer: Renderer2,
    private translateHelper: TranslationHelperService,
    private ocpiPartnerService: OcpiPartnerService
  ) {
    super(renderer);
  }

  public ngOnInit(): void {
    super.ngOnInit();
  }

  public async onUpdateLocationDataForPartner(partner: OcpiPartner): Promise<void> {
    if (partner.updateLocationDataStatus !== 'RUNNING') {
      try {
        await this.ocpiPartnerService.updateLocationDataForPartner(partner);
        showInfo(new Message('ocpi.partner.initiate.sync.scheduled'));
      } catch (e) {
        showError(new Message('ocpi.partner.initiate.sync.error'));
      }
    }
  }

  public onActivate(toDeactivate: OcpiPartner): void {
    this.activateEvent.emit(toDeactivate);
  }

  public onPushRfidTokensToPartner(partner: OcpiPartner): void {
    this.pushRfidTokensToPartner.emit(partner);
  }

  public activationPossible(element: OcpiPartner): boolean {
    const status = element.status;
    return OcpiPartnerStatus.CONFIGURED === status;
  }

  public isPartnerActive(element: OcpiPartner): boolean {
    const status = element.status;
    return OcpiPartnerStatus.ACTIVE === status;
  }

  public isSyncRunning(toCheck: OcpiPartner): boolean {
    return this.ocpiPartnerService.isSyncRunning(toCheck);
  }

  public canPushTokens(toCheck: OcpiPartner): boolean {
    return toCheck.tokenSyncStatus !== 'REQUESTED' && toCheck.tokenSyncStatus !== 'PENDING';
  }

  public getDateTimeTranslation(value: string): string {
    return this.translateHelper.translateDateTimeToLocal(value);
  }

  public canBeTranslated(col: string): boolean {
    return !this.isBoolean(col) && col !== OcpiPartnerTableColumns.PLATFORM && !this.isDate(col);
  }

  public isBoolean(col: string): boolean {
    return this.booleanValueColumns.includes(col);
  }

  public isDate(col: string): boolean {
    return col === OcpiPartnerTableColumns.LAST_FULL_TOKEN_SYNC;
  }
}

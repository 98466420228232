/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
export class NavMenu {
  constructor(
    private readonly _name: string,
    private readonly _key: string,
    private readonly _homeUrl: string,
    private readonly _matIcon: string,
    private readonly _faIcon: string
  ) {
  }

  public get name(): string {
    return this._name;
  }

  public get key(): string {
    return this._key;
  }

  public get homeUrl(): string {
    return this._homeUrl;
  }

  public get matIcon(): string {
    return this._matIcon;
  }

  public get faIcon(): string {
    return this._faIcon;
  }

  public get faIconClass(): string {
    return 'fa ' + this._faIcon;
  }
}

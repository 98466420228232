/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {HttpUrlEncodingCodec} from '@angular/common/http';

export class HttpParamEncoder extends HttpUrlEncodingCodec {

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

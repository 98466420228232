<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-toolbar class="headerToolbar mat-elevation-z1">
  <span class="headerSpan">{{'ionityContractData.header' | translate }}</span>
  <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
</mat-toolbar>

<div class="section-container">
  <mat-accordion>
    <mat-expansion-panel [expanded]="true" hideToggle disabled="true" class="statistic-expansion-panel">
      <mat-expansion-panel-header>
        <span class="headerSpan">{{'ionityContractData.description' | translate }}</span>
      </mat-expansion-panel-header>
      <mat-form-field>
        <mat-label>{{ "ionityContractData.label.contractId" | translate }}</mat-label>
        <input matInput [formControl]="contractId" required>
        <mat-error *ngIf="contractId.invalid">{{getError(contractId) | async}}</mat-error>
      </mat-form-field>
      <mat-action-row class="margin-right">
        <button class="actionButton" mat-raised-button (click)="fetchData()" [disabled]="!contractId.valid">
          {{ 'ionityContractData.button.fetchData' | translate }}
        </button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</div>

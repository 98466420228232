/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {UserInfo} from './user-info';

export class UserAuthDetails extends UserInfo {
  public accessToken: string;
}


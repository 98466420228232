/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UserCredential } from '../../datatypes/user-credential';


@Component({
  selector: 'user-cred-table',
  templateUrl: './user-cred-table.component.html',
  styleUrls: ['./user-cred-table.component.css']
})
export class UserCredTableComponent implements OnInit {

  @Input() users: UserCredential[] = [];
  hidePass = true;
  displayedColumns: string[] = ['login', 'password'];
  userData = new MatTableDataSource<UserCredential>();

  constructor() { }

  ngOnInit() {
    if (this.users) {
      this.userData = new MatTableDataSource(this.users);
    }
    console.log('got new manager data: ' + JSON.stringify(this.userData.data));
  }

  getPasswordHidden(pass: string) {
    if (!pass || !this.hidePass) {
      return pass;
    }
    return pass.replace(/./g, '*');
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {Utils} from '../../utils/utils';

@Component({
  selector: 'multi-header-table-base',
  template: ''
})
export class MultiHeaderTableBaseComponent extends OptimizedTableBaseComponent implements OnChanges {

  @Input() columnGroupMap: Map<string, string[]>;
  groupNames: string[] = [];
  dataColumns: string[] = [];
  groupActionColumn = 'group.ActionColumn';

  constructor(renderer: Renderer2) {
    super(renderer);
  }


  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.columnGroupMap) {
      this.handleColumnGroupMapChanges();
    }
  }

  handleColumnGroupMapChanges() {
    console.log(this.constructor.name, ': handling column group map change');
    this.groupNames = [];
    const columns = [];
    this.columnGroupMap.forEach((value, key) => {
      this.groupNames.push(key);
      columns.push(...value);
    });
    this.dataColumns = columns;
    if (this.groupNames.includes(this.groupActionColumn)) {
      Utils.removeArrayItem(this.groupNames, this.groupActionColumn);
      this.groupNames.push(this.groupActionColumn);
      return;
    }
    if (this.actionButtons) {
      this.groupNames.push(this.groupActionColumn);
    }
    this.handleDataColumnChanges();
  }

  calcRowSpan(col: string): number {
    if (col === this.actionColumn) {
      return 2;
    }

    if (!this.columnGroupMap || this.columnGroupMap.size <= 0) {
      return 2;
    }

    let rowSpan = 1;
    for (const entry of this.columnGroupMap.entries()) {
      const children: string[] = entry[1];
      if (children.includes(col)) {
        rowSpan = children.length > 1 ? 1 : 2;
        break;
      }
    }
    return rowSpan;
  }

  calcGroupRowSpan(group): number {
    if (group === this.groupActionColumn) {
      return 2;
    }

    if (!this.columnGroupMap || this.columnGroupMap.size <= 0 || !this.columnGroupMap.has(group)) {
      return 2;
    }
    /*
     if the group has more than 1 child, 1st row = group_name and 2nd row = child_name
     else, group_name will take 2 rows
     */
    return this.columnGroupMap.get(group).length > 1 ? 1 : 2;
  }

  calcColSpan(group: string): number {
    if (group === this.groupActionColumn) {
      return 1;
    }
    return this.columnGroupMap.get(group).length;
  }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div id="rejectedCdrs" class="mat-elevation-z1">
  <mat-toolbar class="headerToolbar">
    <span class="headerSpan">{{'cdrClearing.rejectedCdrs' | translate }}</span>
    <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
  </mat-toolbar>
  <filterable-table [data]="rejectedCdrs$"
                    [actionButtons]="true"
                    [readonly]="true"
                    [dataColumns]="rejectedCdrsColumns"
                    [selectColumnConfigs]="selectColumnConfigs"
                    [translateColumns]="translateColumns"
                    [nonSearchableColumns]="nonSearchableColumns"
                    (readEvent)="showClearingEntry($event)"
                    (filterEvent)="onFilterChange($event)"
                    class="padding-lr-24"
  >
  </filterable-table>
  <mat-paginator #paginator
                 [pageSizeOptions]="pageSizeOptions"
                 (page)="handlePageChange($event)" [length]="resultLength">
  </mat-paginator>
</div>

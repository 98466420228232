/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import { Message } from 'pcs-commons/datatypes';

export class ErrorRecord {
  public i18nError: string;
  public param0: string;
  public param1: string;
  public param2: string;

  constructor() {}

  public toMessage(): Message {
    const msg =  new Message();
    msg.message =  this.i18nError;
    msg.param0 =  this.param0;
    msg.param1 = this.param1;
    msg.param2 =  this.param2;
    return msg;
  }
}

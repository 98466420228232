/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {TariffImportRecord} from '../../datatypes/tariff-import-record';
import {Observable} from 'rxjs';
import {AbstractNotificationHandler, NotificationService} from 'pcs-commons/notification';
import {WebConfigRouterService} from '../../services/web-config-router.service';
import {SharedDataService} from '../../services/shared-data.service';
import {BaseUrls} from '../../datatypes/base-urls.enum';
import {Message} from '../../datatypes/message';

@Component({
  selector: 'app-tariff-import-result',
  templateUrl: './tariff-import-result.component.html',
  styleUrls: ['./tariff-import-result.component.css']
})
export class TariffImportResultComponent extends AbstractNotificationHandler implements OnInit {

  tariffImportResult$: Observable<TariffImportRecord[]>;
  importResultTableColumns = TariffImportRecord.tableColumns;

  constructor(
    private router: WebConfigRouterService,
    notificationService: NotificationService,
    private dataService: SharedDataService) {
    super();
  }

  ngOnInit(): void {
    this.tariffImportResult$ = this.dataService.tariffImportResults$;
    this.tariffImportResult$.subscribe(result => {
      const msg = new Message();
      if (result.some(record => record.action === 'FAILED')) {
        msg.message = 'purchaseConditions.tariff.import.failed';
        this.showError(msg);
      } else {
        msg.message = 'purchaseConditions.tariff.import.success';
        this.showInfo(msg);
      }
    });
  }

  onBack() {
    this.router.navigate([BaseUrls.PURCHASE_CONDITION_OVERVIEW]);
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Pipe, PipeTransform} from '@angular/core';
import {NavigationMenuStore} from '../navigation-menu-store';
import {NavMenu} from '../datatypes/nav-menu';
import {TranslateService} from '@ngx-translate/core';
import {StringUtils} from 'pcs-commons/utils';
import {Domain} from '../datatypes/domain';
import {routes} from "../app-routing.module";

@Pipe({
  name: 'fetchMenus'
})
export class FetchMenusPipe implements PipeTransform {
  public domainToMenusMap: Map<string, string[]> = new Map<string, string[]>();

  constructor(private translateService: TranslateService) {
    NavigationMenuStore.getAllNavMenus().forEach((menu) => this.addMenuToDomainMap(menu));
  }

  /**
   * @param updated triggers rendering when the user changes their language settings
   */
  public transform(domainName: string, updated?: Date): string {
    const menuKeys = this.domainToMenusMap.get(domainName);
    const translatedMenus = menuKeys.map((key) => {
      if (key.includes('configurations')) {
        const sectionKey = Domain.CPO_AND_PARTNER.name === domainName ? 'configurations.platform.list' : 'configurations.vehicles.import';
        return this.translateService.instant(key) + '(' + this.translateService.instant(sectionKey) + ')';
      }
      return this.translateService.instant(key);
    });

    return '[' + StringUtils.concat(', ', ...translatedMenus) + ']';
  }

  private addMenuToDomainMap(menu: NavMenu): void {
    const routeForMenu = routes.find(route => route.path === menu.homeUrl);
    const domains = routeForMenu?.data?.domains;
    if (domains === undefined || domains === null) {
      return;
    }
    domains.forEach((domain) => {
      const domainName = domain.name;
      if (!this.domainToMenusMap.has(domainName)) {
        this.domainToMenusMap.set(domainName, []);
      }
      this.domainToMenusMap.get(domainName).push(menu.key);
    });
  }
}

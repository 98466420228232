/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Pipe, PipeTransform} from '@angular/core';
import {SharedDataService} from '../services/shared-data.service';
import {PriceUtils} from '../utils/price-utils';
import {CountryPriceRecord, CountryPriceRecordColumns} from '../country-price-record';
import {PriceColumnPipeBase} from './price-column-pipe-base';

@Pipe({
  name: 'countryPriceColumnValue'
})
export class CountryPriceColumnValuePipe extends PriceColumnPipeBase implements PipeTransform {

  constructor(dataSource: SharedDataService) {
    super(dataSource);
  }

  transform(priceRecord: CountryPriceRecord, column: string): string {
    if (!priceRecord) {
      return null;
    }

    switch (column) {
      case CountryPriceRecordColumns.TAX:
        return PriceUtils.toPercent(PriceUtils.getTaxForTime(priceRecord['country'], this.taxData,
          priceRecord.validFrom ? priceRecord.validFrom : priceRecord.validUntil)) + '%';
      default:
        return priceRecord[column];
    }
  }
}

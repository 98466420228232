<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content>
  <h2 mat-dialog-title>
    {{'audit.dialog.header'|translate}}
    <mat-spinner [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
  </h2>
  <div class="messageText">{{message.message | translate:message}}</div>
  <form [formGroup]="changeRequestForm" class="audit-form-field">
    <mat-form-field appearance="outline" class="audit-form-field">
      <mat-label>{{'audit.dialog.title' | translate}}</mat-label>
      <mat-error *ngIf="title.invalid">{{title | validationMessage | async}}</mat-error>
      <input matInput formControlName="title">
    </mat-form-field>
    <mat-form-field appearance="outline" class="audit-form-field">
      <mat-label>{{'audit.dialog.reviewer1' | translate}}</mat-label>
      <input matInput [matAutocomplete]="matAutocompleteReviewer1" maxLength="100" formControlName="reviewer1Input">
      <mat-error *ngIf="reviewer1Input.invalid">{{ 'audit.reviewer.notInList' | translate}}</mat-error>
      <mat-autocomplete #matAutocompleteReviewer1 [displayWith]="displayText"
                        (optionSelected)="reviewer2Input.updateValueAndValidity({emitEvent: true})">
        <mat-option *ngFor="let user of filteredOptionsReviewer1 | async"
                    [value]="user">
          {{user.login}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="outline" class="audit-form-field">
      <mat-label>{{'audit.dialog.reviewer2' | translate}}</mat-label>
      <input matInput [matAutocomplete]="matAutocompleteReviewer2" maxLength="100" formControlName="reviewer2Input">
      <mat-error *ngIf="reviewer2Input.invalid">{{ 'audit.reviewer.notInList' | translate}}</mat-error>
      <mat-autocomplete #matAutocompleteReviewer2 [displayWith]="displayText"
                        (optionSelected)="reviewer1Input.updateValueAndValidity({emitEvent: true})">
        <mat-option *ngFor="let user of filteredOptionsReviewer2 | async"
                    [value]="user">
          {{user.login}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="outline" class="audit-form-field">
      <mat-label>{{'audit.dialog.comment' | translate}}</mat-label>
      <mat-error *ngIf="comment.invalid">{{comment | validationMessage | async}}</mat-error>
      <textarea matInput
                cdkTextareaAutosize
                formControlName="comment"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button id="actionBtn" class="dialogueButton"
          (click)="sendCreateChangeRequest()"
          [disabled]="changeRequestForm.invalid">
    {{ 'button.save' | translate}}
  </button>
  <button mat-stroked-button id="cancelBtn" class="dialogueButton" [mat-dialog-close]="false">
    {{'button.cancel' | translate}}
  </button>
</mat-dialog-actions>

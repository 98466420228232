/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {AbstractFleetEditManager} from './fleet-edit/abstract-fleet-edit-manager';
import {Message} from '../datatypes/message';
import {FleetService} from '../services/http/fleet.service';
import {SharedDataService} from '../services/shared-data.service';
import {MatDialog} from '@angular/material/dialog';
import {WebConfigRouterService} from '../services/web-config-router.service';
import {TranslateService} from '@ngx-translate/core';

export abstract class AbstractFleetManager extends AbstractFleetEditManager {

  protected constructor(
    router: WebConfigRouterService,
    fleetService: FleetService,
    dataSource: SharedDataService,
    dialog: MatDialog,
    translateService: TranslateService) {
    super(router, fleetService, dataSource, dialog, translateService);
  }

  public onActivate(): void {
    console.log('activating fleet: ', this.fleet.fleetId);
    this.fleetService.activateFleet(this.fleet.fleetId).subscribe((fleetUpdate) => {
        this.updateFleetData(fleetUpdate);
        const msg = new Message();
        msg.message = 'fleet.activate.success';
        this.showInfo(msg);
      }
    );
  }

  public onDeactivate(): void {
    console.log('deactivating fleet: ', this.fleet.fleetId);
    this.fleetService.deactivateFleet(this.fleet.fleetId).subscribe((fleetUpdate) => {
        this.updateFleetData(fleetUpdate);
        const msg = new Message();
        msg.message = 'fleet.deactivate.success';
        this.showInfo(msg);
      }
    );
  }

  public onDelete(): void {
    console.log('deleting fleet: ', this.fleet.fleetId);
    this.fleetService.deleteFleet(this.fleet.fleetId).subscribe(() => {
        const msg = new Message();
        msg.message = 'fleet.delete.success';
        this.showInfo(msg);
        this.router.navigate(['fleets']);
      }
    );
  }
}

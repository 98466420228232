/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {HeaderEnabled} from './header-enabled.abstract';
import {HttpClient} from '@angular/common/http';
import {UserGroup} from '../../datatypes/user-group';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserGroupService extends HeaderEnabled {
  constructor(private http: HttpClient) {
    super();
  }

  public getAllUserGroups(): Observable<UserGroup[]> {
    const uri = environment.userGroupEndpoint;
    return this.http.get<UserGroup[]>(uri, {headers: this.headers});
  }

  public createNewGroup(newGroup: UserGroup): Observable<UserGroup> {
    const uri = environment.userGroupEndpoint;
    return this.http.post<UserGroup>(uri, newGroup, {headers: this.headers});
  }

  public updateGroup(toUpdate: UserGroup): Observable<UserGroup> {
    const uri = environment.userGroupEndpoint;
    return this.http.put<UserGroup>(uri, toUpdate, {headers: this.headers});
  }

  public deleteGroup(toDelete: UserGroup): Observable<void> {
    const uri = `${environment.userGroupEndpoint}/${toDelete.id}`;
    return this.http.delete<void>(uri, {headers: this.headers});
  }
}

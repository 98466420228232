/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, Renderer2} from '@angular/core';
import {ChargepointClassPriceColumn} from '../../chargepoint-class-price';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';

@Component({
  selector: 'class-prices-table',
  templateUrl: './class-prices-table.component.html',
  styleUrls: [
    '../price-table-styles.css',
    '../data-table.component.css',
    './class-prices-table.component.css'
  ]
})
export class ClassPricesTableComponent extends OptimizedTableBaseComponent {
  @Input() country: string;
  @Input() taxDateTime: string;

  CHARGEPOINT_CLASS_COLUMN = ChargepointClassPriceColumn.CHARGEPOINT_CLASS;

  constructor(renderer: Renderer2) {
    super(renderer);
  }

}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
const productBaseUrl = 'http://localhost:8085';
const chargepointBaseUrl = 'http://localhost:8081';
export const environment = {
  production: false,
  local: true,
  webapp: `FLEETUI`,
  statisticUrl: `${chargepointBaseUrl}/statistics/v1/de/de_DE/statistics`,
  statisticV2Url: `${chargepointBaseUrl}/statistics/v2/de/de_DE/statistics/`,
  contractImportUrl: `${productBaseUrl}/contractimport/v1/de/de_DE/import`,
  fleetsEndpoint: `${productBaseUrl}/fleet/v1/de/de_DE/fleets`,
  productsEndpoint: `${productBaseUrl}/product/v1/de/de_DE/products`,
  userGroupEndpoint: `${productBaseUrl}/usergroup/v1/de/de_DE/groups`,
  userServiceEndpoint: `${productBaseUrl}/user/v1/de/de_DE/users/`,
  invoiceEndpoint: `${productBaseUrl}/invoice/v1`,
  driverEndpoint: `${productBaseUrl}/fleet/v1/drivers`,
  cognitoLoginEndpoint: 'https://pcsdev-auth.auth.eu-central-1.amazoncognito.com/login',
  cognitoLogoutEndpoint: 'https://pcsdev-auth.auth.eu-central-1.amazoncognito.com/logout',
  configEndpoint: `${productBaseUrl}/config`,
  localLoginUrl: 'local-login',
  redirectURI: 'http%3A%2F%2Flocalhost%3A4100%2Ftokens',
  clientId: 'akv1gjlgkhsbsvk9ptqus7j5s'
};

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {AuthenticationService} from './auth/authentication.service';

@Component({
  selector: 'tokens',
  templateUrl: './token.component.html',
  styleUrls: ['./token.component.css']
})
export class TokenComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private authService: AuthenticationService
  ) {
  }

  public ngOnInit(): void {
    this.activatedRoute.fragment.subscribe((fragment: string) => {
      const urlParams = new URLSearchParams(fragment);
      const accessToken = urlParams.get('access_token');
      console.log('access_token: ', accessToken);
      this.authService.fetchUserAuthDetailsAndNavigateToHomePage(accessToken ? accessToken : null);
    });
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {HeaderEnabled} from './header-enabled.abstract';
import {Observable} from 'rxjs';
import {timeout} from 'rxjs/operators';
import {Parameters} from '../../global/parameters';
import {ManualChargepointDataImportResultDto} from '../../datatypes/manual-chargepoint-data-import-result-dto';

@Injectable()
export class ChargepointAttributeImportService extends HeaderEnabled {

  constructor(private http: HttpClient) {
    super();
  }

  public importAttributes(body: any): Observable<ManualChargepointDataImportResultDto> {
    const newHeader = this.headers.set('Content-Type', 'application/octet-stream');
    const url = environment.chargepointUrl + '/manualdata';
    return this.http.put<ManualChargepointDataImportResultDto>(
      url,
      body,
      {
        headers: this.headers
      }
    ).pipe(
      timeout(Parameters.CP_ATTRIBUTE_IMPORT_TIMEOUT_MILLIS)
    );
  }

  public resetAttributes(body: any): Observable<ManualChargepointDataImportResultDto> {
    const newHeader = this.headers.set('Content-Type', 'application/octet-stream');
    const url = environment.chargepointUrl + '/manualdata';
    const options = {
      headers: this.headers,
      body
    };
    return this.http.delete<ManualChargepointDataImportResultDto>(url, options).pipe(
      timeout(Parameters.CP_ATTRIBUTE_IMPORT_TIMEOUT_MILLIS)
    );
  }
}

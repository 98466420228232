/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, Renderer2} from '@angular/core';
import {OptimizedTableBaseComponent} from 'pcs-commons/data-tables';
import {FormControl, FormGroup} from '@angular/forms';
import {Utils} from '../../utils/utils';
import {TranslateService} from '@ngx-translate/core';
import {ValidationMessagePipe} from 'pcs-commons/validation';
import {CurrencyFactor} from '../../datatypes/currency-factor';
import {DateUtils, FormUtils} from 'pcs-commons/utils';
import {Observable} from 'rxjs';
import {DateTime} from "luxon";

@Component({
  selector: 'pcs-edit-currency-factors-table',
  templateUrl: './edit-currency-factors-table.component.html',
  styleUrls: ['../scrollable-table/scrollable-table.component.css',
    '../data-table.component.css',
    './edit-currency-factors-table.component.css']
})
export class EditCurrencyFactorsTableComponent extends OptimizedTableBaseComponent {

  @Input() public editCurrencyFactorForm: FormGroup;

  constructor(renderer: Renderer2,
              private translateService: TranslateService,
              private validationPipe: ValidationMessagePipe) {
    super(renderer);
  }

  public getFormControl(col: string, index: number): FormControl {
    const name = Utils.getFormattedFormControlName(col, index);
    const control = FormUtils.getFormControl(this.editCurrencyFactorForm, name);
    if (control instanceof FormControl) {
      return control;
    }
    console.log("No form control");
    return null;
  }

  public getDatePlaceholder(): any {
    return this.translateService.instant('global.choosedate.placeholder');
  }

  public getError(control: FormControl): Observable<any> {
    return this.validationPipe.transform(control);
  }

  public alreadyStarted(element: CurrencyFactor): boolean {
    const validFrom = FormUtils.getValueAsDateTimeString(this.editCurrencyFactorForm, `validFromDate-${element.index}`, false);
    if (!validFrom) {
      return true;
    }
    return !element.added && !DateUtils.dateTimeIsInFuture(validFrom);
  }

  public deletionAllowed(element: CurrencyFactor): boolean {
    if (element.added) {
      return true;
    }
    return !this.alreadyStarted(element);
  }

  public getMinDate(): DateTime {
    return DateTime.now().plus({minutes: 10});
  }
}

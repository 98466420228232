/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Pipe, PipeTransform} from '@angular/core';
import {BasicFeeRecord, BasicFeeTableColumns} from '../datatypes/basicfee-record';
import {SharedDataService} from '../services/shared-data.service';
import {PriceUtils} from '../utils/price-utils';
import {PriceColumnPipeBase} from './price-column-pipe-base';

@Pipe({
  name: 'basicFeeColumnValue'
})
export class BasicFeeColumnValuePipe extends PriceColumnPipeBase implements PipeTransform {

  constructor(dataSource: SharedDataService) {
    super(dataSource);
  }

  transform(basicFeeRecord: BasicFeeRecord, column: string): string {
    if (!basicFeeRecord) {
      return null;
    }

    switch (column) {
      case BasicFeeTableColumns.TAX:
        return PriceUtils.toPercent(PriceUtils.getTaxForTime(basicFeeRecord.country, this.taxData,
          basicFeeRecord.validFrom ? basicFeeRecord.validFrom : basicFeeRecord.validUntil)) + '%';
      case BasicFeeTableColumns.BASIC_FEE:
      case BasicFeeTableColumns.DEFAULT_PRICE_MIN:
      case BasicFeeTableColumns.DEFAULT_PRICE_KWH:
        return PriceUtils.formatPrice(basicFeeRecord[column]);
      case BasicFeeTableColumns.BASIC_FEE_NETTO:
        return PriceUtils.getNettoPrice(basicFeeRecord.basicFee, PriceUtils.getTaxForTime(basicFeeRecord.country, this.taxData,
          basicFeeRecord.validFrom ? basicFeeRecord.validFrom : basicFeeRecord.validUntil));
      case BasicFeeTableColumns.DEFAULT_PRICE_MIN_NETTO:
        return PriceUtils.getNettoPrice(basicFeeRecord.defaultPriceMin, PriceUtils.getTaxForTime(basicFeeRecord.country, this.taxData,
          basicFeeRecord.validFrom ? basicFeeRecord.validFrom : basicFeeRecord.validUntil));
      case BasicFeeTableColumns.DEFAULT_PRICE_KWH_NETTO:
        return PriceUtils.getNettoPrice(basicFeeRecord.defaultPriceKwh, PriceUtils.getTaxForTime(basicFeeRecord.country, this.taxData,
          basicFeeRecord.validFrom ? basicFeeRecord.validFrom : basicFeeRecord.validUntil));
      default:
        return basicFeeRecord[column];
    }
  }
}

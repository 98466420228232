<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div class="mat-elevation-z1">
  <mat-toolbar class="headerToolbar">
    <span class="headerSpan">{{ "products.header" | translate }}</span>
    <button mat-raised-button class="headerActionBtn icon-only" (click)="createNewProduct()" *ifAnyPerm="reqEditPermission">
      <mat-icon> add</mat-icon>
    </button>
    <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
  </mat-toolbar>
  <product-table [data]="products$"
                 [dataColumns]="productTableColumns"
                 i18nBase="global.label.product"
                 actionButtons="true"
                 (editEvent)="toEditProduct($event)"
                 (showDetailsEvent)="toProductDetails($event)">
  </product-table>
</div>

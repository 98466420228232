<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div id="fleesList" class="mat-elevation-z1" *ngIf="fleets">
  <mat-toolbar class="headerToolbar">
    <span class="headerSpan">{{'fleets.header' | translate }}</span>
    <button mat-raised-button class="headerActionBtn icon-only" (click)="toCreateFleet()">
      <mat-icon> add</mat-icon>
    </button>
    <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
  </mat-toolbar>
  <data-table [data]="observableOfFleets()" [i18nBase]="'fleet.tableheader'" [dataColumns]="fleetTableColumns"
              [actionButtons]="true" [disableDelete]="true" (editEvent)="onEditFleet($event)"
              class="padding-lr-24">
  </data-table>
</div>

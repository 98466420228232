<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div id="keyTagImport">
  <mat-toolbar class="headerToolbar mat-elevation-z1">
    <span class="headerSpan">{{ "global.link.keyTagImport" | translate }}</span>
    <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="importing"></mat-spinner>
  </mat-toolbar>
  <div class="mat-elevation-z1 keyTagImportForm">
    <import-form (importEvent)="handleImport($event)" [importAllowed]="reqEditPermission | accessAllowed"
                 [disabled]="importing" [fileFormat]="'csv'"></import-form>
  </div>
  <mat-card class="keyTagImportInfoCard" appearance="raised">
    <mat-card-header>
      <mat-card-title>{{ "keyTagImport.info.title" | translate }}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <ol>
        <li>{{ "keyTagImport.info.rules.1" | translate }}</li>
        <li>{{ "keyTagImport.info.rules.2" | translate }}</li>
        <li>{{ "keyTagImport.info.rules.3" | translate }}</li>
        <li>{{ "keyTagImport.info.rules.4" | translate }}</li>
        <li>{{ "keyTagImport.info.rules.5" | translate }}</li>
      </ol>
    </mat-card-content>
  </mat-card>
</div>

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavMenu } from './datatypes/nav-menu';
import { UserDataSourceService } from './services/user-data-source.service';
import { WebConfigRouterService } from './services/web-config-router.service';
import { AuthorizationService } from './auth/authorization.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'my-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  public menuList: NavMenu[];
  public title: 'charm-webconfig';
  public userLoggedIn = false;
  private toUnsub: Subscription[] = [];

  constructor(
    private translate: TranslateService,
    private router: WebConfigRouterService,
    private userDataSource: UserDataSourceService,
    private accessControlService: AuthorizationService
  ) {}

  public ngOnInit(): void {
    this.toUnsub.push(this.userDataSource.accessibleNavMenus$.subscribe((accessibleMenus) => (this.menuList = accessibleMenus)));
    this.toUnsub.push(
      this.userDataSource.currentUserAuthDetails$.subscribe((user) => {
        if (user && user.role) {
          this.accessControlService.findAndStoreAccessibleNavMenusForUser();
          this.userLoggedIn = true;
        } else {
          this.userLoggedIn = false;
        }
      })
    );
  }

  public goHome(): void {
    this.router.toUsersHomePage();
  }

  public ngOnDestroy(): void {
    this.toUnsub.forEach((sub) => sub.unsubscribe());
  }
}

/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
export enum ChangeRequestPayloadType {
  PRODUCT = 'PRODUCT',
  CPO = 'CPO',
  SUB_CPO = 'SUB_CPO',
  CDR = 'CDR',
  INVOICE = 'INVOICE',
  USER_GROUP = 'USER_GROUP',
  USER = 'USER',
  CHARGEPOINT = 'CHARGEPOINT'
}

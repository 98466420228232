/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
const baseUrl = 'https://api.dev.porsche-mobility.com';

export const environment = {
  production: true,
  appName: 'WebConfig-dev',
  local: false,
  webapp: `WEBCONFIG`,
  productUrl: `${baseUrl}/product/v1/de/DE_de/products/`,
  chargepointUrl: `${baseUrl}/chargepointadmin/v1/de/DE_de/chargepoints`,
  subOperatorUrl: `${baseUrl}/suboperator/v1`,
  chargepointUrlWithoutCountryLanguage: `${baseUrl}/chargepointadmin/v1/chargepoints`,
  locationServiceUrl: `${baseUrl}/locationadmin/v1/de/DE_de/locations`,
  chargepointV2Url: `${baseUrl}/chargepointadmin/v2/de/DE_de/chargepoints`,
  taxRateUrl: `${baseUrl}/product/v1/de/de_DE/taxes/`,
  statisticUrl: `${baseUrl}/statistics/v1/de/de_DE/statistics/`,
  statisticV2Url: `${baseUrl}/statistics/v2/de/de_DE/statistics/`,
  contractImportUrl: `${baseUrl}/contract/v1/de/de_DE/import/`,
  fleetsEndpoint: `${baseUrl}/fleet/v1/de/DE_de/fleets/`,
  databaseEndpoint: `${baseUrl}/database/v1/query`,
  queryHistoryEndpoint: `${baseUrl}/database/v1/query-history`,
  databaseBackupEndpoint: `${baseUrl}/database/v1/backup/latestBackupTime`,
  userServiceEndpoint: `${baseUrl}/user/v1/de/DE_de/users/`,
  platformServiceEndpoint: `${baseUrl}/platform/v1/de/DE_de/platforms/`,
  ocpiPartnerEndpoint: `${baseUrl}/chargepointadmin/v1/ocpi/partner`,
  ticktockRemoteControlEndpoint: `${baseUrl}/jobcontrol/v1`,
  cognitoLoginEndpoint: `https://pcs-dev-webconfig.auth.eu-central-1.amazoncognito.com/login`,
  cognitoLogoutEndpoint: `https://pcs-dev-webconfig.auth.eu-central-1.amazoncognito.com/logout`,
  configEndpoint: `${baseUrl}/config`,
  redirectURI: `https%3A%2F%2Fconfig.dev.porsche-chargingservice.com%2Ftoken.html`,
  vehicleServiceUrl: `${baseUrl}/vehicle/v1/de/DE_de/`,
  chargingCardServiceUrl: `${baseUrl}/chargingcard/v1/de/de_DE/manage`,
  mycontractServiceUrl: `${baseUrl}/mycontract/v1/de/de_DE/contracts`,
  userGroupEndpoint: `${baseUrl}/usergroup/v1/de/de_DE/groups`,
  purchaseConditionUrl: `${baseUrl}/purchase/v1/de/de_DE/`,
  currencyFactorUrl: `${baseUrl}/purchase/v1/de/de_DE/currencyfactor`,
  cdrServiceUrl: `${baseUrl}/cdr/v1`,
  invoiceEndpoint: `${baseUrl}/invoice/v1`,
  ionitydataUrl: `${baseUrl}/ionitydata/v1`,
  contractHistory: `${baseUrl}/vehicle/v1/DE/de_DE/#{vin}/history`,
  inboxServiceUrl: `${baseUrl}/audit-service/inbox/v1`,
  auditChangeRequestServiceUrl: `${baseUrl}/audit-service/change-request`,
  keyTagUrl: `${baseUrl}/keytag/v1`,
  cloudWatchUrl:
    'https://eu-central-1.console.aws.amazon.com/cloudwatch/home?region=eu-central-1#dashboards:name=External_Systems_dev',
  localLoginUrl: 'page-not-found',
  clientId: '1lor65dot0lrdrrffg2op2ke2f'
};

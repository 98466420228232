/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component } from '@angular/core';
import { AbstractNotificationHandler } from 'pcs-commons/notification';
import { DatabaseService } from '../services/http/database.service';
import { ExcelService } from 'pcs-commons/statistics';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { Message } from '../datatypes/message';
import { Utils } from '../utils/utils';
import { UserDataSourceService } from '../services/user-data-source.service';
import { QueryDto } from '../datatypes/database-queries/QueryDto';
import { WithLoadingSpinner } from 'pcs-commons/components';

@Component({
  selector: 'pcs-database',
  templateUrl: './database.component.html',
  styleUrls: ['./database.component.css']
})
export class DatabaseComponent extends AbstractNotificationHandler {
  public queryResultComplete: Map<string, unknown>[];
  public queryResultSubject = new BehaviorSubject<Map<string, unknown>[]>([]);

  public queryResultSubject$ = this.queryResultSubject.asObservable();

  public dataColumns = null;

  public resultLength = 0;

  constructor(
    private databaseService: DatabaseService,
    private excelService: ExcelService,
    private userDataSourceService: UserDataSourceService
  ) {
    super();
  }

  private async getQueryResult(query: QueryDto): Promise<void> {
    try {
      const queryResult = await firstValueFrom(this.databaseService.getQueryResults(query));
      if (queryResult) {
        this.queryResultComplete = queryResult;
        this.handleQueryResult(this.queryResultComplete);
      }
    } catch (error) {
      this.handleOnQueryResultError();
    }
  }

  @WithLoadingSpinner()
  public async executeQuery(queryDto: QueryDto): Promise<void> {
    if (queryDto?.query && queryDto?.database) {
      queryDto.userLogin = (await firstValueFrom(this.userDataSourceService.currentUserAuthDetails$)).login;
      await this.getQueryResult(queryDto);
    }
  }

  private handleOnQueryResultError(): void {
    this.resultLength = 0;
    this.queryResultSubject.next([]);
  }

  private handleQueryResult(queryResult: Map<string, unknown>[]): void {
    this.resultLength = queryResult.length;
    console.log('Found results: ' + queryResult.length);
    if (queryResult.length > 0) {
      this.dataColumns = Object.keys(queryResult[0]);
      if (this.dataColumns.includes('id')) {
        Utils.moveToFirst('id', this.dataColumns);
      }
    }
    this.queryResultSubject.next(queryResult);
    if (queryResult.length <= 0) {
      const msg = new Message();
      msg.message = 'database.noRecordsFound';
      this.showInfo(msg);
    }
  }

  @WithLoadingSpinner()
  public async onDownloadResults(): Promise<void> {
    console.log('Converting query result to excel. Number of lines: ', this.resultLength);
    const columns = Object.assign([], this.dataColumns);
    const completeResult = Object.assign([], this.queryResultComplete);
    await this.exportAsExcelFile(completeResult, columns);
  }

  private async exportAsExcelFile<T>(completeResult: T[], columns: string[]): Promise<void> {
    this.excelService.exportAsExcelFile(
      completeResult,
      'database-export',
      'Query-Result' + new Date().toISOString().split('T')[0],
      columns // make a copy since the xlsx lib for some reason can change this array
    );
  }
}

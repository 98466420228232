/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * (c) 2020 - 2035, Dr. Ing. h.c. F. Porsche AG.
 */
import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DatabaseBackupService {
  constructor(private http: HttpClient) {}

  public getLatestBackupTime(): Observable<string> {
    const headers = new HttpHeaders({
      Accept: 'text/plain; charset=utf-8'
    });
    return this.http.get(environment.databaseBackupEndpoint, { headers, responseType: 'text' });
  }
}

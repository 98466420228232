<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data && editTaxesForm" class="scrollable-table-container" id="editTaxesTable">
  <form [formGroup]="editTaxesForm">
    <mat-table [dataSource]="tableDataSource">
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container matColumnDef="{{col}}" *ngIf="col !== actionColumn">
          <mat-header-cell [styledColumn]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}
          </mat-header-cell>
          <mat-cell [styledColumn]="col" *matCellDef="let element">

            <mat-form-field *ngIf="col.startsWith('taxRate')">
              <input matInput [formControlName]="getFromControlNameWithIndex(col, element.index)"
                     [formControl]="getFormControl(col, element.index)">
              <mat-error *ngIf="isMaxLengthError(col, element.index)">
                {{'taxes.validation.taxrate.maxlength'| translate}}
              </mat-error>
              <mat-error *ngIf="!isMaxLengthError(col, element.index) && getFormControl(col, element.index) && getFormControl(col, element.index).invalid">
                {{ getError(getFormControl(col, element.index)) | async}}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="col.startsWith('currency')">
              <mat-select required [formControlName]="getFromControlNameWithIndex(col, element.index)"
                          class="currency-selection">
                <mat-option *ngFor="let currency of currencies" [value]="currency">{{currency}}</mat-option>
              </mat-select>
              <mat-error
                *ngIf="getFormControl(col, element.index) && getFormControl(col, element.index).invalid">
                {{ getError(getFormControl(col, element.index)) | async}}
              </mat-error>
            </mat-form-field>

            <mat-form-field *ngIf="col.startsWith('validFromDate')" class="date-time-picker">
              <input matInput [matDatepicker]="pickerValidFrom" [placeholder]="getDatePlaceholder()"
                     [formControlName]="getFromControlNameWithIndex(col, element.index)" [formControl]="getFormControl(col, element.index)">
              <mat-datepicker-toggle matSuffix [for]="pickerValidFrom"></mat-datepicker-toggle>
              <mat-datepicker #pickerValidFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field *ngIf="col.startsWith('validFromTime')"
                            class="time-picker">
              <input matInput [formControlName]="getFromControlNameWithIndex(col, element.index)" type="time" step="1">
            </mat-form-field>


            <mat-form-field *ngIf="col.startsWith('validUntilDate')" class="date-time-picker">
              <input matInput [matDatepicker]="pickerValidUntil" [placeholder]="getDatePlaceholder()"
                     [formControlName]="getFromControlNameWithIndex(col, element.index)" [formControl]="getFormControl(col, element.index)">
              <mat-datepicker-toggle matSuffix [for]="pickerValidUntil"></mat-datepicker-toggle>
              <mat-datepicker #pickerValidUntil></mat-datepicker>
            </mat-form-field>
            <mat-form-field *ngIf="col.startsWith('validUntilTime')"
                            class="time-picker">
              <input matInput [formControlName]="getFromControlNameWithIndex(col, element.index)" type="time" step="1">
            </mat-form-field>


          </mat-cell>
        </ng-container>
      </div>
      <ng-container matColumnDef="tableAction" stickyEnd>
        <mat-header-cell *matHeaderCellDef
                         class="actionCell center details-column"> {{'taxes.tableheader.action' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="actionCell center details-column">
          <button mat-mini-fab class="tableActionBtn delete" (click)="deleteElement(element)">
            <mat-icon> delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
    </mat-table>
  </form>
</div>

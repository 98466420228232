/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component} from '@angular/core';
import {ImportFormBaseComponent} from '../../import-form/import-form-base.component';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'attribute-import-form',
  templateUrl: './attribute-import-form.component.html',
  styleUrls: [
    './attribute-import-form.component.css'
  ]
})
export class AttributeImportFormComponent extends ImportFormBaseComponent {

  public cpAttributeImportForm: FormGroup;
  public loading: boolean;

  constructor(
    private formBuilder: FormBuilder) {
    super();
    this.cpAttributeImportForm = this.formBuilder.group({});
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {environment} from '../../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {HeaderEnabled} from './header-enabled.abstract';
import {Observable} from 'rxjs';
import {IonityCharmDataDto} from "../../datatypes/ionity-contract-data/ionity-charm-data-dto";

@Injectable({
  providedIn: 'root'
})
export class IonityDataService extends HeaderEnabled {
  constructor(private http: HttpClient) {
    super();
  }

  public retrieveDataForContract(contractId: string): Observable<IonityCharmDataDto> {
    return this.http.get<IonityCharmDataDto>(
      environment.ionitydataUrl + '/' + contractId,
      {headers: this.headers}
    );
  }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-dialog-content>
  <h2 mat-dialog-title>
    <span *ngIf="selectedInvoice.contractId">{{
      'invoice-orderitem.dialog.header.contract'| translate :{ param0: selectedInvoice.invoiceIdent, param1: selectedInvoice.contractId }
      }}</span>
    <span *ngIf="!selectedInvoice.contractId">{{
      'invoice-orderitem.dialog.header.fleet'| translate :{ param0: selectedInvoice.invoiceIdent, param1: selectedInvoice.fleetId }
      }}</span>
    <mat-spinner class="header-spinner" [diameter]="30" [strokeWidth]="5" *ngIf="loading"></mat-spinner>
  </h2>
  <pcs-invoice-order-items-table-component
    [dataColumns]="dataColumns"
    [i18nBase]="'invoice-orderitem.tableHeader'"
    [data]="orderItems$"
    [actionButtons]="reqEditPermission |accessAllowed"
    (readEvent)="onOpenEditDialog($event)">
  </pcs-invoice-order-items-table-component>

  <mat-dialog-actions>
    <button mat-raised-button class="dialogueButton dialogueButtonRight updateOrderItemsBtn" id="actionBtn"
            *ngIf="(reqEditPermission | accessAllowed)"
            (click)="updateOrderItems()">
      {{ 'invoice-orderitem.update' | translate}}
    </button>
    <button cancelAction mat-raised-button class="dialogueButton dialogueButtonRight"
            (click)="onCancel()">
      {{'button.cancel' | translate}}
    </button>
  </mat-dialog-actions>
</mat-dialog-content>

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-list *ngIf="address" class="address-overview">
  <mat-list-item *ngIf="address.ciamId">
    <div>
      <mat-label class="address-part-label">{{'address.ciamId' | translate}}</mat-label>
      <span>: {{address.ciamId}}</span>
    </div>
  </mat-list-item>
  <mat-list-item *ngIf="address.vatId">
    <div>
      <mat-label class="address-part-label">{{'address.vatId' | translate}}</mat-label>
      <span>: {{address.vatId}}</span>
    </div>
  </mat-list-item>
  <mat-list-item *ngIf="address.invoiceLanguage">
    <div>
      <mat-label class="address-part-label">{{'address.invoiceLanguage' | translate}}</mat-label>
      <span>: {{address.invoiceLanguage}}</span>
    </div>
  </mat-list-item>
  <mat-list-item *ngIf="address.email">
    <div>
      <mat-label class="address-part-label">{{'address.email' | translate}}</mat-label>
      <span>: {{address.email}}</span>
    </div>
  </mat-list-item>
  <mat-list-item *ngIf="address.company">
    <div>
      <mat-label class="address-part-label">{{'address.company' | translate}}</mat-label>
      <span>: {{address.company}}</span>
    </div>
  </mat-list-item>
  <mat-list-item *ngIf="address.salutation">
    <div>
      <mat-label class="address-part-label">{{'address.salutation' | translate}}</mat-label>
      <span>: {{address.salutation}}</span>
    </div>
  </mat-list-item>
  <mat-list-item *ngIf="address.name">
    <div>
      <mat-label class="address-part-label">{{'address.name' | translate}}</mat-label>
      <span>: {{address.name}}</span>
    </div>
  </mat-list-item>
  <mat-list-item *ngIf="address.coName">
    <div>
      <mat-label class="address-part-label">{{'address.coName' | translate}}</mat-label>
      <span>: {{address.coName}}</span>
    </div>
  </mat-list-item>
  <mat-list-item *ngIf="address.address">
    <div>
      <mat-label class="address-part-label">{{'address.address' | translate}}</mat-label>
      <span>: {{address.address}}</span>
    </div>
  </mat-list-item>
  <mat-list-item *ngIf="address.poBox">
    <div>
      <mat-label class="address-part-label">{{'address.poBox' | translate}}</mat-label>
      <span>: {{address.poBox}}</span>
    </div>
  </mat-list-item>
  <mat-list-item *ngIf="address.supplement">
    <div>
      <mat-label class="address-part-label">{{'address.supplement' | translate}}</mat-label>
      <span>: {{address.supplement}}</span>
    </div>
  </mat-list-item>
</mat-list>

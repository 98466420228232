<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <div class="expansion-panel-header">
        <span class="headerSpan headerToolbarSub">{{'enrollment.eaec.filter.header' | translate}}</span>
      </div>
    </mat-expansion-panel-header>
    <form class="ci-filter-form" [formGroup]="filterFormGroup" *ngIf="filterFormGroup">
      <div class="multi-column-div">
        <mat-form-field class="filter-form-field">
          <mat-label>{{'enrollment.eaec.emaId' | translate}}</mat-label>
          <input [maxlength]="20" formControlName="emaId" matInput>
        </mat-form-field>

        <mat-form-field class="filter-form-field">
          <mat-label>{{'enrollment.eaec.vin' | translate}}</mat-label>
          <input [maxlength]="17" formControlName="vin" matInput>
        </mat-form-field>
      </div>
    </form>
    <mat-action-row>
      <button class="normalButton" mat-stroked-button (click)="triggerFetchEnrollmentInfo()"
              [disabled]="!onlyOneFieldIsSet()">
        <span class="filter-button-span">{{ "enrollment.eaec.button.filter" | translate }}</span>
        <i class="fa fa-refresh" aria-hidden="true"></i>
      </button>
    </mat-action-row>
  </mat-expansion-panel>
</mat-accordion>

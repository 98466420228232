/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {UserAuthDetails} from '../datatypes/user-auth-details';
import {BehaviorSubject} from 'rxjs';
import {Parameters} from '../global/parameters';
import {AccessRights} from '../datatypes/access-rights.enum';
import {Role} from '../datatypes/role.enum';
import {MFADetails} from '../datatypes/mfadetails';
import {MFATypes} from '../datatypes/mfatypes.enum';
import {NavMenu} from "../datatypes/nav-menu";

@Injectable({
  providedIn: 'root'
})
export class UserDataSourceService {
  private currentUserAuthDetailSubject = new BehaviorSubject<UserAuthDetails>(new UserAuthDetails());
  public currentUserAuthDetails$ = this.currentUserAuthDetailSubject.asObservable();
  private currentMfaDetailSubject = new BehaviorSubject<MFADetails>(new MFADetails());
  public mfaDetails$ = this.currentMfaDetailSubject.asObservable();
  private currentAccessibleNavMenusSubject = new BehaviorSubject<NavMenu[]>([]);
  public accessibleNavMenus$ = this.currentAccessibleNavMenusSubject.asObservable();

  /*
    ideally to get the values, you should subscribe to the observable currentUserAuthDetails$
    but if you are sure that the UserAuthDetails is already present, it is safe to access the values using the
    get methods below as these values don't change after user logs in
   */
  public get currentUserName(): string {
    return this.getUserAuthDetails().userName;
  }

  public get currentAccessToken(): string {
    return this.getUserAuthDetails().accessToken;
  }

  public get currentUserRights(): AccessRights[] {
    return this.getUserAuthDetails().rights;
  }

  public get currentUserLogin(): string {
    return this.getUserAuthDetails().login;
  }

  public get currentUserRole(): Role {
    return this.getUserAuthDetails().role;
  }

  public get currentUserId(): string {
    return this.getUserAuthDetails().userId;
  }

  public get currentUserDBId(): number {
    return this.getUserAuthDetails().id;
  }

  public get currentAccessibleNavMenus(): NavMenu[] {
    return this.currentAccessibleNavMenusSubject.getValue();
  }

  public getUserAuthDetails(): UserAuthDetails {
    return this.currentUserAuthDetailSubject.getValue();
  }

  public getMFADetails(): MFADetails {
    return this.currentMfaDetailSubject.getValue();
  }

  public clearAllUserData(): void {
    console.log('clearing user authentication and access details');
    sessionStorage.removeItem(Parameters.KEY_ACCESS_TOKEN);
    this.currentUserAuthDetailSubject.next(new UserAuthDetails());
    this.currentMfaDetailSubject.next(new MFADetails());
    this.currentAccessibleNavMenusSubject.next([]);
  }

  public updateUserAuthDetails(toUpdate: UserAuthDetails): void {
    console.log('Updating user auth details ', toUpdate);
    this.currentUserAuthDetailSubject.next(toUpdate);
  }

  public updateMFADetails(toUpdate: MFADetails): void {
    if (!toUpdate.preferredMfaSetting) {
      toUpdate.preferredMfaSetting = MFATypes.SMS_MFA;
    }
    console.log('Updating user\'s MFA details ', toUpdate);
    this.currentMfaDetailSubject.next(toUpdate);
  }

  public updateAvailableNavMenus(toUpdate: NavMenu[]): void {
    console.log('Updating available nav-menus for user', toUpdate);
    this.currentAccessibleNavMenusSubject.next(toUpdate);
  }
}

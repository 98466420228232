/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProductQuota } from '../datatypes/product-quota';
import { User } from '../datatypes/user';
import { TaxData } from '../datatypes/taxdata';
import { UserGroup } from '../datatypes/user-group';
import { Product } from '../datatypes/product';
import { BasicFeeRecord } from '../datatypes/basicfee-record';
import { CountryPriceRecord } from '../country-price-record';
import { SavedQueryDto } from '../datatypes/database-queries/SavedQueryDto';
import { Operator } from '../datatypes/operator';
import { TariffImportRecord } from '../datatypes/tariff-import-record';
import { ManualChargepointDataImportResultDto } from '../datatypes/manual-chargepoint-data-import-result-dto';
import { VinCheckResult } from '../datatypes/vin-check-result';
import { ReimbursementBilledItemDto } from '../datatypes/invoices/ReimbursementBilledItemDto';
import { ChangeRequestOverviewFilterData } from '../datatypes/ChangeRequestOverviewFilterData';
import { OrderItemDto } from '../datatypes/invoices/OrderItemDto';

@Injectable()
export class SharedDataService {
  private productDataSource = new BehaviorSubject<ProductQuota[]>([]);
  public currentProductData$ = this.productDataSource.asObservable();

  private managerDataSource = new BehaviorSubject<User[]>([]);
  public currentManagers$ = this.managerDataSource.asObservable();

  private taxDatSource = new BehaviorSubject<TaxData>(null);
  public currentTaxData$ = this.taxDatSource.asObservable();

  private userGroupsSource = new BehaviorSubject<UserGroup[]>([]);
  public currentUserGroups$ = this.userGroupsSource.asObservable();

  private groupToEdit = new BehaviorSubject<UserGroup>(null);
  public currentGroupToEdit$ = this.groupToEdit.asObservable();

  private productToEdit = new BehaviorSubject<Product>(null);
  public productToEdit$ = this.productToEdit.asObservable();

  private basicFeeToEdit = new BehaviorSubject<BasicFeeRecord>(null);
  public basicFeeToEdit$ = this.basicFeeToEdit.asObservable();

  private countryPriceToEdit = new BehaviorSubject<CountryPriceRecord>(null);
  public countryPriceToEdit$ = this.countryPriceToEdit.asObservable();

  private currentCpoList = new BehaviorSubject<Operator[]>([]);
  private currentCpoList$ = this.currentCpoList.asObservable();

  private currentCurrencyList = new BehaviorSubject<string[]>([]);
  public currentCurrencyList$ = this.currentCurrencyList.asObservable();

  private queryToEdit = new BehaviorSubject<SavedQueryDto>(new SavedQueryDto());
  public queryToEdit$ = this.queryToEdit.asObservable();

  private allQueriesSource = new BehaviorSubject<SavedQueryDto[]>([]);
  public allQueries$ = this.allQueriesSource.asObservable();

  private tariffImportResultsSource = new BehaviorSubject<TariffImportRecord[]>([]);
  public tariffImportResults$ = this.tariffImportResultsSource.asObservable();

  private operatorUpdateOnProcessSource = new BehaviorSubject<boolean>(undefined);
  private operatorUpdateOnProcess$ = this.operatorUpdateOnProcessSource.asObservable();

  private selectedInvoiceTransactionsSource = new BehaviorSubject<ReimbursementBilledItemDto[]>([]);
  public selectedInvoiceTransactions$ = this.selectedInvoiceTransactionsSource.asObservable();

  private evUpdateResultSource = new BehaviorSubject<boolean>(undefined);
  public evUpdateResult$ = this.evUpdateResultSource.asObservable();

  private cpAttributeImportResultSource = new BehaviorSubject<ManualChargepointDataImportResultDto>(null);
  public cpAttributeImportResult$ = this.cpAttributeImportResultSource.asObservable();

  private vinCheckResultSource = new BehaviorSubject<VinCheckResult[]>([]);
  public vinCheckResult$ = this.vinCheckResultSource.asObservable();

  private clearTransactionSelectionSource = new BehaviorSubject<boolean>(undefined);
  public clearTransactionSelection$ = this.clearTransactionSelectionSource.asObservable();

  private changeRequestOverviewFilterData = new BehaviorSubject<ChangeRequestOverviewFilterData>(undefined);
  public changeRequestOverviewFilterData$ = this.changeRequestOverviewFilterData.asObservable();

  private orderItemList = new BehaviorSubject<OrderItemDto[]>([]);
  private orderItemList$ = this.orderItemList.asObservable();

  public updateCurrentCpoList(newCpoList: Operator[]): void {
    console.log('updating cpo list: ', newCpoList);
    this.currentCpoList.next(newCpoList);
  }

  public updateCurrentCurrencyList(newCurrencyList: string[]): void {
    console.log('updating currency list: ', newCurrencyList);
    this.currentCurrencyList.next(newCurrencyList);
  }

  public updateProductData(products: ProductQuota[]): void {
    console.log('updating product data: ', products);
    this.productDataSource.next(products);
  }

  public updateFleetManagers(managers: User[]): void {
    console.log('updating manager data: ', managers);
    this.managerDataSource.next(managers);
  }

  public updateTaxData(newTaxData: TaxData): void {
    console.log('updating tax data: ', newTaxData);
    this.taxDatSource.next(newTaxData);
  }

  public updateUserGroups(newUserGroupList: UserGroup[]): void {
    console.log('Updating user group list: ', newUserGroupList);
    this.userGroupsSource.next(newUserGroupList);
  }

  public get previousUserGroups(): UserGroup[] {
    return this.userGroupsSource.value;
  }

  public updateCurrentUserGroupOnEdit(newGroupToEdit: UserGroup): void {
    console.log('Updating current user group on edit: ', newGroupToEdit);
    this.groupToEdit.next(newGroupToEdit);
  }

  public updateCurrentProductOnEdit(newProductToEdit: Product): void {
    console.log('Updating current product on edit: ', newProductToEdit);
    this.productToEdit.next(newProductToEdit);
  }

  public updateCurrentBasicFeeOnEdit(newBasicFeeToEdit: BasicFeeRecord): void {
    console.log('Updating current basic fee on edit: ', newBasicFeeToEdit);
    this.basicFeeToEdit.next(newBasicFeeToEdit);
  }

  public updateCurrentCountryPriceOnEdit(newCountryPriceToEdit: CountryPriceRecord): void {
    console.log('Updating current country price on edit: ', newCountryPriceToEdit);
    this.countryPriceToEdit.next(newCountryPriceToEdit);
  }

  public updateDatabaseQueries(queries: SavedQueryDto[]): void {
    console.log('Updating existing queries: ', queries);
    this.allQueriesSource.next(queries);
  }

  public updateCurrentQueryOnEdit(query: SavedQueryDto): void {
    console.log('Updating query on edit: ', query);
    this.queryToEdit.next(query);
  }

  public updateTariffImportResults(importResult: TariffImportRecord[]): void {
    console.log('Updating import tariff results: ', importResult);
    this.tariffImportResultsSource.next(importResult);
  }

  public updateOperatorUpdateOnProcessValue(isOnProcess: boolean): void {
    console.log('Updating operator update on process value to: ', isOnProcess);
    this.operatorUpdateOnProcessSource.next(isOnProcess);
  }

  public updateSelectedInvoiceTransactions(transactions: ReimbursementBilledItemDto[]): void {
    console.log('Updating selected invoice transactions to: ', transactions);
    this.selectedInvoiceTransactionsSource.next(transactions);
  }

  public updateEvUpdateResult(success: boolean): void {
    console.log('Updating electric vehicle update result to: ', success);
    this.evUpdateResultSource.next(success);
  }

  public getEvUpdateResultObservable(): Observable<boolean> {
    return this.evUpdateResult$;
  }

  public updateVinCheckResult(results: VinCheckResult[]): void {
    console.log('Updating vin check result: ', results);
    this.vinCheckResultSource.next(results);
  }

  public updateCpAttributeImportResults(cpAttributeImportResult: ManualChargepointDataImportResultDto): void {
    console.log('Updating chargepoint attribute import result to: ', cpAttributeImportResult);
    this.cpAttributeImportResultSource.next(cpAttributeImportResult);
  }

  public updateChangeRequestOverviewFilterData(data: ChangeRequestOverviewFilterData): void {
    console.log('Updating change request overview filter data to: ', data);
    this.changeRequestOverviewFilterData.next(data);
  }

  public getCPAttributeImportResultObservable(): Observable<ManualChargepointDataImportResultDto> {
    return this.cpAttributeImportResult$;
  }

  public clearTransactionSelection(clear: boolean): void {
    console.log('Updating clear transaction selection to: ', clear);
    this.clearTransactionSelectionSource.next(clear);
  }

  public updateOrderItemList(orderItems: OrderItemDto[]): void {
    console.log('Updating order item list to: ', orderItems);
    this.orderItemList.next(orderItems);
  }

  public getClearTransactionSelectionObservable(): Observable<boolean> {
    return this.clearTransactionSelection$;
  }

  public getCurrentCpoListObservable(): Observable<Operator[]> {
    return this.currentCpoList$;
  }

  public getOperatorUpdateOnProcessObservable(): Observable<boolean> {
    return this.operatorUpdateOnProcess$;
  }

  public getChangeRequestOverviewFilterData(): Observable<ChangeRequestOverviewFilterData> {
    return this.changeRequestOverviewFilterData$;
  }

  public getOrderItemList(): Observable<OrderItemDto[]> {
    return this.orderItemList$;
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {WorkSheet} from 'xlsx';
import {DateUtils} from 'pcs-commons/utils';
import {DateTime} from "luxon";

@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  private static readonly EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  private static readonly EXCEL_EXTENSION = '.xlsx';

  constructor() {
  }

  public static saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: ExcelService.EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + ExcelService.EXCEL_EXTENSION);
  }

  public exportAsExcelFile<T>(jsonData: T[], excelFileName: string, worksheetName: string, headers?: string[],
                              columnWidths?: Map<string, number>): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonData, {header: headers});
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    if (columnWidths) {
      const wsCols = [];
      columnWidths.forEach((width) => wsCols.push({wch: width}));
      worksheet['!cols'] = wsCols;
    }
    workbook.Sheets[worksheetName] = worksheet;
    workbook.SheetNames = [worksheetName];
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array', compression: true});
    ExcelService.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportSheetsToExcel(workSheets: Map<string, WorkSheet>, excelFileName: string, addDateToFileName = true): void {
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    workSheets.forEach((worksheet, name) => {
      workbook.Sheets[name] = worksheet;
      workbook.SheetNames.push(name);
    });
    const fileName = addDateToFileName ? excelFileName + '-' + DateUtils.toBackendDate(DateTime.now()) : excelFileName;
    const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array', compression: true, cellDates: true});
    ExcelService.saveAsExcelFile(excelBuffer, fileName);
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {FleetService} from '../../services/http/fleet.service';
import {UpdateFleet} from '../../datatypes/update-fleet';
import {Utils} from '../../utils/utils';
import {FormUtils, Utils as CommonsUtils} from 'pcs-commons/utils';
import {Fleet} from '../../datatypes/fleet';
import {SharedDataService} from '../../services/shared-data.service';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {Message} from '../../datatypes/message';
import {MatDialog} from '@angular/material/dialog';
import {DeleteDialogComponent} from '../../dialog/delete-dialog/delete-dialog.component';
import {ProductQuota} from '../../datatypes/product-quota';
import {WebConfigRouterService} from '../../services/web-config-router.service';
import {
  AddressOverview,
  AddressType,
  FleetAddress,
  FleetAddressList,
  FleetAddressListAttributes
} from 'pcs-commons/datatypes';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AddressFormManager, EditHandlerComponent} from 'pcs-commons/components';
import {Directive, ViewChild} from '@angular/core';
import {isEqual} from 'lodash';
import {ConfirmationDialogComponent} from '../../dialog/confirmation-dialog/confirmation-dialog.component';

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class AbstractFleetEditManager extends AbstractNotificationHandler {
  public readonly TOTAL = 'total';
  public readonly billingAddType = AddressType.BILLING;
  public readonly shippingAddType = AddressType.SHIPPING;
  public readonly billingAddAttr = FleetAddressListAttributes.BILLING_ADDRESS;
  public readonly shippingAddAttr = FleetAddressListAttributes.SHIPPING_ADDRESS;

  public productToEdit;
  public accumulatedProductStats: ProductQuota = new ProductQuota();
  public fleetUpdateDto: UpdateFleet;
  public fleet: Fleet = new Fleet();
  public loading = false;
  public showProductEditPanel = false;
  public basicInfoForm: FormGroup;

  // fleet address
  public addressListForm: FormGroup;
  public useBillingAsShippingAddress: FormControl;
  public billingAddressForm: FormGroup;
  public shippingAddressForm: FormGroup;

  // validators
  public fleetName: FormControl;
  public fleetId: FormControl;
  public fleetType: FormControl;
  public sapDebtorNumber: FormControl;
  public doNotSendToFinanceEnabler: FormControl;

  public currentFleetAddresses: FleetAddressList;

  public currBillingAddOverview = new BehaviorSubject<AddressOverview>(null);
  public currBillingAddressOverview$ = this.currBillingAddOverview.asObservable();

  public currShippingAddOverview = new BehaviorSubject<AddressOverview>(null);
  public currShippingAddressOverview$ = this.currShippingAddOverview.asObservable();

  @ViewChild('addressEditHandler') public addressEditHandler: EditHandlerComponent;

  protected constructor(
    public router: WebConfigRouterService,
    public fleetService: FleetService,
    public dataSource: SharedDataService,
    public dialog: MatDialog,
    public translateService: TranslateService) {
    super();
    this.translateService.onLangChange.subscribe(() => this.updateAddressOverviews());
  }

  private static addOrUpdateControl(parentForm: FormGroup, controlName: string, control: AbstractControl): void {
    if (parentForm.contains(controlName)) {
      parentForm.setControl(controlName, control);
    } else {
      parentForm.addControl(controlName, control);
    }
  }

  public onDeleteProduct(product: ProductQuota): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: Utils.deleteProductConfirmMessage(product.productCode)
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        Utils.removeArrayItem(this.fleetUpdateDto.products, product);
        this.updateFleet();
      }
    });
  }

  public enableProductForEdit(product: ProductQuota): void {
    this.productToEdit = product;
    this.showProductEditPanel = true;
  }

  public onSaveProduct(product: any): void {
    if (!product) {
      // it's a close event
      this.productToEdit = null;
      this.showProductEditPanel = false;
      return;
    }

    // if product exist, delete and add the new one
    Utils.removeArrayItem(this.fleetUpdateDto.products, product);
    this.fleetUpdateDto.products.push(product);
    this.updateFleet();
    if (this.productToEdit) { // this mean edit mode
      this.productToEdit = null;
    }
    this.showProductEditPanel = false;
  }

  public updateFleet(): void {
    console.log('updating fleet with the following properties:', this.fleetUpdateDto);

    this.fleetService.updateFleet(this.fleet.fleetId, this.fleetUpdateDto)
      .subscribe({
        next: (response) => {
          // if the fleetId was changed we need to load the page with the new fleetId
          if (this.fleet.fleetId !== response.fleetId) {
            this.router.navigateByUrl('/fleet-edit', {skipLocationChange: true})
              .then(() => this.router.navigate(['fleet-edit', response.fleetId]));
          } else {
            this.updateFleetData(response); // update the dto for future updates
            console.log('Fleet updated to: ', this.fleet);
            this.toggleBasicInfoEdit();
          }
        }
      });
  }

  public updateFleetData(newFleetData: Fleet): void {
    this.fleet = newFleetData;
    this.dataSource.updateProductData(newFleetData.products);
    this.dataSource.updateFleetManagers(newFleetData.fleetManagers);

    this.fleetId.setValue(this.fleet.fleetId);
    this.fleetName.setValue(this.fleet.name);
    this.fleetType.setValue(this.fleet.fleetType);
    this.sapDebtorNumber.setValue(this.fleet.sapDebtorNumber);
    this.doNotSendToFinanceEnabler.setValue(this.fleet.doNotSendToFinanceEnabler);

    this.prepareFleetUpdateDto();
    this.accumulateQuotaStats(newFleetData.products);
    this.refreshAddressListData(newFleetData.fleetAddresses);
  }

  private refreshAddressListData(fleetAddresses: FleetAddressList): void {
    this.currentFleetAddresses = fleetAddresses;
    this.useBillingAsShippingAddress.setValue(this.currentFleetAddresses?.useBillingAsShippingAddress);
    this.updateAddressOverviews();
  }

  private accumulateQuotaStats(products: ProductQuota[]): void {
    if (!products || products.length < 1) {
      this.accumulatedProductStats = new ProductQuota();
      this.accumulatedProductStats.productCode = this.TOTAL;
      return;
    }

    if (products.length === 1) {
      this.accumulatedProductStats = {...products[0]};
      this.accumulatedProductStats.productCode = this.TOTAL;
      return;
    }

    // calculate product quota usage sums
    console.log('reducing products...');
    const result = products.reduce((a, b) => {
      const p = new ProductQuota();
      p.usedAmount = a.usedAmount + b.usedAmount;
      p.maxAmount = a.maxAmount + b.maxAmount;
      p.activeContractsTaycan = a.activeContractsTaycan + b.activeContractsTaycan;
      p.activeContractsOther = a.activeContractsOther + b.activeContractsOther;
      p.newContractsLastMonthTaycan = a.newContractsLastMonthTaycan + b.newContractsLastMonthTaycan;
      p.newContractsLastMonthOther = a.newContractsLastMonthOther + b.newContractsLastMonthOther;
      p.extensionsLastMonth = a.extensionsLastMonth + b.extensionsLastMonth;
      p.extensionsCurrentMonth = a.extensionsCurrentMonth + b.extensionsCurrentMonth;
      p.extensionsNextMonth = a.extensionsNextMonth + b.extensionsNextMonth;
      return p;
    });
    this.accumulatedProductStats = result;
    this.accumulatedProductStats.productCode = this.TOTAL;
    console.log('reduction result: ', result);
  }

  public prepareFleetUpdateDto(): void {
    this.fleetUpdateDto = new UpdateFleet();
    this.fleetUpdateDto.products = this.fleet.products || [];
    this.fleetUpdateDto.name = this.fleet.name;
    this.fleetUpdateDto.fleetId = this.fleet.fleetId;
    this.fleetUpdateDto.sapDebtorNumber = this.fleet.sapDebtorNumber;
    this.fleetUpdateDto.doNotSendToFinanceEnabler = this.fleet.doNotSendToFinanceEnabler;
  }

  public validateAndToggleProductEditPanel(): void {
    if (!this.showProductEditPanel && (this.getProductsNotInFleet().length < 1)) {
      const err = new Message();
      err.message = 'fleet.product.add.error';
      this.showError(err);
      return;
    }
    this.showProductEditPanel = !this.showProductEditPanel;
  }

  public toggleBasicInfoEdit(): void {
    if (this.basicInfoForm.disabled) {
      this.basicInfoForm.enable();
      this.fleetId.disable();
      this.fleetType.disable();
      return;
    }
    this.basicInfoForm.disable();
  }

  public onSaveBasicInfo(): void {
    const updatedBasicInfoValue = this.basicInfoForm.getRawValue();
    let basicInfoUpdated = false;
    basicInfoUpdated = basicInfoUpdated || updatedBasicInfoValue.fleetName !== this.fleet.name;
    basicInfoUpdated = basicInfoUpdated || updatedBasicInfoValue.sapDebtorNumber !== this.fleet.sapDebtorNumber;
    basicInfoUpdated = basicInfoUpdated || updatedBasicInfoValue.doNotSendToFinanceEnabler !== this.fleet.doNotSendToFinanceEnabler;
    if (!basicInfoUpdated) {
      // nothing to update
      console.log('[Update BasicInfo] nothing to update');
      this.toggleBasicInfoEdit();
      return;
    }
    this.fleetUpdateDto.fleetId = updatedBasicInfoValue.fleetId;
    this.fleetUpdateDto.name = updatedBasicInfoValue.fleetName;
    this.fleetUpdateDto.sapDebtorNumber = updatedBasicInfoValue.sapDebtorNumber;
    this.fleetUpdateDto.doNotSendToFinanceEnabler = updatedBasicInfoValue.doNotSendToFinanceEnabler;
    this.updateFleet();
    // this.toggleBasicInfoEdit();
  }

  public onCancelSaveBasicInfo(): void {
    // reset any changes the user made
    this.basicInfoForm.reset();
    this.basicInfoForm.setValue(
      {
        fleetId: this.fleet.fleetId,
        fleetName: this.fleet.name,
        fleetType: this.fleet.fleetType,
        sapDebtorNumber: this.fleet.sapDebtorNumber,
        doNotSendToFinanceEnabler: this.fleet.doNotSendToFinanceEnabler
      });
    this.toggleBasicInfoEdit();
  }

  public abstract getProductsNotInFleet(): string[];

  private updateAddressOverviews(): void {
    console.log('updating address overviews..');
    this.currBillingAddOverview.next(CommonsUtils.toAddressOverview(this.currentFleetAddresses?.billingAddress, this.translateService));
    this.currShippingAddOverview.next(CommonsUtils.toAddressOverview(this.currentFleetAddresses?.shippingAddress, this.translateService));
  }

  public onEditAddress(): void {
    this.addressListForm.enable();
  }

  public onCancelAddressEdit(): void {
    this.addressListForm.disable();
    this.useBillingAsShippingAddress.setValue(this.currentFleetAddresses?.useBillingAsShippingAddress);
  }

  public onSaveAddressEdit(): void {
    this.loading = true;
    console.log('after onSave, addressListForm: ', this.addressListForm);
    const toUpdate = this.fetchFleetAddressList();
    const currentBillingAddress: FleetAddress =
      Object.assign(new FleetAddress(), this.currentFleetAddresses.billingAddress);
    const billingAddressChanged = !isEqual(currentBillingAddress, toUpdate.billingAddress);
    if (billingAddressChanged) {
      this.notifyBillingAddressChangeAndStore(toUpdate);
    } else {
      this.doStoreAddressList(toUpdate);
    }
  }

  private notifyBillingAddressChangeAndStore(addressesToUpdate: FleetAddressList): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,
      {
        maxWidth: 600,
        data: {
          message: CommonsUtils.confirmMessageBillingAddressChange(),
          actionKey: 'button.ok'
        }
      });
    dialogRef.afterClosed().subscribe(
      (confirmed) => {
        if (confirmed) {
          this.doStoreAddressList(addressesToUpdate);
        }
      });
  }

  private doStoreAddressList(toUpdate: FleetAddressList): void {
    if (!this.currentFleetAddresses.billingAddress) {
      this.addFleetAddresses(toUpdate);
    } else {
      this.updateFleetAddresses(toUpdate);
    }
  }

  private addFleetAddresses(toAdd: FleetAddressList): void {
    this.fleetService.addAddresses(this.fleet.fleetId, toAdd).subscribe({
      next: (addressList) => {
        this.onUpdateFleetAddressSuccess(addressList);
      },
      error: () => {
        this.addressEditHandler.isEditMode = true;
        this.loading = false;
      }
    });
  }

  private onUpdateFleetAddressSuccess(addressList: FleetAddressList): void {
    this.refreshAddressListData(addressList);
    this.addressListForm.disable();
    this.loading = false;
    this.showInfo(new Message('address.save.success'));
  }

  private updateFleetAddresses(toUpdate: FleetAddressList): void {
    this.fleetService.updateAddresses(this.fleet.fleetId, toUpdate).subscribe({
      next: (updatedAddressList) => {
        this.onUpdateFleetAddressSuccess(updatedAddressList);
      },
      error: () => {
        this.addressEditHandler.isEditMode = true;
        this.loading = false;
      }
    });
  }

  public onAddressFormInit(addressForm: FormGroup, addressType: AddressType): void {
    console.log(`init event for ${addressType} address form: ${addressForm}`);
    if (AddressType.BILLING === addressType) {
      this.billingAddressForm = addressForm;
      AbstractFleetEditManager.addOrUpdateControl(this.addressListForm, this.billingAddAttr, addressForm);
    } else {
      this.shippingAddressForm = addressForm;
      AbstractFleetEditManager.addOrUpdateControl(this.addressListForm, this.shippingAddAttr, addressForm);
    }
    console.log('addressListForm: ', this.addressListForm);
  }

  protected fetchFleetAddressList(): FleetAddressList {
    const temp = new FleetAddressList();
    temp.useBillingAsShippingAddress = this.useBillingAsShippingAddress.value;
    temp.billingAddress =
      AddressFormManager.fetchAddressData(FormUtils.getFormControl(this.addressListForm, this.billingAddAttr) as FormGroup);
    temp.billingAddress.id = this.currentFleetAddresses.billingAddress?.id;
    if (!temp.useBillingAsShippingAddress) {
      temp.shippingAddress =
        AddressFormManager.fetchAddressData(FormUtils.getFormControl(this.addressListForm, this.shippingAddAttr) as FormGroup);
      temp.shippingAddress.id = this.currentFleetAddresses.shippingAddress?.id;
    }
    console.log('fetched updated address list: ', temp);
    return temp;
  }

}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Message} from './message';
import {NotificationType} from './notification-type.enum';

export class Notification {
  constructor(
    /**
     * Severity (INFO, WARN, ERROR)
     */
    public type?: NotificationType,
    /**
     * Message to be shown: i18n key plus parameters
     */
    public message?: Message,
    /**
     * Indicates notification can't be closed by the user (no close button)
     */
    public isPersistent = false
  ) {}

  public static info(msg: Message): Notification {
    return new Notification(NotificationType.INFO, msg);
  }
  public static warn(msg: Message): Notification {
    return new Notification(NotificationType.WARN, msg);
  }
  public static error(msg: Message, isPersistent = false): Notification {
    return new Notification(NotificationType.ERROR, msg, isPersistent);
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {FormBuilder, FormControl, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {PurchasePriceComponent} from '../../datatypes/purchase-conditions/purchase-price-component';
import {
  PurchaseConditionElement,
  PurchaseConditionElementAttributes
} from '../../datatypes/purchase-conditions/purchase-condition-element';
import {WeekDays} from '../../datatypes/week-days.enum';
import {PurchasePriceComponentType} from '../../datatypes/purchase-conditions/purchase-price-component-type.enum';
import {ValidationType, ValidatorList} from 'pcs-commons/validation';
import {PurchasePriceListValidatorService} from '../../validation/purchase-price-list-validator.service';
import {FormUtils} from 'pcs-commons/utils';
import {CrossFieldFormErrorMatcher} from '../../common/cross-field-form-error-matcher';
import {DateUtils} from 'pcs-commons/utils';
import {ChargepointClass} from '../../datatypes/chargepoint-class';
import {ChargepointClasses} from '../../datatypes/chargepoint-classes';
import {DateTime} from "luxon";

export abstract class PcElementFormManager {
  public readonly weekDaysList: string[] = Object.values(WeekDays);
  public readonly acPowerClassList: ChargepointClass[] = ChargepointClasses.ALL_CP_CLASSES.filter((cpClass) => cpClass.ac);
  public readonly dcPowerClassList: ChargepointClass[] = ChargepointClasses.ALL_CP_CLASSES.filter((cpClass) => !cpClass.ac);
  public readonly priceTypes: string[] = Object.values(PurchasePriceComponentType);

  public crossFieldErrorMatcher = new CrossFieldFormErrorMatcher();
  public currElement: PurchaseConditionElement;
  public startTime: FormControl<string>;
  public endTime: FormControl<string>;
  public startDate: FormControl<string>;
  public endDate: FormControl<string>;
  public timeDataForm: UntypedFormGroup;

  public minKwhConsumption: FormControl<number>;
  public maxKwhConsumption: FormControl<number>;
  public consumptionDataForm: UntypedFormGroup;

  public minDuration: FormControl<number>;
  public maxDuration: FormControl<number>;
  public durationDataForm: UntypedFormGroup;

  public daysOfWeek: FormControl<string[]>;
  public powerClasses: FormControl<string[]>;

  public prices: PurchasePriceComponent[] = [];
  public pricesForm: UntypedFormGroup;

  public unixEpoch: DateTime;
  public minStartDate: DateTime;
  public maxStartDate: DateTime;
  public minEndDate: DateTime;
  public maxEndDate: DateTime;
  public pcValidFrom: DateTime;
  public pcValidUntil: DateTime;
  public pcStatus: string;

  protected constructor(
    public data: any,
    public purchasePriceListValidator: PurchasePriceListValidatorService,
    public formBuilder: FormBuilder) {
    this.currElement = data.elemOnEdit;
    console.log('data gor from purchase condition: ', data);
    this.pcValidFrom = data.validFrom ? DateUtils.convertToDateTimeWithUTC(data.validFrom) : null;
    this.pcValidUntil = data.validUntil ? DateUtils.convertToDateTimeWithUTC(data.validUntil) : null;
    this.pcStatus = data.pcStatus;
    if (!this.currElement) {
      this.currElement = new PurchaseConditionElement();
    }
    if (!this.currElement.prices) {
      this.currElement.prices = [];
    }
    console.log('current PurchaseConditionElement on Edit: ', this.currElement);
    this.unixEpoch = DateTime.fromMillis(0);
    this.minStartDate = this.pcValidFrom ? this.pcValidFrom : this.unixEpoch;
    this.maxStartDate = this.pcValidUntil ? DateUtils.previousDay(this.pcValidUntil) : null;
    this.minEndDate = this.pcValidFrom ? DateUtils.nextDay(this.pcValidFrom) : this.unixEpoch;
    this.maxEndDate = this.pcValidUntil ? this.pcValidUntil : null;
  }

  public setupFromData(): void {
    this.defineFromControls();
    this.defineForms();

    this.startDate.valueChanges.subscribe((newStartDate) => {
      this.minEndDate = newStartDate ? DateUtils.nextDay(newStartDate) : this.unixEpoch;
    });
  }

  private defineForms(): void {
    this.consumptionDataForm = this.formBuilder.group({
        minKwhConsumption: this.minKwhConsumption,
        maxKwhConsumption: this.maxKwhConsumption
      },
      {
        validators: [
          this.validateConsumptionData.bind(this)
        ]
      });

    this.durationDataForm = this.formBuilder.group({
        minDuration: this.minDuration,
        maxDuration: this.maxDuration
      },
      {
        validators: [this.validateDurationData.bind(this)]
      });

    this.timeDataForm = this.formBuilder.group({
      startTime: this.startTime,
      endTime: this.endTime,
      startDate: this.startDate,
      endDate: this.endDate
    });

    this.definePricesFrom();
  }

  private defineFromControls(): void {
    this.startTime = new FormControl<string>(this.currElement.startTime);
    this.endTime = new FormControl<string>(this.currElement.endTime);
    this.startDate = new FormControl<string>(this.currElement.startDate);
    this.endDate = new FormControl<string>(this.currElement.endDate);
    this.minKwhConsumption = new FormControl<number>(this.currElement.minKwhConsumption, ValidatorList.POSITIVE_DECIMAL_OPTIONAL);
    this.maxKwhConsumption = new FormControl<number>(this.currElement.maxKwhConsumption, ValidatorList.POSITIVE_DECIMAL_OPTIONAL);
    this.minDuration = new FormControl<number>(this.currElement.minDuration, ValidatorList.NOT_NEGATIVE_INTEGER_OPTIONAL);
    this.maxDuration = new FormControl<number>(this.currElement.maxDuration, ValidatorList.NOT_NEGATIVE_INTEGER_OPTIONAL);
    this.daysOfWeek = new FormControl<string[]>(this.currElement.daysOfWeek);
    this.powerClasses = new FormControl<string[]>(this.currElement.powerClasses);
  }

  public allFormsValid(): boolean {
    let isValid = this.timeDataForm && this.timeDataForm.valid;
    isValid = isValid && this.consumptionDataForm && this.consumptionDataForm.valid;
    isValid = isValid && this.durationDataForm && this.durationDataForm.valid;
    isValid = isValid && this.pricesForm && this.pricesForm.valid;
    isValid = isValid && this.daysOfWeek && this.daysOfWeek.valid;
    isValid = isValid && this.powerClasses && this.powerClasses.valid;
    return isValid;
  }

  private definePricesFrom(): void {
    // basically there can be 4 different prices for each price type. We define fromControls for each type of prices and their properties.
    const pricesFormControls = new Map<string, FormControl<number>>();
    this.priceTypes.forEach((pType) => {
      // find existing price info for the price type
      const typePrice: PurchasePriceComponent = this.currElement.prices.find((price) => price.type === pType);
      const fcPriceName = pType + '_' + 'price';
      const fcPrice = new FormControl<number>(typePrice ? typePrice.price : null, ValidatorList.PRICE_OPTIONAL);
      pricesFormControls.set(fcPriceName, fcPrice);
      if (PurchasePriceComponentType.FLAT !== pType) {
        const fcStepSizeName = pType + '_' + 'stepSize';
        const fcStepSize = new FormControl<number>(typePrice ? typePrice.stepSize : null, ValidatorList.POSITIVE_INTEGER_OPTIONAL);
        pricesFormControls.set(fcStepSizeName, fcStepSize);
      }
    });
    this.pricesForm = this.formBuilder.group(
      {},
      {validators: [this.purchasePriceListValidator.validate.bind(this.purchasePriceListValidator)]});
    pricesFormControls.forEach((fc, name) => this.pricesForm.addControl(name, fc));
  }

  public validateConsumptionData(form: UntypedFormGroup): ValidationErrors | null {
    const minConsumptionFC = PurchaseConditionElementAttributes.MIN_KWH_CONSUMPTION;
    const maxConsumptionFC = PurchaseConditionElementAttributes.MAX_KWH_CONSUMPTION;

    const minConsumptionNum = FormUtils.getNullableNumber(form, minConsumptionFC);
    const maxConsumptionNum = FormUtils.getNullableNumber(form, maxConsumptionFC);
    if (!minConsumptionNum || !maxConsumptionNum) {
      return null;
    }
    const validConsumptionInfo = minConsumptionNum <= maxConsumptionNum;
    return validConsumptionInfo ? null : {[ValidationType.INVALID_MIN_MAX_COMBINATION]: true};
  }

  public validateDurationData(form: UntypedFormGroup): ValidationErrors | null {
    const minDurationFC = PurchaseConditionElementAttributes.MIN_DURATION;
    const maxDurationFC = PurchaseConditionElementAttributes.MAX_DURATION;

    const minDuration = FormUtils.getNullableNumber(form, minDurationFC);
    const maxDuration = FormUtils.getNullableNumber(form, maxDurationFC);
    if (!minDuration || !maxDuration) {
      return null;
    }
    const validDurationInfo = minDuration <= maxDuration;
    return validDurationInfo ? null : {[ValidationType.INVALID_MIN_MAX_COMBINATION]: true};
  }
}

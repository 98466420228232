<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div id="import" class="mat-elevation-z1">
  <mat-toolbar class="headerToolbar">
    <span class="headerSpan">{{'fleet.idcard.import' | translate }}</span>
    <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="importOnProcess"></mat-spinner>
  </mat-toolbar>
  <import-form (importEvent)="importCards($event)"></import-form>
</div>
<mat-divider class="section-divider-small" *ngIf="cardInventory$ || cardImportResult.length > 0"></mat-divider>
<div id="importResult" class="mat-elevation-z1" *ngIf="cardImportResult.length > 0">
  <mat-toolbar class="headerToolbar">
    <span class="headerSpan">{{'fleet.idcard.importresult' | translate }}</span>
  </mat-toolbar>
  <data-table id="importResultTable" [data]="observableOfCardImportResult()" [i18nBase]="'fleet.tableheader'"
              [dataColumns]="importResultTableColumns" [actionButtons]="false">
  </data-table>
</div>
<div id="cardList" *ngIf="cardInventory$ && (cardImportResult.length < 1)" class="mat-elevation-z1 padding-lr-24">
  <mat-toolbar class="headerToolbar">
    <span class="headerSpan">{{'fleet.idcard.list' | translate }}</span>
    <mat-spinner [diameter]="30" [strokeWidth]="5" class="toolbarSpinner" *ngIf="loading"></mat-spinner>
  </mat-toolbar>
  <pcs-rfidcards-table class="rfidcards-table"
                       [selectColumnConfigs]="selectColumnConfigs"
                       [data]="cardInventory$" [i18nBase]="'fleet.tableheader'"
                       [dataColumns]="cardsTableColumns"
                       [actionButtons]="false">
  </pcs-rfidcards-table>
</div>
<mat-divider class="section-divider-small"></mat-divider>
<button mat-raised-button class="bottom-button normalButton" (click)="onBack()">
  <mat-icon class="button-icon">arrow_back</mat-icon>
  <span>{{ 'button.back' | translate }}</span>
</button>

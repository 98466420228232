/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[autofocus]'
})
export class AutofocusDirective implements OnInit {
  @Input('autofocus') isFocused: boolean;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    if (this.isFocused) {
      console.log('autofocus element: ', this.el);
      this.el.nativeElement.focus();
      this.el.nativeElement.scrollIntoView({behavior: 'smooth', block: 'center'});
    }
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {Message} from "../../datatypes/message";
import {User} from "../../datatypes/user";
import {ChangeRequestService} from "../../services/http/audit/change-request.service";
import {CreateChangeRequest} from "../../datatypes/audit-flow/create-change-request";
import {CreateChangeRequestDialogData} from "../../datatypes/audit-flow/create-change-request-dialog-data";
import {map, Observable, startWith} from "rxjs";
import {NotificationStore} from "pcs-commons/notification";
import {Notification} from "pcs-commons/datatypes";
import {ChangeRequestPayloadType} from "../../datatypes/inbox/change-request-payload-type";
import {ChangeRequestSubscriberType} from "../../datatypes/audit-flow/change-request-subscriber-type";
import {ChangeRequestSubscriberDto} from "../../datatypes/audit-flow/change-request-subscriber-dto";
import {UserDataSourceService} from "../../services/user-data-source.service";

@Component({
  selector: 'pcs-create-change-request-dialog',
  templateUrl: './create-change-request-dialog.component.html',
  styleUrls: [
    '../dialog-common-styles.css', './create-change-request-dialog.component.css']
})
export class CreateChangeRequestDialogComponent implements OnInit {
  public changeRequestForm: UntypedFormGroup;
  public title: FormControl<string>;
  public reviewer1Input: FormControl<User>;
  public reviewer2Input: FormControl<User>;
  public comment: FormControl<string>;
  private readonly potentialReviewers: User[] = [];
  private readonly objectType: ChangeRequestPayloadType | null = null;
  private readonly updatedValue: string | null = null;
  private readonly originalValue: string | null = null;
  private readonly objectId: number | null = null;
  public message: Message;
  public loading = false;
  public filteredOptionsReviewer1: Observable<User[]> = null;
  public filteredOptionsReviewer2: Observable<User[]> = null;

  constructor(
    private matDialogRef: MatDialogRef<CreateChangeRequestDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: CreateChangeRequestDialogData,
    public changeRequestService: ChangeRequestService,
    private userDataSource: UserDataSourceService,) {
    this.message = data.message;
    this.potentialReviewers = data.reviewerChoices;
    this.objectType = data.objectType;
    this.objectId = data.objectId;
    this.updatedValue = data.updatedValueAsJson;
    this.originalValue = data.originalValueAsJson;
    console.log('data', data);
  }

  public ngOnInit(): void {
    this.reviewer1Input = new FormControl(undefined, [Validators.required, this.reviewerValidator()]);
    this.reviewer2Input = new FormControl(undefined, [Validators.required, this.reviewerValidator()]);
    this.comment = new FormControl(undefined, [Validators.required, Validators.maxLength(500)]);
    this.title = new FormControl(undefined, [Validators.required, Validators.maxLength(50)]);
    this.changeRequestForm = this.formBuilder.group(
      {
        reviewer1Input: this.reviewer1Input,
        reviewer2Input: this.reviewer2Input,
        comment: this.comment,
        title: this.title
      });

    this.filteredOptionsReviewer1 = this.reviewer1Input.valueChanges.pipe(
      startWith(undefined),
      map(value => {
        return this._filterReviewerList(value, this.reviewer2Input.value);
      }),
    );
    this.filteredOptionsReviewer2 = this.reviewer2Input.valueChanges.pipe(
      startWith(undefined),
      map(value => {
        return this._filterReviewerList(value, this.reviewer1Input.value);
      }),
    );
  }

  private _filterReviewerList(value: User|string, userToExclude: User): User[] {
    const searchString: string = (value instanceof User) ? value.login : String(value || '');
    return this.potentialReviewers.filter(user => {
      return (userToExclude ? userToExclude.login != user.login : true)
        && (searchString ? user.login.toLowerCase().includes(searchString.toLowerCase()) : true);
    });
  }

  private reviewerValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.potentialReviewers && (control.value && !(control.value instanceof Object))) {
        return {wrong: true};
      }
      return null;
    };
  }

  public displayText(user: User): string {
    if (!user) {
      return '';
    }
    return user.login;
  }

  public sendCreateChangeRequest(): void {
    this.loading = true;
    const changeRequest = this.createChangeRequest();
    this.changeRequestService.createChangeRequest(changeRequest).subscribe({
      next: () => {
        this.notifySuccess();
        this.matDialogRef.close(true);
      },
      complete: () => this.loading = false
    });
  }

  private createChangeRequest(): CreateChangeRequest {
    const changeRequest = new CreateChangeRequest();
    changeRequest.comment = this.comment.value;
    changeRequest.payloadObjectId = this.objectId;
    changeRequest.payloadObjectType = this.objectType;
    changeRequest.updatedValue = this.updatedValue;
    changeRequest.originalValue = this.originalValue;
    changeRequest.action = this.data.action;
    const initiator = this.createSubscriber(ChangeRequestSubscriberType.INITIATOR, this.userDataSource.currentUserId, this.userDataSource.currentUserLogin);
    const reviewer1 = this.createSubscriber(ChangeRequestSubscriberType.REVIEWER, this.reviewer1Input.value.userId, this.reviewer1Input.value.login);
    const reviewer2 = this.createSubscriber(ChangeRequestSubscriberType.REVIEWER, this.reviewer2Input.value.userId, this.reviewer2Input.value.login);
    changeRequest.subscribers = [];
    changeRequest.subscribers.push(initiator, reviewer1, reviewer2);
    changeRequest.title = this.title.value;
    return changeRequest;
  }

  private createSubscriber(userType: ChangeRequestSubscriberType, userId: string, login: string): ChangeRequestSubscriberDto {
    const user = new ChangeRequestSubscriberDto();
    user.userId = userId;
    user.login = login;
    user.type = userType;
    return user;
  }

  private notifySuccess(): void {
    NotificationStore.instance.notify(Notification.info(new Message("audit.changeRequest.create.success")));
  }
}


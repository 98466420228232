<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data && basicFeeForm" class="scrollable-table-container" id="editFeeTable">
  <form [formGroup]="basicFeeForm">
    <mat-table [dataSource]="tableDataSource">
      <div *ngFor="let col of dataColumns; let columnIndex = index">
        <ng-container matColumnDef="{{col}}" *ngIf="col !== actionColumn">
          <mat-header-cell priceTableCell [column]="col" *matHeaderCellDef>{{getI18nBase(col) | translate}}
          </mat-header-cell>
          <mat-cell priceTableCell [column]="col" *matCellDef="let element">
            <mat-form-field *ngIf="col === VALID_FROM_DATE" class="date-time-picker">
              <input matInput [matDatepicker]="pickerValidFrom"
                     [formControl]="getFormControl(element, col)">
              <mat-datepicker-toggle matSuffix [for]="pickerValidFrom"></mat-datepicker-toggle>
              <mat-datepicker #pickerValidFrom></mat-datepicker>
            </mat-form-field>
            <mat-form-field *ngIf="col === VALID_FROM_TIME"
                            class="time-picker">
              <input matInput [formControl]="getFormControl(element, col)" type="time" step="1">
            </mat-form-field>

            <mat-form-field *ngIf="col === VALID_UNTIL_DATE" class="date-time-picker">
              <input matInput [matDatepicker]="pickerValidUntil"
                     [formControl]="getFormControl(element, col)">
              <mat-datepicker-toggle matSuffix [for]="pickerValidUntil"></mat-datepicker-toggle>
              <mat-datepicker #pickerValidUntil></mat-datepicker>
            </mat-form-field>

            <mat-form-field *ngIf="col !== VALID_FROM_DATE && col !== VALID_FROM_TIME && col !== VALID_UNTIL_DATE && col !== VALID_UNTIL_TIME && isColEditable(col)" class="price-cell">
              <input class="price-cell" matInput [formControl]="getFormControl(element, col)">
            </mat-form-field>
            <mat-form-field *ngIf="col === VALID_UNTIL_TIME"
                            class="time-picker">
              <input matInput [formControl]="getFormControl(element, col)" type="time" step="1">
            </mat-form-field>

            <div *ngIf="!isColEditable(col)">
              {{getValue(element, col)}}
            </div>
          </mat-cell>
        </ng-container>
      </div>
      <ng-container matColumnDef="tableAction" stickyEnd>
        <mat-header-cell *matHeaderCellDef
                         class="actionCell center details-column"> {{'taxes.tableheader.action' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let element" class="actionCell center details-column">
          <button mat-mini-fab class="tableActionBtn delete" (click)="deleteElement(element)">
            <mat-icon> delete_outline</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: dataColumns"></mat-row>
    </mat-table>
  </form>
</div>

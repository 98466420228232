/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Injectable} from '@angular/core';
import {HeaderEnabled} from './header-enabled.abstract';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Currency} from '../../datatypes/currency';
import {environment} from '../../../environments/environment';
import {PurchaseCondition} from '../../datatypes/purchase-conditions/purchase-condition';
import {
  PurchaseConditionOverviewElement
} from '../../datatypes/purchase-conditions/purchase-condition-overview-element';
import {PurchaseConditionFilterDto} from '../../datatypes/PurchaseConditionFilterDto';
import {timeout} from 'rxjs/operators';
import {Parameters} from '../../global/parameters';
import {TariffImportRecord} from '../../datatypes/tariff-import-record';
import {DateUtils} from "pcs-commons/utils";

@Injectable({
  providedIn: 'root'
})
export class PurchaseConditionService extends HeaderEnabled {

  constructor(private http: HttpClient) {
    super();
  }

  public getCurrencies(): Observable<Currency[]> {
    const url = environment.purchaseConditionUrl + 'currencies';
    return this.http.get<Currency[]>(url, {headers: this.headers});
  }

  public create(toCreate: PurchaseCondition): Observable<PurchaseCondition> {
    const url = environment.purchaseConditionUrl + 'conditions';
    return this.http.post<PurchaseCondition>(url, toCreate, {headers: this.headers});
  }

  public update(toUpdate: PurchaseCondition): Observable<PurchaseCondition> {
    const url = environment.purchaseConditionUrl + 'conditions';
    return this.http.put<PurchaseCondition>(url, toUpdate, {headers: this.headers});
  }

  public delete(id: number): Observable<any> {
    const url = environment.purchaseConditionUrl + 'conditions/' + id;
    return this.http.delete(url, {headers: this.headers});
  }

  public getPurchaseConditionById(id: string): Observable<PurchaseCondition> {
    const url = environment.purchaseConditionUrl + 'conditions/' + id;
    return this.http.get<PurchaseCondition>(url, {headers: this.headers});
  }

  public getPurchaseConditions(filter: PurchaseConditionFilterDto, offset, pageSize): Observable<PurchaseConditionOverviewElement[]> {
    const url = environment.purchaseConditionUrl + 'conditions/find';
    return this.http.post<PurchaseConditionOverviewElement[]>(url,
      filter,
      {
        headers: this.headers,
        params: {
          offset,
          pageSize
        }
      });
  }

  public importTariffsForEVSE(cpoid: number, validFrom: Date, body: any): Observable<TariffImportRecord[]> {
    const url = environment.purchaseConditionUrl + 'tariff/import';
    return this.http.post<TariffImportRecord[]>(
      url,
      body,
      {
        headers: this.headers,
        params: {
          operatorid: cpoid,
          validFrom: validFrom.toISOString().split('T')[0]
        }
      }
    ).pipe(
      timeout(Parameters.TARIFF_IMPORT_TIMEOUT_MILLIS)
    );
  }
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Operator} from '../operator';
import {PurchaseConditionElement} from './purchase-condition-element';
import {Identifiable} from 'pcs-commons/datatypes';

export class PurchaseCondition extends Identifiable {
  public cpo: Operator;
  public country: string;
  public tariffId: string;
  public evseIds: string[];
  public currency: string;
  public maxPrice: number;
  public validFrom: string;
  public validUntil: string;
  public status: string;
  public elements: PurchaseConditionElement[];
}

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ContractHistoryItem, ContractHistoryService} from "../services/http/contract-history.service";
import {FormControl, FormGroup} from "@angular/forms";
import {ValidatorList} from "pcs-commons/validation";
import {Parameters} from "../global/parameters";

@Component({
  selector: 'pcs-contract-history',
  templateUrl: './contract-history.component.html',
  styleUrls: ['./contract-history.component.css']
})
export class ContractHistoryComponent {
  public loading = false;

  private historySource = new BehaviorSubject<ContractHistoryItem[]>([]);
  public history$ = this.historySource.asObservable();

  public vinFilter = new FormControl('', ValidatorList.STRING_DEFAULT);

  public inputMaxLength = Parameters.DEFAULT_MAX_LENGTH;

  public filterFormGroup: FormGroup = new FormGroup<any>({
    vinFilter: this.vinFilter
  });

  constructor(private contractHistoryService: ContractHistoryService) {
  }

  public async loadHistory(): Promise<void> {
    if (this.vinFilter.value && this.vinFilter.valid) {
      this.loading = true;
      try {
        this.historySource.next(await this.contractHistoryService.readHistory(this.vinFilter.value));
      } finally {
        this.loading = false;
      }
    }
  }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div *ngIf="data && editCurrencyFactorForm" class="scrollable-table-container" id="editCurrencyFactorsTable">
  <mat-table [dataSource]="tableDataSource">
    <div *ngFor="let col of dataColumns; let columnIndex = index" [formGroup]="editCurrencyFactorForm">
      <ng-container matColumnDef="{{col}}" *ngIf="col !== actionColumn">
        <mat-header-cell *matHeaderCellDef>{{ getI18nBase(col) | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">

          <mat-form-field *ngIf="col.startsWith('description')">
              <textarea matInput [formControlName]="getFromControlNameWithIndex(col, element.index)"
                        [formControl]="getFormControl(col, element.index)"></textarea>
            <mat-error *ngIf="getFormControl(col, element.index) && getFormControl(col, element.index).invalid">
              {{ getError(getFormControl(col, element.index)) | async }}
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="col.startsWith('value')">
            <input matInput [formControlName]="getFromControlNameWithIndex(col, element.index)"
                   [formControl]="getFormControl(col, element.index)">
            <mat-error
              *ngIf="getFormControl(col, element.index) && getFormControl(col, element.index).invalid">
              {{ getError(getFormControl(col, element.index)) | async }}
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="col.startsWith('reverseValue')">
            <input matInput [formControlName]="getFromControlNameWithIndex(col, element.index)"
                   [formControl]="getFormControl(col, element.index)">
            <mat-error
              *ngIf="getFormControl(col, element.index) && getFormControl(col, element.index).invalid">
              {{ getError(getFormControl(col, element.index)) | async }}
            </mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="col.startsWith('validFromDate')"
                          class="date-picker">
            <input matInput [matDatepicker]="pickerValidFrom" [placeholder]="getDatePlaceholder()"
                   [formControlName]="getFromControlNameWithIndex(col, element.index)"
                   [formControl]="getFormControl(col, element.index)" [min]="getMinDate()">
            <mat-datepicker-toggle [for]="pickerValidFrom" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #pickerValidFrom></mat-datepicker>
          </mat-form-field>

          <mat-form-field *ngIf="col.startsWith('validFromTime')"
                          class="time-picker">
            <input matInput [formControlName]="getFromControlNameWithIndex(col, element.index)"
                   type="time" step="1">
          </mat-form-field>

          <mat-form-field *ngIf="col.startsWith('validUntilDate')"
                          class="date-picker">
            <input matInput [matDatepicker]="pickerValidUntil" [placeholder]="getDatePlaceholder()"
                   [formControlName]="getFromControlNameWithIndex(col, element.index)"
                   [formControl]="getFormControl(col, element.index)" [min]="getMinDate()">
            <mat-datepicker-toggle matSuffix [for]="pickerValidUntil"></mat-datepicker-toggle>
            <mat-datepicker #pickerValidUntil></mat-datepicker>
          </mat-form-field>
          <mat-form-field *ngIf="col.startsWith('validUntilTime')"
                          class="time-picker">
            <input matInput [formControlName]="getFromControlNameWithIndex(col, element.index)" type="time" step="1">
          </mat-form-field>
        </mat-cell>
      </ng-container>
    </div>
    <ng-container matColumnDef="tableAction" stickyEnd>
      <mat-header-cell *matHeaderCellDef
                       class="actionCell center details-column"> {{ 'tableHeader.action' | translate }}
      </mat-header-cell>
      <mat-cell *matCellDef="let element" class="actionCell center details-column">
        <button mat-mini-fab class="tableActionBtn delete" (click)="deleteElement(element)"
                *ngIf="deletionAllowed(element)">
          <mat-icon> delete_outline</mat-icon>
        </button>
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="dataColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataColumns;"></mat-row>
  </mat-table>
</div>

/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Pipe, PipeTransform} from '@angular/core';
import {TranslationHelperService} from 'pcs-commons/http';
import {CountryCode} from 'pcs-commons/datatypes';

@Pipe({
  name: 'translateCountry',
  pure: false
})
export class TranslateCountryPipe implements PipeTransform {

  constructor(private transactionHelper: TranslationHelperService) {
  }

  public transform(value: string): string {
    if (!value) {
      return "";
    }
    const alpha2Code = CountryCode[value];
    if (alpha2Code) {
      return this.transactionHelper.getCountryTranslation(alpha2Code);
    }
    // assuming the value is already alpha2 country code
    return this.transactionHelper.getCountryTranslation(value);
  }

}

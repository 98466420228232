/**
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2025 Dr. Ing. h.c. F. Porsche AG.
 */
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Operator } from '../datatypes/operator';
import { ChargepointService } from '../services/http/chargepoint.service';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { PermissionAware } from '../permission-aware';
import { AccessRights } from '../datatypes/access-rights.enum';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SharedDataService } from '../services/shared-data.service';
import { CpoDownloaderService } from '../services/cpo-downloader.service';
import { OperatorFilter } from '../datatypes/operator-filter';
import { AbstractNotificationHandler } from 'pcs-commons/notification';
import { Message } from '../datatypes/message';
import { WebConfigRouterService } from '../services/web-config-router.service';
import { BaseUrls } from '../datatypes/base-urls.enum';
import { AuditService } from '../services/audit.service';
import { SubOperatorService } from '../services/http/sub-operator.service';
import { OperatorSearchState } from './OperatorSearchState';
import { Router } from '@angular/router';

@Component({
  selector: 'pcs-operators',
  templateUrl: './operators.component.html',
  styleUrls: ['./operators.component.css']
})
export class OperatorsComponent extends AbstractNotificationHandler implements OnInit, PermissionAware, OnDestroy {
  public readonly reqEditPermission = [AccessRights.CPO_AND_PARTNER_EDIT_WEB];
  public readonly toUnsubscribe: Subscription[] = [];
  public errormessage: string;
  public operators: Operator[] = [];
  public dataFound: boolean;
  public loading = false;
  public exportingCpos = false;

  public readonly pageSizeOptions = [10, 50, 100, 200];
  public resultLength = 0;
  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;

  public pageSize = this.pageSizeOptions[0];
  public pageIndex = 0;

  protected operatorFilter: OperatorFilter;
  private state: OperatorSearchState = undefined;

  constructor(
    private chargepointService: ChargepointService,
    private subOperatorService: SubOperatorService,
    private cpoDownloader: CpoDownloaderService,
    private dataService: SharedDataService,
    private auditService: AuditService,
    private routerService: WebConfigRouterService,
    private router: Router
  ) {
    super();
    const navigation = this.router.getCurrentNavigation();
    console.log('current state from cpo page:', navigation?.extras?.state);
    if (navigation?.extras?.state) {
      this.state = navigation.extras.state['data'];
    } else {
      this.state = undefined;
    }
  }

  public ngOnDestroy(): void {
    this.toUnsubscribe.forEach((sub) => sub.unsubscribe());
    this.state = undefined;
  }

  public ngOnInit(): void {
    this.operators = [];
    console.log('state passed to cpo view: ', this.state);
    this.operatorFilter = this.state?.filter || new OperatorFilter();
    this.paginator.pageSize = this.state?.pageSize || this.pageSizeOptions[0];
    this.paginator.pageIndex = this.state?.pageIndex || 0;
    this.getOperators(this.paginator.pageIndex, this.paginator.pageSize);
    this.toUnsubscribe.push(this.cpoDownloader.downloadingCpos$.subscribe((next) => (this.exportingCpos = next)));
  }

  public getOperators(pageIndex: number, pageSize: number): void {
    // reset error message:
    this.loading = true;
    this.errormessage = null;
    this.pageSize = pageSize;
    this.pageIndex = pageIndex;

    const offset = pageIndex * pageSize;
    // call the chargepoint service method to load chargepoint operators:
    this.chargepointService.getOperators(offset, pageSize + 1, this.operatorFilter).subscribe({
      next: (operators) => {
        this.handleResponse(operators, pageIndex, pageSize);
        this.loading = false;
      },
      error: () => (this.loading = false)
    });
  }

  public handlePageChange(pageEvent: PageEvent): void {
    this.getOperators(pageEvent.pageIndex, pageEvent.pageSize);
  }

  public get observableOfOperators(): Observable<Operator[]> {
    return of(this.operators);
  }

  public handleResponse(operators: Operator[], pageIndex: number, pageSize: number): void {
    this.resultLength = pageIndex * pageSize + operators.length;
    // since we requested one extra element, we remove it before showing
    if (operators.length > pageSize) {
      operators.splice(operators.length - 1, 1);
    }
    this.operators = operators;
    this.dataFound = this.operators?.length > 0;

    if (this.operators?.length === 0) {
      this.showInfoMsg('operators.noRecordsFound');
    }
  }

  public get cpoDataColumns(): string[] {
    return Operator.dataColumns;
  }

  public onDownloadCPOs(): void {
    this.cpoDownloader.downloadCPOs();
  }

  public onShowSubOperators(operator: Operator): void {
    const data: OperatorSearchState = {
      selectedCpoCode: operator.cpoCode,
      filter: this.operatorFilter,
      pageSize: this.paginator.pageSize,
      pageIndex: this.paginator.pageIndex
    };
    this.routerService
      .navigate([BaseUrls.OPERATORS, operator.id], {
        state: { data },
        skipLocationChange: true
      })
      .then(() => {
      });
  }

  public toggleEnergySource(operator: Operator): void {
    const toUpdate = Operator.clone(operator);
    toUpdate.renewableEnergySource = !toUpdate.renewableEnergySource;
    this.updateOperator(toUpdate);
  }

  public togglePlugAndCharge(operator: Operator): void {
    const toUpdate = Operator.clone(operator);
    toUpdate.enablePlugAndCharge = !toUpdate.enablePlugAndCharge;
    this.updateOperator(toUpdate);
  }

  public toggleShowStationName(operator: Operator): void {
    const toUpdate = Operator.clone(operator);
    toUpdate.showStationName = !toUpdate.showStationName;
    this.updateOperator(toUpdate);
  }

  public updateDisplayName(operator: Operator): void {
    const toUpdate = Operator.clone(operator);
    this.updateOperator(toUpdate);
  }

  public toggleRemoteAuth(operator: Operator): void {
    const toUpdate = Operator.clone(operator);
    toUpdate.enableRemoteAuth = !toUpdate.enableRemoteAuth;
    this.updateOperator(toUpdate);
  }

  public toggleRfidAuth(operator: Operator): void {
    const toUpdate = Operator.clone(operator);
    toUpdate.enableRfidAuth = !toUpdate.enableRfidAuth;
    this.updateOperator(toUpdate);
  }

  public async togglePreferred(operator: Operator): Promise<void> {
    const previousPreferredAttribute = operator.preferred;
    const newPreferredAttribute = !operator.preferred;
    const subOperators = await firstValueFrom(this.subOperatorService.getSubOperatorsByOperatorId(operator.id));
    await this.auditService.initiateChangeRequestForCPOToSetPreferredAttribute(
      operator,
      subOperators,
      previousPreferredAttribute,
      newPreferredAttribute
    );
  }

  public onFilterChange(filter: OperatorFilter): void {
    this.operatorFilter = filter;
    this.paginator.pageIndex = 0;
    this.getOperators(this.paginator.pageIndex, this.paginator.pageSize);
  }

  private showInfoMsg(message: string): void {
    const msg = new Message();
    msg.message = message;
    this.showInfo(msg);
  }

  private updateOperator(toUpdate: Operator): void {
    this.dataService.updateOperatorUpdateOnProcessValue(true);
    this.chargepointService.updateOperator(toUpdate).subscribe({
      next: () => this.getOperators(this.pageIndex, this.pageSize),
      error: () => this.getOperators(this.pageIndex, this.pageSize),
      complete: () => this.dataService.updateOperatorUpdateOnProcessValue(false)
    });
  }
}

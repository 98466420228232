/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ValidationMessagePipe, ValidatorList} from 'pcs-commons/validation';
import {Action, CountryCode, Message, Notification, NotificationType} from 'pcs-commons/datatypes';
import {FormHelper} from '../../utils/form-helper';
import {OcpiPartnerInitializationData} from '../../datatypes/ocpi-partner-initialization-data';
import {OcpiPartnerService} from '../../services/http/ocpi-partner.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ClipboardService} from 'ngx-clipboard';
import {TranslateService} from '@ngx-translate/core';
import {firstValueFrom, Observable} from "rxjs";
import {RoamingPlatformService} from "../../services/http/roaming-platform.service";
import {IopPlatformDto} from "../../datatypes/iop-platform-dto";
import {OcpiPartnerDialogData} from "../ocpi-partner-dialog-data";
import {OcpiPartner} from "../../datatypes/ocpi-partner";
import {NotificationStore} from "pcs-commons/notification";
import {PullStaticDataOption} from "../../datatypes/pull-static-data-option";

@Component({
    selector: 'pcs-ocpi-partner-details',
    templateUrl: './ocpi-partner-details.component.html',
    styleUrls: [
        '../../dialog/dialog-common-styles.css',
        './ocpi-partner-details.component.css']
})
export class OcpiPartnerDetailsComponent implements OnInit {
    public loading = false;
    public partnerForm: FormGroup;
    public partyId: FormControl<string>;
    public url: FormControl<string>;
    public countryCode: FormControl<string>;
    public token: FormControl<string>;
    public platform: FormControl<string>;
    public certCn: FormControl<string>;
    public authCheckLocation: FormControl<boolean>;
    public pullStaticDataOption: FormControl<string>;
    public requestAuthorizationId: FormControl<boolean>;
    public allowOfflineAuthentication: FormControl<boolean>;
    public countryList: string[] = Object.values(CountryCode);
    public charmToken: string;
    public platformList: string[] = [];
    public pullStaticDataOptionList: string[] = Object.values(PullStaticDataOption);
    private readonly mode: Action;
    private readonly partnerSource: OcpiPartner;


    constructor(
        private formBuilder: FormBuilder,
        private ocpiPartnerService: OcpiPartnerService,
        private dialogRef: MatDialogRef<OcpiPartnerDetailsComponent>,
        private validationPipe: ValidationMessagePipe,
        private clipboardService: ClipboardService,
        private translate: TranslateService,
        private platformService: RoamingPlatformService,
        @Inject(MAT_DIALOG_DATA) private data: OcpiPartnerDialogData) {
        this.mode = data.mode;
        this.partnerSource = data.partnerSource;
    }

    public async ngOnInit(): Promise<void> {
        console.log("OcpiPartnerDetailsComponent: isCreateMode: ", this.isCreateMode());
        console.log("partnerSource: ", this.partnerSource);
        await this.getRoamingPlatforms();
        this.initializeFormGroup();
    }

    public addNewPartner(): void {
        const newPartner = new OcpiPartnerInitializationData();
        newPartner.partyId = FormHelper.getStringValue(this.partnerForm, 'partyId');
        newPartner.url = FormHelper.getStringValue(this.partnerForm, 'url');
        newPartner.token = FormHelper.getStringValue(this.partnerForm, 'token');
        newPartner.countryCode = FormHelper.getStringValue(this.partnerForm, 'countryCode');
        newPartner.platformName = FormHelper.getStringValue(this.partnerForm, 'platform');
        newPartner.certCn = FormHelper.getStringValue(this.partnerForm, 'certCn');
        newPartner.authCheckLocation = FormHelper.getValue(this.partnerForm, 'authCheckLocation');
        newPartner.pullStaticDataOption = FormHelper.getStringValue(this.partnerForm, 'pullStaticDataOption');
        newPartner.requestAuthorizationId = FormHelper.getValue(this.partnerForm, 'requestAuthorizationId');
        newPartner.allowOfflineAuthentication = FormHelper.getValue(this.partnerForm, 'allowOfflineAuthentication');

        this.ocpiPartnerService.configureNewPartner(newPartner).subscribe(
            (response) => this.charmToken = response.token
        );
    }

    public isSaveAllowed(): boolean {
        return !this.partnerForm?.pristine && this.partnerForm?.valid;
    }

    public isCreateMode(): boolean {
        return this.mode && Action.CREATE === this.mode;
    }

    public copyTokenToClipboard(token: string): void {
        if (token) {
            this.clipboardService.copyFromContent(token);
            const notification = new Notification(NotificationType.INFO, new Message('ocpi.partner.message.copiedToken'));
            NotificationStore.instance.notify(notification);
        }
    }

    public onSave(): void {
        if (Action.CREATE === this.mode) {
            this.addNewPartner();
        } else if (Action.EDIT === this.mode) {
            this.updatePartner();
        }
    }

    public onCancel(): void {
        this.dialogRef.close(false);
    }

    public onOk(): void {
        this.dialogRef.close(true);
    }

    public getError(fc: FormControl): Observable<any> {
        return this.validationPipe.transform(fc);
    }

    private initializeFormGroup(): void {
        this.partyId = new FormControl(this.partnerSource?.partyId, ValidatorList.PARTNER_ID);
        this.countryCode = new FormControl(this.partnerSource?.country, ValidatorList.ALPHA2_COUNTRY_REQUIRED);
        this.platform = new FormControl(this.partnerSource?.platform?.name);
        this.authCheckLocation = new FormControl(this.partnerSource?.authCheckLocation);
        this.pullStaticDataOption = new FormControl(this.partnerSource?.pullStaticDataOption);
        this.requestAuthorizationId = new FormControl(this.partnerSource?.requestAuthorizationId);
        this.allowOfflineAuthentication = new FormControl(this.partnerSource?.allowOfflineAuthentication);
        this.partnerForm = this.formBuilder.group({
            partyId: this.partyId,
            countryCode: this.countryCode,
            platform: this.platform,
            authCheckLocation: this.authCheckLocation,
            pullStaticDataOption: this.pullStaticDataOption,
            requestAuthorizationId: this.requestAuthorizationId,
            allowOfflineAuthentication: this.allowOfflineAuthentication
        });
        if (this.isCreateMode()) {
            this.url = new FormControl('', ValidatorList.PARTNER_URL);
            this.token = new FormControl('', ValidatorList.PARTNER_TOKEN);
            this.certCn = new FormControl('', Validators.maxLength(255));
            this.partnerForm.addControl('url', this.url);
            this.partnerForm.addControl('token', this.token);
            this.partnerForm.addControl('certCn', this.certCn);
        } else {
            this.partyId.disable();
            this.countryCode.disable();
            this.platform.disable();
        }
    }

    private async getRoamingPlatforms(): Promise<void> {
        const platforms = await firstValueFrom(this.platformService.getRoamingPlatforms());
        this.platformList = IopPlatformDto.toIopPlatformDto(platforms).map(value => value.name);
    }

    private updatePartner(): void {
        const partnerUpdateData = this.partnerForm.value;
        console.log("updating ocpi partner data: ", partnerUpdateData);
        this.ocpiPartnerService.updatePartner(this.partnerSource.id, partnerUpdateData).subscribe(() => {
            const notification = new Notification(NotificationType.INFO, new Message('ocpi.partner.message.update.successful'));
            NotificationStore.instance.notify(notification);
            this.dialogRef.close(true);
        });
    }
}

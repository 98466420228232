/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Input, Renderer2} from '@angular/core';
import {UserDataSourceService} from '../../services/user-data-source.service';
import {UserGroup} from '../../datatypes/user-group';
import {FormControl} from '@angular/forms';
import {ValidationMessagePipe} from 'pcs-commons/validation';
import {InlineEditableTableBaseComponent} from '../table-base/inline-editable-table-base.component';
import {User} from '../../datatypes/user';
import {Role} from '../../datatypes/role.enum';

@Component({
  selector: 'pcs-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: [
    '../data-table.component.css',
    '../action-column-style.css'
  ]
})
export class UserTableComponent extends InlineEditableTableBaseComponent {
  @Input() public availableGroups: UserGroup[];

  public userGroupCtrl = new FormControl('');
  public userGroupColumn = 'userGroup';

  constructor(
    renderer: Renderer2,
    private userDataSource: UserDataSourceService,
    validationPipe: ValidationMessagePipe) {
    super(renderer, validationPipe);
  }

  public resetAllControlValues(): void {
    this.userGroupCtrl.reset();
  }

  public resetToOriginalValues(): void {
    this.userGroupCtrl.setValue(this.currElementOnProcess.userGroup);
  }

  public setControlValues(source: User): void {
    this.userGroupCtrl.setValue(source.userGroup);
  }

  public doEditUser(toEdit: User): void {
    if (toEdit.userGroup === this.userGroupCtrl.value) {
      this.onCancel();
      return;
    }

    const updatedUser = new User();
    updatedUser.clone(toEdit);
    updatedUser.userGroup = this.userGroupCtrl.value;
    console.log('Updated fleet manager: ', updatedUser);
    this.editElement(updatedUser);
    this.observeActionResult();
  }

  public isLoggedInUser(user: User): boolean {
    const login = this.userDataSource.currentUserLogin;
    return user.login === login;
  }

  public readOnly(user: User): boolean {
    return Role.PCS_ADMIN !== user.role || this.isLoggedInUser(user);
  }

  public tooltip(user: User): string | undefined {
    if (user?.hasActiveChangeRequest) {
      return "usermanagement.users.tooltip.blocked-by-change-request";
    }
    return undefined;
  }

}

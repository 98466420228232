/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {HttpHeaders} from '@angular/common/http';
import {Parameters} from 'pcs-commons/global';

export abstract class HeaderEnabled {
  public headers: HttpHeaders;

  protected constructor() {
    // this is the default case. Please do change the header when it needs different configurations
    this.headers = this.defaultHeaders();
  }

  public headersWithDisabledErrorHandling(): HttpHeaders {
    return this.defaultHeaders().set(Parameters.HEADER_NO_ERROR_HANDLE, 'true');
  }

  public defaultHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    });
  }

}

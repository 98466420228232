/**
* This code is protected by intellectual property rights.
* Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
* © 2025 Dr. Ing. h.c. F. Porsche AG.
*/
import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {firstValueFrom, Observable, of} from 'rxjs';
import {SharedDataService} from '../../services/shared-data.service';
import {InvoiceService} from '../../services/http/invoice.service';
import {ReimbursementRequestDto} from '../../datatypes/invoices/ReimbursementRequestDto';
import {InputValidationService} from '../../services/input-validation.service';
import {
  ReimbursementBilledItemColumns,
  ReimbursementBilledItemDto
} from '../../datatypes/invoices/ReimbursementBilledItemDto';
import {Message} from '../../datatypes/message';
import {AbstractNotificationHandler} from 'pcs-commons/notification';
import {AuditService} from "../../services/audit.service";
import {ChangeRequestPayloadType} from "../../datatypes/inbox/change-request-payload-type";

@Component({
  selector: 'invoice-transaction-dialog',
  templateUrl: './invoice-transaction-dialog.component.html',
  styleUrls: ['../../dialog/dialog-common-styles.css',
    './invoice-transaction-dialog.component.css']
})
export class InvoiceTransactionDialogComponent extends AbstractNotificationHandler {
  public transactions: ReimbursementBilledItemDto[];
  public invoiceIdent: string;
  public id: number;
  public contractId: string;
  public fleetId: string;

  public dataColumns: string[] = Object.values(ReimbursementBilledItemColumns);

  public loading = false;

  public selectedInvoiceTransactions: ReimbursementBilledItemDto[] = [];

  constructor(
    private invoiceService: InvoiceService,
    private dialogRef: MatDialogRef<InvoiceTransactionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dataSource: SharedDataService,
    private inputValidationService: InputValidationService,
    private auditService: AuditService) {
    super();
    this.transactions = data.transactions;
    this.id = data.id;
    this.invoiceIdent = data.invoiceIdent;
    this.contractId = data.contractId;
    this.fleetId = data.fleetId;
    dataSource.selectedInvoiceTransactions$.subscribe((invoiceTransactions) =>
      this.selectedInvoiceTransactions = invoiceTransactions);
  }

  public observableOfTransactions(): Observable<ReimbursementBilledItemDto[]> {
    return of(this.transactions);
  }

  public onCancel(): void {
    this.dialogRef.close(false);
  }

  public allInputsValid(): boolean {
    return this.selectedInvoiceTransactions?.length >= 1;
  }

  public async onStart(): Promise<void> {
    this.dataSource.clearTransactionSelection(false);
    const requestDto = new ReimbursementRequestDto();


    requestDto.itemsToReimburse = this.selectedInvoiceTransactions;
    requestDto.contractId = this.contractId;
    requestDto.fleetId = this.fleetId;
    this.loading = true;

    try {

      await firstValueFrom(this.invoiceService.validateReimbursementProcess(this.id, requestDto));
      if (await this.auditService.objectHasActiveChangeRequest(ChangeRequestPayloadType.INVOICE, this.id)) {
        this.showError(new Message('audit.changeRequest.alreadyExists'));
      } else {
        const crCreated = await this.auditService.initiateChangeRequestForReimbursement(requestDto, this.id,
          this.invoiceIdent);

        if (crCreated) {
          this.showInfo(new Message('invoices.reimbursementStarted.successfully'));
          this.dataSource.clearTransactionSelection(true);
        } else {
          this.showError(new Message('invoices.reimbursementStarted.failed'));
        }
      }
    } finally {
      this.loading = false;
    }
  }
}

<!-- This code is protected by intellectual property rights. Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use. © 2025 Dr. Ing. h.c. F. Porsche AG. -->
<div id="cpAttributeImportResult" class="mat-elevation-z1">
  <mat-toolbar class="headerToolbar">
    <span class="headerSpan" *ngIf="!resetExecuted()">{{'cpAttributeImport.importResult' | translate }}</span>
    <span class="headerSpan" *ngIf="resetExecuted()">{{'cpAttributeImport.resetResult' | translate }}</span>
  </mat-toolbar>
  <div class="cpAttributeImportResultInfo">
    <span class="cpAttributeImportResultInfoText">
      {{
      'cpAttributeImport.importResult.info'| translate :{
        param0: successfullyImportedRecordsCount,
        param1: allRecordsCount
      }
      }}
    </span>
    <div></div>
    <span class="cpAttributeImportResultInfoText"
          *ngIf="(tableData$| async)?.length > 0">{{'cpAttributeImport.import.tableHeader'| translate}}</span>
  </div>
  <chargepoint-attribute-import-result-table id="cpAttributeImportResultTable" [data]="tableData$"
                                             *ngIf="(tableData$| async)?.length > 0"
                                             [i18nBase]="'cpAttributeImport.import.tableHeader'"
                                             [dataColumns]="cpAttributeImportResultTableColumns"
                                             [actionButtons]="false">
  </chargepoint-attribute-import-result-table>
</div>
<mat-divider style="height: 10px;"></mat-divider>
<button mat-raised-button class="normalButton back-button" (click)="onBack()">
  <mat-icon class="button-icon">arrow_back</mat-icon>
  <span>{{ 'button.back' | translate }}</span>
</button>
